import React, {useState} from 'react';
import SettingInformation from "../../components/setting/SettingInformation";
import Checkbox from "../../components/checkbox/Checkbox";
import {Trans, useTranslation, withTranslation} from "react-i18next";
import {settingService} from '../../services';
import {history} from '../../helper';
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from "../../helper/responseHandler";
import {commonConstants} from "../../constants";
import {connect} from "react-redux";
import {popupAction} from "../../actions";

const WithdrawMember = (props) => {
    const { addPopup, closePopup } = props;
    const {t} = useTranslation();

    const withdraw = () => {
        settingService.withdrawMyAccount().then(res => {
            addPopup({
                type: commonConstants.COMMON_ALERT_POPUP,
                id: 'WITHDRAW_CONFIRM_POPUP',
                title: t("COM_BUTTON_WITHDRAW"),
                message: t("MESSAGE_USER_SUCCESS_WITHDRAWAL_P"),
                width: 340,
                height: 120,
                buttonTitle : t("BUTTON_OK_P"),
                onClose: () => {
                    closePopup('WITHDRAW_CONFIRM_POPUP');
                    localStorage.removeItem('user');
                    history.push('/login');
                }
            });
    }).catch((e) => {
            toastr.error(getErrorMessage(e));
        });
    }
    
    const [buttonStatus, setButtonStatus] = useState(true);
    const changeButton = () => {
        let button = buttonStatus;
        setButtonStatus(!button);
    }
    return (
        <div className="settings_list mt20" style={{borderTop: '1px solid#f2f2f2', overflowY: 'auto', overflowX: 'hidden',display: props.currContent === 'WITHDRAW_MEMBERSHIP' ? 'block':'none'}}>
            <SettingInformation id={"WITHDRAW_MEMBERSHIP"} title={t("TEXT_TITLE_USER_WITHDRAWAL_P")}
            list={[
                {id: 'WITHDRAW_MEMBERSHIP_NOTICE_1', name: <Trans i18nKey={"MESSAGE_USER_NOTICE_WITHDRAWAL2_P"} />, value: ''},
                {id: 'WITHDRAW_MEMBERSHIP_NOTICE_2', name: <Trans i18nKey={"MESSAGE_USER_NOTICE_WITHDRAWAL3_P"} />, value: ''},
                {id: 'WITHDRAW_MEMBERSHIP_NOTICE_3', name: <Trans i18nKey={"MESSAGE_USER_NOTICE_WITHDRAWAL4_P"} />, value: ''},
                {id: 'WITHDRAW_MEMBERSHIP_NOTICE_check', name: <Checkbox id="user_withdraw_notice_confirm" name={t("MESSAGE_USER_NOTICE_CONFIRM_P")} onChange={()=>changeButton()}/>, value: ''},
            ]}
            buttons={[
                {id: 'buttonWithdrawal', name: <Trans i18nKey={"COM_BUTTON_WITHDRAW"} />, action: () => withdraw(),  disable: buttonStatus }
            ]}
            />
        </div>
    )
}
export default connect(
    null,
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)(withTranslation()(WithdrawMember));