import React from 'react';
import MenuButton from "./MenuButton";
import './Menu.css';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import LOGO from '../../images/img/logo.png';
import {history} from "../../helper";

const Menu = () => {

    const {t} = useTranslation();

    const {tabs, menus} = useSelector(state =>  ({
        menus: state.menu.menus,
        tabs: state.menu.tabs
    }))
    const tab = tabs != null && tabs.find(tab=>tab.active === true);
    const moveMain = () => {
        history.go('/');
    }

    const getIcon = (id) => {
        switch (id) {
            case 'CONTENT':
                return 'gnb01'
            case 'RULESET' :
                return 'gnb10';
            case 'PLAYLIST':
                return 'gnb02';
            case 'SCHEDULE':
                return 'gnb03';
            case 'DEVICE':
                return 'gnb04';
            case 'STATISTICS':
                return 'gnb05';
            case 'USER':
                return 'gnb06';
            case 'SETTING':
                return 'gnb07';
            case 'INSIGHT':
                return 'gnb11';
            case 'RULE_MANAGER':
                return 'gnb09';
        }
    }

    const getTitle = (id) => {
        switch (id) {
            case 'CONTENT':
                return 'TEXT_TITLE_CONTENT_P'
            case 'RULESET' :
                return 'MIS_SID_RULESET';
            case 'PLAYLIST':
                return 'TEXT_TITLE_PLAYLIST_P';
            case 'SCHEDULE':
                return 'TEXT_TITLE_SCHEDULE_P';
            case 'DEVICE':
                return 'TEXT_TITLE_DEVICE_P';
            case 'STATISTICS':
                return 'COM_TEXT_TITLE_STATISTICS_P';
            case 'USER':
                return 'TEXT_TITLE_USER_P';
            case 'SETTING':
                return 'COM_TEXT_SETTING_P';
            case 'INSIGHT':
                return 'MIS_SID_INSIGHT';
            case 'RULE_MANAGER':
                return 'MIS_SID_SERVER_ANALYTICS';
        }
    }

    return (
        <div className="gnb">
            <span className="logo" onClick={moveMain} style={{cursor: 'pointer'}}>
                <img src={LOGO} alt={"MagicInfo"}/>
            </span>
            <ul style={{marginTop: 61}}>
                {
                    menus !== undefined && menus.length > 0 && menus.map(
                        (menu) => menu.enable ? <MenuButton key={menu.id} id={menu.id} title={t(getTitle(menu.id))} icon={getIcon(menu.id)} active={tab != null && tab.id === menu.id}/> : ''
                    )
                }
            </ul>
        </div>
    )
}
export default Menu