import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {menuAction, popupAction, userActions} from "../../../actions";
import {useDispatch} from "react-redux";
import {dashboardService, userService} from "../../../services";
import {commonConstants, authServerConstants} from "../../../constants";
import './LogoutPopup.css';
import {Loading} from "../../loading/Loading";
import {useOutsideClick, toMomentDateTimeFormat, createQuery} from "../../../helper";
import WhiteButton from "../../../components/button/WhiteButton";
import FilterImage from '../../../images/bg/filter_arrow.png';
import Moment from "react-moment";
import {useMISOpt} from '../../misopt';
import {isNil} from 'lodash';
import {toastr} from '../../../helper/toastrIntercept'
import {authServerUtil} from '../../../helper/authserver/authServerUtil';

const LogoutPopup = () => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();

    const [userInfo, setUserInfo] = useState({});
    const [logging, setLogging] = useState(true);
    const ref = useRef();

    const buttons = [
        {id: 'LOGOUT_MY_USER_INFO', title: t("COM_TV_SID_MY_ACCOUNT"), onClick: ()=>goToUserInfo()},
        {id: 'LOGOUT_BUTTON', title: t("COM_IDWS_MOIP_POPUP_EXIT_SIGN_OUT"), onClick: ()=>logout()}
    ];

    const logout = () => {
        new Promise((resolve) => {
            closePopup();
            resolve();
        }).then(() => {
            if(!authServerUtil.isAuthServerUsed()) {
                dispatch(userActions.logout());
                return;
            }
            let authLogoutUrl = authServerUtil.getAuthServerExitUrl();
            if(isNil(authLogoutUrl)) {
                toastr.error(t('MESSAGE_COMMON_AUTH_SERVER_LOGOUT_ERROR_P'));
                return;
            }
            if(authServerUtil.isAuthServerAdminLogin()) {
                const param = {[authServerConstants.ADMIN_LOGOUT] : true};
                authLogoutUrl = authLogoutUrl + '?' + createQuery(param);
            }
            userService.deleteToken();
            localStorage.removeItem('user');
            authServerUtil.removeAuthServerInfo();
            window.location.assign(authLogoutUrl);
        })
    }

    const goToUserInfo = () => {
        closePopup();
        dispatch(menuAction.addTab({id: 'SETTING', title : t("COM_TEXT_SETTING_P"), active: true, close: true}));
    }

    const closePopup = () => {
        dispatch(popupAction.closePopup(commonConstants.LOGOUT_POPUP));
    }

    const getFormatDate = (date) => {
        const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
        return (
            <Moment format={dateTimeFormat}>{date}</Moment>
        )
    }

    useOutsideClick(ref, ()=> {
        closePopup();
    })

    useEffect(() => {
        dashboardService.fetchMyInfo()
            .then(
                res=> {
                    setUserInfo({...res.items});
                }
            ).finally(()=>setLogging(false))
    }, [])

    return (
        <div className={'logout_popup'}>
            {
                <>
                    <div className={'dim'}></div>
                    <div className="user_info_wrap" style={{zIndex: 102}} ref={ref}>
                        <div>
                            <span>
                                <img src={FilterImage}/>
                            </span>
                            <h3>{t("TEXT_TITLE_USER_INFORMATION_VIEW_P")}</h3>
                            
                            <div className="clearfix">
                                {
                                    logging &&
                                    <div style={{margin: "80px 130px"}}><Loading /></div>
                                }
                                {
                                    !logging && 
                                    <>
                                    <div className="user_thum"><span>{t("COM_TEXT_LOGIN_ID_P")}<span>{userInfo.userId}</span></span></div>
                                    <div className="user_detail">
                                        <ul>
                                            <li><span>{t("COM_TEXT_USER_NAME_P")}<span>{userInfo.userName}</span></span></li>
                                            <li><span>{t("COM_TEXT_ROLE_P")}<span>{userInfo.role}</span></span></li>
                                            <li><span>{t("TEXT_LAST_LOGIN_DATE_P")}<span>{getFormatDate(userInfo.lastSignInDate)}</span></span></li>
                                        </ul>
                                    </div>
                                    </>
                                }
                            </div>
                            <div className={"mis_dialog_buttons_wrap"} style={{height: 50, lineHeight: '40px', textAlign: 'center', borderTop: '0px'}}>
                                <div>
                                    {
                                        buttons &&
                                        buttons.map(
                                            button =>
                                                button.hide ? null : <WhiteButton key={button.id} name={button.title} id={button.id} onClick={button.onClick} disable={button.disable}/>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </>
            }
        </div>
    )
}
export default LogoutPopup;