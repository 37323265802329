import React, {useEffect, useState,} from 'react';
import {playlistEffectList} from '../../constants/PlaylistConstants';
import {useTranslation} from "react-i18next";
import Select from "../../components/selectbox/Select";
import TextInput from "../../components/input/TextInput";
import {APLAYER} from "../../constants/DevicesConstants";

const IPLAYER = 'IPLAYER';

const directionSelect = [{ value: '1', title: 'E'}, {value: '3', title: 'W'}, {value: '2', title: 'S'}, {title: 'N', value: '0'}];
const divisionSelect = [{ value: '1', title: '1'}, {value: '2', title: '2'}];
const div4dSelect = [{ value: '-1', title: '-'}, {value: '1', title: 'NE'}, {value: '3', title: 'SE'}, {title: 'SW', value: '5'}, {title: 'NW', value: "7"}];
const div4SelectForVideoWall = [{ value: '1', title: 'E'}, {value: '3', title: 'W'}, {value: '2', title: 'S'}, {title: 'N', value: '0'}];
const div4Select = [{ value: '-1', title: '-'}, { value: '1', title: 'E'}, {value: '3', title: 'W'}, {value: '2', title: 'S'}, {title: 'N', value: '0'}];
const div8dSelect =[{ value: '-1', title: '-'}, { value: '2', title: 'E'}, {value: '6', title: 'W'}, {value: '4', title: 'S'}, {title: 'N', value: '0'}, {title: 'NE', value: '1'}, {title: 'SE', value: '3'}, {title: 'SW', value: '5'}, {title: 'NW', value: '7'}];
const div3Select =  [{ value: '0', title: 'X'}, {value: '1', title: 'Y'}, {value: '2', title: 'Z'}];

const PlaylistEffect = ({deviceType, playlistType, mode, arrowClick, order, effects,outSide}) => {
	const [effectItem, setEffectItem] = useState(effects);
	const [effectDirectionInList, setEffectDirectionInList] = useState([{value:'-1', title:'-'}]);
	const [effectDirectionIn, setEffectDirectionIn] = useState(effects.inDirection);
	const [effectDirectionOutList, setEffectDirectionOutList] = useState([{value:'-1', title:'-'}]);
	const [effectDirectionOut, setEffectDirectionOut] = useState(effects.outDirection);
	const [effectDurationIn, setEffectDurationIn] = useState(effects.inDuration);
	const [effectDurationOut, setEffectDurationOut] = useState(effects.outDuration);
	const [effectInDelayDirection, setEffectInDelayDirection] = useState(effects.inDelayDirection);
	const [effectOutDelayDirection, setEffectOutDelayDirection] = useState(effects.outDelayDirection);
	const [effectInDelayDuration, setEffectInDelayDuration] = useState(effects.inDelayDuration);
	const [effectOutDelayDuration, setEffectOutDelayDuration] = useState(effects.outDelayDuration);
	const [effectInDelayDivision, setEffectInDelayDivision] = useState(effects.inDelayDiv);
	const [effectOutDelayDivision, setEffectOutDelayDivision] = useState(effects.outDelayDiv);
	const [effectInDivisionStyle, setEffectInDivisionStyle] = useState({display: 'none'});
	const [effectOutDivisionStyle, setEffectOutDivisionStyle] = useState({display: 'none'});

	useEffect(() => {
		if (effects.inName === playlistEffectList.TYPE3[5].id) {
			setEffectInDivisionStyle({display:'table-row'});
		} else if (effects.outName === playlistEffectList.TYPE3[5].id) {
			setEffectOutDivisionStyle({display:'table-row'});
		} else {
			setEffectInDivisionStyle({display:'none'});
			setEffectOutDivisionStyle({display:'none'});
		}
		setEffectItem(effects);
		setEffectDirectionIn(effects.inDirection);
		if (effects.inDirection !== "-1") {
			const directionStyle = getEffectDirectionStyle(effects.inName);
			setEffectDirectionInList(directionStyle)
		}
		if (effects.outDirection !== "-1") {
			const directionStyle = getEffectDirectionStyle(effects.outName);
			setEffectDirectionOutList(directionStyle)
		}
		setEffectDurationIn(effects.inDuration);
		setEffectDirectionOut(effects.outDirection);
		setEffectDurationOut(effects.outDuration);
		setEffectInDelayDirection(effects.inDelayDirection);
		setEffectOutDelayDirection(effects.outDelayDirection);
		setEffectInDelayDuration(effects.inDelayDuration);
		setEffectOutDelayDuration(effects.outDelayDuration);
		setEffectInDelayDivision(effects.inDelayDiv);
		setEffectOutDelayDivision(effects.outDelayDiv);

	}, [effects]);

	const {t} = useTranslation();

	const needEffectOut = () => {
		return (deviceType.toUpperCase() == IPLAYER || deviceType == APLAYER);
	};

	const renderEffectLi =  (effectMode, item) => {
		let classOn = '';
		if (effectMode === 'IN' && effects.inName && (effects.inName === item.id)) {
			classOn = 'on';
		} else if (effectMode === 'IN' && !effects.inName && item.effectName === 'none') {
			classOn = 'on';
		} else if (effectMode === 'OUT' && effects.outName && (effects.outName === item.id)) {
			classOn = 'on';
		} else if (effectMode === 'OUT' && !effects.outName && item.effectName === 'none') {
			classOn = 'on';
		}

		return <li id={item.id} onClick={()=> {
			const selectedEffectItem = {...effectItem};
				effectMode === 'IN' ? selectedEffectItem.inName = item.id : selectedEffectItem.outName = item.id;
				if (item.id === 'Ext_Eff_Reveal2::Blocks') {
					if (effectMode === 'IN') {
						setEffectInDivisionStyle({display:'table-row'});
					} else {
						setEffectOutDivisionStyle({display:'table-row'});
					}
				} else {
					if (effectMode === 'IN') {
						setEffectInDivisionStyle({display:'none'});
					} else {
						setEffectOutDivisionStyle({display:'none'});
					}
				}
				if (selectedEffectItem.inName === 'none') selectedEffectItem.inName = '';
				if (selectedEffectItem.outName === 'none') selectedEffectItem.outName = '';

				const directionStyle = getEffectDirectionStyle(item.id);

				if (effectMode === 'IN') {
					setEffectDirectionInList(directionStyle)
					arrowClick({...selectedEffectItem, inDirection: directionStyle[0].value}, order, mode)
				} else {
					setEffectDirectionOutList(directionStyle);
					arrowClick({...selectedEffectItem, outDirection: directionStyle[0].value}, order, mode)
				}

			}} className={classOn}>
			<img src={item.imgSrc} /><span className={"hover_cover"}>{item.effectName}</span></li>;
	};

	const renderEffectList = (effectMode) => {
		if (deviceType.toUpperCase() === IPLAYER && playlistType !== '2' ) {
			return (
				playlistEffectList.TYPE1.map((item) => {
					return renderEffectLi(effectMode, item)
				})
			)
		} else if (deviceType == APLAYER) {
			return (
				playlistEffectList.TYPE2.map((item) => {
					return renderEffectLi(effectMode, item)
				})
			)
		} else if ((deviceType.toUpperCase() == IPLAYER || deviceType == APLAYER) && playlistType == "2") {
			return (
				playlistEffectList.TYPE3.map((item) => {
					return renderEffectLi(effectMode, item)
				})
			)
		} else {
			return (
				playlistEffectList.TYPE4.map((item) => {
					return renderEffectLi(effectMode, item)
				})
			)
		}
	}

	const renderPlaylistEffect = (effectMode) => {
		return (
			<div className={"transition_wrap bot_wrap"} style={{
				zIndex:5,
				marginBottom: effectMode === 'IN' && needEffectOut() ? 110 : 0,
				bottom: 90,
				paddingLeft: 30,
				width: 'auto',
				marginRight: 36
			}}>
				<div className="transition_option">
					<table>
						<tbody>
						<tr>
							<td style={{whiteSpace:'initial'}}><span><strong>{
								effectMode === 'IN' ? t("TEXT_EFFECT_IN_P") : t("TEXT_EFFECT_OUT_P")
							}</strong></span></td>
						</tr>
						{
							(deviceType.toUpperCase() === IPLAYER || deviceType.toUpperCase() === APLAYER) &&
							<tr>
								<td>
									<div className="menu_select_box mr8">
										<Select width={80}
												value={effectMode === 'IN' ? effectDirectionIn : effectDirectionOut}
												selects={effectMode === 'IN' ? effectDirectionInList: effectDirectionOutList}
												optionStyle={{zIndex: 100, display: 'block'}}
												ulStyle={{height: 65, overflowY: 'auto'}}
												disabled={effectMode === 'IN' ? (effectDirectionInList.length > 1 ? false: true) : effectDirectionOutList.length > 1 ? false: true}
												onChange={effectMode === 'IN' ? (e, value) => {
													const selectedEffectItem = {...effectItem, inDirection: value};
													arrowClick(selectedEffectItem, order, mode);
												}: (e,value) => {
													const selectedEffectItem = {...effectItem, outDirection: value};
													arrowClick(selectedEffectItem, order, mode);
												}} />
									</div>
								</td>
							</tr>
						}
						{
							(deviceType.toUpperCase() === IPLAYER || deviceType.toUpperCase() === APLAYER) &&
							<tr>
								<td>
									<TextInput width={50} type={"text"}
										className={"light_gray ta_c"}
										value={effectMode === 'IN' ? effectDurationIn : effectDurationOut}
										onChange={(e) => {
											if (effectMode === 'IN') {
												arrowClick({...effectItem, inDuration: e.target.value}, order, mode);
											} else {
												arrowClick({...effectItem, outDuration: e.target.value}, order, mode);
											}
										}}
									>
									<span className="space">{t("COM_SID_SEC")}</span>
									</TextInput>
								</td>
							</tr>
						}
						</tbody>
					</table>
				</div>
				{
					playlistType === '2' && deviceType.toUpperCase() === IPLAYER &&
					<div className={"transition_delay_option"} style={{left:130,width:200}}>
						<table>
							<tbody>
							<tr>
								<td colSpan="2"></td>
							</tr>
							<tr>
								<td><span>{t("MIS_SID_DELAY_DIRECTION")}</span></td>
								<td>
									<div className="select_box mr8">
										<Select width={80}
												value={effectMode === 'IN' ? effectInDelayDirection : effectOutDelayDirection}
												selects={directionSelect}
												optionStyle={{zIndex: 100, display: 'block'}}
												ulStyle={{height: 65, overflowY: 'auto'}}
												onChange={effectMode === 'IN' ? (e, value) => {
													arrowClick({...effectItem, inDelayDirection: value}, order, mode)
												}: (e,value) => {
													arrowClick({...effectItem, outDelayDirection: value}, order, mode)
												}} />
									</div>
								</td>
							</tr>
							<tr>
								<td><span>{t("MIS_SID_DELAY_DURATION")}</span></td>
								<td>
									<TextInput width={50} type={"text"}
											   className={"light_gray ta_c"}
											   value={effectMode === 'IN' ? effectInDelayDuration : effectOutDelayDuration}
											   onChange={(e) => {
												   effectMode === 'IN'?  arrowClick({...effectItem, inDelayDuration: e.target.value}, order, mode) : arrowClick({...effectItem, outDelayDuration: e.target.value}, order, mode)
											   }}
									><span className="space">{t("COM_SID_SEC")}</span>
									</TextInput>
								</td>
							</tr>
							<tr className={"effect_division"} style={effectMode === 'IN'? effectInDivisionStyle: effectOutDivisionStyle}>
								<td><span>{t("MIS_SID_20_DIVISION")}</span></td>
								<td>
									<div className="select_box mr8">
										<Select width={80}
												value={effectMode === 'IN' ? effectInDelayDivision : effectOutDelayDivision}
												selects={divisionSelect}
												optionStyle={{zIndex: 100, height: 50, top: 0, }}
												ulStyle={{height: 50, overflowY: 'auto', position: 'relative', top: 0}}
												onChange={effectMode === 'IN' ? (e, value) => {
													arrowClick({...effectItem, effectInDelayDivision:value}, order, mode)
												}: (e,value) => {
													arrowClick({...effectItem, effectOutDelayDivision:value}, order, mode)
												}} />
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				}
				<div className={"transition"} style={{overflow:'auto', width: 'auto', left: (playlistType === '2' && deviceType.toUpperCase() === IPLAYER) ? 300: 96}}>
					<ul style={{width:'max-content'}}>
						{
							renderEffectList(effectMode)
						}
					</ul>
				</div>
			</div>
		)
	};

	const getEffectDirectionStyle = (effectId) => {
		let e_name = effectId.split("::")[1];
		let dir4 = new Array( "StraightThrough", "ScrollClosed", "SlatsCrash", "RollOn", "DoubleRollOn", "UnrollStripts", "Clasp",
			"DoubleSlam", "DoubleVision", "PushMe", "PushMoreStrips", "PushStrips", "PushMoreWave", "ScaleIn",
			"ScrollDivide", "ScrollIn", "SlatsTogether2", "SlatsTogether4", "SlatsClosed8", "Slam", "Slicer",
			"SweepSlicer", "Trislide", "TrislideLR", "Wipe", "Split", "SmallBlinds", "Striper",
			"BasketWeave", "BlindsFantasy1", "BlindsFantasy2", "BlindsFantasy3", "ClosingBlinds", "Closure",
			"Curtain", "DiagonalStrips", "MiniQuadrant", "PaintDrip", "Peel", "PremiereReveal", "ScaleTheWall",
			"SmoothWall", "SplitBlinds", "Stacker", "Stripes", "SuperStripper", "WideBlinds", "XmasTree",
			"ZoomSpiral", "BouncingBlinds", "FlipFour", "MiddleStretch", "OurSponsor", "Premiere", "PremiereEdges",
			"QuatroFlipCoin", "RandomGrowth", "Recollection", "SlideStretch", "SlimZoom", "Stretch", "StripFoldOut",
			"Troika");
		let dir8 = new Array( "Straight", "Sine", "Wiggle", "DropWave", "GlideWave", "Crescent", "Loop", "Boomerang", "Damped",
			"FadeWave", "FadeLoop", "FlyFade", "FlyZoom", "FlyZoomWave", "FlyZoomX", "FlyZoomY", "Rub", "MirrorMazeRing",
			"NewImproved");

		let dir4d = new Array("Cornor", "CornerSlice", "DiagonalBlocks", "SpiralBlock", "ArcIn", "CornerStretch", "CornerZoom",
			"DiagonalZooms", "DualZoom", "QuarterBounceOn", "SwimIn");

		let dir3 = new Array("AMSpinFade"); //For Vwl

		let flag = true;
		let rtn  = [{value:'-1', title:'-'}];
		let i=0;

		for (i=0;i<dir4d.length;i++){
			if(dir4d[i] == e_name){
				flag = false;
				rtn = div4dSelect;
				break;
			}
		}
		if (flag){
			for(i=0;i<dir4.length;i++){
				if(dir4[i] == e_name){
					flag = false;
					if(playlistType!=null && (playlistType == '2' && (deviceType.toUpperCase() == IPLAYER || deviceType.toUpperCase() == APLAYER))){
						rtn = div4SelectForVideoWall;
					}else{
						rtn = div4Select;
					}
					break;
				}
			}
		}
		if(flag){
			for(i=0;i<dir8.length;i++){
				if(dir8[i] == e_name){
					rtn = div8dSelect;
					break;
				}
			}
		}

		if(flag){
			for(i=0;i<dir3.length;i++){
				if(dir3[i] == e_name){
					rtn = div3Select;
					break;
				}
			}
		}

		return rtn;
	};

	return (
		<div ref={outSide} style={{position:'relative', left: -30, top: 90}}>
			{
				renderPlaylistEffect('IN')
			}
			{
				needEffectOut() && renderPlaylistEffect('OUT')
			}
		</div>
	)

};
export default PlaylistEffect;