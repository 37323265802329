import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {popupAction} from "../../../actions";
import {useTranslation} from "react-i18next";
import SelectSupportedDevice from "../../utils/SelectSupportedDevice";
import MISDialog from "../MISDialog";
import {getMixString} from "../../../language/languageUtils";
import {commonConstants} from "../../../constants";
import {toastr} from 'helper/toastrIntercept';
import './CreateRulesetPopup.css';
import TooltipIcon from "../../icon/TooltipIcon";

const CreatePlayPopup = ({ save, mode, close }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [selectedSupportDevice, setSelectedSupportDevice] = useState({
        devices: [],
        selected: []
    })

    const closePopup = () => {
        dispatch(popupAction.closePopup(commonConstants.CREATE_PLAY_POPUP));
    };
    



    const selectedDevice = (devices, selected) => {
        setSelectedSupportDevice({devices: devices, selected: selected});
    }

    const createPlay = () => {
        const { devices, selected } = selectedSupportDevice;
        if ((devices !== undefined && devices.length > 0) && (selected !== undefined && selected.length > 0)) {
            const maxPriorityIndex = Math.max(...selected);
            const device = devices[maxPriorityIndex];
            dispatch(
                popupAction.addPopup({
                    id: commonConstants.RULESET_PLAY_POPUP,
                    type: commonConstants.RULESET_PLAY_POPUP,
                    mode: mode,
                    isLibrary : true,
                    device: device,
                    save: save,
                    close: close
                })
            );
            closePopup();
        }else {
            toastr.error(t("MESSAGE_DEVICE_SELECT_DEVICE_GROUP_P"))
        }
    };

    const dialogProps = {
        title: getMixString(["MIS_SID_MIX_NEW", "MIS_SID_PLAY_RULE"]),
        closeOnEscape: true,
        width: 782,
        height: 100,
        position: { x: -300, y: -400 },
        modal: true,
        onClose: () => closePopup()
    };

    return (
        <MISDialog
            dialog={dialogProps}
            buttons= {{
                rightButtons: [
                    {title: t("COM_TEXT_CREATE_P"), id: 'createNewRulesetBtn', onClick: () => createPlay()},
                    {title: t("BUTTON_CANCEL_P"), id: 'cancelNewRulestBtn', onClick: ()=> closePopup()}
                ]
            }}>

            <div className="create_ruleset_popup_wrap">
            <div className="quest_img_noti">
                {t("COM_MAPP_SID_SUPPORTED_DEVICES")}
                <TooltipIcon message={"MIS_SID_20_WHEN_YOU_SELECT_A_PLAYER_ALL_HIGHER_VERSIONS_ARE_AUTOMATICALLY_SELECTED"}/>
            </div>
                <div
                    className="device_type_btn mt15"
                    style={{ display: "inline-block" }}
                >
                    <SelectSupportedDevice
                        selectedDevice={selectedDevice}
                        type={"ruleset"}
                    />
                </div>
                <span
                    className="error_txt2"
                    id="supportedDeviceErrorMsg"
                ></span>
            </div>
        </MISDialog>
    );
};

export default CreatePlayPopup;
