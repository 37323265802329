import React, {useState} from 'react';
import MISDialog from '../../MISDialog';
import {useDispatch} from "react-redux";
import {useTranslation} from 'react-i18next';
import {popupAction} from '../../../../actions';
import EdgeInformation from './EdgeInformation';
import {settingConstants} from "../../../../constants";
import {settingService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept'
import SubTab from "../../../tab/SubTab";
import EditSyncConfiguration from "./EditSyncConfiguration";
import EditAutoStorageManagement from "./EditAutoStorageManagement";
import {getErrorMessage} from "../../../../helper/responseHandler";
import {validateNumbers} from "../../../../helper";
import {isEmpty} from "lodash";

const EdgeServerPopup = ({onClose, edgeInfo, masterList,  setItemsForRefresh, index}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const edgeDetailSubTabs = [
        "MIS_SID_CAFEB_SERVER_INFORMATION",
        "MIS_SID_CAFEB_SYNC_CONFIGURATION",
        "MIS_SID_AUTO_STORAGE_MANAGEMENT"
    ];
    const [subTab, setSubTab] = useState(edgeDetailSubTabs[0]);
    const originalHostName = edgeInfo.hostName;
    const [updatedEdge, setUpdatedEdge] = useState(edgeInfo);
    const [nameChecked, setNameChecked] = useState(false);

    const onSaveEdge = async ({updatedEdge}) => {

        if(isEmpty(updatedEdge.hostName)) {
            toastr.error(t('MIS_MESSAGE_SETUP_ENTER_SERVERNAME_P'));
            return;
        }

        if((originalHostName !== updatedEdge.hostName) && !nameChecked){
            toastr.error(t('MIS_TEXT_SETUP_ASK_CHECK_SERVERNAME_P'));
            return;
        }

        let storageLimitByGBTest = validateNumbers(updatedEdge.storageLimitByGB);
        if(!storageLimitByGBTest.isValid){
            toastr.error(t(storageLimitByGBTest.messageId));
            return;
        }

        let syncPeriodTest = validateNumbers(parseInt(updatedEdge.syncPeriod));
        if(!syncPeriodTest.isValid){
            toastr.error(t(syncPeriodTest.messageId));
            return;
        }

        if(updatedEdge.syncPeriod < 0 || updatedEdge.syncPeriod > 60){
            toastr.error(t('COM_TV_SID_MIX_INVALID_VALUE_CHECK_AGAIN').replace('<<A>>', '1~60'));
            return;
        }


        if(updatedEdge.storageLimitByGB < 0 || updatedEdge.storageLimitByGB > 999999999){
            toastr.error(t('COM_TV_SID_MIX_INVALID_VALUE_CHECK_AGAIN').replace('<<A>>', t('MIS_SID_STORAGE_SPACE')));
            return;
        }

        if(!updatedEdge.master &&
            (updatedEdge.connectedMasterUuid===updatedEdge.uuid|| updatedEdge.connectedMasterUuid === '')){
            toastr.error(t('MIS_SID_SELECT_MASTER_SERVER'));
            return;
        }

        if(updatedEdge.master === true){
            updatedEdge.connectedMasterUuid = updatedEdge.uuid;
        }

        if(originalHostName === '-' && updatedEdge.hostName !== '-'){
            updatedEdge.status = 'REGISTRATION_COMPLETE';
        }

        await settingService.editEdge(updatedEdge)
            .then(res=>{
                toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                setItemsForRefresh();
            })
            .catch(err => {
                toastr.error(getErrorMessage(err, 'MESSAGE_SCHEDULE_DATA_SAVE_FAILED_P'));
            });

        await dispatch(popupAction.closePopup(settingConstants.EDIT_EDGE_POPUP));
    };

    const setEdgeInfo = (key, value) => {
        setUpdatedEdge({
            ...updatedEdge,
            [key]: value
        });
    };

    const setEdgeInfo2 = (key1, value1, key2, value2) => {
        setUpdatedEdge({
            ...updatedEdge,
            [key1]: value1,
            [key2]: value2,
        });
    };

    const overwriteInfo = obj => {
        setUpdatedEdge({
            ...obj
        });
    };

    return(
        <MISDialog
            dialog={{
                title: edgeInfo.hostName,
                modal: true,
                closeOnEscape: true,
                width: 595,
                height: 420,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: ()=> onSaveEdge({updatedEdge}),
                        disable: false
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose
                    }
                ]
            }}>

            <div>
                <SubTab tabs={edgeDetailSubTabs} selected={subTab} onClick={id=>setSubTab(id)} viewSize={3}/>
                {subTab === edgeDetailSubTabs[0] &&
                    <EdgeInformation edgeInfo={updatedEdge} setEdgeInfo={setEdgeInfo} originalHostName={edgeInfo.hostName} masterList={masterList} overwriteInfo={overwriteInfo} nameChecked={nameChecked} setNameChecked={setNameChecked}/>}
                {subTab === edgeDetailSubTabs[1] &&
                    <EditSyncConfiguration edgeInfo={updatedEdge} setEdgeInfo={setEdgeInfo} setEdgeInfo2={setEdgeInfo2}/>}
                {subTab === edgeDetailSubTabs[2] &&
                    <EditAutoStorageManagement edgeInfo={updatedEdge} setEdgeInfo={setEdgeInfo}/>}
            </div>

        </MISDialog>
    )
};

export default EdgeServerPopup;