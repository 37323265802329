import React, {useState, useRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useMISOpt} from '../misopt';
import WhiteButton from '../button/WhiteButton';
import PlayerIcon from '../icon/players';
import Status3 from '../../images/icon/status3.png';
import Status6 from '../../images/icon/status6.png';
import Status7 from '../../images/icon/status7.png';
import Status8 from '../../images/icon/status8.png';
import Status9 from '../../images/icon/status9.png';
import Status14 from '../../images/icon/status14.png';
import Status15 from '../../images/icon/status15.png';
import Status16 from '../../images/icon/status16.png';
import BackupIcon from '../../images/icon/backup.png';
import PresetIcon from '../../images/icon/icon_preset.png';
import './DeviceInfoButton.css';
import { LEDBOX, SIGNAGE } from '../../constants';

const iconButtonStyle = {
    minWidth: '62px',
    border: '0px', 
    float: 'left', 
};

const DeviceInfoPoper = ({onBlur}) => {
    const poperRef = useRef();
    const {t} = useTranslation();
    const {misopt: {devices}} = useMISOpt();

    useEffect(() => {
        poperRef.current.focus();
    }, []);

    return (
        <div className="device_info_poper_wrap info" tabIndex={-1} ref={poperRef} onBlur={onBlur} >
            <div>
                <div className="clearfix">
                    <table>
                        <colgroup>
                            <col width="170px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            {
                                devices.filter(device => device.deviceType !== LEDBOX && device.deviceType !== SIGNAGE)
                                    .map(device => (
                                        <tr key={device.deviceFullType}>
                                            <th><PlayerIcon deviceType={device.deviceType} deviceTypeVersion={device.deviceTypeVersion} text /></th>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                    <div className="underline"></div>
                    <table>
                        <colgroup>
                            <col width="170px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><img src={Status3} className="mr4 va_btm"/>{t('COM_DID_RMS_DEVICE_VIDEO_WALL')}</th>
                            </tr>
{/*                            <tr>
                                <th><img src={Status16} className="mr2" style={{verticalAlign: 'bottom'}}/>{t('COM_LIST_TITLE_AUDIENCE_MEASUREMENT_KR_AUDIENCE')}</th>
                            </tr>*/}
                            <tr>
                                <th><img src={BackupIcon} width="16px" height="16px" className="mr4 va_btm"/>{t('MIS_TEXT_DEVICE_BACKUPPLAYER_P')}</th>
                            </tr>
                        </tbody>
                    </table>
                    <div className="underline"></div>
                    <table>
                        <colgroup>
                            <col width="170px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><img src={Status6} className="mr4 va_btm"/>{t('MIS_SID_20_HARDWARE_ERROR')}</th>
                            </tr>
                            <tr>
                                <th><img src={Status7} className="mr4" style={{verticalAlign: 'bottom'}}/>{t('TABLE_ALARM_P')}</th>
                            </tr>
                        </tbody>
                    </table>
                    <div className="underline"></div>
                    <table>
                        <colgroup>
                            <col width="170px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><img src={Status8} className="mr4 va_btm"/>{t('MIS_SID_20_DOWNLOADING_CONTENT')}</th>
                            </tr>
                            <tr>
                                <th><img src={Status15} className="mr4 va_btm"/>{t('MIS_SID_20_COLLECTING_LOGS')}</th>
                            </tr>
                            <tr>
                                <th><img src={Status9} className="mr4 va_btm"/>{t('MIS_SID_20_UPDATING_SOFTWARE')}</th>
                            </tr>
                            <tr>
                                <th><img src={Status14} width="16px" height="16px" className="mr4 va_btm"/>{t('COM_IDS_MEMO')}</th>
                            </tr>
                            <tr>
                                <th><img src={PresetIcon} width="16px" height="16px" className="mr4 va_btm"/>{t('MIS_SID_PRESET_MANAGEMENT')}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const DeviceInfoButton = ({buttonStyle}) => {
    const {t} = useTranslation();
    const [showPoper, setShowPoper] = useState(false);
    const style = Object.assign({}, iconButtonStyle, buttonStyle);

    return (
        <>
            <WhiteButton id='DEVICE_ICON' name={
                <span className='question_icon_wrap' onMouseDown={() => setShowPoper(!showPoper)}>
                    <span>{t('MIS_SID_ICON')}</span>
                    <span className='question_icon'/>
                </span>
            } style={style} />
            {
                showPoper && <DeviceInfoPoper onBlur={() => setShowPoper(false)} />
            }
        </>
    );
};

export default DeviceInfoButton;