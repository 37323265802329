import React from 'react';
import {connect} from "react-redux";
import classNames from 'classnames';
import {withTranslation} from "react-i18next";
import {toastr} from 'helper/toastrIntercept';
import {toUpper, toInteger} from 'lodash';
import {deviceService} from "../../../../services";
import {deviceAction, menuAction, popupAction} from "../../../../actions";
import {commonConstants, DEVICE_DETAIL_SUB_TABS, LEDBOX, LPLAYER, RLEDBOX} from "../../../../constants";
import MISDialog from '../../MISDialog';
import Checkbox from "../../../checkbox/Checkbox";
import './DeviceDetailView.css';
import SubTab from '../../../tab/SubTab';
import DeviceInfoDetail from './DeviceInfoDetail';
import PlayingContentDetail from './PlayingContentDetail';
import ContentDownloadStatusDetail from './ContentDownloadStatusDetail';
import SystemUsageDetail from './SystemUsageDetail';
import DeviceHealthDetail from './DeviceHealthDetail';
import TagDetail from './TagDetail';
import LogDataDetail from './LogDataDetail';
import withMISOpt from '../../../misopt';
import PlayerIcon from '../../../icon/players';
import DevicePowerIcon from '../../../device/DevicePowerIcon';
import ConnectedDeviceDetail from './ConnectedDeviceDetail';
import PowerThumb from '../../../../images/img/thumb_img_power.png';
import RMServerOpener from '../../../device/RMServerOpener';
import {getImageUrls} from '../../../device/Device';
import handleRemoteControl from '../../../device/RemoteControlHandler';

const DetailTitle = ({device}) => {
    return (
        <>
            <DevicePowerIcon power={device.power} />
            <PlayerIcon text={false} deviceType={device.deviceType} deviceTypeVersion={parseInt(device.deviceTypeVersion)} />
            {device.deviceName}
        </>
    );
};

// var buttons = [,];
// 	if(canWriteDevice){
// 		buttons['memo'] = $.i18n.prop('COM_IDS_MEMO');
// 		buttons['edit'] = $.i18n.prop('TEXT_EDIT_P');
// 		buttons['samsungWorkspace'] = $.i18n.prop('COM_ID_DEVICEDISPLAY_REMOTE_CONTROL');
// 	} else if(canControlDevice) {
// 		buttons['edit'] = $.i18n.prop('TEXT_EDIT_P');
// 		buttons['samsungWorkspace'] = $.i18n.prop('COM_ID_DEVICEDISPLAY_REMOTE_CONTROL');
// 	} else if(canReadDevice && json.deviceType.toUpperCase() == 'SPLAYER') {
// 		buttons['samsungWorkspace'] = $.i18n.prop('COM_ID_DEVICEDISPLAY_REMOTE_CONTROL');
// 	}
// 	if(json.deviceType == 'LEDBOX' || json.deviceType == 'RLEDBOX'){
// 		buttons['open_cabinet_list'] = $.i18n.prop('MIS_SID_SERVER_OPEN_CABINET');
//     }
    
class DeviceDetailView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            device: undefined,
            screenCapture: false,
            thumbUrl: PowerThumb, 
            captureUrl: PowerThumb,
            showMemo: false,
            rmServer: {
                show: false,
            },
            tags: {
                mediaTags: undefined,
                variableTags: undefined,
            },
            logInfo: {
                script: '',
                duration: 1
            },
            systemUsage: {
                isStarted: false
            },
            subTab: DEVICE_DETAIL_SUB_TABS[0],
        };
        this.windowObject = null;
        this.handleRemoteControl = handleRemoteControl.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate() {
        const {subTab, tags} = this.state;
        const {id} = this.props;

        if(subTab === 'MIS_TEXT_TAG_P') {
            if(!tags.mediaTags) {
                Promise.all([
                    deviceService.fetchDeviceTagsById(id, 'media'),
                    deviceService.fetchDeviceTagsById(id, 'variable'),
                ]).then(values => {
                    this.setState({tags: {
                        mediaTags: values[0].items,
                        variableTags: values[1].items,
                    }})
                }).catch(
                    error => console.log(error)
                )

            }
        }
    }

    fetchData = () => {
        const {id} = this.props;
        deviceService.fetchDeviceById(id).then(res => {
            const device = res.items;
            this.setState({device});
            getImageUrls({...device, basicSource: device.inputSource}).then(res => {
                this.setState({thumbUrl: res[0], captureUrl: res[1]});
            });
            device.logCollect.collectInfo && this.setState({
                logInfo: {
                    script: device.logCollect.collectInfo.script ? device.logCollect.collectInfo.script : '',
                    duration: device.logCollect.collectInfo.duration ? device.logCollect.collectInfo.duration : 1
                }
            });
        }).catch(
            error =>
                console.log(error)
        )
    }

    closePopup = (deviceId) => {
        const {closePopup} = this.props;
        closePopup(deviceId);
    }

    onClickSubTab = (id) => {
        this.setState({subTab: id});
    };

    onClickMemo = () => {
        const {device} = this.state;
        const {addPopup, t} = this.props;

        addPopup({
            id: commonConstants.DEVICE_MEMO,
            type: commonConstants.DEVICE_MEMO,
            deviceId: device.deviceId,
            onSave: () => {
                toastr.info(t("MIS_TEXT_SUCCESS_SENT_P"));
                this.onCloseMemo()
            },
            onDelete: (res) => {
                if(res.status.toLowerCase() === 'success') {
                    toastr.info(t("ALERT_SUCCESS_DELETE"));
                }
                this.onCloseMemo()
            },
            onClose: this.onCloseMemo,
        });
    };

    onCloseMemo = () => {
        this.props.closePopup(commonConstants.DEVICE_MEMO);
    };

    onClickEdit = () => {
        const {addPopup, closePopup, zIndex, hasDeviceSecurity} = this.props;
        const {device} = this.state;

        let popupId = commonConstants.UPDATE_DEVICE;
        if(device.deviceType === 'FLIP') {
            popupId = commonConstants.UPDATE_FLIP_DEVICE;
        }

        addPopup({
            id: popupId,
            type: popupId,
            deviceIds: [this.state.device.deviceId],
            zIndex: zIndex+1 ,
            hasDeviceSecurity: (hasDeviceSecurity == 'true'),
            onChange: () => this.fetchData(),
            onClose: () => closePopup(popupId),
        });
    };

    onChangeScreenCapture = e => {
        this.setState({screenCapture: e.target.checked});
    };

    onApplyTags = (tagType, tags) => {
        let newTags = tagType === 'MEDIA' ? Object.assign({}, this.state.tags, {mediaTags: tags}) :
            Object.assign({}, this.state.tags, {variableTags: tags});
        this.setState({tags: newTags});
    };

    onChangeLogCollectInfo = (script, duration, isStopped) => {
        this.setState({
            logInfo: {
                script: script,
                duration: duration,
                isStopped: isStopped
            }
        });
    };

    onChangeSystemUsageStatus = (isStarted) => {
        this.setState({
            systemUsage: {
                isStarted: isStarted
            }
        });
    }

    onOpenLedCabinet = () => {
        const {addTab, loadContent, loadLedCabinet, t} = this.props;
        const {device} = this.state;

        addTab({
            id: 'LED_CABINET',
            title: t('TEXT_TITLE_DEVICE_P'),
            tabTitle: device.deviceName,
            active: true,
            close: true,
        });
        loadContent('LED_CABINET', true);
        loadLedCabinet(device.deviceId);
        // this.closePopup(device.deviceId);
    };

    onRemoteControl = () => {
        const {device} = this.state;
        this.handleRemoteControl(device);
    };

    onPostBoot = () => {
        const {device} = this.state;
        const {t} = this.props;

        if(!device.power) {
            toastr.warning(t('MESSAGE_DEVICE_DISCONNECT_DEVICE_P'));
            return;
        }

        deviceService.sendPostBoot(device.deviceId).then(res => {
            toastr.info(t('MIS_TEXT_SUCCESS_SENT_P'));
        }).catch(error => {
            toastr.error(t('ALERT_FAIL'));
        });
    };

    render() {
        const {device = {}, tags, subTab, screenCapture, thumbUrl, captureUrl, showMemo = false, rmServer, memo, logInfo, systemUsage} = this.state;
        const {t, misopt: {misOptionDevice},zIndex,id} = this.props;
        let subTabs = ['TEXT_TITLE_DEVICE_INFO_P'];

        if(!device.deviceType) {
            return null;
        }
        
        const {deviceViewPlayingContent, deviceViewContentDownload, deviceTabSystemUsage,
            deviceTabError, deviceTagMapping, deviceScreenCapture, deviceRemoteControl} = misOptionDevice(toUpper(device.deviceType), toInteger(device.deviceTypeVersion)) || {};

        // if(json.has_child == true && json.deviceType != 'LEDBOX' && json.deviceType != 'RLEDBOX' ){
        //     popSource += '<li class="tabtitle m6" rel="mini_6"><a href="#" title="'+$.i18n.prop('MIS_SID_CONNECTED_DEVICE_INFO')+'">' + $.i18n.prop('MIS_SID_CONNECTED_DEVICE_INFO') + '</a></li>';
        // }
        if(device.hasChild && device.deviceType !==  LEDBOX && device.deviceType !== RLEDBOX) {
            subTabs.push(DEVICE_DETAIL_SUB_TABS[1]);
        }
        deviceViewPlayingContent && subTabs.push(DEVICE_DETAIL_SUB_TABS[2]);
        deviceViewContentDownload && subTabs.push(DEVICE_DETAIL_SUB_TABS[3]);
        deviceTabSystemUsage && subTabs.push(DEVICE_DETAIL_SUB_TABS[4]);
        if(device.deviceType !== LPLAYER) {
            deviceTabError && subTabs.push(DEVICE_DETAIL_SUB_TABS[5]);
        }
        deviceTagMapping && subTabs.push(DEVICE_DETAIL_SUB_TABS[6]);
        
        const {logCollect: {isEnable} = {isEnable: false}} = device;
        isEnable && subTabs.push(DEVICE_DETAIL_SUB_TABS[7]);

        return (
            <div className='device_detail_view_wrap'>
                {
                    device != null &&
                    <MISDialog
                        dialog={{
                            title: <DetailTitle device={device} />,
                            isDraggable: true,
                            closeOnEscape: true,
                            allowMinimize: true,
                            width: 850,
                            height: 500,
                            zIndex: zIndex,
                            popupId : id,
                            onClose: () => this.closePopup(device.deviceId),
                        }}
                        buttons={{
                            leftButtons: [
                                (<span key='replash2' className='replash2' style={{margin: '15px 0px', cursor: 'pointer'}} onClick={this.onPostBoot} />)
                            ],
                            rightButtons: [
                                {
                                    id: 'COM_IDS_MEMO',
                                    title: t('COM_IDS_MEMO'),
                                    onClick: this.onClickMemo,
                                },
                                {
                                    id: 'TEXT_EDIT_P',
                                    title: t('TEXT_EDIT_P'),
                                    onClick: this.onClickEdit,
                                },
                                {
                                    id: 'COM_ID_DEVICEDISPLAY_REMOTE_CONTROL',
                                    title: t('COM_ID_DEVICEDISPLAY_REMOTE_CONTROL'),
                                    hide: !deviceRemoteControl,
                                    onClick: this.onRemoteControl,
                                },
                                {
                                    id: 'MIS_SID_SERVER_OPEN_CABINET',
                                    title: t('MIS_SID_SERVER_OPEN_CABINET'),
                                    hide: device.deviceType !== 'LEDBOX' && device.deviceType !== 'RLEDBOX',
                                    onClick: this.onOpenLedCabinet,
                                },
                                {
                                    id: 'COM_BUTTON_CLOSE_P',
                                    title: t('COM_BUTTON_CLOSE_P'),
                                    onClick: () => this.closePopup(device.deviceId)
                                }
                            ]
                        }}>
                        <div className="popup_contents clearfix">
                            <div className="playlist_imgview mt25" style={{width:320}}>
                                <div style={{textAlign:'right',paddingBottom:4}}>
                                    &nbsp;{device.contentChannel && device.contentChannel > 0 && `Ch.${device.contentChannel}`}
                                </div>
                                <div className={classNames('preview_wrap', device.landscape, {'power': device.power})}>
                                    {
                                        screenCapture ? (device.landscape === 'landscape' || !device.power) ?
                                        <div style={{
                                            width:'100%',
                                            height:'100%',
                                            background: `url('${captureUrl}') center center / contain no-repeat`,
                                            // backgroundPosition:'center',
                                            // backgroundSize: 'auto 100%',
                                            }}>
                                        </div> :
                                        <div style={{
                                            width:'100%',
                                            height:'100%',
                                            background: `url('${captureUrl}') no-repeat`,
                                            backgroundPosition: 'center',
                                            backgroundSize: '100% 56.25%',
                                            MsTransform: 'rotate(90deg)',
                                            WebkitTransform: 'rotate(90deg)',
                                            transform: 'rotate(90deg)',
                                            }}>
                                        </div> :
                                        (device.landscape === 'landscape' || !device.power) ?
                                        <div style={{
                                                width:'100%',
                                                height:'100%',
                                                background:`url('${thumbUrl}') center center / contain no-repeat`,
                                                // backgroundSize: 'auto 100%',
                                                // backgroundPosition:'center',
                                            }}>
                                        </div> :
                                        <div style={{width:180, height:320, marginLeft:'70px', marginRight:'-70px', position:'relative', backgroundColor:'black'}}>
                                            <img src={thumbUrl} style={{
                                                width: '100%',
                                                top: '0', 
                                                bottom: '0', 
                                                marginTop: 'auto',
                                                marginBottom: 'auto',
                                                position: 'absolute',
                                            }}/>
                                        </div>
                                    }
                                </div>
                                {
                                    deviceScreenCapture &&
                                    <div className="devpopup_img float_l mt10 mr8">
                                        <Checkbox
                                            name={t("LIST_TITLE_CAPTURE")}
                                            id={`dev_capture_switch_popup_${device.deviceId}`}
                                            checked={screenCapture}
                                            onChange={this.onChangeScreenCapture} />
                                    </div>
                                }
                                {
                                    device.errorFlag && device.errorFlag.charAt(0) === '1' &&
                                    <>
                                        <br/><br/><br/>
                                        <span className="error_txt" style={{lineHeight:'18px'}}>* {t('MIS_SID_DEVICE_TYPE_CHANGED_WITHOUT_AUTHORIZATION')}</span>
                                    </>
                                }
                            </div>
                            <div className="playlist_detail ml25 mb5">
                                <SubTab tabs={subTabs} selected={subTab} viewSize={3} onClick={this.onClickSubTab} />
                                { subTab === DEVICE_DETAIL_SUB_TABS[0] && <DeviceInfoDetail device={device} /> }
                                { subTab === DEVICE_DETAIL_SUB_TABS[1] && <ConnectedDeviceDetail device={device} />}
                                { subTab === DEVICE_DETAIL_SUB_TABS[2] && <PlayingContentDetail device={device} />}
                                { subTab === DEVICE_DETAIL_SUB_TABS[3] && <ContentDownloadStatusDetail device={device} />}
                                { subTab === DEVICE_DETAIL_SUB_TABS[4] && <SystemUsageDetail device={device} status={systemUsage.isStarted} onChange={this.onChangeSystemUsageStatus}/>}
                                { subTab === DEVICE_DETAIL_SUB_TABS[5] && <DeviceHealthDetail device={device} />}
                                { subTab === DEVICE_DETAIL_SUB_TABS[6] && <TagDetail device={device} tags={tags} onApply={this.onApplyTags} />}
                                { subTab === DEVICE_DETAIL_SUB_TABS[7] && <LogDataDetail device={device} logInfo={logInfo} onChange={this.onChangeLogCollectInfo}/>}
                            </div>
                        </div>
                    </MISDialog>
                }
                {
                    rmServer.show &&
                    <RMServerOpener url={rmServer.url} token={rmServer.token} />
                }
            </div>
        )
    }
}
export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: (id)=>dispatch(popupAction.closePopup(id)),
        addTab: tabInfo => dispatch(menuAction.addTab(tabInfo)),
        loadContent: id => dispatch(menuAction.loadContent(id)),
        loadLedCabinet: deviceId => dispatch(deviceAction.loadLedCabinet(deviceId)),
    })

)(withTranslation()(withMISOpt(DeviceDetailView)));