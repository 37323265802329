import React from 'react';
import {deviceService} from "../../../services";
import Tree from "rc-tree/es";
import MISDialog from "../MISDialog";
import {withTranslation} from "react-i18next";
import './DeviceGroupPopup.css';
import {SearchButton} from "../../button/SearchButton";
import {toastr} from 'helper/toastrIntercept';
import Checkbox from "../../checkbox/Checkbox";
import {getErrorMessage} from "../../../helper/responseHandler";
import {Loading} from "../../loading/Loading";
import {jsonToTreeForDevice, jsonToTreeForDeviceAfterSearch} from "../../../helper";


class DeviceGroupPopup extends React.Component {

    state = {
        allCount: 0,
        inputText: '',
        isCheckAll: false,
        autoExpandParent : true,
        deviceGroups: [],
        treeNode: [],
        checkedKeys: [],
        expandedKeys: [],
        selectedGroups: [],
        loading: false
    };

    constructor(props) {
        super(props);
        //this.state.checkedKeys = props.selectedGroups;
        this.setState({
            selectedGroups: this.props.selected !== undefined ? this.props.selected : [],
            checkedKeys: this.props.checkedKeys !== undefined ? this.props.checkedKeys : []
        })
    }

    componentDidMount() {
        this.fetchDeviceGroupData();
    }

    initGroupState = () => {
        this.setState({
            inputText: '',
            isCheckAll: false,
            checkedKeys: [],
        });
    };

    onCheck = (keys, info) => {

        let strGroupId = info.node.props.groupId;

        if (this.props.isSync !== undefined && this.props.isSync === true && info.checked) {
            deviceService.checkAllDevicesTagged(strGroupId).then(res => {
                if (res.items === true) {
                    this.processForOnCheck(keys, info);
                } else {
                    toastr.error("You have selected a group of devices that cannot deploy the sync play schedule.");
                }
            }).catch(error => toastr.error(getErrorMessage(error)));
        } else {
            this.processForOnCheck(keys, info);
        }
    };

    processForOnCheck = (keys, info) => {

        let {selectedGroups = [], checkedKeys = [], allCount} = this.state;
        let strGroupId = info.node.props.groupId;

        this.checkedKeys= checkedKeys;
        this.selectedGroups = selectedGroups;

        if(info.checked) {
            if(!this.checkedKeys.find(key => key === strGroupId)) {
                const node = info.node.props;
                this.checkedKeys = this.checkedKeys.concat(strGroupId);
                this.selectedGroups = this.selectedGroups.concat({groupId: node.groupId, groupName: node.title, groupNameText: node.groupName});
            }
        } else {
            this.checkedKeys = this.checkedKeys.filter(id => id !== strGroupId);
            this.selectedGroups = this.selectedGroups.filter(group => group.groupId !== parseInt(strGroupId));
        }
        if(info.node.props.children && info.node.props.children.length > 0 && this.props.isSync !== true) {
            this.checkNode(info.checked, info.node.props.children);
        }
        this.setState({
            checkedKeys: this.checkedKeys,
            isCheckAll: allCount > 0 && allCount === this.checkedKeys.length,
            selectedGroups: this.selectedGroups
        });
        delete this.checkedKeys;
        delete this.selectedGroups;
    };

    onSelect = (keys, info) => {
        let {checkedKeys = [], selectedGroups =  [], allCount} = this.state;
        let strGroupId = info.node.props.groupId;

        this.checkedKeys= checkedKeys;
        this.selectedGroups = selectedGroups;

        const checked = this.checkedKeys.find(key => key === strGroupId);

        if(!checked) {
            const node = info.node.props;
            this.checkedKeys = this.checkedKeys.concat(strGroupId);
            this.selectedGroups = this.selectedGroups.concat({groupId: node.groupId, groupName: node.title, groupNameText: node.groupName});
        } else {
            this.checkedKeys = this.checkedKeys.filter(id => id !== strGroupId);
            this.selectedGroups = this.selectedGroups.filter(group => group.groupId !== parseInt(strGroupId));
        }

        if(info.node.props.children && info.node.props.children.length > 0) {
            this.checkNode(!checked, info.node.props.children);
        }
        this.setState({
            checkedKeys: this.checkedKeys,
            isCheckAll: allCount > 0 && allCount === this.checkedKeys.length,
            selectedGroups: this.selectedGroups
        });
        delete this.checkedKeys;
        delete this.selectedGroups;
    };

    onAllChecked = (event) => {
        let checkedKeys = [], selectedGroups = [];
        const {allEnabled, allowSelectOrganization} = this.props;
        const loopCheck = (treeNode) => {
            treeNode.forEach((node) => {
                if (!allEnabled && !allowSelectOrganization) {
                    if (!node.disabled) {
                        checkedKeys.push(node.groupId);
                        selectedGroups.push({groupId: node.groupId, groupName: node.title, groupNameText: node.groupName})
                    }
                } else {
                    checkedKeys.push(node.groupId);
                    selectedGroups.push({groupId: node.groupId, groupName: node.title, groupNameText: node.groupName})
                }
                if (node.children && node.children.length > 0) {
                    loopCheck(node.children)
                }
            });
        };
        if (event.target.checked) {
            const {treeData} = this.state;
            loopCheck(treeData);
        }
        this.setState({
            checkedKeys: checkedKeys,
            expandedKeys: event.target.checked ? checkedKeys : [],
            isCheckAll: event.target.checked,
            selectedGroups: selectedGroups
        });
    };

    onResetClick = () => {
        this.initGroupState();
        this.fetchDeviceGroupData();
    };

    onKeyDownForSearch = e => {
        if (e.keyCode === 13) {
            this.onSearch();
        }
    };

    onSearch = () => {
        this.fetchDeviceGroupData(this.state.inputText);
    };

    onSave() {
        const {save} = this.props;
        const {selectedGroups} = this.state;
        if (save) {
            save(selectedGroups)
        }
    };

    checkNode = (checked, children) => {
        children.forEach(child => {
            const node = child.props;
            if (!node.disabled) {
                if(checked) {
                    if(!this.checkedKeys.find(key => key === child.props.groupId)) {
                        this.checkedKeys = this.checkedKeys.concat(child.props.groupId);
                        this.selectedGroups = this.selectedGroups.concat({groupId: node.groupId, groupName: node.title, groupNameText: node.groupName});
                    }
                } else {
                    this.checkedKeys = this.checkedKeys.filter(id => id !== child.props.groupId);
                    this.selectedGroups = this.selectedGroups.filter(group => group.groupId !== child.props.groupId);
                }
                if(child.props.children && child.props.children.length > 0) {
                    this.checkNode(checked, child.props.children);
                }
            }
        });
    };

    onExpand = (expandedKeys) => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    fetchDeviceGroupData = (searchText) => {
        this.setState({
            loading: true
        })
        const {t, tagAssign, selectAllUse} = this.props;
        deviceService.searchGroupByName({
            allEnabled: this.props.allEnabled,
            mode: "general",
            searchText: searchText,

            searchKey : this.props.searchKey !== undefined ? this.props.searchKey : undefined,
            programId: this.props.programId !== undefined ? this.props.programId : '',
            videoWallMode: this.props.isVwl !== undefined ? this.props.isVwl : undefined,
            syncMode: this.props.isSync !== undefined ? this.props.isSync : undefined,
            priority: this.props.priority !== undefined ? this.props.priority : undefined,
            organizationId: this.props.organId !== undefined ? this.props.organId : undefined

        }).then(res => {
            if (res) {
                let groups;
                if (searchText !== undefined && searchText !== '') {
                    groups = jsonToTreeForDeviceAfterSearch('device', res.items, tagAssign);
                } else {
                    groups = jsonToTreeForDevice(res.items, this.props.checkedKeys, this.props.allowSelectOrganization, this.props.allEnabled, tagAssign, selectAllUse);
                }
                this.setState({
                    treeData: groups.treeData,
                    allCount: groups.allCount,
                    defaultCheckedKeys: groups.defaultCheckedKeys,
                    expandedKeys: groups.defaultCheckedKeys !== undefined ? groups.defaultCheckedKeys : [],
                    checkedKeys: this.props.checkedKeys !== undefined ? this.props.checkedKeys : groups.defaultCheckedKeys,
                    selectedGroups: groups.selected !== undefined ? groups.selected : this.props.selected,
                    loading: false,
                })
            } else {
                toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
            }
        }).catch(err => toastr.error(getErrorMessage(err, err.errorMessage)));
    };

    render() {
        const {t, close, title = t("TEXT_SEL_GROUP_P"), selectAllUse} = this.props;
        const {inputText, isCheckAll = false, checkedKeys, treeData, expandedKeys, autoExpandParent, loading} = this.state;
        const dialogProps = {title: title, closeOnEscape: true, width: 355, height: 400, modal: true, onClose: () => close(), zIndex: 3000};
        return (
            <MISDialog
                classname="device_group_popup"
                dialog={dialogProps}
                buttons={{
                    rightButtons: [
                        {id: "SAVE_GROUP_POPUP_BTN", title: t("COM_BUTTON_SAVE"), onClick: () => this.onSave()},
                        {id: "CANCEL_GROUP_POPUP_BTN", title: this.props.noTitle !== undefined ? this.props.noTitle : t("BUTTON_CANCEL_P"), onClick: close}
                    ]
                }}>
                <div className="device_group_popup_container">
                    <div className="popup_contents" style={{height: 'calc(100% - 60px)'}}>
                        {
                            selectAllUse && <Checkbox id={'ALL'} name={t('BUTTON_ALL_SELECT_P')} checked={isCheckAll} onChange={this.onAllChecked}/>
                        }
                        <div style={{height: 40, lineHeight: 40, verticalAlign: 'middle', textAlign: 'right', marginTop: 10, display: 'flex'}}>
                            <input type="text" style={{width: 180}} placeholder={t("COM_TABLE_GROUP_NAME_P")} value={inputText} onChange={(e) => this.setState({inputText: e.target.value})} onKeyDown={this.onKeyDownForSearch}/>
                            <SearchButton classname={"ml10"} onClick={this.onSearch}/>
                            <button className="base ml10" style={{width: 50}} id="searchGroupResetBtn" onClick={() => this.onResetClick()}>
                                <span style={{width: 40, textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{t("COM_DID_LFD_RESET")}</span>
                            </button>
                        </div>
                        <div className="pop_list" style={{overflowX: 'auto', borderTop: '1px solid #e7e7e7', padding: 10}}>
                            {!loading ?
                                <Tree
                                    onExpand={this.onExpand}
                                    multiple={true}
                                    showIcon={true}
                                    checkable={true}
                                    selectable={true}
                                    checkStrictly={true}
                                    autoExpandParent={autoExpandParent}
                                    onCheck={this.onCheck}
                                    onSelect={this.onSelect}
                                    loadedKeys={checkedKeys}
                                    checkedKeys={checkedKeys}
                                    expandedKeys={expandedKeys}
                                    defaultExpandedKeys={checkedKeys}
                                    treeData={treeData}>
                                </Tree> : <div style={{display: 'flex', justifyContent: 'center'}}><Loading /></div>
                            }
                        </div>
                    </div>
                </div>
            </MISDialog>
        )
    }
}

DeviceGroupPopup.defaultProps = {
    showCount : false,
    allEnabled: true,
    searchKey: undefined,
    programId : undefined,
    isVwl : undefined,
    isSync : undefined,
    priority : undefined,
    organizationId: undefined,
    allowSelectOrganization: true
}

export default withTranslation()(DeviceGroupPopup);
