import React from 'react';

const abilities = [
    {abilityName: 'contentRead', abilityIds: [1]},
    {abilityName: 'contentCreate', abilityIds: [2]},
    {abilityName: 'contentLock', abilityIds: [102]},
    {abilityName: 'contentManage', abilityIds: [3]},
    {abilityName: 'contentScheduleRead', abilityIds: [9, 68]},
    {abilityName: 'contentScheduleCreate', abilityIds: [10, 69]},
    {abilityName: 'contentScheduleManage', abilityIds: [11, 70]},
    {abilityName: 'userRead', abilityIds: [35]},
    {abilityName: 'userCreate', abilityIds: [36]},
    {abilityName: 'userManage', abilityIds: [37]},
    {abilityName: 'deviceRead', abilityIds: [19, 78]},
    {abilityName: 'deviceControl', abilityIds: [94]},
    {abilityName: 'deviceCreate', abilityIds: [20, 79]},
    {abilityName: 'deviceManage', abilityIds: [21, 80]},
    {abilityName: 'deviceApproval', abilityIds: [99]},
    {abilityName: 'deviceMove', abilityIds: [96]},
    {abilityName: 'deviceCustomize', abilityIds: [97]},
    {abilityName: 'deviceDelete', abilityIds: [95]},
    {abilityName: 'deviceSoftwareUpdate', abilityIds: [98]},
    {abilityName: 'deviceSecurity', abilityIds: [103]},
    {abilityName: 'serverSetupManage', abilityIds: [40]},
    {abilityName: 'statisticsManage', abilityIds: [93]},
    {abilityName: 'ruleStoreManager', abilityIds: [100]},
    {abilityName: 'ruleHQManager', abilityIds: [101]},
];

const notifications = [
    {type: 'DISCONNECTED_DEVICE', sid: 'MIS_SID_SERVER_DISCONNECTED_DEVICE'},
    {type: 'TIMEZONE_NOT_SET', sid: 'MIS_SID_TIME_ZONE_NOT_SET'},
    {type: "SCHEDULE_NOT_PUBLISHED", sid: 'MIS_SID_SCHEDULE_NOT_PUBLISHED'},
    {type: "CONTENT_DOWNLOAD_ERROR", sid: 'MIS_SID_SERVER_CONTENT_DOWNLOAD_ERROR'},
    {type: "EXPIRE_SCHEDULE", sid: 'MIS_SID_SERVER_SCHEDULES_TO_EXPIRE'},
    {type: "EXPIRE_PLAYLIST", sid: 'MIS_SID_SERVER_PLAYLISTS_TO_EXPIRE'},
    {type: "ADD_CONTENT", sid: 'MIS_SID_SERVER_CONTENT_ADDED'},
    {type: "EDIT_CONTENT", sid: 'MIS_SID_SERVER_CONTENT_EDITED'},
    {type: "DELETE_CONTENT", sid: 'MIS_SID_SERVER_CONTENT_DELETED'},
    {type: "ADD_PLAYLIST", sid: 'MIS_SID_SERVER_PLAYLIST_ADDED'},
    {type: "EDIT_PLAYLIST", sid: 'MIS_SID_SERVER_PLAYLIST_EDITED'},
    {type: "DELETE_PLAYLIST", sid: 'MIS_SID_SERVER_PLAYLIST_DELETED'},
    {type: "ADD_CONTENT_SCHEDULE", sid: 'MIS_SID_SERVER_SCHEDULE_ADDED'},
    {type: "EDIT_CONTENT_SCHEDULE", sid: 'MIS_SID_SERVER_SCHEDULE_EDITED'},
    {type: "DELETE_CONTENT_SCHEDULE", sid: 'MIS_SID_SERVER_SCHEDULE_DELETED'},
];

const defaultRoles = [
    'Server Administrator',
    'Administrator',
    'Content Manager',
    'Schedule Manager',
    'Device Manager',
    'User Manager',
    'Content Uploader',
    'Schedule Editor',
    'Content Manager',
    'Schedule Manager',
    'Device Manager',
    'User Manager',
    'Content Schedule Manager'
];

export const ruleManagerRole = {
    ruleManager: 'Rule Manager',
    storeManager: 'Store Manager',
    hqManager: 'HQ Manager',
}

export const getIdsByName = (name) => {
    let ability = abilities.find(ability => ability.abilityName === name);
    if(ability) {
        return ability.abilityIds;
    } else {
        return [];
    }
}

export const getNameById = (id) => {
    for(let i = 0; i < abilities.length; i++) {
        let index = abilities[i].abilityIds.findIndex(id);
        if(index > 0) {
            return abilities[i].abilityName;
        }
    }
    return null;
}

export const getAbilityNames = () => {
    return abilities.map(ability => ability.abilityName);
}

export const getNotificationSIDByType = (type) => {
    let notification = notifications.find(notification => notification.type === type);
    if(notification) {
        return notification.sid;
    } else {
        return null
    }
}

export const checkDefaultRole = (roleName) => {
    let defaultRole = defaultRoles.find(defaultRole => defaultRole.toUpperCase() === roleName.toUpperCase())
    return defaultRole ? true : false;
}