import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RadioGroup from "../../../radio/RadioGroup";
import "../ConditionPopup.css";
import { commonConstants } from "../../../../constants";
import { settingService } from "../../../../services";
import { useDispatch } from "react-redux";
import { popupAction } from "../../../../actions";

const MediaTag = ({ handleValues, handleSign, orgValues, orgSign }) => {
    const [sign, setSign] = useState(orgSign ? orgSign : "ia");
    const [tags, setTags] = useState(orgValues ? orgValues : []);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [selectedTags, setSelectedTags] = useState(orgValues || []);
    const [filter, setFilter] = useState({
        organId: 'all',
        searchText: '',
        startIndex: 1,
        pageSize: 1000,
    });

    const onClickSearch = () => {

        let updatedSelectedTags = [];
        Promise.all([
            settingService.fetchTags({
                organId: filter.organId,
                startIndex: filter.startIndex,
                pageSize: filter.pageSize,
                searchText: filter.searchText,
                type: "media"
            }),
        ]).then(values => {            
            
            if(selectedTags.length > 0 && selectedTags[0].value !== undefined){
                updatedSelectedTags =  selectedTags.map((selectedTag) => {
                    const isSelected = values[0].items.find(tag => tag.tagName === selectedTag.value);                   
                    return isSelected;
                });
                
                setSelectedTags(updatedSelectedTags);
            }else{
                updatedSelectedTags = [...selectedTags];
            }

            dispatch(
                popupAction.addPopup({
                    id: commonConstants.TAG_SETTING,
                    type: commonConstants.TAG_SETTING,
                    tagType: "media",
                    showDevice: false,
                    showTagValue: false,
                    selectedTags: updatedSelectedTags,
                    onApply: onTagSettingPopupApply,
                    onClose: () => dispatch(popupAction.closePopup(commonConstants.TAG_SETTING))
                })
            )
        }).catch(
            error => console.log(error)
        ).finally(            
            
        );       
    };

    const onTagSettingPopupApply = selected => {
        
        const newTags = [];
        selected.forEach((v) => {
            const value = { value: v.tagName };
            newTags.push(value);
        });
        setTags(newTags);
        setSelectedTags(selected);
        handleValues(newTags);
        dispatch(popupAction.closePopup(commonConstants.TAG_SETTING));
    };

    const onChangeSign = (e, value) => {
        setSign(value);
        handleSign(value);
    };

    const deleteTag = tagName => {
        const newTags = tags.filter(i => i.value !== tagName);    
        setTags(newTags);
        handleValues(newTags);
    };

    return (
        <table className="dev_ip">
            <tbody>
                <tr id="operator">
                    <th style={{ width: "130px", verticalAlign: "top", paddingTop: "10px" }}>
                    {t("MIS_SID_OPERATOR")}
                    </th>
                    <td>
                        <RadioGroup
                            propertyName={"sign"}
                            selects={[
                                { title: t("MIS_SID_CAFEB_FULL_MATCH"), value: "ia" },
                                { title: t("MIS_SID_CAFEB_PARTIAL_MATCH"), value: "ip" }
                            ]}
                            value={sign}
                            onChange={onChangeSign}
                            multiLang={true}
                        />
                    </td>
                </tr>
                <tr>
                    <th>{t("MIS_TEXT_TAG_P")}</th>
                    <td>
                        <button className="base searchBtn" onClick={onClickSearch}></button>
                        <div className="selected_item_box_condition">
                            {tags.length>0&&tags.map((item, index) => (
                                <div
                                    style={{
                                        padding: "5px",
                                        borderBottom: "1px solid #e7e7e7"
                                    }}
                                    className="selected"
                                    value={item.value}
                                    id={item.value}
                                    key={index}
                                >
                                    <span>{item.value}</span>
                                    <button
                                        style={{ float: "right" }}
                                        className="selectedDeviceDelBtn"
                                        onClick={() => {
                                            deleteTag(item.value);
                                        }}
                                    ></button>
                                </div>
                            ))}
                        </div>
                    </td>
                </tr>
                <tr>
                    <th></th>
                    <td></td>
                </tr>
            </tbody>
        </table>
    );
};

export default MediaTag;
