import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import TextInput from "../../../input/TextInput";
import {LICENSE_INFO, settingConstants} from "../../../../constants";
import MISDialog from "../../MISDialog";
import RadioGroup from "../../../radio/RadioGroup";
import {toastr} from 'helper/toastrIntercept';
import {getMixString} from '../../../../language/languageUtils';
import "./ActivationPopup.css"

const ActivationPopup = ({onSave, onClose, type, title, updateLicKey}) => {
    const {t} = useTranslation();
    const [internetType, setInternetType] = useState(true);

    const [showDesc, setShowDesc] = useState(true);
    const [showMigrationGuide, setShowMigrationGuide] = useState(false);

    const [licKey, setLicKey] = useState({
        key0: '',
        key1: '',
        key2: '',
        key3: ''
    });

    const migrationGuideStr = getMixString(['MIS_SID_MIX_CAAPR_NOT_APPROVE_WHILE_LIMITED_MUST_OBTAIN', 'Migration License']).split('*');

    useEffect(() => {
        initLicKeyinput();
    },[]);

    const initLicKeyinput = () => {
        if (type == settingConstants.ADDITIONAL_ACTIVATION_POPUP_INIT) {
            const tempLicKey = updateLicKey.split('-');
            setLicKey({...licKey, key0: tempLicKey[0], key1: tempLicKey[1], key2: tempLicKey[2], key3: tempLicKey[3] });
        }
    }

    const internetOption = [
        {value: true, title: t('COM_MIS_TEXT_CONNECTED_P')},
        {value: false, title: t('MIS_TEXT_DISCONNECTED_P')}
    ];


    const onChangeValue= (e, value) =>{
        setInternetType(value);
    }

    const showDescription = (e) => {
        setShowDesc(!showDesc);
    }

    const onChangeKey = (e) => {
        if(LICENSE_INFO.NOT_USED_PRODUCT_CODE_LIST.includes(e.target.value)){
            toastr.error(t('MIS_TEXT_DOES_NOT_SUPPORT_THE_PRODUCT_P'));
            setShowMigrationGuide(false);
        } else if(LICENSE_INFO.MIGRATION_PRODUCT_CODE === e.target.value){
            setShowMigrationGuide(true);
        } else {
            setShowMigrationGuide(false);
        }
        setLicKey({...licKey, key0: e.target.value})
    }

    return (
        <div>
            <MISDialog
                dialog={{
                    title: title,
                    closeOnEscape: true,
                    modal: true,
                    width: 620,
                    height: 150,
                    position: {x: -300, y: -400},
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'ACTIVATION_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose,
                            disable: false,
                        },
                        {
                            id: 'ACTIVATION_NEXT',
                            title: t('COM_BUTTON_NEXT'),
                            onClick: () => onSave(licKey,internetType)
                        }
                    ]
                }}>


                <div className="detail_view ">
                    <table>
                        <colgroup>
                            <col width="182px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>{t('COM_MIS_TEXT_INTERNET_CONNECTION_P')}</th>
                            <td>
                                <RadioGroup propertyName='internetType' selects={internetOption} onChange={(e, value) => onChangeValue(e, value)} value={internetType}  multiLang={false}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_LICENSE_KEY_P')}</th>
                            <td>
                                <TextInput wrapperStyle={{float: 'left', marginRight: 8}} width={80} value={licKey.key0} onChange={(e) => onChangeKey(e)} disabled= {type == settingConstants.ADDITIONAL_ACTIVATION_POPUP_INIT}/>
                                <span className="space float_l mr8">-</span>
                                <TextInput wrapperStyle={{float: 'left', marginRight: 8}} width={80} value={licKey.key1} onChange={(e) => setLicKey({...licKey, key1: e.target.value})} disabled= {type == settingConstants.ADDITIONAL_ACTIVATION_POPUP_INIT}/>
                                <span className="space float_l mr8">-</span>
                                <TextInput wrapperStyle={{float: 'left', marginRight: 8}} width={80} value={licKey.key2} onChange={(e) => setLicKey({...licKey, key2: e.target.value})} disabled= {type == settingConstants.ADDITIONAL_ACTIVATION_POPUP_INIT}/>
                                <span className="space float_l mr8">-</span>
                                <TextInput wrapperStyle={{float: 'left', marginRight: 8}} width={80} value={licKey.key3} onChange={(e) => setLicKey({...licKey, key3: e.target.value})} disabled= {type == settingConstants.ADDITIONAL_ACTIVATION_POPUP_INIT}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                {
                    !showMigrationGuide && type == settingConstants.FIRST_ACTIVATION_POPUP_INIT &&
                    <div>
                        <button className="detail_btn mt10" style={{width: '200px', 'textAlign': 'left'}} onClick={showDescription} >
                            <span></span>
                            {t('COM_MIS_TEXT_FORGOT_ACTIVATION_KEY_P')}
                        </button>
                    </div>
                }
                {
                    !showMigrationGuide && type == settingConstants.FIRST_ACTIVATION_POPUP_INIT &&
                    <span style={{display:showDesc?'inline':'none'}}>{t('MIS_SID_20_LICENSE_KEY_22_DIGIT_ENGLISH_PRODUCT_SELLER')}</span>
                }
                {
                    showMigrationGuide && type == settingConstants.FIRST_ACTIVATION_POPUP_INIT &&
                    <>
                        <div>
                            <button className="detail_btn mt10" style={{width: '200px', 'textAlign': 'left'}}>
                                <span></span>
                                {getMixString(['MIS_SID_MIX_CAAPR_GUIDE', 'Migration License'])}
                            </button>
                        </div>                    
                        <span>
                        * {migrationGuideStr.length >= 2 && migrationGuideStr[1]} <br/>
                        * {migrationGuideStr.length >= 3 && migrationGuideStr[2]}
                        </span>
                    </>
                }
            </MISDialog>

        </div>
    );
};

export default ActivationPopup;