import React, {Component} from "react";
import MISDialog from "../../../components/popup/MISDialog";
import TextInput from "../../../components/input/TextInput";
import Checkbox from "../../../components/checkbox/Checkbox";
import DateInput from "../../../components/input/DateInput";
import './NewNoticePopup.css';
import Moment from "moment";
import {withTranslation} from "react-i18next";
import {toastr} from 'helper/toastrIntercept';
import {dashboardService} from "../../../services";
import {getErrorMessage} from "../../../helper/responseHandler";

class NewNoticePopup extends Component {
    state = {
        selectId: null,
        notice: {},
        mode: "write",
        title: "",
        startDate: Moment(new Date()).format("YYYY-MM-DD"),
        endDate: '2999-12-31',
        important: false,
        always: true,
        content: "",
    };

    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.onTitleInputChanged = this.onTitleInputChanged.bind(this);
        this.onContentInputChanged = this.onContentInputChanged.bind(this);
        this.onImportCheckChanged = this.onImportCheckChanged.bind(this);
        this.onAlwaysCheckChanged = this.onAlwaysCheckChanged.bind(this);

        this.user = JSON.parse(localStorage.getItem('user'));
    }

    close() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    save() {
        let {t} = this.props;
        let {title, content, startDate, endDate, important} = this.state;
        if (!this.isValid(title) || !this.isValid(content) || !this.isValid(startDate) || !this.isValid(endDate)) {
            toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
            return;
        }
        this.fetchCreateNotice({
            noticeTitle: title,
            noticeSubject: content,
            noticeStartDate: startDate,
            noticeEndDate: endDate,
            important: important,
        });
    }

    onChangeDate(name, date) {
        if (name === 'start') {
            let startDate = Moment(date);
            let endDate = Moment(this.state.endDate);
            if (endDate < startDate) {
                endDate = Moment(date);
            }
            this.setState({
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
            });
        } else if (name === 'end') {
            let endDate = Moment(date);
            let startDate = Moment(this.state.startDate);
            if (endDate < startDate) {
                startDate = Moment(date);
            }
            this.setState({
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
            });
        }
    }

    onTitleInputChanged(value) {
        this.setState({
            title: value,
        });
    }

    onContentInputChanged(value) {
        this.setState({
            content: value,
        });
    }

    onImportCheckChanged() {
        this.setState({
            important: !this.state.important,
        });
    }

    onAlwaysCheckChanged() {
        let always = !this.state.always;
        let endDate = always ? '2999-12-31' : Moment(new Date()).format('YYYY-MM-DD');
        let startDate = this.state.startDate;
        if(!always) {
            if (Moment(startDate) > Moment(endDate)) {
                startDate = Moment(new Date()).format('YYYY-MM-DD');
            }
        }
        this.setState({
            always: always,
            startDate: startDate,
            endDate: endDate,
        });
    }

    isValid(value) {
        if (!value || value.trim().length <= 0) {
            return false;
        }
        return true;
    }

    fetchCreateNotice = (data) => {
        const {t} = this.props;
        dashboardService.createNotice(data).then(res => {
            toastr.success(t("MESSAGE_SCHEDULE_SUCCESS_ADD_P"));
            this.props.onSave();
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    render() {
        const {t} = this.props;
        let {title, startDate, endDate, important, always, content} = this.state;
        let dateFormat = this.user ? this.user.dateFormat : "YYYY-MM-DD";
        return (
            <MISDialog
                dialog={{title: t("COM_DID_MAIN_NOTICE"), closeOnEscape: true, width: 650, height: 442, modal: true, onClose: this.close}}
                buttons={{
                    rightButtons: [
                        {id: "SAVE", title: t("COM_BUTTON_SAVE"), onClick: this.save},
                        {id: "CANCEL", title: t("BUTTON_CANCEL_P"), onClick: this.close}
                    ]
                }}>
                <div className='new-notice-popup'>
                    <div className='new-notice-popup-title'>
                        <div className='new-notice-popup-title title'>{t('COM_SID_TITLE')}</div>
                        <TextInput width={410} value={title} maxLength={50} onChange={(e) => this.onTitleInputChanged(e.target.value)}/>
                        <div style={{width: '10px'}}/>
                        <Checkbox id={'new-notice-important'} name={t("TEXT_IMPORTANT_P")} checked={important} onChange={this.onImportCheckChanged}/>
                    </div>
                    <div className='new-notice-popup-period'>
                        <div className='new-notice-popup-period title'>{t('TEXT_NOTICE_PERIOD_P')}</div>
                        <DateInput className='new-notice-popup-period date' width={130} dateFormat={dateFormat} date={startDate} maxDate={new Date(endDate)} onChange={(date) => this.onChangeDate('start', date)}/>
                        <div style={{width: '10px'}}/>
                        ~
                        <div style={{width: '10px'}}/>
                        <DateInput className='new-notice-popup-period date' width={130} dateFormat={dateFormat} date={endDate} minDate={new Date()} disabled={always} onChange={(date) => this.onChangeDate('end', date)}/>
                        <div style={{width: '40px'}}/>
                        <Checkbox id={'new-notice-always'} name={t("TEXT_ALWAYS_P")} checked={always} onChange={this.onAlwaysCheckChanged}/>
                    </div>
                    <div className='new-notice-popup-content'>
                        <div className='new-notice-popup-content title'>{t('DID_ADMIN_NOTICE_CONTENT')}</div>
                        <textarea style={{width: '524px', height: '352px'}} maxLength="1000" value={content} onChange={(e) => this.onContentInputChanged(e.target.value)}/>
                    </div>
                </div>
            </MISDialog>
        );
    };
}

export default withTranslation()(NewNoticePopup);