import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from 'react-i18next';
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {playlistService} from '../../services';
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import SupportedDeviceIcon from "../../components/icon/SupportedDeviceIcon";
import Size from "../../components/utils/Size";
import {commonConstants, playlistConstants} from "../../constants";
import CategoryButton from "../../components/category/CategoryButton";
import {connect} from "react-redux";
import {menuAction, playlistAction, popupAction} from "../../actions";
import MultiSelectButton from "../../components/button/MultiSelectButton";
import Pagination from '../../components/table/Pagination';
import {toastr} from 'helper/toastrIntercept';
import ContentThumbnail from "../../components/image/ContentThumbnail";
import DeviceRelativeTimeCell from "../../components/device/DeviceRelativeTimeCell";
import ICON_SHARE from '../../images/icon/icon_share.png';
import {useMISOpt} from "../../components/misopt";
import {dateToString, getPageSize, useCheckRefForContent, useFilter, usePrevious} from "../../helper";
import './AllPlaylist.css'
import EMPTY_IMAGE from "../../images/img/empty_img_2.png";
import {getMixString} from "../../language/languageUtils";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {isEqual} from "lodash";
import {useTrGroupProps} from "../../helper/tables";
import {updateCache} from "../../helper/cache/tableCache";
import {initCacheTable} from "../../helper/cache/tableCache";

const AllPlaylist = (props) => {

    const {t} = useTranslation();
    const {getAuthority} = useMISOpt();
    const [authority] = useState(getAuthority('CONTENT_PLAYLIST_RULESET'));
    const [showNoData, setNoData] = useState(false);

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [style, setStyle] = useState({
        height: '740px'
    });

    const {menu} = useSelector(state =>  ({
        menu: state.menu
    }))

    const {items = [], loading = false, totalCount = 0, pages = 0,} = data;
    //searchTex -> keyword
    const {page, pageSize, keyword, searchCreator, categoryIds, playlistType, startDate, endDate, sorted , userId, groupId} = filter;
    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt ] = useCheckRefForContent(items);

    const {openDetailView, addPopup, closePopup, reloadGroup} = props;
    const {addTab, activeTab, loadPlaylist, loadContent, initEditPlaylist, destroyPlaylist} = props;

    const updateDimensions = () => {
        let height = window.innerHeight - 204;
        setStyle({
            ...style,
            height: height
        })
    }


    const getPlaylistTypes = () => {
        const playlistTypeData = [];
        playlistConstants.PLAYLISTTYPES.forEach(el => {
            playlistTypeData.push({id: el.id, name: t(el.name), isChecked: true});
        });
        return playlistTypeData;
    };

    const fetchData = () => {
        
        if(props.currContent === "PLAYLIST_BY_USER" && (props.groupId === undefined || props.groupId === "") && (props.userId === undefined || props.userId === "")){
            return;
        }

        setNoData(false);
        if (!loading) {
            setData({...data, loading: true});
        }

        let params = {};
        switch(props.currContent) {
            case 'ALL_PLAYLIST':
                params = {
                    groupId: "",
                    userId:""
                };
                break;
            default:
                params = {
                    groupId: props.cache.filter.groupId !== undefined ? props.cache.filter.groupId : props.groupId,
                    userId: props.cache.filter.userId !== undefined ? props.cache.filter.userId : props.userId
                };
            break;
        }

        playlistService.fetchPlaylistFilter({
            ...params,
            page: page + 1,
            pageSize,
            categoryIds,
            playlistType,
            searchCreator: searchCreator,
            startModifiedDate: dateToString(startDate),
            endModifiedDate: dateToString(endDate),
            searchText: keyword,
            sorted: sorted,
        }).then(res => {
            if(res.totalCount === 0){
                setNoData(true);
            }
            setData({
                ...data,
                loading: false,
                items: res.items,
                totalCount: res.totalCount
            })
            updateCache('PLAYLIST', {items: res.items, filter: filter, totalCount: res.totalCount}, props.currContent)
        }).catch(error => {
            setData({...data, loading: false});
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
        }).finally(() => {
        });
    };

    const handleExport = (docType) => {
        docType = 'EXCEL';
        playlistService.exportPlaylists({
            ...filter,
            searchCreator: searchCreator,
            startModifiedDate: dateToString(startDate),
            endModifiedDate: dateToString(endDate),
            searchText: keyword,
            sorted: sorted,
            exportType: docType
        }, docType);
    };

    const setCheckAll = (check) => {
        if (checkAll && checkAll.current) {
            checkAll.current.checked = check;
        }
    };


    const onChangePlaylistType = Ids => {
        setFilter({
            ...filter,
            playlistType: Ids,
            page: 0

        });
    };

    const onClickDetailSearch = d => {
        setFilter(
            {
                ...filter,
                keyword: '',
                searchCreator: d.creator,
                startDate: d.date.startDate,
                endDate: d.date.endDate,
                page: 0
            });
    };

    const getCheckedPlaylistId = () => {
        return selected.current.map(key => items[key].playlistId);
    };

    const getCheckedPlaylistItems = () => {
        const ids = getCheckedPlaylistId();
        const checkedItems = [];
        ids.forEach(id => {
            checkedItems.push(items.find(item => item.playlistId === id));
        });

        return checkedItems;
    };

    const openPlaylistView = (id) => {
        openDetailView({
            type: commonConstants.COMMON_DETAIL_VIEW,
            viewType: 'PLAYLIST',
            id: id,
            onSave: () => {
                reloadGroup("MY_PLAYLIST", 0);
                reloadGroup('PLAYLIST_BY_USER');
                fetchData();
            },
            onClose: () => closePopup(id),
        });
    };

    const onEditPlaylist = () => {
        const playlistIds = getCheckedPlaylistId();
        if (playlistIds && playlistIds.length > 0) {
            const idx = playlistIds[0];
            const playlists = getCheckedPlaylistItems();

            if(menu.tabs.filter(tab => tab.id === "EDIT_PLAYLIST").length > 0) {
                activeTab("EDIT_PLAYLIST");
                addPopup({
                    id: commonConstants.COMMON_CONFIRM_POPUP,
                    type: commonConstants.COMMON_CONFIRM_POPUP,
                    message: t("MESSAGE_UPLOADER_REALLY_CANCEL_P"),
                    title: t("COM_BUTTON_CONFIRM"),
                    onClickYes: ()=> {
                        destroyPlaylist('EDIT');
                        const tab = {id: 'EDIT_PLAYLIST', title: t("TEXT_PLAYLIST_EDIT_P"), active: true, close: true};
                        const add = () => addTab(tab);
                        new Promise((resolve) => {
                            closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                            add();
                            resolve();
                        }).then(
                            initEditPlaylist(playlists[0])
                        ).then(
                            loadPlaylist(playlists[0])
                        ).then(
                            loadContent('EDIT_PLAYLIST')
                        )
                    },
                    onClose: () => {
                        closePopup(commonConstants.COMMON_CONFIRM_POPUP)}
                });
            }
            else {
                const tab = {id: 'EDIT_PLAYLIST', title: t("TEXT_PLAYLIST_EDIT_P"), active: true, close: true};
                const add = () => addTab(tab);
                new Promise((resolve) => {
                    add();
                    resolve();
                }).then(
                    initEditPlaylist(playlists[0])
                ).then(
                    loadPlaylist(playlists[0])
                ).then(
                    loadContent('EDIT_PLAYLIST')
                )
            }
        }
    };


    const handleDeleteForce = (failList) => {
        const ids = failList.map(el => el.id);
        const obj = {playlistIds: ids, deleteMethod: 'GO_TO_RECYCLEBIN_FORCE', productType: ''};
        playlistService.deletePlaylist(obj).then(res => {
            toastr.success(t("COM_TEXT_SUCCESS_P"));
            // reload group Tree
            reloadGroup("MY_PLAYLIST", 0);
            reloadGroup('PLAYLIST_BY_USER');
        }).catch(error => {
            console.log(error);
            toastr.error(t('ALERT_FAIL'));
        }).finally(() => {
            fetchData();
            closePopup('PLAYLIST_NOT_DELETE_POPUP');
        });
    }

    const handleDelete = () => {
        const ids = getCheckedPlaylistId();
        const obj = {playlistIds: ids, deleteMethod: 'GO_TO_RECYCLEBIN', productType: ''};

        playlistService.deletePlaylist(obj).then(res => {
            toastr.success(t("COM_TEXT_SUCCESS_P"));
            // reload group Tree
            reloadGroup("MY_PLAYLIST", 0);
            reloadGroup('PLAYLIST_BY_USER');
        }).catch(error => {

            if (typeof error !== 'undefined' &&
                typeof error.items !== 'undefined' &&
                typeof error.items.failList !== 'undefined' &&
                error.items.failList.length > 0
            ) {
                notDeletePlaylist(error.items.failList);
            }
        }).finally(() => {
            fetchData();
            closePopup(commonConstants.COMMON_CONFIRM_POPUP);
        });
    };

    const onDeletePlaylist = () => {
        let message = t('MESSAGE_CONTENT_CONFIRM_DELETE_PLAYLIST_P');
        const playlists = getCheckedPlaylistItems();
        if (playlists.find(el => el.playlistType === '6')) { // Nested
            message = t('MIS_SID_SERVER_NESTED_PLAYLIST_SKIPPED_PROCEED');
        }

        addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t('COM_BUTTON_DELETE'),
            message: message,
            onClickYes: handleDelete,
            height: 90,
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        });

    };


    const handleCopy = (param) => {
        playlistService.copyPlaylist(param).then(res => {
            toastr.success(t("COM_TEXT_SUCCESS_P"));
            // reload group Tree
            reloadGroup("MY_PLAYLIST", 0);
            reloadGroup('PLAYLIST_BY_USER');
        }).catch(error => {
            console.log(error);
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
        }).finally(() => {
            fetchData();
            closePopup('PLAYLIST_COPY');
        });
    };


    const copyPlaylist = () => {

        const playlists = getCheckedPlaylistItems();
        if( ! playlists || playlists.length === 0 ){
            return;
        }

        const playlistId = playlists[0].playlistId;
        const groupId = playlists[0].groupId;
        const groupName = playlists[0].groupName;

        addPopup({
            type: commonConstants.COMMON_DETAIL_VIEW,
            viewType: 'PLAYLIST_COPY',
            id: 'PLAYLIST_COPY',
            param: {playlistId: playlistId, groupId: groupId, groupName: groupName},
            title: t('BUTTON_SAVE_AS_P'),
            onSave: handleCopy,
            onClose: () => closePopup('PLAYLIST_COPY'),
        });
    };

    const handleMovePlaylist = (param) => {

        if (typeof param === 'undefined' || param.length === 0 ||
            typeof param[0].groupId === 'undefined' ||
            param[0].groupId === null) {
            closePopup('PLAYLIST_GROUP_POPUP');
            return;
        }
        const groupId = param[0].groupId;
        const ids = getCheckedPlaylistId();
        const obj = {playlistIds: ids, groupId: groupId};

        playlistService.movePlaylist(obj).then(res => {
            toastr.success(t("COM_TEXT_SUCCESS_P"));
            // reload group Tree
            reloadGroup("MY_PLAYLIST", 0);
            reloadGroup('PLAYLIST_BY_USER', groupId);
        }).catch(error => {
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
        }).finally(() => {
            fetchData();
            closePopup('PLAYLIST_GROUP_POPUP');
        });
    };

    const movePlaylist = () => {
        const ids = getCheckedPlaylistId();
        const obj = {playlistIds: ids};

        playlistService.movePlaylistCheck(obj).then(res => {
            addPopup({
                type: commonConstants.COMMON_DETAIL_VIEW,
                viewType: 'PLAYLIST_GROUP_POPUP',
                id: 'PLAYLIST_GROUP_POPUP',
                title: t("MIS_SID_SELECT_FOLDER"),
                onSave: handleMovePlaylist,
                onClose: () => closePopup('PLAYLIST_GROUP_POPUP')
            });
        }).catch(error => {
            toastr.error(t('MIS_MESSAGE_CONTENT_CANNOT_EDIT_OTHERS_PLAYLIST_GROUP_P'))
        }).finally(() => {
        });
    };

    const handleMultiplePlaylist = (categoryIds) => {
        const ids = getCheckedPlaylistId();
        const obj = {playlistIds: ids, categoryIds: categoryIds};

        playlistService.editCategory(obj).then(res => {
            toastr.success(t("COM_TEXT_SUCCESS_P"));
        }).catch(error => {
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
        }).finally(() => {
            fetchData();
            closePopup('PLAYLIST_CATEGORY_POPUP');
        });
    };


    const editMultiplePlaylist = () => {
        const ids = getCheckedPlaylistId();
        addPopup({
            type: commonConstants.COMMON_DETAIL_VIEW,
            viewType: 'PLAYLIST_CATEGORY_POPUP',
            id: 'PLAYLIST_CATEGORY_POPUP',
            title: t("MIS_SID_20_EDIT_MULTIPLE"),
            playlistIds: ids,
            onSave: handleMultiplePlaylist,
            onClose: () => closePopup('PLAYLIST_CATEGORY_POPUP')
        });
    };

    const notDeletePlaylist = (failList) => {
        addPopup({
            type: commonConstants.COMMON_DETAIL_VIEW,
            viewType: 'PLAYLIST_NOT_DELETE_POPUP',
            id: 'PLAYLIST_NOT_DELETE_POPUP',
            title: t("COM_BUTTON_DELETE"),
            failList: failList,
            onSave: handleDeleteForce,
            onClose: () => closePopup('PLAYLIST_NOT_DELETE_POPUP')
        });
    }

    const onCategoryCheck = (checkedIds) => {
        if(!isEqual(categoryIds, checkedIds)) {
            setFilter({
                ...filter,
                categoryIds: checkedIds
            });
        }
    };

    const getTrGroupProps = (state, rowInfo) => {
        if (rowInfo) {
            return {
                onClick: (e) => {
                    const idx = rowInfo.index;

                    if(
                        e.target.classList.contains('data_name') ||
                        e.target.classList.contains('rt-expander') ||
                        e.target.classList.contains('status_wrap') ||
                        e.target.nodeName === 'A'||
                        e.target.tagName === 'LABEL' ||
                        e.target.tagName === 'INPUT') {
                        return;
                    }
                    checkBoxes.current[idx].checked = !checkBoxes.current[idx].checked;
                    toggleRow({target: checkBoxes.current[idx]});
                },
                className: 'playlist_tr_group'
            };
        }
        return {};
    };


    const renderPlaylistType = (playlist) => {
        let playlistTypeName;
        switch (playlist.playlistType) {
            case '0' :
                playlistTypeName = t('COM_TEXT_GENERAL_P');
                break;
            case '2' :
                playlistTypeName = t('TEXT_TITLE_VIDEOWALL_P');
                break;
            case '3' :
                playlistTypeName = t('MIS_SID_SYNC_PLAY');
                break;
            case '4' :
                playlistTypeName = t('COM_DID_LFD_ADVERTISEMENT');
                break;
            case '5' :
                playlistTypeName = t('MIS_TEXT_TAG_P');
                break;
            case '6' :
                playlistTypeName = t('MIS_SID_NESTED');
                break;
            default :
                playlistTypeName = t('COM_TEXT_GENERAL_P');
                break;
        }

        if (playlist.playlistType === '5') {
            if (playlist.ignoreTag === 1) {
                playlistTypeName += ', ' + t('MIS_SID_SERVER_IGNORE_TAG');
            }
            if (playlist.evennessPlayback === 1) {
                playlistTypeName += ', ' + t('MIS_SID_SERVER_PLAY_EVENTLY');
            }
        }
        return (<div>
            <div><Size size={playlist.totalSize}/></div>
            <div><span>{playlist.playTime}</span></div>
            <div><span>{playlistTypeName}</span></div>
        </div>);
    };

    const renderNameCell = (row) => {
        return (
            <span className={"data_name"} title={row.original.playlistName} onClick={() => openPlaylistView(row.original.playlistId)}>{row.value}</span>
        )
    };

    const SupportedDeviceIconCell = ({original}) => {
        return (<SupportedDeviceIcon deviceType={original.deviceType} deviceTypeVersion={original.deviceTypeVersion}/>)
    };


    const renderThumbnail = (original) => {
        if (original.thumbnailInfo != null) {
            const images = original.thumbnailInfo.split("|");

            if (images.length > 1) {
                if (original.hasSubPlaylist) {
                    return (
                        <div className="playlist_thumb_render">
                            <div className="playlist_thumb_wrap_nestedPlaylist">
                                <ContentThumbnail id={images[0]} width={100} height={56}
                                                  style={{display: 'block', height: '100%'}}/>
                            </div>
                            <div className="playlist_thumb_wrap_nestedIcon"><img src={ICON_SHARE}/></div>
                        </div>);
                } else {
                    if (original.playlistType === "5") { // tagplaylist
                        return (
                            <div className="playlist_thumb_render">
                                <div className="playlist_thumb_wrap playlist_folder_thumbnail">
                                <span className="float_l" style={{width: '52px'}}>
                                    <ContentThumbnail id={images[0]} height={29} style={{verticalAlign: 'middle'}}/>
                                </span>
                                </div>
                            </div>);
                    } else {
                        return (
                            <div className="playlist_thumb_render">
                                <div className="playlist_thumb_wrap">
                                <span style={{paddingLeft: 4}}>
                                    <ContentThumbnail id={images[0]} width={100} height={56}
                                                      style={{display: 'block', height: '100%'}}/>
                                </span>
                                </div>
                            </div>);
                    }
                }
            } else {
                return ''
            }
        }
        return '';
    };

    //const columns = useMemo(() => [items]);
    const renderModifiedDate = (lastModifiedDate) => {
        return <DeviceRelativeTimeCell value={lastModifiedDate} />
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'AllPlaylist_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox
                        id={items[row.index].playlistId}
                        index={row.index}
                        classname="table"
                        name="check"
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                )
            },
            width: 46,
            sortable: false,
            resizable: false,
            style: {"textAlign": "center"}
        },
        {
            accessor: "playlistId",
            show: false
        },
        {
            Header: t("TEXT_TITLE_CONTENT_P"),
            width: 147,
            accessor: 'thumbnailInfo',
            Cell: props => renderThumbnail(props.original),
            sortable: false,
        },
        {
            Header: t("TABLE_PLAYLIST_NAME_P"),
            accessor: "playlistName",
            width: 550,
            Cell: renderNameCell,
        },
        {
            Header: t("BUTTON_DETAIL_P"),
            width: 250,
            Cell: props => renderPlaylistType(props.original),
            sortable: false
        },
        {
            Header: t("COM_MAPP_SID_SUPPORTED_DEVICES"),
            width: 250,
            Cell: SupportedDeviceIconCell,
            sortable: false
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "lastModifiedDate",
            width: 180,
            Cell: props => renderModifiedDate(props.original.lastModifiedDate)
        },
        {
            Header: t("TEXT_CREATOR_P"),
            accessor: "creatorId"
        }
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow,'playlist_tr_group');

    const renderListView = () => {
        return (
            <div className="playlist_table_wrap">
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    noDataFunc={renderNodata}
                    page={page}
                    pages={pages}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    onSortedChange={onSortedChange}
                    getTrGroupProps={getTrGroupPropsType2}
                    //noDataText={t("MESSAGE_COMMON_NO_DATA_P")}
                    columns={columns}
                    className="-striped -highlight"
                    manual
                    showPagination={false}
                    style={style}
                />
                <Pagination totalCount={totalCount}
                            page={page}
                            defaultPageSize={pageSize}
                            pageSizeOptions={playlistConstants.PAGE_SIZE_OPTIONS}
                            onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            divide={props.divide}/>
            </div>
        )
    };

    const renderNodata = () => {
        return (
            <div className='no_data_wrap'>
                <div>
                    <img src={EMPTY_IMAGE} style={{marginTop:260}}/>
                    <span className="empty_title">{t('TEXT_TITLE_PLAYLIST_P')}</span>
                    <span>{getMixString(["MIS_SID_MIX_ADD_YOUR", "TEXT_TITLE_PLAYLIST_P"])}</span>
                </div>
            </div>
        );
    };


    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const preUserId = usePrevious(props.cache.filter.userId);
    const preGroupId = usePrevious(props.cache.filter.groupId);
    const preCurrContent = usePrevious(props.currContent);
    useEffect(() => {
        if (
            (preUserId !== undefined && preUserId !== props.userId) ||
            (preGroupId !== undefined && preGroupId !== props.groupId) ||
            (preCurrContent !== undefined && preCurrContent !== props.currContent)
        ) {
            if (props.mode === 'GROUPED' && (props.groupId === undefined && props.userId === undefined)) {
                return;
            }

            if(props.currContent === "PLAYLIST_BY_USER" &&
                props.groupId === "" &&
                props.userId === ""){
                return;
            }

            if (props.currContent === 'ALL_PLAYLIST') {   //MY_PLAYLIST
                setFilter({
                    page: 0,
                    pageSize: getPageSize('playlist'),
                    categoryIds: [],
                    playlistType: ['on'],
                    searchCreator: '',
                    startDate: '',
                    endDate: '',
                    keyword: '',
                    sorted: [{id: 'lastModifiedDate', desc: true}],
                    userId: '',
                    groupId: ''
                });
            } else if (props.currContent === 'MY_PLAYLIST') {
                setFilter({
                    page: 0,
                    pageSize: getPageSize('playlist'),
                    categoryIds: [],
                    playlistType: ['on'],
                    searchCreator: '',
                    startDate: '',
                    endDate: '',
                    keyword: '',
                    sorted: [{id: 'lastModifiedDate', desc: true}],
                    userId: props.userId !== undefined ? props.userId : '',
                    groupId: props.groupId !== undefined ? props.groupId : '',
                });
             } else {
                setFilter({
                    page: 0,
                    pageSize: getPageSize('playlist'),
                    categoryIds: [],
                    playlistType: ['on'],
                    searchCreator: '',
                    startDate: '',
                    endDate: '',
                    keyword: '',
                    sorted: [{id: 'lastModifiedDate', desc: true}],
                    userId: props.userId !== undefined ? props.userId : '',
                    groupId: props.groupId !== undefined ? props.groupId : '',
                });
            }
        }
    }, [props.groupId, props.userId]);

    return (
        <div style={{width: '100%',  display: (props.currContent === 'ALL_PLAYLIST' || props.currContent === 'MY_PLAYLIST' || props.currContent === 'PLAYLIST_BY_USER') ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton
                        id={"PLAYLIST_EDIT"}
                        name={t("COM_BUTTON_EDIT")}
                        onClick={onEditPlaylist}
                        disable={ selectedCnt !== 1}
                        authority={authority.CREATE || authority.MANAGE}
                    />
                    <WhiteButton
                        id={"PLAYLIST_DELETE"}
                        name={t("COM_BUTTON_DELETE")}
                        onClick={onDeletePlaylist}
                        disable={ selectedCnt < 1}
                        authority={authority.CREATE || authority.MANAGE}
                    />
                    <WhiteButton
                        id={"PLAYLIST_COPY"}
                        name={t("COM_IDS_EDIT_COPY")}
                        onClick={copyPlaylist}
                        disable={ selectedCnt !== 1}
                        authority={authority.CREATE || authority.MANAGE}
                    />
                    <WhiteButton
                        id={"PLAYLIST_MOVE"}
                        name={t("BUTTON_MOVE_P")}
                        onClick={movePlaylist}
                        disable={ selectedCnt < 1}
                        authority={authority.CREATE || authority.MANAGE}
                    />
                    <WhiteButton
                        id={"PLAYLIST_MULTIPLE"}
                        name={t("MIS_SID_20_EDIT_MULTIPLE")}
                        onClick={editMultiplePlaylist}
                        disable={ selectedCnt < 1}
                        authority={authority.CREATE || authority.MANAGE}
                    />
                    <WhiteButton
                        id={"PLAYLIST_EXPORT"}
                        name={t("BUTTON_EXPORT_P")}
                        onClick={handleExport}
                        style={{borderColor: '#5e5e5e'}}
                        authority={authority.READ || authority.CREATE || authority.MANAGE}
                    />
                </div>
                <div className="rightButton">
                    <CategoryButton onCategoryCheck={onCategoryCheck} defaultCheckedKeys={categoryIds}/>
                    <MultiSelectButton
                        itemAll={true}
                        title={t("MIS_SID_PLAYLIST_TYPE")}
                        data={getPlaylistTypes()}
                        onChange={onChangePlaylistType}
                        selected={filter.playlistType}
                        currContent={props.currContent}
                    />
                    <SearchBar
                        id="playlistDetailSearch"
                        placeholder={t("TABLE_PLAYLIST_NAME_P")}
                        onClickSearch={onKeywordChange}
                        onClickDetailSearch={onClickDetailSearch}
                        enableDetail={true}
                        keyword={keyword}
                        searchDetails={[
                            {title: 'creator', type: 'input'},
                            {title: 'date', type: 'date'},
                        ]}
                    />
                </div>
            </div>
            {showNoData && renderNodata()}
            {!showNoData && renderListView()}
        </div>
    )
};


export default connect(
    state => ({
        menu: state.menu,
    }),
    dispatch => ({
        openDetailView: (type) => dispatch(popupAction.openDetailView(type)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        addTab: (tab) => dispatch(menuAction.addTab(tab)),
        activeTab: (id) => dispatch(menuAction.activeTab(id)),
        loadContent: (id) => dispatch(menuAction.loadContent(id)),
        loadPlaylist: (playlistInfo) => dispatch(playlistAction.loadPlaylist(playlistInfo)),
        initEditPlaylist: (playlistInfo) => dispatch(playlistAction.initEditPlaylist(playlistInfo)),
        reloadGroup: (submenuId, groupId) => dispatch(menuAction.reloadGroup(submenuId, groupId)),
        destroyPlaylist : (mode) =>dispatch(playlistAction.destroyPlaylist(mode))
    })
)(AllPlaylist);