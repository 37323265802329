import React, {Component, Fragment} from "react";
import './DashboardPanel.css'
import './EdgeServerSummary.css'
import {SortableHandle} from "react-sortable-hoc";
import {connect} from "react-redux";
import {menuAction} from "../../actions";
import {withTranslation} from "react-i18next";
import Moment from "react-moment";
import {dashboardService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import {MoonLoader} from "react-spinners";
import {getErrorMessage} from "../../helper/responseHandler";

class EdgeServerSummary extends Component {

    state = {
        edgeServerInfo: {},
        loading: true,
    };

    constructor(props) {
        super(props);
        this.onAllServerClickEvent = this.onAllServerClickEvent.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.user = JSON.parse(localStorage.getItem('user'));
    }

    componentDidMount() {
        this.fetchGetDownloadServerInfo();
    }

    async onAllServerClickEvent() {
        const {removeTab, moveTab, t} = this.props;
        removeTab('SETTING');
        moveTab({id: 'SETTING', title : t("COM_TEXT_SETTING_P"), active: true, close: true}, 'EXTERNAL_SERVER_MANAGEMENT', 'EDGE_SERVER');
    }

    fetchGetDownloadServerInfo = () => {
        this.setState({loading: true,});
        dashboardService.fetchEdgeServerInfo().then(res => {
            this.setState({
                edgeServerInfo: res.items,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally(() => {this.setState({loading: false});});
    };

    render() {
        let {t} = this.props;
        let {edgeServerInfo, loading} = this.state;
        return (
            <div className='dashboard-widget'>
                {this.renderHeader()}
                <div className='dashboard-widget-body'>
                    {loading && <MoonLoader css={`position: absolute;top: calc(50% - 40px);left: calc(50% - 40px);`} color="#6e78c8"/>}
                    {!loading &&
                    <Fragment>
                        <div className='edge-summary'>
                            <div className='edge-summary-total' onClick={this.onAllServerClickEvent}>
                                {edgeServerInfo.totalServerCnt}<span>{t("MIS_SID_20_TOTAL_NUMBER_OF_SERVERS")}</span>
                            </div>
                            <div className='edge-summary-running'>
                                {edgeServerInfo.runningCnt}<span>{t("MIS_SID_20_NUMBER_OF_RUNNING_SERVERS")}</span>
                            </div>
                        </div>
                        <div className='edge-server-list'>
                            {this.renderServerList()}
                        </div>
                    </Fragment>
                    }
                </div>
            </div>
        )
    };

    renderServerList() {
        let {edgeServerInfo} = this.state;
        if(!edgeServerInfo.severInfolist) {
            return (null);
        }
        return edgeServerInfo.severInfolist.map((server, index) => {
            let status = 'red';
            if(server.isServerChk === 'NORMAL') {
                status = 'green';
            }
            return (
                <div key={index} className='edge-server-row'>
                    <div className='status' ><span className={status}></span></div>
                    <div className='title' title={server.serverName}>{server.serverName}</div>
                    <div className='id' title={server.ipAddress}>{server.ipAddress}</div>
                    <div className='date'><Moment format={this.user ? this.user.dateFormat.replace('yyyy', 'YYYY').replace('mm', 'MM').replace('dd', 'DD') : 'YYYY-MM-DD'}>{server.currentDate}</Moment></div>
                </div>
            );
        });
    }

    renderHeader() {
        let {id, isEditMode, t} = this.props;
        const DragHandle = SortableHandle(() =>
            <div className='title'>{t("MIS_SID_EDGE_SERVER")}</div>
        );
        return (
            <div className='dashboard-widget-header'>
                <DragHandle />
                {
                    isEditMode && <div title="Close" className="remove" onClick={() => this.props.onRemoveLayoutClickEvent(id)}></div>
                }
            </div>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        moveTab: (tab, id, childrenId) => dispatch(menuAction.moveTab(tab, id, childrenId)),
        removeTab: (id) => dispatch(menuAction.removeTab(id)),
    })
)(withTranslation()(EdgeServerSummary));
