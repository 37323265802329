import React, { useState, useEffect }  from 'react'
import {isNil, get, set} from 'lodash';
import { withTranslation, Trans } from "react-i18next";
import { useTranslation } from 'react-i18next';
import { settingService } from '../../services';
import WhiteButton from "../../components/button/WhiteButton";
import { getErrorMessage } from "../../helper/responseHandler";
import { createMultilineHtmlStringUsingSeperator } from "../../helper/utils";
import "./ServerSettings.css";


const DeviceSummary = (props) => {
    const {t} = useTranslation();
    const [data, setData] = useState({deviceSummary: [], modelSummary: [], firmwareSummary:[]});

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => { 
        settingService.fetchDeviceSummary().then(res => {
            setData({
                deviceSummary: res.items.deviceSummary, 
                modelSummary: res.items.modelSummary,
                firmwareSummary: res.items.firmwareSummary
            });
        });
    }

    const exportDeviceSummary = () => {
        settingService.exportDeviceSummary().then(res => {

        }).catch(e => {
            getErrorMessage(e);
        })
    }

    return (
        <>
        <div className="contents_buttonWrap" style={{display: props.currContent === 'DEVICE_SUMMARY' ? 'block':'none'}}>
            <div className="leftButton float_l">
                <WhiteButton id={"EXPORT"} name={t("BUTTON_EXPORT_P")} onClick={exportDeviceSummary}/>
            </div>
        </div>
        <div className="settings_list mt20" style={{borderTop: '1px solid#f2f2f2', overflow: 'hidden', height: window.innerHeight - 180,display: props.currContent === 'DEVICE_SUMMARY' ? 'block':'none'}}>
            <div style={{height: '40%'}}>
                <div className="float_l" style={{height: '100%', width: '45%', overflowY: 'hidden', margin: '20px'}}>
                    <span><b>{t("TABLE_DEVICE_MODEL_NAME_P")}</b></span>
                    <div className="server_setting none_scrolling check_list mr10 mt10">
                        <table style={{width:'100%'}}>
                            <colgroup>
                                <col width="50%"></col>
                                <col width="50%"></col>
                            </colgroup>
                            <thead>
                                <tr>    
                                    <th>{t("TABLE_DEVICE_MODEL_NAME_P")}</th>
                                    <th>{t("TABLE_DEVICE_COUNT_P")}</th>
                                </tr>
                            </thead>
                        </table>
                        <div style={{width:'100%', height:'200px', overflow:'auto'}}>
                            <table style={{width:'100%'}}>
                                <colgroup>
                                    <col width="50%"></col>
                                    <col width="50%"></col>
                                </colgroup>
                                <tbody>
                                    {
                                        !isNil(data.modelSummary) && data.modelSummary.length > 0 &&
                                        data.modelSummary.map((model) =>
                                            <tr key={model.modelName}>
                                                <td>{model.modelName}</td>
                                                <td>{model.deviceCount}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="float_r" style={{height: '100%', width: '45%', overflow: 'auto', margin: '20px'}}>
                <span><b>{t("TABLE_SOFTWARE_VERSION_P")}</b></span>
                    <div className="server_setting none_scrolling check_list mr10 mt10">
                        <table style={{width:'90%'}}>
                            <colgroup>
                                <col width="50%"></col>
                                <col width="50%"></col>
                            </colgroup>
                            <thead>
                                <tr>    
                                    <th>{t("TABLE_FIRMWARE_VERSION_P")}</th>
                                    <th>{t("TABLE_DEVICE_COUNT_P")}</th>
                                </tr>
                            </thead>
                        </table>
                        <div style={{width:'90%', height:'200px', overflow:'auto'}}>
                            <table style={{width:'100%'}}>
                                <colgroup>
                                    <col width="50%"></col>
                                    <col width="50%"></col>
                                </colgroup>
                                <tbody>
                                    {
                                        !isNil(data.firmwareSummary) && data.firmwareSummary.length > 0 &&
                                        data.firmwareSummary.map((firmware) =>
                                            <tr key={firmware.firmwareName}>
                                                <td style={{whiteSpace:'normal'}}>{createMultilineHtmlStringUsingSeperator(firmware.firmwareName,";",'N/A')}</td>
                                                <td>{firmware.deviceCount}</td>
                                            </tr>
                                        )
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: '60%', margin: '0 20px', float: 'left', width: '93%'}}>
                <span><b>{t("COM_MRMS_CON_MENU_DEVICE_LIST")}</b></span>
                <div className="server_setting none_scrolling check_list mr10 mt10" style={{ overflowY: 'auto', height: '70%'}}>
                    <table style={{width:'100%'}}>
                        <colgroup>
                            <col width="20%"></col>
                            <col width="17%"></col>
                            <col width="15%"></col>
                            <col width="15%"></col>
                            <col width="18%"></col>
                            <col width="15%"></col>
                        </colgroup>
                        <thead>
                            <tr>    
                                <th>{t("DID_ADMIN_LICENSE_MAC")}</th>
                                <th>{t("TABLE_DEVICE_NAME_P")}</th>
                                <th>{t("TABLE_IP_ADDR_P")}</th>
                                <th>{t("TABLE_DEVICE_MODEL_NAME_P")}</th>
                                <th>{t("TABLE_FIRMWARE_VERSION_P")}</th>
                                <th>{t("MIS_TEXT_SERIAL_KEY_P")}</th>
                            </tr>
                        </thead>
                    </table>
                    <div style={{width:'100%', height:'200px', overflow:'auto'}}>
                        <table style={{width:'100%'}}>
                            <colgroup>
                                <col width="20%"></col>
                                <col width="17%"></col>
                                <col width="15%"></col>
                                <col width="15%"></col>
                                <col width="18%"></col>
                                <col width="15%"></col>
                            </colgroup>
                            <tbody>
                                {
                                    !isNil(data.deviceSummary) && data.deviceSummary.length > 0 &&
                                    data.deviceSummary.map((device) =>
                                        <tr key={device.deviceId||'-'}>
                                            <td>{device.deviceId||'-'}</td>
                                            <td>{device.deviceName||'-'}</td>
                                            <td>{device.ipAddress||'-'}</td>
                                            <td>{device.deviceModelName||'-'}</td>
                                            <td style={{whiteSpace:'normal'}}>{createMultilineHtmlStringUsingSeperator(device.firmwareVersion,";","-")}</td>
                                            <td>{device.serialDecimal||'-'}</td>
                                        </tr>
                                    )
                                }                            
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default (withTranslation()(DeviceSummary));