import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {isNil} from 'lodash';
import MISDialog from '../../MISDialog';
import moment from 'moment';
import {popupAction} from "../../../../actions";
import {commonConstants} from '../../../../constants';
import {deviceOptions, useMISOpt} from '../../../misopt'
import RadioGroup from '../../../radio/RadioGroup';
import Select from '../../../selectbox/Select';
import {deviceService} from '../../../../services';
import DateInput from '../../../input/DateInput';
import WhiteButton from '../../../button/WhiteButton';
import {dateToString, toMomentDateTimeFormat} from '../../../../helper';
import './PublishSoftwarePopup.css';
import TimeSelect from '../../../selectbox/TimeSelect';
import PublishGroup from '../../../device/PublishGroup';

const PublishSoftwarePopup = ({softwareId, onPublish, onClose}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
    const [swApplicableDevices, setSwApplicableDevices] = useState([]);
    
    const now = new Date();
    const [software, setSoftware] = useState({
        publishType: 'NOW',
        appliedType: '',
        deployAppliedVer: '',
        rsvDate: now,
        rsvTime: moment()
    });

    const onClickGroup = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_GROUP_POPUP,
            type: commonConstants.COMMON_GROUP_POPUP,
            mode: 'device',
            checkbox: true,
            allEnabled: true,
            save: groups => {
                setSoftware({...software, groups});
                dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
            }, 
            close: () => dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP)),
        }));
    };

    const onDeleteGroup = groupId => {
        const newGroups = software.groups.filter(group => group.groupId !== groupId);
        setSoftware({...software, groups: newGroups});
    };

    const onClickPublish = () => {
        const {groups, rsvDate, rsvTime} = software;

        if( software.publishType !== 'NOW') {
            if(isNil(rsvDate) || rsvDate === null) {
                toastr.error(t('MESSAGE_SCHEDULE_CHECK_DATE_VALUES_P'));
                return;
            }

            if(isNil(rsvTime) || rsvTime === null) {
                toastr.error(t('MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P'));
                return;
            }
        }

        if(software.appliedType !== 'MODEL' && (isNil(groups) || groups.length === 0)) {
            toastr.error(t('MESSAGE_DEVICE_SELECT_DEVICE_GROUP_P'));
            return;
        }

        const rsvDateTime = moment(rsvDate);
        rsvDateTime.hour(rsvTime.hour());
        rsvDateTime.minute(rsvTime.minute());

        let groupIds ;
        if(software.appliedType !== 'MODEL'){
            groupIds = groups.map(group => group.groupId);
        }

        deviceService.publishSoftware({
            softwareId: software.softwareId,
            appliedType: software.appliedType,
            deployAppliedVer: software.deployAppliedVer,
            // groupId: (groups && groups.length > 0) ? groups[0].groupId : null,
            groupIds: groupIds,
            rsvDate: software.publishType === 'NOW' ? 'NOW' : rsvDateTime.format(dateTimeFormat)
        }).then(res => {
            toastr.success(t('MESSAGE_DEVICE_SOFTWARE_FILE_DEPLOY_SUCCESS_P'));
            if(onPublish) {
                onPublish();
            }
        }).catch(error => {
            if(error.errorCode === '500633') {
                toastr.error(t('MIS_SID_ALREADY_APPLIED_FIRWMARE')); //+' : '+json.errorMessage.ALREADY_FIRMWARE
            } else if(error.errorCode === '500634') {
                toastr.error(t('MIS_SID_SERVER_DISCONNECTED_DEVICE')); //+' : '+json.errorMessage.OFFLINE_DEVICE);
            } else {
                toastr.error(t('MESSAGE_DEVICE_SOFTWARE_FILE_DEPLOY_FAIL_P'));
            }
            onClose && onClose();
        });
    };

    useEffect(() => {
        deviceService.fetchSoftwareForPublish(softwareId).then(res => {
            // setSoftware(Object.assign(res.items, software));
            if(res.items.deviceType !== 'WPLAYER' || res.items.softwareType !== '05') {
                setSwApplicableDevices([...deviceOptions.swApplicableDevices]);
                setSoftware({
                    ...res.items,
                    publishType: 'NOW',
                    appliedType: 'MODEL',
                    deployAppliedVer: 'VERSION_ALL',
                    rsvDate: now,
                    rsvTime: moment()
                });
            } else {
                setSwApplicableDevices([deviceOptions.swApplicableDevices[1]]);
                setSoftware({
                    ...res.items,
                    publishType: 'NOW',
                    appliedType: 'GROUP',
                    deployAppliedVer: '',
                    rsvDate: now,
                    rsvTime: moment()
                });
            }
        }).catch(error => {
            console.log(error);
            toastr.error(t('COM_BDP_SID_ASC_POPUP_LIST_TEXT_PREPARING_KR_DOT') + t('COM_PLEASE_WAIT_A_MOMENT_AND_TRY_AGAIN'));
            onClose && onClose();
        }).finally(() => {

        });
    }, [softwareId]);

    let versionOption = [{value: 'VERSION_ALL', title: 'VERSION_ALL'}]; 
    
    if(software.versionList) {
        software.versionList.forEach(ver => {
            versionOption.push({value: ver, title: ver});
            return true;
        });
    }



    return (
        <MISDialog 
            dialog={{
                title: t('MIS_SID_PUBLISH'),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 780,
                height: 550,
                // position: {x: -380, y: -350},
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'MIS_SID_PUBLISH',
                        title: t('MIS_SID_PUBLISH'),
                        onClick: onClickPublish,
                        // disable: !isEmpty(error)
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className='devicepop'>
                <div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="140px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{t('MIS_TEXT_DEVICE_TYPE_P')}</th>
                                <td>
                                    {software.deviceType}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('TABLE_DEVICE_MODEL_NAME_P')}</th>
                                <td>
                                    {software.deviceModelName}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('COM_TEXT_SOFTWARE_NAME_P')}</th>
                                <td>
                                    {software.softwareName}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('TEXT_SOFTWARE_FILE_NAME_P')}</th>
                                <td>
                                    {software.softwareFileName}
                                </td>
                            </tr>
                            {
                                software.softwareType !== '' &&
                                <tr>
                                    <th>{t('TEXT_SOFTWARE_TYPE_P')}</th>
                                    <td>
                                        {software.softwareType === '05' ? t('MIS_SID_WEB_APP') : ''}
                                    </td>
                                </tr>
                            }
                            <tr>
                                <th>{t('TABLE_SOFTWARE_VERSION_P')}</th>
                                <td>
                                    {software.softwareVersion}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('TEXT_CRC_INFO_P')}</th>
                                <td>
                                    {software.crc}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="140px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{t('MIS_ID_PUBLISH_TIME')}</th>
                                <td>
                                    <RadioGroup selects={deviceOptions.swPublishOptions} value={software.publishType} onChange={(e, value) => setSoftware({...software, publishType: value})} />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('COM_ID_SCHEDULE_SCHEDULE_PUBLISH')}</th>
                                <td>
                                    <span className='mr8'>
                                        <DateInput width={120} minDate={now} date={dateToString(software.rsvDate)} disabled={software.publishType !== 'RESERVED'} onChange={value => setSoftware({...software, rsvDate: value})} />
                                    </span>
                                    <span>
                                        <TimeSelect style={{width: 120}} format='hh:mm A' disabled={software.publishType !== 'RESERVED'} value={software.rsvTime} showSecond={false} onChange={value => setSoftware({...software, rsvTime: value})} />
                                    </span>
                                </td>
                            </tr>
                            {
                                software.deployAppliedVer !== '' &&
                                <tr>
                                    <th>{t('LIST_TITLE_APPLY_VERSION')}</th>
                                    <td>
                                        <Select width={400} multiLang={false} selects={versionOption}
                                                value={software.deployAppliedVer} onChange={(e, value) => setSoftware({
                                            ...software,
                                            deployAppliedVer: value
                                        })}/>
                                    </td>
                                </tr>
                            }
                            {
                                swApplicableDevices.length > 0 &&
                                <tr>
                                    <th>{t('TEXT_SELECT_APPLICABLE_DEVICE_P')}</th>
                                    <td>
                                        <RadioGroup selects={swApplicableDevices} value={software.appliedType}
                                                    onChange={(e, value) => setSoftware({
                                                        ...software,
                                                        appliedType: value
                                                    })}/>
                                        {
                                            (software.appliedType === 'GROUP' || swApplicableDevices.length === 1) &&
                                            <WhiteButton name={t('TEXT_SELECT_P')} onClick={onClickGroup}/>
                                        }
                                    </td>
                                </tr>
                            }
                            <tr>
                                <th></th>
                                <td>
                                    <div className='group_container'>
                                        {
                                            software.groups &&
                                            software.groups.map(group => <PublishGroup key={group.groupId} group={group} onDelete={() => onDeleteGroup(group.groupId)} />)
                                        }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    );
};

export default PublishSoftwarePopup;