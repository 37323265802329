import React, {useState} from "react";
import "./SelectMultipleContentsPopup.css";
import MISDialog from "../../MISDialog";
import {useTranslation, withTranslation} from "react-i18next";
import {Contents} from "./Contents";
import {Playlists} from "./Playlists";
import {toastr} from 'helper/toastrIntercept';
import {Statistics} from "./Statistics";

const SelectMultipleContentsPopup = ({device, contentType, handleSelected, multiSelect, onClose}) => {
    const { t } = useTranslation();

    const [selectedContents, setSelectedContents] = useState([]);

    if(multiSelect === undefined) {
        multiSelect = true;
    }

    const dialogProps = {
        title: t("COM_TEXT_SELECT_CONTENT_P"),
        closeOnEscape: true,
        width: 980,
        height: 480,
        // position: { x: (680 / 2) * -1, y: (480 / 2) * -1 },
        modal: true,
        onClose: () => onClose()
    };
    const buttons = {
        rightButtons: [
            {
                id: "SELECT_CONTENT",
                title: t("BUTTON_OK_P"),
                onClick: () => {
                    if(!selectedContents.length){
                        toastr.error(t("MESSAGE_CONTENT_SELECT_ONE_OR_MORE_CONTENT_P"));                        
                    }else{
                        handleSelected(selectedContents);
                        onClose();
                    }                   
                }
            },
            {
                id: "CLOSE_CONTENT_POPUP",
                title: t("BUTTON_CANCEL_P"),
                onClick: () => onClose()
            }           
        ]
    };

    return (
        <div className="select_multiple_content_dialog">
            <MISDialog {...dialogProps} dialog={dialogProps} buttons={buttons}>
                { contentType === "statistics" && (
                    <Statistics
                        device= {device}
                        selectedContents={setSelectedContents}
                        multiSelect={multiSelect}
                    />
                )}
                { contentType === "content" && (
                    <Contents
                        device= {device}                        
                        selectedContents={setSelectedContents}
                        multiSelect={multiSelect}
                    />
                )} 
                { contentType === "playlist" && (
                    <Playlists
                        device= {device}                        
                        selectedContents={setSelectedContents}
                        multiSelect={multiSelect}
                    />
                )}
            </MISDialog>
        </div>
    );
};

export default withTranslation()(SelectMultipleContentsPopup);
