import React from 'react';
import {useDispatch} from 'react-redux';
import {menuAction} from '../../actions/';
import './MenuButton.css';
import {tabsConstants} from "../../constants/TabsConstants";

const MenuButton = ({id, active, title, icon}) => {

    const dispatch = useDispatch();

    const addTab = () => {
        const tab = tabsConstants[id];
        dispatch(menuAction.addTab(tab))
    }

    return (
        <li className={active === true ? 'on' : ''} onClick={addTab}>
            <a id={id} href="#">
                <span className={icon}></span>
                {title}
            </a>
        </li>
    )
}
export default MenuButton