import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import WhiteButton from "../../components/button/WhiteButton";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import SupportedDeviceIcon from "../../components/icon/SupportedDeviceIcon";
import "../../components/table/react-table.css";
import {useDispatch} from "react-redux";
import {commonConstants, RULESET_FILTERS, RULESET_PAGE_SIZE_OPTIONS} from "../../constants";
import {useTranslation} from "react-i18next";
import {rulesetService} from '../../services/ruleset.service';
import {menuAction, popupAction} from "../../actions";
import {rulesetAction} from '../../actions/ruleset.action';
import {toastr} from 'helper/toastrIntercept';
import GroupsPopup from '../../components/popup/GroupsPopup';
import {getMixString} from '../../language/languageUtils';
import Filter from "../../components/filter/Filter";
import fileDownload from 'js-file-download';
import '../../components/ruleset/Ruleset.css';
import {getPageSize, useCheckRefWithSelectedCnt as useCheckRef, useFilter, usePrevious, useResizeWindow} from '../../helper';
import {getErrorMessage} from '../../helper/responseHandler';
import {useMISOpt} from '../../components/misopt';
import {snakeCase} from "lodash";
import EMPTY_IMAGE from "../../images/img/empty_img_ruleset.png";
import DeviceRelativeTimeCell from '../../components/device/DeviceRelativeTimeCell';
import MagicInfoTable from '../../components/table/MagicInfoTable';
import {useTrGroupProps} from "../../helper/tables";
import {updateCache} from "../../helper/cache/tableCache";


const AllRuleset = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {getAuthority} = useMISOpt();
    const [authority] = useState(getAuthority('CONTENT_PLAYLIST_RULESET'));
    const [showNoData, setNoData] = useState(false);
    
    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [popups, setPopups] = useState({
        rulesetGroupPopup: false,
    });

    const [style, setStyle] = useState({height: '762px'});
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted} = filter;

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);
    let filterComp = useRef();
    const fetchData = (reloadGroup = false) => {
        const {page, pageSize, keyword, sorted: [{id, desc}], groupId, deviceType, deviceTypeVersion, customFilter} = filter;
        setData({...data, loading: true});
        rulesetService.fetchRulesetFilter({
            ...customFilter,
            startIndex: (page * pageSize) + 1,
            pageSize,
            searchText: keyword,
            groupId: groupId,
            deviceType: deviceType,
            deviceTypeVersion: deviceTypeVersion,
            sortColumn: snakeCase(id).toUpperCase(),
            sortOrder: desc ? 'DESC' : 'ASC',
            includeTemp: true   // Ruleset 메뉴에서만 사용. is_temp 가 true 인 ruleset은 Schedule 사용 불가.
        }).then(res => {
            if(res.totalCount === 0){
                setNoData(true);
            }
            else {
                setNoData(false);
            }
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount});
            if(reloadGroup) {
                dispatch(menuAction.reloadGroup('RULESET_BY_GROUP'));
            }
            updateCache('RULESET', {items: res.items, filter: filter, totalCount: res.totalCount}, props.currContent)
        });
    };

    const editRuleset = () => {
        const rulesetIds = getCheckedId();
        if(rulesetIds.length > 1 || rulesetIds.length === 0) {
            toastr.error(t("MESSAGE_COMMON_SELECT_ONE_CHECKBOX_P"));
        }

        else {
            const rulesetId = rulesetIds[0];
            dispatch(popupAction.addPopup({id: 'EDIT_RULESET_LOADING_POPUP', type : commonConstants.LOADING_POPUP}));
            rulesetService.fetchRulesetById(rulesetId).then(res => {
                if(res.items) {
                    const tab = {id: 'EDIT_RULESET', title: getMixString(['MIS_SID_MIX_CAFEB_EDIT', 'MIS_SID_RULESET']), active: true, close: true};
                    dispatch(menuAction.addTab(tab));
                    dispatch(rulesetAction.initEditRuleset(res.items));
                    dispatch(menuAction.loadContent('EDIT_RULESET'));
                    dispatch(popupAction.closePopup('EDIT_RULESET_LOADING_POPUP'));
                }
            }).catch((error) => {
                dispatch(popupAction.closePopup('EDIT_RULESET_LOADING_POPUP'));
                toastr.error(getErrorMessage(error));
            });
        }
    };

    const deleteRuleset = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_DELETE"),
            message: t("COM_TV_SID_DO_YOU_WANT_TO_DELETE_THE_SELECTED_ITEMS_KR_SELECTED"),
            onClickYes: handleDelete,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    };

    const changeGroup = () => {
        showRulesetGroupPopup();
    };

    const saveAs = () => {
        const rulesetIds = getCheckedId();
        if(rulesetIds.length > 1 || rulesetIds.length === 0) {
            toastr.error(t("MESSAGE_COMMON_SELECT_ONE_CHECKBOX_P"));
        }

        else {
            const rulesetId = rulesetIds[0];
            rulesetService.fetchRulesetById(rulesetId).then(
                res => {
                    if(res.items) {
                        const ruleset = res.items;
                        dispatch(popupAction.addPopup({
                            type: commonConstants.SAVE_RULESET,
                            id: 'SAVE_RULESET',
                            mode: "SAVE_AS_FROM_LIST",
                            ruleset: ruleset,
                            onClose: () => {
                                fetchData(true);
                            }
                        }));
                    }
                }
            ).catch(error => toastr.error(getErrorMessage(error)))
            .finally();
        }
    };

    const exportRuleset = () => {
        const {keyword, sorted: [{id, desc}], groupId, deviceType, deviceTypeVersion, customFilter} = filter;
        
        dispatch(popupAction.addPopup({id: 'EXPORT_RULESET_LOADING_POPUP', type : commonConstants.LOADING_POPUP}));

        rulesetService.exportRuleset("EXCEL", {
            startIndex: 1,
            pageSize: data.totalCount,
            searchText: keyword,
            groupId: groupId,
            sortColumn: snakeCase(id).toUpperCase(),
            sortOrder: desc ? 'DESC' : 'ASC',
            includeTemp: true,   // Ruleset 메뉴에서만 사용. is_temp 가 true 인 ruleset은 Schedule 사용 불가.
            deviceType: deviceType,
            deviceTypeVersion: deviceTypeVersion,
            rulesetStatus: customFilter.rulesetStatus,
            creatorIds: customFilter.creatorIds
        }).then(res => {
            fileDownload(res.blob, res.fileName);
            dispatch(popupAction.closePopup('EXPORT_RULESET_LOADING_POPUP'));
        }).catch(error => toastr.error(getErrorMessage(error)));
    };

    const handleDelete = () => {
        const rulesetIds = getCheckedId();

        const params = {
            deleteMethod: 'GO_TO_RECYCLEBIN',
            rulesetIds: rulesetIds
        }

        rulesetService.fetchDeleteRuleset(params)
            .then(res => {
                toastr.success(t('ALERT_SUCCESS_DELETE'));
                fetchData(true);
            })
            .catch((error) => {
                if(error.items && error.items.failList && error.items.failList.length > 0) {

                    dispatch(popupAction.addPopup({
                        type: commonConstants.USED_RULESET_DELETE,
                        id: commonConstants.USED_RULESET_DELETE,
                        usedRulesetList: error.items.failList,
                        onClickYes: () => {
                            rulesetService.fetchDeleteRuleset({
                                deleteMethod: 'GO_TO_RECYCLEBIN_FORCE',
                                rulesetIds: rulesetIds
                            }).then(res => {
                                toastr.success(t('ALERT_SUCCESS_DELETE'));
                                fetchData(true);
                            }).catch(error => toastr.error(getErrorMessage(error)));
                        }
                    }));
                }

                else {
                    toastr.error(getErrorMessage(error));
                }
            })
            .finally(dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)));
    };

    const openDetailViewPopup = (e, rulesetId) => {
        dispatch(
            popupAction.openDetailView({
                type: commonConstants.COMMON_DETAIL_VIEW,
                id: commonConstants.RULESET_DETIAL_POPUP,
                viewType: "RULESET",
                rulesetId: rulesetId,
                refresh: () => fetchData()
            })
        );
    };

    useResizeWindow(() => {
        setStyle({height: window.innerHeight - 204});
    });

    useEffect(() => {
        setStyle({height: window.innerHeight - 204});
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const preGroupId = usePrevious(props.groupId);
    const preCurrContent = usePrevious(props.currContent);

    useEffect(()=> {
        if (
            (preCurrContent !== undefined && preCurrContent !== props.currContent) ||
            (preGroupId !== undefined && preGroupId !== props.groupId)
        ) {
            const pageSize =  getPageSize('RULESET');
            setFilter({...RULESET_FILTERS['ALL_RULESET'], groupId : props.groupId, pageSize});
        }

        if(filterComp.current !== undefined){
            filterComp.current.resetCheckBox();
        }
    }, [props.groupId])

    const showRulesetGroupPopup = () => {
        setPopups({...popups, rulesetGroupPopup: !popups.rulesetGroupPopup});
    }

    const setRulesetGroup = (groups) => {
        if(groups !== undefined && groups.length > 0) {
            showRulesetGroupPopup();

            let rulesetIds = [];
            checkBoxes.current.map((checkbox, index) => {
                if (checkbox.checked) {
                    const {rulesetId} = data.items[index];
                    rulesetIds.push(rulesetId);
                }
            });

            rulesetService.moveRuleset(rulesetIds, groups[0].groupId)
            .then(res => {
                toastr.success(t('COM_TEXT_SUCCESS_P'));
                fetchData(true);
            })
            .catch(error => toastr.error(getErrorMessage(error)))
            .finally();
        }
        else {
            toastr.error(t('MESSAGE_COMMON_SELECT_GROUP_P'));
        }
    }

    const onSaveFilter = (result) => {
        if(result.devices && result.devices.length > 0) {
            delete filter.deviceType;
            delete filter.deviceTypeVersion;
            let maxDeviceVersion = -1;
            
            for(let i = 0; i < result.devices.length; i++) {
                if(result.devices[i] === "ALL" || result.devices[i] === "iPLAYER") {
                    maxDeviceVersion = 0;
                    break;
                }

                if(/S([0-9]|10)+PLAYER/.test(result.devices[i])) {
                    let n = parseInt(result.devices[i].replace(/([^0-9])/g,""));
                    if(n > maxDeviceVersion) {
                        maxDeviceVersion = n;
                    }
                }

                else if(result.devices[i] === "SPLAYER") {
                    if(1 > maxDeviceVersion) {
                        maxDeviceVersion = 1;
                    }
                }
            }

            if(maxDeviceVersion > 0) {
                setFilter({
                    ...filter,
                    deviceType: "SPLAYER",
                    deviceTypeVersion: maxDeviceVersion,
                    customFilter: {
                        rulesetStatus: result.rulesetStatus,
                        creatorIds: result.users,
                        deviceTypes: result.devices,
                    }});
            }

            else if(maxDeviceVersion === 0) {
                setFilter({
                    ...filter,
                    customFilter: {
                        rulesetStatus: result.rulesetStatus,
                        creatorIds: result.users,
                        deviceTypes: result.devices,
                    }});
            }

            else {
                setFilter({
                    ...filter,
                    deviceType: "SPLAYER",
                    deviceTypeVersion: 0,
                    customFilter: {
                        rulesetStatus: result.rulesetStatus,
                        creatorIds: result.users,
                        deviceTypes: result.devices,
                    }});
            }
        }

        else {
            setFilter({
                ...filter,
                customFilter: {
                    rulesetStatus: result.rulesetStatus,
                    creatorIds: result.users,
                }
            });
        }
    };

    const getCheckedId = () => {
        return selected.current.map(s => items[s].rulesetId);
    }

    const renderNodata = () => {
        return (
            <div className='ruleset no_data_wrap'>
                <div>
                    <img src={EMPTY_IMAGE} style={{marginTop:260}}/>
                        <span>{getMixString(["MIS_SID_MIX_ADD_YOUR", "MIS_SID_RULESET"])}</span>
                </div>
            </div>
        );
    };
    
    const columns = useMemo(()=>[
        {
            accessor: "rulesetId",
            show: false
        },
        {
            id: "checkbox",
            width: 46,
            sortable: false,
            Header:() => {
                return (
                    <Checkbox
                        id={'AllRuleset_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={items[row.index].rulesetId}
                        index={row.index}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                )
            },
            resizable: false
        },
        {
            Header: t("LIST_TITLE_NAME"),
            accessor: "name",
            Cell: props => (
                <span
                    className={"data_name"}
                    title={props.original.name}
                    onClick={e => openDetailViewPopup(e, props.original.rulesetId)}
                >
                    {props.original.name}
                </span>
            ),
            width: 450
        },
        {
            Header: getMixString(["MIS_SID_MIX_NUMBER_OF", "MIS_SID_RULE_TREE"]),
            accessor: "numberOfRules",
            width: 150
        },
        {
            Header: t("COM_MAPP_SID_SUPPORTED_DEVICES"),
            Cell: props => <SupportedDeviceIcon deviceType={props.original.deviceType} deviceTypeVersion={props.original.deviceTypeVersion}/>,
            width: 200,
            sortable: false
        },
        {
            Header: t("TEXT_STATUS_P"),
            accessor: "programId",
            Cell: props => (
                <span className={props.original.isTemp ? "ruleset-unschedulable" : ""}>
                    {props.original.programId &&
                        t("COM_SID_USED_KR_MIS20")
                    }
                    {props.original.programId === undefined && !props.original.isTemp &&
                        t("MIS_SID_NOT_USED")
                    }
                    {props.original.programId === undefined && props.original.isTemp &&
                        t("MIS_SID_CAMAR_IMCOMPLETE")
                    }
                </span>
            ),
            width: 150,
            sortable: false
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "modifyDate",
            width: 200,
            Cell: ({value}) => <DeviceRelativeTimeCell value={value} />
        },
        {
            Header: t("TEXT_CREATOR_P"),
            accessor: "creator",
            minWidth: 150
        }
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);

    return (
        <div style={{width: '100%'}} className={'ruleset_table_wrap'} style={{display: props.currContent === 'ALL_RULESET' || props.currContent === 'RULESET_BY_GROUP' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id="rulesetEdit" name={t("COM_BUTTON_EDIT")} disable={selectedCnt !== 1} authority={authority.CREATE || authority.MANAGE} onClick={()=>editRuleset()}/>
                    <WhiteButton id="rulesetDelete" name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} authority={authority.CREATE || authority.MANAGE} onClick={()=>deleteRuleset()}/>
                    <WhiteButton id="rulesetMove" name={t("BUTTON_MOVE_P")} disable={selectedCnt < 1} authority={authority.CREATE || authority.MANAGE} onClick={()=>changeGroup()}/>
                    <WhiteButton id="rulesetSaveAs" name={t("BUTTON_SAVE_AS_P")} disable={selectedCnt !== 1} authority={authority.CREATE || authority.MANAGE} onClick={()=>saveAs()}/>
                    <WhiteButton id="rulesetExport" name={t("BUTTON_EXPORT_P")} onClick={()=>exportRuleset()}/>
                </div>
                <div className="rightButton">
                    <Filter type={"RULESET"} width={590} onRef={ref => (filterComp.current = ref)} onSaveFilter={onSaveFilter} cachedFilter={filter.customFilter}/>
                    <div className="float_l ml8">
                        <SearchBar
                            id="rulesetSearch"
                            placeholder={getMixString(["MIS_SID_MIX_NAME", "MIS_SID_RULESET"])}
                            onClickSearch={onKeywordChange}
                            enableDetail={false}
                            keyword={filter.keyword}
                        />
                    </div>
                </div>
            </div>
            { 
                popups.rulesetGroupPopup &&
                    <GroupsPopup
                        mode="ruleset"
                        checkbox={false}
                        close={() => showRulesetGroupPopup()}
                        save={(groups) => setRulesetGroup(groups)}/>
            }
            { 
                <div className="ruleset_list_view" style={{width: "100%"}}>
                    <MagicInfoTable
                        data={items}
                        loading={loading}
                        sorted={sorted}
                        noDataFunc={renderNodata}
                        onSortedChange={onSortedChange}
                        style={style}
                        columns={columns}
                        noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                        getTrGroupProps={getTrGroupPropsType2}
                        usePagination={true}
                        paginationOptions={{
                            totalCount: totalCount,
                            page: page,
                            defaultPageSize: pageSize,
                            pageSizeOptions: RULESET_PAGE_SIZE_OPTIONS,
                            onPageChange: onPageChange,
                            onPageSizeChange: onPageSizeChange,
                            divide: props.divide
                        }}
                    />
                </div>
            }
        </div>
    )
}

export default memo(AllRuleset);