import {getPageSize} from "../helper";

export const contentConstants = {

    REQUEST_CONTENT : 'REQUEST_CONTENT',
    FAIL_CONTENT : 'FAIL_CONTENT',

    GET_CONTENT_GROUP: 'GET_CONTENT_GROUP',
    REMOVE_CONTENT_GROUP : 'REMOVE_CONTENT_GROUP',
    ADD_CONTENT_GROUP : 'ADD_CONTENT_GROUP',
    MODIFY_CONTENT_GROUP : 'MODIFY_CONTENT_GROUP',

    GET_CONTENT_SCHEDULE: 'GET_CONTENT_SCHEDULE',
    GET_CONTENT_PLAYLIST: 'GET_CONTENT_PLAYLIST',
    PAGE_SIZE_OPTIONS: [10, 25, 50, 100],

    GO_TO_RECYCLEBIN_FORCE : "GO_TO_RECYCLEBIN_FORCE",
    DELETE_FORCE : "DELETE_FORCE",
    GO_TO_RECYCLEBIN : "GO_TO_RECYCLEBIN",

    APPROVAL_CONTENT : "approval_content",
    UNAPPROVAL_CONTENT :"unapproval_content",

    APPROVED : "APPROVED",
    REJECTED : "REJECTED",

    MY_CONTENT_GROUP : "MY_CONTENT_GROUP",
    TEMPLATE_GROUP : "TEMPLATE_GROUP",
    BY_USER_GROUP : "BY_USER_GROUP",
    SHARED_GROUP : "SHARED_GROUP",
    ORGANIZATION: "ORGANIZATION",

    SELECT_CONTENT_POPUP: "SELECT_CONTENT_POPUP",

    TAG_TYPE_TEXT : 0,
    TAG_TYPE_NUMBER : 1,
    TAG_TYPE_BOOLEAN : 2,
    TAG_VALUE_MAXLENGTH:50,
    CONTENT_TYPE_SAPP: "SAPP",
    CONTENT_TYPE_WEB: "HTML",
    CONTENT_TYPE_URL: "URL",
    CONTENT_TYPE_STREAMING: "STRM",
    UPDATE: "UPDATE",
    FILTER_MEDIA_TYPE: ["LFD", "IMAGE", "MOVIE", "OFFICE", "PDF", "FLASH", "SOUND", "DLK", "FTP", "CIFS", "STRM", "VWL", "HTML", "URL", "LFT", "SAPP"]
}

export const CONTENT_ACTIONS = {
    RELOAD_CONTENT: 'RELOAD_CONTENT',
    SET_MY_CONTENT_GROUP_ID: 'SET_MY_CONTENT_GROUP_ID',
    LOAD_TRASH_BASKET:'LOAD_TRASH_BASKET',
    CONTENT_DOWNLOADING: 'CONTENT_DOWNLOADING'
};

export const APPROVAL_STATUS_LABEL = [
    {status : "APPROVED", label : "COM_TEXT_AD_STATUS_APPROVED_P"},
    {status : "REJECTED", label : "COM_TEXT_AD_STATUS_REJECTED_P"},
    {status : "UNAPPROVED", label : "COM_TV_SID_UNAPPROVED"},
];

export const MEDIA_TYPE_CHECK_FOR_WPLAYER_ICON = ["LFD", "LFT", "DLK", "TLFD"];

export const EDIT_DISABLED_MEDIA_TYPE = ["IMAGE", "MOVIE", "OFFICE", "PDF", "FLASH", "SOUND", "STRM"];

export const CONTENT_FILTERS = {
    ALL_CONTENT: {
        force: false,
        page: 0,
        keyword: '',
        groupId: '',
        sorted: [{id: 'lastModifiedDate', desc: true}],
        reload: false,
        customFilter: [],
        categoryIds: []
    },
    CONTENT_RECYCLE_BIN: {
        force: false,
        page: 0,
        keyword: '',
        groupId: '',
        sorted: [{id: 'lastModifiedDate', desc: true}],
        reload: false,
    },
    CONTENT_TEMPLATE_BY_FOLDER: {
        force: false,
        page: 0,
        keyword: '',
        groupId:  '',
        sorted: [{id: 'lastModifiedDate', desc: true}],
        reload: false,
    },
    UNAPPROVED_CONTENT : {
        force: false,
        page: 0,
        keyword: '',
        groupId: '',
        sorted: [{id: 'lastModifiedDate', desc: true}],
        reload: false,
    }
}

export const MEDIA_TYPE_FTP = 'FTP';
export const MEDIA_TYPE_CIFS = 'CIFS';