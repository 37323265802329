import React, {Fragment, useEffect, useRef, useState} from 'react';
import MISDialog from '../MISDialog';
import {useTranslation} from "react-i18next";
import TextInput from "../../input/TextInput";
import WhiteButton from "../../button/WhiteButton";
import "./AddRolePopup.css"
import Select from "../../selectbox/Select";
import Checkbox from "../../checkbox/Checkbox";
import {userService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import {getIdsByName, ruleManagerRole} from "../../../helper/user/roleUtils";
import {getErrorMessage} from "../../../helper/responseHandler";

const AddRolePopup = ({onSave, onClose}) => {
    const {t} = useTranslation();

    const [data, setState] = useState(() => {
        return {
            roleName: "",
            isDetailView: false,
            ruleManagerEnable: false,
            rmMode: false,
            isCheckedDuplicationName: false,
            optionRoles: [],
            optionOrganizations: [],
            selectedOptionOrganizationId: -1,
            selectedOptionRoleIndex: 0,
            checkContentRead: false,
            checkContentCreate: false,
            checkContentLock: false,
            checkContentManage: false,
            checkContentScheduleRead: false,
            checkContentScheduleCreate: false,
            checkContentScheduleManage: false,
            checkUserRead: false,
            checkUserCreate: false,
            checkUserManage: false,
            checkDeviceRead: false,
            checkDeviceControl: false,
            checkDeviceCreate: false,
            checkDeviceManage: false,
            checkDeviceApproval: false,
            checkDeviceMove: false,
            checkDeviceCustomize: false,
            checkDeviceDelete: false,
            checkDeviceSoftwareUpdate: false,
            checkDeviceSecurity: false,
            checkServerSetupManage: false,
            checkStatisticsManage: false,
            checkStoreRuleManager: false,
            checkHQRuleManager: false,
        }
    });

    const {
        roleName = "", isCheckedDuplicationName = false, isDetailView = false, optionRoles = [], selectedOptionRoleIndex = 0,
        selectedOptionOrganizationId = -1, optionOrganizations = [],
        ruleManagerEnable = false, rmMode = false,
        checkContentRead = false, checkContentCreate = false, checkContentLock = false, checkContentManage = false,
        checkContentScheduleRead = false, checkContentScheduleCreate = false, checkContentScheduleManage = false,
        checkUserRead = false, checkUserCreate = false, checkUserManage = false,
        checkDeviceRead = false, checkDeviceControl = false, checkDeviceCreate = false, checkDeviceManage = false, checkDeviceApproval = false,
        checkDeviceMove = false, checkDeviceCustomize = false, checkDeviceDelete = false, checkDeviceSoftwareUpdate = false, checkDeviceSecurity = false,
        checkServerSetupManage = false, checkStatisticsManage = false, checkStoreRuleManager = false, checkHQRuleManager = false,
    } = data;
    const checkContentReadRef = useRef();
    const checkContentCreateRef = useRef();
    const checkContentLockRef = useRef();
    const checkContentManageRef = useRef();
    const checkContentScheduleReadRef = useRef();
    const checkContentScheduleCreateRef = useRef();
    const checkContentScheduleManageRef = useRef();
    const checkUserReadRef = useRef();
    const checkUserCreateRef = useRef();
    const checkUserManageRef = useRef();
    const checkDeviceReadRef = useRef();
    const checkDeviceControlRef = useRef();
    const checkDeviceCreateRef = useRef();
    const checkDeviceManageRef = useRef();
    const checkDeviceApprovalRef = useRef();
    const checkDeviceMoveRef = useRef();
    const checkDeviceCustomizeRef = useRef();
    const checkDeviceDeleteRef = useRef();
    const checkDeviceSoftwareUpdateRef = useRef();
    const checkDeviceSecurityRef = useRef();
    const checkServerSetupManagerRef = useRef();
    const checkStatisticsManagerRef = useRef();
    const checkStoreRuleManagerRef = useRef();
    const checkHQRuleManagerRef = useRef();

    useEffect(() => {
        Promise.all([
            userService.fetchAllRoleAbilities(),
            userService.fetchOrganizations(),
            userService.fetchUserRoles({}),
        ]).then(res => {
            let ruleManagerEnable = (res[0].items.ruleManagerEnable === true || res[0].items.ruleManagerEnable === 'true');
            let rmMode = (res[0].items.rmMode === true || res[0].items.rmMode === 'true');
            const organizationList = res[1].items.map(org => {
                return {value: org.organizationId, title: org.groupName};
            });
            let optionRoles = [];
            let roles = res[2].items;
            optionRoles.push({value: 0, title: t('TEXT_CUSTOM_ROLE_P')})
            roles.forEach((role, index) => {
                optionRoles.push({value: index + 1, id: role.roleId, title: role.roleName,});
            });
            setState({...data, optionRoles: optionRoles, optionOrganizations: organizationList, ruleManagerEnable: ruleManagerEnable, rmMode: rmMode});
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage));
            return;
        });
    }, []);

    const onChangeRoleName = (value) => {
        setState({
            ...data,
            roleName: value,
        })
    };

    const onChangeRole = (value) => {
        if (value > 0) {
            getUserRoleDetail(value, optionRoles[value].id);
        } else {
            setState({
                ...data,
                selectedOptionRoleIndex: value,
                checkContentRead: false,
                checkContentCreate: false,
                checkContentLock: false,
                checkContentManage: false,
                checkContentScheduleRead: false,
                checkContentScheduleCreate: false,
                checkContentScheduleManage: false,
                checkUserRead: false,
                checkUserCreate: false,
                checkUserManage: false,
                checkDeviceRead: false,
                checkDeviceControl: false,
                checkDeviceCreate: false,
                checkDeviceManage: false,
                checkDeviceApproval: false,
                checkDeviceMove: false,
                checkDeviceCustomize: false,
                checkDeviceDelete: false,
                checkDeviceSoftwareUpdate: false,
                checkDeviceSecurity: false,
                checkServerSetupManage: false,
                checkStatisticsManage: false,
            });
        }
    };

    const onChangeOrganization = (value) => {
        setState({...data, selectedOptionOrganizationId: value});
    };

    const onClickDuplicationRoleName = () => {
        if (!roleName || roleName.trim().length <= 0) {
            toastr.error(t('MESSAGE_USER_ENTER_ROLE_NAME_P'));
            return;
        }
        checkUserRoleNameValidation();
    };

    const onClickDetailView = () => {
        setState({
            ...data,
            isDetailView: true,
        })
    };

    const onClickMainView = () => {
        setState({
            ...data,
            isDetailView: false,
        })
    };

    const onClickSave = () => {
        if (!roleName || roleName.trim().length <= 0) {
            toastr.error(t('MESSAGE_USER_ENTER_ROLE_NAME_P'));
            return;
        }
        if (!isCheckedDuplicationName) {
            toastr.error(t('MESSAGE_USER_CHK_ROLE_NAME_P'));
            return;
        }
        if (selectedOptionOrganizationId === -1) {
            toastr.error(t('TEXT_SEL_ORGANIZATION_P'));
            return;
        }
        if (!checkContentRead && !checkContentCreate && !checkContentLock && !checkContentManage &&
            !checkContentScheduleRead && !checkContentScheduleCreate && !checkContentScheduleManage &&
            !checkUserRead && !checkUserCreate && !checkUserManage &&
            !checkDeviceRead && !checkDeviceControl && !checkDeviceCreate && !checkDeviceManage && !checkDeviceApproval &&
            !checkDeviceMove && !checkDeviceCustomize && !checkDeviceDelete && !checkDeviceSoftwareUpdate && !checkDeviceSecurity &&
            !checkServerSetupManage && !checkStatisticsManage &&
            !checkStoreRuleManager && !checkHQRuleManager) {
            toastr.error(t('MESSAGE_USER_CLICK_LEAST_ONE_ABILITY_P'));
            return;
        }
        createUserRole();
    };

    const onChangeContentRead = () => {
        const checked = checkContentReadRef.current.checked;
        setState({
            ...data,
            checkContentRead: checked,
            checkContentCreate: checked ? checkContentCreate : false,
            checkContentLock: checked ? checkContentLock : false,
            checkContentManage: checked ? checkContentManage : false,
            checkDeviceMove: checked ? checkDeviceMove : false,
            checkDeviceCustomize: checked ? checkDeviceCustomize : false,
            checkDeviceDelete: checked ? checkDeviceDelete : false,
            checkDeviceSoftwareUpdate: checked ? checkDeviceSoftwareUpdate : false,
            checkDeviceSecurity: checked ? checkDeviceSecurity : false,
        })
    };

    const onChangeContentCreate = () => {
        const checked = checkContentCreateRef.current.checked;
        setState({
            ...data,
            checkContentRead: checked ? true : checkContentRead,
            checkContentCreate: checked,
            checkContentLock: checked ? checkContentLock : false,
            checkContentManage: checked ? checkContentManage : false,
        })
    };

    const onChangeContentLock = () => {
        const checked = checkContentLockRef.current.checked;
        setState({
            ...data,
            checkContentRead: checked ? true : checkContentRead,
            checkContentCreate: checked ? true : checkContentCreate,
            checkContentLock: checked,
            checkContentManage: checked ? checkContentManage : false,
        })
    };

    const onChangeContentManage = () => {
        const checked = checkContentManageRef.current.checked;
        setState({
            ...data,
            checkContentRead: checked ? true : checkContentRead,
            checkContentCreate: checked ? true : checkContentCreate,
            checkContentLock: checked ? true : checkContentLock,
            checkContentManage: checked,
        })
    };

    const onChangeContentScheduleRead = () => {
        const checked = checkContentScheduleReadRef.current.checked;
        setState({
            ...data,
            checkContentScheduleRead: checked,
            checkContentScheduleCreate: checked ? checkContentScheduleCreate : false,
            checkContentScheduleManage: checked ? checkContentScheduleManage : false,
        })
    };

    const onChangeContentScheduleCreate = () => {
        const checked = checkContentScheduleCreateRef.current.checked;
        setState({
            ...data,
            checkContentScheduleRead: checked ? true : checkContentScheduleRead,
            checkContentScheduleCreate: checked,
            checkContentScheduleManage: checked ? checkContentScheduleManage : false,
        })
    };

    const onChangeContentScheduleManage = () => {
        const checked = checkContentScheduleManageRef.current.checked;
        setState({
            ...data,
            checkContentScheduleRead: checked ? true : checkContentScheduleRead,
            checkContentScheduleCreate: checked ? true : checkContentScheduleCreate,
            checkContentScheduleManage: checked,
        })
    };

    const onChangeUserRead = () => {
        const checked = checkUserReadRef.current.checked;
        setState({
            ...data,
            checkUserRead: checked,
            checkUserCreate: checked ? checkUserCreate : false,
            checkUserManage: checked ? checkUserManage : false,
        })
    };

    const onChangeUserCreate = () => {
        const checked = checkUserCreateRef.current.checked;
        setState({
            ...data,
            checkUserRead: checked ? true : checkUserRead,
            checkUserCreate: checked,
            checkUserManage: checked ? checkUserManage : false,
        })
    };

    const onChangeUserManage = () => {
        const checked = checkUserManageRef.current.checked;
        setState({
            ...data,
            checkUserRead: checked ? true : checkUserRead,
            checkUserCreate: checked ? true : checkUserCreate,
            checkUserManage: checked,
        })
    };

    const onChangeDeviceRead = () => {
        const checked = checkDeviceReadRef.current.checked;
        setState({
            ...data,
            checkDeviceRead: checked,
            checkDeviceApproval: checked ? checkDeviceApproval : false,
            checkDeviceControl: checked ? checkDeviceControl : false,
            checkDeviceCreate: checked ? checkDeviceCreate : false,
            checkDeviceMove: checked ? checkDeviceMove : false,
            checkDeviceDelete: checked ? checkDeviceDelete : false,
            checkDeviceManage: checked ? checkDeviceManage : false,
            checkDeviceCustomize: checked ? checkDeviceCustomize : false,
            checkDeviceSoftwareUpdate: checked ? checkDeviceSoftwareUpdate : false,
            checkDeviceSecurity: checked ? checkDeviceSecurity : false,
        })
    };

    const onChangeDeviceControl = () => {
        const checked = checkDeviceControlRef.current.checked;
        setState({
            ...data,
            checkDeviceRead: checked ? true : checkDeviceRead,
            checkDeviceControl: checked,
            checkDeviceCreate: checked ? checkDeviceCreate : false,
            checkDeviceManage: checked ? checkDeviceManage : false,
        })
    };

    const onChangeDeviceCreate = () => {
        const checked = checkDeviceCreateRef.current.checked;
        setState({
            ...data,
            checkDeviceRead: checked ? true : checkDeviceRead,
            checkDeviceControl: checked ? true : checkDeviceControl,
            checkDeviceCreate: checked,
            checkDeviceMove: checked,
            checkDeviceDelete: checked,
            checkDeviceManage: checked ? checkDeviceManage : false,
            checkDeviceCustomize: checked ? checkDeviceCustomize : false,
            checkDeviceSoftwareUpdate: checked ? checkDeviceSoftwareUpdate : false,
        })
    };

    const onChangeDeviceManage = () => {
        const checked = checkDeviceManageRef.current.checked;
        setState({
            ...data,
            checkDeviceRead: checked ? true : checkDeviceRead,
            checkDeviceControl: checked ? true : checkDeviceControl,
            checkDeviceCreate: checked ? true : checkDeviceCreate,
            checkDeviceMove: checked ? true : checkDeviceMove,
            checkDeviceDelete: checked ? true : checkDeviceDelete,
            checkDeviceCustomize: checked,
            checkDeviceSoftwareUpdate: checked,
            checkDeviceSecurity: checked,
            checkDeviceManage: checked,
        })
    };

    const onChangeDeviceApproval = () => {
        const checked = checkDeviceApprovalRef.current.checked;
        setState({
            ...data,
            checkDeviceApproval: checked,
        })
    };

    const onChangeDeviceMove = () => {
        const checked = checkDeviceMoveRef.current.checked;
        setState({
            ...data,
            checkDeviceRead: checked ? true : checkDeviceRead,
            checkDeviceMove: checked,
            checkDeviceCreate: checked ? checkDeviceCreate : false,
        })
    };

    const onChangeDeviceCustomize = () => {
        const checked = checkDeviceCustomizeRef.current.checked;
        setState({
            ...data,
            checkDeviceRead: checked ? true : checkDeviceRead,
            checkDeviceCustomize: checked,
            checkDeviceManage: checked ? checkDeviceManage : false,
        })
    };

    const onChangeDeviceDelete = () => {
        const checked = checkDeviceDeleteRef.current.checked;
        setState({
            ...data,
            checkDeviceRead: checked ? true : checkDeviceRead,
            checkDeviceDelete: checked,
            checkDeviceCreate: checked ? checkDeviceCreate : false,
        })
    };

    const onChangeDeviceSoftwareUpdate = () => {
        const checked = checkDeviceSoftwareUpdateRef.current.checked;
        setState({
            ...data,
            checkDeviceRead: checked ? true : checkDeviceRead,
            checkDeviceSoftwareUpdate: checked,
            checkDeviceManage: checked ? checkDeviceManage : false,
        })
    };

    const onChangeDeviceSecurity = () => {
        const checked = checkDeviceSecurityRef.current.checked;
        setState({
            ...data,
            checkDeviceRead: checked ? true : checkDeviceRead,
            checkDeviceSecurity: checked,
        })
    };

    const onChangeServerSetupManage = () => {
        const checked = checkServerSetupManagerRef.current.checked;
        setState({
            ...data,
            checkServerSetupManage: checked,
        })
    };

    const onChangeStatisticsManage = () => {
        const checked = checkStatisticsManagerRef.current.checked;
        setState({
            ...data,
            checkStatisticsManage: checked,
        })
    };

    const onChangeStoreRuleManager = () => {
        const checked = checkStoreRuleManagerRef.current.checked;
        setState({
            ...data,
            checkContentRead: rmMode ? false : checked ? true : checkContentRead,
            checkContentCreate: rmMode ? false : checked ? true : checkContentCreate,
            checkContentLock: rmMode ? false : checked ? true : checkContentLock,
            checkContentManage: rmMode ? false : checked ? true : checkContentManage,
            checkDeviceRead: checked ? true : checkDeviceRead,
            checkDeviceControl: checked ? true : checkDeviceControl,
            checkDeviceCreate: checked ? true : checkDeviceCreate,
            checkDeviceMove: checked ? true : checkDeviceMove,
            checkDeviceDelete: checked ? true : checkDeviceDelete,
            checkStoreRuleManager: checked,
        })
    };

    const onChangeHQRuleManager = () => {
        const checked = checkHQRuleManagerRef.current.checked;
        setState({
            ...data,
            checkContentRead: rmMode ? false : checked ? true : checkContentRead,
            checkContentCreate: rmMode ? false : checked ? true : checkContentCreate,
            checkContentLock: rmMode ? false : checked ? true : checkContentLock,
            checkContentManage: rmMode ? false : checked ? true : checkContentManage,
            checkDeviceRead: checked ? true : checkDeviceRead,
            checkDeviceControl: checked ? true : checkDeviceControl,
            checkDeviceCreate: checked ? true : checkDeviceCreate,
            checkDeviceMove: checked ? true : checkDeviceMove,
            checkDeviceDelete: checked ? true : checkDeviceDelete,
            checkHQRuleManager: checked,
        })
    };

    const isActiveAbility = (abilities, name) => {
        if (!abilities || abilities.length <= 0) {
            return false;
        }
        let ids = getIdsByName(name);
        if (!ids || ids.length <= 0) {
            return false;
        }
        for (let i = 0; i < abilities.length; i++) {
            if (abilities[i].active) {
                for (let j = 0; j < ids.length; j++) {
                    if (abilities[i].abilityId === ids[j]) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    const getUserRoleDetail = (index, id) => {
        userService.fetchUserRoleAbilities(id).then(res => {
            setState({
                ...data,
                selectedOptionRoleIndex: index,
                checkContentRead: !rmMode && isActiveAbility(res.items.abilityList, 'contentRead') ? true : false,
                checkContentCreate: !rmMode && isActiveAbility(res.items.abilityList, 'contentCreate') ? true : false,
                checkContentLock: !rmMode && isActiveAbility(res.items.abilityList, 'contentLock') ? true : false,
                checkContentManage: !rmMode && isActiveAbility(res.items.abilityList, 'contentManage') ? true : false,
                checkContentScheduleRead: !rmMode && isActiveAbility(res.items.abilityList, 'contentScheduleRead') ? true : false,
                checkContentScheduleCreate: !rmMode && isActiveAbility(res.items.abilityList, 'contentScheduleCreate') ? true : false,
                checkContentScheduleManage: !rmMode && isActiveAbility(res.items.abilityList, 'contentScheduleManage') ? true : false,
                checkUserRead: isActiveAbility(res.items.abilityList, 'userRead'),
                checkUserCreate: isActiveAbility(res.items.abilityList, 'userCreate'),
                checkUserManage: isActiveAbility(res.items.abilityList, 'userManage'),
                checkDeviceRead: isActiveAbility(res.items.abilityList, 'deviceRead'),
                checkDeviceControl: isActiveAbility(res.items.abilityList, 'deviceControl'),
                checkDeviceCreate: isActiveAbility(res.items.abilityList, 'deviceCreate'),
                checkDeviceManage: isActiveAbility(res.items.abilityList, 'deviceManage'),
                checkDeviceApproval: isActiveAbility(res.items.abilityList, 'deviceApproval'),
                checkDeviceMove: isActiveAbility(res.items.abilityList, 'deviceMove'),
                checkDeviceCustomize: isActiveAbility(res.items.abilityList, 'deviceCustomize'),
                checkDeviceDelete: isActiveAbility(res.items.abilityList, 'deviceDelete'),
                checkDeviceSoftwareUpdate: isActiveAbility(res.items.abilityList, 'deviceSoftwareUpdate'),
                checkDeviceSecurity: isActiveAbility(res.items.abilityList, 'deviceSecurity'),
                checkServerSetupManage: isActiveAbility(res.items.abilityList, 'serverSetupManage'),
                checkStatisticsManage: isActiveAbility(res.items.abilityList, 'statisticsManage'),
                checkStoreRuleManager: ruleManagerEnable && isActiveAbility(res.items.abilityList, 'ruleStoreManager') ? true : false,
                checkHQRuleManager: ruleManagerEnable && isActiveAbility(res.items.abilityList, 'ruleHQManager') ? true : false,
            })
        }).catch(err => {toastr.error(getErrorMessage(err, err.errorMessage));});
    };

    const checkUserRoleNameValidation = () => {
        userService.checkUserRoleNameValidation({roleName: roleName}).then(res => {
            if (res.items && res.items.result === 'available') {
                toastr.success(t('MESSAGE_USER_ROLE_NAME_AVAILABLE_P'));
                setState({
                    ...data,
                    isCheckedDuplicationName: true,
                });
            } else {
                toastr.error(t('MIS_SID_20_THE_ROLE_NAME_YOU_ENTERED_IS_ALREADY_IN_USE_ENTER_A_DIFFERENT_NAME'));
            }
        }).catch(err => {toastr.error(getErrorMessage(err, err.errorMessage));});
    }

    const createUserRole = () => {
        let abilityIds = [];
        if (checkContentRead) abilityIds = [...abilityIds, ...getIdsByName('contentRead')];
        if (checkContentCreate) abilityIds = [...abilityIds, ...getIdsByName('contentCreate')];
        if (checkContentLock) abilityIds = [...abilityIds, ...getIdsByName('contentLock')];
        if (checkContentManage) abilityIds = [...abilityIds, ...getIdsByName('contentManage')];
        if (checkContentScheduleRead) abilityIds = [...abilityIds, ...getIdsByName('contentScheduleRead')];
        if (checkContentScheduleCreate) abilityIds = [...abilityIds, ...getIdsByName('contentScheduleCreate')];
        if (checkContentScheduleManage) abilityIds = [...abilityIds, ...getIdsByName('contentScheduleManage')];
        if (checkUserRead) abilityIds = [...abilityIds, ...getIdsByName('userRead')];
        if (checkUserCreate) abilityIds = [...abilityIds, ...getIdsByName('userCreate')];
        if (checkUserManage) abilityIds = [...abilityIds, ...getIdsByName('userManage')];
        if (checkDeviceRead) abilityIds = [...abilityIds, ...getIdsByName('deviceRead')];
        if (checkDeviceControl) abilityIds = [...abilityIds, ...getIdsByName('deviceControl')];
        if (checkDeviceCreate) abilityIds = [...abilityIds, ...getIdsByName('deviceCreate')];
        if (checkDeviceManage) abilityIds = [...abilityIds, ...getIdsByName('deviceManage')];
        if (checkDeviceApproval) abilityIds = [...abilityIds, ...getIdsByName('deviceApproval')];
        if (checkDeviceMove) abilityIds = [...abilityIds, ...getIdsByName('deviceMove')];
        if (checkDeviceCustomize) abilityIds = [...abilityIds, ...getIdsByName('deviceCustomize')];
        if (checkDeviceDelete) abilityIds = [...abilityIds, ...getIdsByName('deviceDelete')];
        if (checkDeviceSoftwareUpdate) abilityIds = [...abilityIds, ...getIdsByName('deviceSoftwareUpdate')];
        if (checkDeviceSecurity) abilityIds = [...abilityIds, ...getIdsByName('deviceSecurity')];
        if (checkServerSetupManage) abilityIds = [...abilityIds, ...getIdsByName('serverSetupManage')];
        if (checkStatisticsManage) abilityIds = [...abilityIds, ...getIdsByName('statisticsManage')];
        if (checkStoreRuleManager) abilityIds = [...abilityIds, ...getIdsByName('ruleStoreManager')];
        if (checkHQRuleManager) abilityIds = [...abilityIds, ...getIdsByName('ruleHQManager')];
        userService.createUserRoles({
            abilityIds: abilityIds,
            roleName: roleName.trim(),
            organizationId: selectedOptionOrganizationId
        }).then(res => {
            toastr.success(t("MESSAGE_SCHEDULE_SUCCESS_ADD_P"));
            onSave();
        }).catch(err => {toastr.error(getErrorMessage(err, err.errorMessage));});
    };

    return (
        <MISDialog
            dialog={{
                title: t('TEXT_TITLE_ADD_ROLE_P'),
                closeOnEscape: true,
                modal: true,
                width: 732,
                height: 503,
                onClose: onClose,
            }}
            buttons={{
                leftButtons: isDetailView ? [{id: "MAIN", title: t("DID_ADMIN_DOWNLOAD_MAINTYPE"), onClick: onClickMainView}] : [{id: "DETAIL", title: t("BUTTON_DETAIL_P"), onClick: onClickDetailView}],
                rightButtons: [
                    {id: "SAVE", title: t("COM_BUTTON_SAVE"), onClick: onClickSave},
                    {id: "CANCEL", title: t("BUTTON_CANCEL_P"), onClick: onClose}
                ]
            }}>
            <div className="role_content">
                <div className='role_content_name'>
                    <span className='role_content_title'>{t('TABLE_ROLE_NAME_P')}</span>
                    <TextInput width={267} height={27} maxLength={60} placeholder={t('MESSAGE_USER_ENTER_ROLE_NAME_P')} value={roleName}
                               onChange={e => onChangeRoleName(e.target.value)} disabled={isCheckedDuplicationName}/>
                    <div style={{width: '10px'}}/>
                    <WhiteButton id={"CHECK_NAME"} name={t('BUTTON_NAME_DUPLICATE_P')} onClick={onClickDuplicationRoleName} style={{marginRight: '4px'}} disable={isCheckedDuplicationName}/>
                </div>
                <div style={{height: '10px', minHeight: '10px'}}/>
                <div className='role_content_name'>
                    <span className='role_content_title'>{t('COM_TEXT_ROLE_P')}</span>
                    <Select multiLang={false} value={selectedOptionRoleIndex} width={267} selects={optionRoles} onChange={(e, value) => onChangeRole(value)} useEllipsis={true}/>
                </div>
                <div style={{height: '10px', minHeight: '10px'}}/>
                <div className='role_content_name'>
                    <span className='role_content_title'>{t('TEXT_ORGANIZATION_P')}</span>
                    <Select multiLang={false} value={selectedOptionOrganizationId} width={267} selects={optionOrganizations} defaultTitle={t('TEXT_SEL_ORGANIZATION_P')} onChange={(e, value) => onChangeOrganization(value)} useEllipsis={true}/>
                </div>
                <div style={{height: '40px', minHeight: '40px'}}/>
                <div className='add_role_content_list'>
                    {
                        !rmMode &&
                        <Fragment>
                            <div className='role_content_list_table'>
                                <table>
                                    <colgroup>
                                        <col width="210px"/>
                                        <col width="129px"/>
                                        <col width="129px"/>
                                        <col width="129px"/>
                                        <col width="129px"/>
                                        <col width=""/>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <td className='role_content_list_table_title_column'></td>
                                        <th className='role_content_list_table_title_column'>{t('TEXT_READ_P')}</th>
                                        <th className='role_content_list_table_title_column'>{t('COM_TEXT_CREATE_P')}</th>
                                        <td className='role_content_list_table_title_column'>{t('BUTTON_CHECK_OUT')}</td>
                                        <th className='role_content_list_table_title_column'>{t('TEXT_MANAGE_P')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className='role_content_list_table_value_raw'>
                                        <td className='role_content_list_table_value_column'>{t('TEXT_CONTENT_P') + " & " + t('TEXT_TITLE_PLAYLIST_P') + " & " + t('MIS_SID_RULESET')}</td>
                                        <td className='role_content_list_table_value_column'>
                                            <Checkbox id={'contentRead'} onChange={onChangeContentRead} ref={checkContentReadRef} checked={checkContentRead}/>
                                        </td>
                                        <td className='role_content_list_table_value_column'>
                                            <Checkbox id={'contentCreate'} onChange={onChangeContentCreate} ref={checkContentCreateRef} checked={checkContentCreate}/>
                                        </td>
                                        <td className='role_content_list_table_value_column'>
                                            <Checkbox id={'contentLock'} onChange={onChangeContentLock} ref={checkContentLockRef} checked={checkContentLock}/>
                                        </td>
                                        <td className='role_content_list_table_value_column'>
                                            <Checkbox id={'contentManage'} onChange={onChangeContentManage} ref={checkContentManageRef} checked={checkContentManage}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{height: '14px', minHeight: '14px'}}/>
                        </Fragment>
                    }
                    <div className='role_content_list_table'>
                        <table>
                            <colgroup>
                                <col width="210px"/>
                                <col width="129px"/>
                                <col width="129px"/>
                                <col width="129px"/>
                                <col width="129px"/>
                                <col width=""/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th className='role_content_list_table_title_column'></th>
                                <th className='role_content_list_table_title_column'>{t('TEXT_READ_P')}</th>
                                <th className='role_content_list_table_title_column'>{t('COM_TEXT_CREATE_P')}</th>
                                <th className='role_content_list_table_title_column'>{t('TEXT_MANAGE_P')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                !rmMode &&
                                <tr className='role_content_list_table_value_raw'>
                                    <td className='role_content_list_table_value_column'>{t('TEXT_TITLE_SCHEDULE_P')}</td>
                                    <td className='role_content_list_table_value_column'>
                                        <Checkbox id={'contentScheduleRead'} onChange={onChangeContentScheduleRead} ref={checkContentScheduleReadRef} checked={checkContentScheduleRead}/>
                                    </td>
                                    <td className='role_content_list_table_value_column'>
                                        <Checkbox id={'contentScheduleCreate'} onChange={onChangeContentScheduleCreate} ref={checkContentScheduleCreateRef} checked={checkContentScheduleCreate}/>
                                    </td>
                                    <td className='role_content_list_table_value_column'>
                                        <Checkbox id={'contentScheduleManage'} onChange={onChangeContentScheduleManage} ref={checkContentScheduleManageRef} checked={checkContentScheduleManage}/>
                                    </td>
                                </tr>
                            }
                            <tr className='role_content_list_table_value_raw'>
                                <td className='role_content_list_table_value_column'>{t('TEXT_TITLE_USER_P')}</td>
                                <td className='role_content_list_table_value_column'>
                                    <Checkbox id={'userRead'} onChange={onChangeUserRead} ref={checkUserReadRef} checked={checkUserRead}/>
                                </td>
                                <td className='role_content_list_table_value_column'>
                                    <Checkbox id={'userCreate'} onChange={onChangeUserCreate} ref={checkUserCreateRef} checked={checkUserCreate}/>
                                </td>
                                <td className='role_content_list_table_value_column'>
                                    <Checkbox id={'userManage'} onChange={onChangeUserManage} ref={checkUserManageRef} checked={checkUserManage}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{height: '14px', minHeight: '14px'}}/>
                    <div className='role_content_list_table'>
                        <table>
                            <colgroup>
                                <col width="210px"/>
                                <col width="129px"/>
                                <col width="129px"/>
                                <col width="129px"/>
                                <col width="129px"/>
                                <col width=""/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th className='role_content_list_table_title_column'></th>
                                <th className='role_content_list_table_title_column'>{t('TEXT_READ_P')}</th>
                                <th className='role_content_list_table_title_column'>{t('COM_EBD_CONTROL_MENUNAME')}</th>
                                <th className='role_content_list_table_title_column'>{t('COM_TEXT_CREATE_P')}</th>
                                <th className='role_content_list_table_title_column'>{t('TEXT_MANAGE_P')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className='role_content_list_table_value_raw'>
                                <td className='role_content_list_table_value_column' rowSpan="7">{t('TEXT_TITLE_DEVICE_P')}</td>
                                <td className='role_content_list_table_value_column'>
                                    <Checkbox id={'deviceRead'} onChange={onChangeDeviceRead} ref={checkDeviceReadRef} checked={checkDeviceRead}/>
                                </td>
                                <td className='role_content_list_table_value_column'>
                                    <Checkbox id={'deviceControl'} onChange={onChangeDeviceControl} ref={checkDeviceControlRef} checked={checkDeviceControl}/>
                                </td>
                                <td className='role_content_list_table_value_column'>
                                    <Checkbox id={'deviceCreate'} onChange={onChangeDeviceCreate} ref={checkDeviceCreateRef} checked={checkDeviceCreate}/>
                                </td>
                                <td className='role_content_list_table_value_column'>
                                    <Checkbox id={'deviceManage'} onChange={onChangeDeviceManage} ref={checkDeviceManageRef} checked={checkDeviceManage}/>
                                </td>
                            </tr>
                            {
                                isDetailView &&
                                <Fragment>
                                    <tr>
                                        <th colSpan="2" className='role_content_list_table_title_column'>{t('COM_BUTTON_APPROVAL')}</th>
                                        <th className='role_content_list_table_title_column'>{t('BUTTON_MOVE_P')}</th>
                                        <th className='role_content_list_table_title_column'>{t('COM_SID_MAGIC_BRIGHT_CUSTOM_KOR_MSG')}</th>
                                    </tr>
                                    <tr className='role_content_list_table_value_raw'>
                                        <td className='role_content_list_table_value_column' style={{verticalAlign: 'top'}} rowSpan='3' colSpan='2'>
                                            <Checkbox id={'deviceApproval'} onChange={onChangeDeviceApproval} ref={checkDeviceApprovalRef} checked={checkDeviceApproval}/>
                                        </td>
                                        <td className='role_content_list_table_value_column'>
                                            <Checkbox id={'deviceMove'} onChange={onChangeDeviceMove} ref={checkDeviceMoveRef} checked={checkDeviceMove}/>
                                        </td>
                                        <td className='role_content_list_table_value_column'>
                                            <Checkbox id={'deviceCustomize'} onChange={onChangeDeviceCustomize} ref={checkDeviceCustomizeRef} checked={checkDeviceCustomize}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='role_content_list_table_title_column'>{t('COM_BUTTON_DELETE')}</th>
                                        <th className='role_content_list_table_title_column'>{t('COM_SOFTWARE_UPDATE')}</th>
                                    </tr>
                                    <tr className='role_content_list_table_value_raw'>
                                        <td className='role_content_list_table_value_column'>
                                            <Checkbox id={'deviceDelete'} onChange={onChangeDeviceDelete} ref={checkDeviceDeleteRef} checked={checkDeviceDelete}/>
                                        </td>
                                        <td className='role_content_list_table_value_column'>
                                            <Checkbox id={'deviceSoftwareUpdate'} onChange={onChangeDeviceSoftwareUpdate} ref={checkDeviceSoftwareUpdateRef} checked={checkDeviceSoftwareUpdate}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='role_content_list_table_title_column' style={{verticalAlign: 'top', background: '#ffffff'}} rowSpan='2' colSpan='3'>{''}</td>
                                        <th className='role_content_list_table_title_column'>{t('COM_SID_SECURITY')}</th>
                                    </tr>
                                    <tr className='role_content_list_table_value_raw'>
                                        <td className='role_content_list_table_value_column'>
                                            <Checkbox id={'deviceSecurity'} onChange={onChangeDeviceSecurity} ref={checkDeviceSecurityRef} checked={checkDeviceSecurity}/>
                                        </td>
                                    </tr>
                                </Fragment>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div style={{height: '14px', minHeight: '14px'}}/>
                    <div className='role_content_list_table'>
                        <table>
                            <colgroup>
                                <col width="132px"/>
                                <col width="129px"/>
                                <col width="129px"/>
                                <col width="129px"/>
                                <col width=""/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th className='role_content_list_table_title_column'></th>
                                <th className='role_content_list_table_title_column'>{t('TEXT_MANAGE_P')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className='role_content_list_table_value_raw'>
                                <td className='role_content_list_table_value_column'>{t('COM_TEXT_TITLE_SERVER_SETUP_P')}</td>
                                <td className='role_content_list_table_value_column'>
                                    <Checkbox id={'serverSetupManage'} onChange={onChangeServerSetupManage} ref={checkServerSetupManagerRef} checked={checkServerSetupManage}/>
                                </td>
                            </tr>
                            <tr className='role_content_list_table_value_raw'>
                                <td className='role_content_list_table_value_column'>{t('COM_TEXT_TITLE_STATISTICS_P')}</td>
                                <td className='role_content_list_table_value_column'>
                                    <Checkbox id={'statisticsManage'} onChange={onChangeStatisticsManage} ref={checkStatisticsManagerRef} checked={checkStatisticsManage}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    {
                        ruleManagerEnable &&
                        <Fragment>
                            <div style={{height: '14px', minHeight: '14px'}}/>
                            <div className='role_content_list_table'>
                                <table>
                                    <colgroup>
                                        <col width="132px"/>
                                        <col width="129px"/>
                                        <col width="129px"/>
                                        <col width="129px"/>
                                        <col width=""/>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th className='role_content_list_table_title_column'></th>
                                        <th className='role_content_list_table_title_column'>{ruleManagerRole.storeManager}</th>
                                        <th className='role_content_list_table_title_column'>{ruleManagerRole.hqManager}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className='role_content_list_table_value_raw'>
                                        <td className='role_content_list_table_value_column' rowSpan="7">{ruleManagerRole.ruleManager}</td>
                                        <td className='role_content_list_table_value_column'>
                                            <Checkbox id={'ruleStoreManager'} onChange={onChangeStoreRuleManager} ref={checkStoreRuleManagerRef} checked={checkStoreRuleManager}/>
                                        </td>
                                        <td className='role_content_list_table_value_column'>
                                            <Checkbox id={'ruleHQManager'} onChange={onChangeHQRuleManager} ref={checkHQRuleManagerRef} checked={checkHQRuleManager}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        </MISDialog>
    );
};
export default AddRolePopup;