import React from "react";
import {connect} from "react-redux";
import {popupAction} from "../../actions";
import {withTranslation} from "react-i18next";
import './ContentCard.css'
import ContentThumbnail from "../image/ContentThumbnail";
import {bytesToSize} from "../../helper/bytesToSize";
import isNil from "lodash/isNil";
import classNames from "classnames";

const ratio = 0.5625;
const marginratio = 0.2;

class ContentCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            select : props.select,
        };
    }

    componentDidMount() {
        const {setRef, index} = this.props;
        if(setRef) {
            setRef(this, index);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!isNil(nextProps.select) && this.props.select !== nextProps.select) {
            this.setState({
                select: nextProps.select
            })
        }
    }

    setSelect(select) {
        this.setState({select});
    }

    getSelect() {
        return this.state.select;
    }

    render() {
        const {content:{contentName, mediaType, totalSize, contentId, thumbFileId, playTimeInString}, index, onTitleClick, onClick, width, t} = this.props;
        const {select} = this.state;
        let truncatedString = (contentName.length > 8 ? contentName.substring(0, 8) + '...' : contentName );
        const height = Math.round(width * ratio);
        const boxHeight = height + 30;
        const showOptionName = (width > 95 ? true : false);
        const marginBottom = Math.round(width * marginratio);

        return (
            <li className='content_card_view_wrap' style={{width,boxHeight,marginBottom}}>
                <div className={classNames({"on" : select === true}, "thumbview_box")}
                     style={{width,height, textAlign:"center"}}
                     onClick={() => onClick(index)}>
                    <ContentThumbnail id={thumbFileId} width={100} height={56} wrapWidth={width} wrapHeight={width} textAlign={true}/>
                    <div className="content_info add">
                        { !!showOptionName ?
                            (<div>
                                <div className='inline_block'>
                                    <strong>{truncatedString}</strong>
                                </div>
                                <div className='inline_block'>
                                        <div className="float_l">{t('MESSAGE_STATISTICS_LEFT_MENU_SUB_CONTENT_TYPE_P')}</div>
                                        <div className="float_l w20">:</div>
                                        <div className="float_l">{mediaType}</div>
                                </div>
                                <div className='inline_block'>
                                    <div className="float_l">{t('ADMIN_CONTENT_REGISTRATION_CONTENTS_LIST_JSP_FILESIZE')}</div>
                                    <div className="float_l w20">:</div>
                                    <div className="float_l">{bytesToSize(totalSize)}</div>
                                </div>
                            {
                                !!playTimeInString &&
                                <div className='inline_block'>
                                    <div className="float_l">{t('TABLE_PLAY_TIME_P')}</div>
                                    <div className="float_l w20">:</div>
                                    <div className="float_l">{playTimeInString}</div>
                                </div>
                            }
                            </div>)
                            :
                            (<div>
                                <div className='inline_block'>
                                    <strong>{truncatedString}</strong>
                                </div>
                                <div className='inline_block'>
                                    <div className="float_l">{mediaType}</div>
                                </div>
                                <div className='inline_block'>
                                    <div className="float_l">{bytesToSize(totalSize)}</div>
                                </div>
                                {
                                    !!playTimeInString &&
                                    <div className='inline_block'>
                                        <div className="float_l">{playTimeInString}</div>
                                    </div>
                                }
                            </div>)
                        }
                    </div>
                    <div className="check">
                        <span style={{backgroundSize: 68}}></span>
                    </div>
                </div>
                <div style={{textAlign:'center'}}>
                    <a href="#" className="name" onClick={e=>onTitleClick(e, contentId)} title={contentName}>{truncatedString}</a>
                </div>
            </li>
        )
    }
}

export default connect(
    state => ({
        menu: state.menu
    }),
    dispatch => ({
        openDetailView : (type)=> dispatch(popupAction.openDetailView(type)),
    })
)(withTranslation()(ContentCard));