import React from 'react';
import {toastr} from 'helper/toastrIntercept';
import {isNil, toLower} from 'lodash';
import fileDownload from 'js-file-download';
import {deviceService} from '../../services';
import {
    commonConstants,
    IPLAYER,
    LEDBOX,
    RIPLAYER,
    RLEDBOX,
    RSIGNAGE,
    RSPLAYER,
    SIGNAGE,
    SPLAYER,
    WPLAYER
} from '../../constants';
import {getErrorMessage} from '../../helper/responseHandler';


function handleRemoteControl(device) {
    const {addPopup, closePopup, t} = this.props;
    const {rmServer} = this.state;

    if(!device.power) {
        toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
        return null;
    }

    if(!rmServer.show) {
        switch(device.deviceType) {
            case RIPLAYER:
            case IPLAYER:
                deviceService.fetchDeviceVnc(device.deviceId, {browserWidth : 1920, browserHeight : 1080}).then(res => {
                    fileDownload(res.blob, res.fileName);
                }).catch(error => console.log(error));
                break;
            case WPLAYER:
            case LEDBOX:
            case SIGNAGE:
            case RSPLAYER:
            case SPLAYER:
            case RLEDBOX:
            case RSIGNAGE:
                addPopup({id: 'REMOTE_CONTROL', type: commonConstants.LOADING_POPUP});
                deviceService.fetchDeviceRMServerVnc(device.deviceId, 'start').then(res => {
                    const url = res.items.url;
                    const token = res.items.token;
                    const strWindowFeatures = "width=1300,height=1000,menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=no";
                    let windowObject = window.open('about:blank', 'RMServer', strWindowFeatures);
    
                    this.setState({rmServer: {...rmServer, show: true, url, token}});
                    setTimeout(() => {
                        this.setState({rmServer: {show: false}});
                    }, 2000);
    
                    this.rmServerPollingHandle && clearInterval(this.rmServerPollingHandle);
                    this.rmServerPollingHandle = setInterval(() => {
                        if(windowObject && windowObject.closed) {
                            clearInterval(this.rmServerPollingHandle);
                            this.rmServerPollingHandle = null;

                            deviceService.fetchDeviceRMServerVnc(device.deviceId, 'stop').then(res => {
    
                            }).catch(error => console.log(error));
                        }
                    }, 1000);
    
                    if(isNil(windowObject)){
                        toastr.error(t('MIS_TEXT_POPUP_HAS_BEEN_BLOCKED_P'));
                    }
                    closePopup('REMOTE_CONTROL');
                }).catch(error => {
                    closePopup('REMOTE_CONTROL');
                    // }else if(results[0] == "1111"){	
                    //     var disConRm = $.i18n.prop('COM_TV_SID_MIX_WANT_DISCONNECT');
                    //     disConRm = disConRm.replace('<<A>>', deviceId);
                    //     $(this).onConfirmPopup({title: $.i18n.prop('COM_BUTTON_CONFIRM'), message: $.i18n.prop('MIS_SID_A_REMOTE_CONTROL_SESSION_IS_ALREADY_RUNNING')+'<br>'+disConRm,
                    //         ok: function(){
                    //             url += '&cmd=stopRMServer';
                    //             $.getJSON(url)
                    //             .done(function(json){
                    //                 if (json.status == 'success') {
                    //                     toastr.success($.i18n.prop('MIS_TEXT_SUCCESS_SENT_P'));
                    //                 } else {
                    //                     toastr.error($.i18n.prop('MIS_TEXT_CONNECT_TO_SERVER_PLEASE_WAIT_P'));
                    //                 }
                    //             }).fail(function( jqxhr, textStatus, error ) {
                    //                 ajaxHandleError(jqxhr, textStatus, error);
                    //             });
                    //         }
                    //     });
                    if(error.errorCode === '500618' /*'9999'*/) {
                        toastr.error(t('MIS_TEXT_NOT_CONNECTED_TO_THE_RMSERVER_P'));
                    } else if(error.errorCode === '500619' || error.errorCode === '500632'/*'1111'*/) {
                        addPopup({
                            id: commonConstants.COMMON_CONFIRM_POPUP,
                            type: commonConstants.COMMON_CONFIRM_POPUP,
                            title: t('COM_BUTTON_CONFIRM'),
                            useMessageLine: false,
                            message: (
                                <div>
                                    {t('MIS_SID_A_REMOTE_CONTROL_SESSION_IS_ALREADY_RUNNING')}
                                    <br/>
                                    {t('COM_TV_SID_MIX_WANT_DISCONNECT').replace('<<A>>', device.deviceId)}
                                </div>
                            ),
                            onClickYes: () => {
                                closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                                addPopup({id: 'REMOTE_CONTROL', type: commonConstants.LOADING_POPUP});
                                deviceService.fetchDeviceRMServerVnc(device.deviceId, 'stop').then(res => {
                                    closePopup('REMOTE_CONTROL');
                                    if(toLower(res.status) === 'success') {
                                        toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                                    } else {
                                        toastr.error(t('MIS_TEXT_CONNECT_TO_SERVER_PLEASE_WAIT_P'));
                                    }
                                }).catch(error => toastr.error(getErrorMessage(error)));
                            },
                            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
                        });
		            }else if(error.errorCode === '403001'){
                    	toastr.error(getErrorMessage(error));
                    } else {
                        toastr.error(t('MIS_TEXT_CONNECT_TO_SERVER_PLEASE_WAIT_P'));
                    }
                });
                break;
            default:
                break;
        }

    }
};

export default handleRemoteControl;