import React, {useEffect, useState} from 'react';
import './TooltipIcon.css';
import {useTranslation} from "react-i18next";
import {isNil} from "lodash";

const TooltipIcon = ({message}) => {
    const {t} = useTranslation();

    const [data, setData] = useState({
        visibility: false,
    });
    const [position, setPosition] = useState([370, 150]);

    const onChangeVisible = (e) => {
        const isVisible = visibility;
        !isNil(e.pageX) && !isNil(e.pageY) && setPosition([e.pageX, e.pageY]);
        setData({visibility: !isVisible});
    };
    const {visibility} = data;

    return (
        <div style={{display:'inline-block', position:'relative'}}>
            <span className="tooltip_icon" onMouseOver={onChangeVisible} onMouseOut={onChangeVisible}/>
            <div className="tooltip_msg" style={{display: visibility ? '' : 'none', left: position[0], top: position[1]}}>{t(message)}</div>
        </div>
    )
};

export default TooltipIcon;