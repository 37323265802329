import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import WhiteButton from "../../components/button/WhiteButton";
import SearchBar from "../../components/search/SearchBar";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {commonConstants, SETTING_PAGE_SIZE_OPTIONS} from "../../constants";
import {useFilter} from "../../helper";
import {settingService} from "../../services";
import {snakeCase} from "lodash";
import Moment from "moment";
import attachedFileIcon from '../../images/icon/icon_attach.png';
import fileDownload from "js-file-download";
import {popupAction} from "../../actions";
import {toastr} from "../../helper/toastrIntercept";
import {getErrorMessage} from "../../helper/responseHandler";

const AlarmMailHistory = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();


    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange ] = useFilter({
        page: 0,
        pageSize: 30,
        searchText: '',
        sorted: [{id: 'id', desc: true}],
        reload: false
    });
    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0,
    });
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted} = filter;

    const [style, setStyle] = useState({
        height: '500px'
    });

    const [searchTextForExport, setSearchTextForExport] = useState('');

    useEffect(() => {
        fetchData();
    }, [filter]);

    useEffect(() => {
        setStyle({height: window.innerHeight - 204});
    }, []);


    const fetchData = () => {
        const {page, pageSize, sorted: [{id, desc}],  searchText} = filter;
        setData({...data, loading: true});
        settingService.fetchAlarmMailHistory({
            startIndex: page*pageSize + 1,
            pageSize,
            sortColumn: snakeCase(id).toUpperCase(),
            sortOrder: desc === true ? 'DESC' : 'ASC',
            searchText: searchText
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount, pages: Math.ceil(res.totalCount / pageSize)});
        }).catch(err => {
            setData({...data, loading: false});
            toastr.error(getErrorMessage(err, err.errorMessage));

        });
    }

    const onDownLoad =(historyId) =>{
        settingService.downloadMailAttachedFile(historyId)
            .then(res => {

            }
        )
    }

    const onMailDetailPopup = (historyId) => {
        settingService.getAlarmMailHistoryInfo(historyId)
            .then(res => {
                let message = (
                    <div className={'detail_view mt8 ml25'}>
                        <table>
                            <colgroup>
                                <col width="120px"></col>
                            </colgroup>
                            <tbody>
                            <tr>
                                <th>{t('COM_EBD_TSC_EMAIL_SUBJECT')}</th>
                                <td>{res.items.title}</td>
                            </tr>
                            <tr>
                                <th>{t('TABLE_ORGANIZATION_P')}</th>
                                <td style={{whiteSpace:'normal', wordBreak:'break-all'}}>{res.items.organizationName}</td>
                            </tr>
                            <tr>
                                <th>{t('COM_IDS_CALENDAR_TO_KR_LETTER')}</th>
                                <td>
                                    <div title={res.items.receiverList.join(',')} style={{
                                        width: '246px',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden'
                                    }}>{res.items.receiverList.join(',')}</div>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('DID_ADMIN_NOTICE_ATTACHFILE')}</th>
                                <td>
                                    <WhiteButton name={t("BUTTON_DOWNLOAD_P")} onClick={() => onDownLoad(historyId)}/>
                                    <span style={{whiteSpace: 'pre-line', paddingLeft: '10px'}}>
                                        {res.items.fileNameList.join(',')}
                                    </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                )

                dispatch(popupAction.addPopup({
                    id: commonConstants.COMMON_ALERT_POPUP,
                    type: commonConstants.COMMON_ALERT_POPUP,
                    title: t('MIS_SID_ABOUT_ALARM_MAIL'),
                    message: message,
                    onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_ALERT_POPUP)),
                }));

            });
    }

    const onChangeSearchText = value => {
        setFilter({...filter, searchText: value, page: 0})
    }

    const onChangeSearchTextForExport = value => {
        setSearchTextForExport(value);
    }

    const renderTitleCell = (props) => {
        const fileAttached=items[props.index].hasAttachedFile
        if (fileAttached)
            return (
                <span className={"data_name"} onClick={() => onMailDetailPopup(props.original.id)}>
                    <img style={{"verticalAlign": "middle" ,"marginRight": "8px"}} src={attachedFileIcon}/> {items[props.index].title}
                </span>
            );
        else
            return (
                <span className={"data_name"} onClick={() => onMailDetailPopup(props.original.id)}> {items[props.index].title}</span>
            );

    }

    const onExport = exportType => {
        const {sorted: [{id, desc}]} = filter;
        settingService.exportAlarmMailHistory({
            searchText: searchTextForExport,
            sortColumn: snakeCase(id),
            sortOrder: desc ? 'desc' : 'asc',
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(error => console.log(error));
    };

    const columns = useMemo(()=>[
        {
            Header: 'No',
            accessor: "id",
            width: 50
        },
        {
            Header: t("TEXT_ORGANIZATION_P"),
            accessor: "organizationName",
            sortable: false,
            width: 200
        },
        {
            Header: t("COM_EBD_TSC_EMAIL_SUBJECT"),
            accessor: "title",
            Cell: renderTitleCell,
            sortable: false,
            width: 400
        },
        {
            Header: t("MIS_SID_SERVER_SMTP_ACCESS"),
            accessor: 'result',
            Cell: ({value}) => value ? t('COM_TEXT_SUCCESS_P') : t('TEXT_FAIL_P'),
            sortable: false,
            width: 200
        },
        {
            Header: t("MIS_SID_SERVER_DATE_SENT"),
            accessor: "sendTime",
            Cell: ({value}) => <span>{Moment(value).format('YYYY-MM-DD HH:mm:ss')}</span>
        }
    ])

    return (
        <div style={{width: '100%', display : props.currContent === 'ALARM_MAIL_HISTORY' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"LOG_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick ={onExport}/>
                </div>
                <div className="rightButton">
                    <SearchBar id="scheduleSearch" placeholder={t('COM_EBD_TSC_EMAIL_SUBJECT')} onClickSearch={onChangeSearchText} onChangeSearch={onChangeSearchTextForExport} />
                </div>
            </div>
            <MagicInfoTable
                data={items}
                loading={loading}
                sorted={sorted}
                onSortedChange={onSortedChange}
                columns= {columns}
                style={style}
                usePagination={true}
                className="-striped -highlight"
                paginationOptions={{
                    totalCount: totalCount,
                    page: page,
                    pageSize: pageSize,
                    pageSizeOptions: SETTING_PAGE_SIZE_OPTIONS,
                    onPageChange: onPageChange,
                    onPageSizeChange: onPageSizeChange,
                    divide: "setting"
                }}
            />            
        </div>
    );

}

export default AlarmMailHistory
