import React, {useEffect, useRef, useState} from 'react'
import './SearchBar.css';
import classNames from 'classnames';
import SearchDetail from "./SearchDetail";
import {useOutsideClick} from "../../helper";

const SearchBar = ({id, title, placeholder, width, onChangeSearch, onClickSearch, type = '', keyword : keywordFromProps = undefined, enableDetail = false, searchDetails, onClickDetailSearch, forceDisable = false, callbackClearSearchFilter = undefined, callbackEnableStatus = undefined, toolTip = undefined}) => {

    const ref = useRef();
    const [showDetail, setShowDetail] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [isInternalPopUpWorking, setIsInternalPopUpWorking] = useState(false);

    const toggleDetailSearch = () => {
        if (id === 'userSearch') {
            if (showDetail === true) {
                if (callbackClearSearchFilter !== undefined) {
                    callbackClearSearchFilter();
                }
            } else {
                if (callbackEnableStatus !== undefined) {
                    callbackEnableStatus();
                }
            }
        }

        setShowDetail(!showDetail);
    }

    const onChange = (e) => {
        setKeyword(e.target.value);

        if(onChangeSearch !== undefined) {       
            onChangeSearch(e.target.value);
        }

    }
    
    const onKeyDown = (e) => {
        if(e.keyCode === 13){
            onSearch(e);
        }
    }

    const onSearch = (e) => {
        if (onClickSearch) {
            if (keyword === undefined || keyword === null) {
                onClickSearch('');
            } else {
                onClickSearch(keyword);
            }
        }
    }

    const onSearchDetail = (state) => {
        state.keyword = keyword;
        onClickDetailSearch(state);
        setShowDetail(false);
    }

    useOutsideClick(ref, (e)=> {
        if(e.target.classList&&e.target.classList.contains("select_option")){
            return;
        }

        if (!isInternalPopUpWorking) {
            setShowDetail(false);
        }
    })

    useEffect(()=> {
        if (keyword !== keywordFromProps) {
            setKeyword(keywordFromProps);
        }
    }, [keywordFromProps])

    useEffect(() => {
        if (forceDisable) {
            setShowDetail(false);
        }
    }, [forceDisable])

    const toggleInternalPopupStatus = (value) => {
        setIsInternalPopUpWorking(value);
    };

    return (
        <div className="float_l" ref={ref}>
            <div className={classNames("search_wrap", "long_search_box", {'contentSchedule': type === 'contentSchedule'})}  data-tip={toolTip}>
                <input
                    type="search"
                    id={id}
                    list="searchDataList"
                    placeholder={placeholder}
                    maxLength="60"
                    title={title}
                    style={{width: width === undefined || width === '' ? 280 : width}}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={keyword}
                />
                {
                    enableDetail &&
                    <button type="button" id="onSearchDetailBtn" className="search_detail_btn"
                            onClick={toggleDetailSearch} />
                }
                <button type="button" id="onSearchBtn" className="search_btn" onClick={() => onClickSearch(keyword ? keyword : '')}/>
            </div>
            {showDetail && <SearchDetail searchDetails={searchDetails} toggleInternalPopupStatus={toggleInternalPopupStatus} onClickSearch={onSearchDetail}/>}
        </div>
    )
}


export default SearchBar;