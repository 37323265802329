import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";

import MISDialog from "../MISDialog";
import RadioGroup from "../../radio/RadioGroup";

import './PlaylistSettingPopup.css';
import Checkbox from "../../checkbox/Checkbox";
import Moment from "moment";
import NumberInput from "../../input/NumberInput";
import {isArray, isEmpty, isNil} from "lodash";
import TimeInput from "../../input/TimeInput";
import {commonConstants, LPLAYER, TAG_ITEM, WPLAYER} from "../../../constants";
import {popupAction} from "../../../actions";
import {useDispatch} from "react-redux";
import {changeSecondToHMSTime} from "../../../containers/playlist/playlistUtil";
import TextNumberInput from "../../input/TextNumberInput";
import {checkNumberValidation} from "../../../helper";
import TextInput from "../../input/TextInput";
import {toastr} from 'helper/toastrIntercept';
import DateInput from "../../input/DateInput";

const onOffTagMatchOptions = [
    {value:'and', title:'MIS_TEXT_TAG_MATCH_TYPE_AND_P'},
    {value:'or', title:'MIS_TEXT_TAG_MATCH_TYPE_OR_P'}
];

const IPLAYER = 'IPLAYER';

const convertSettingInfoByPlaylistType = (item, playlistType) => {
    let {contentDuration, playWeight, randomCount, effects, contentTag, isSubPlaylist, playTime, startDate, expiredDate} = item;

    if (playlistType === '5') {
        contentDuration = item.duration;
        startDate = item.startDate;
        expiredDate = item.expiredDate;
    } else if (playlistType === '4') {
        contentDuration = item.contentDuration;
        startDate = item.effects.startDate;
        expiredDate = item.effects.expiredDate;
    } else if (playlistType === '6') {
        startDate = item.effects.startDate;
        expiredDate = item.effects.expiredDate;
    } else {
        startDate = item.effects.startDate;
        expiredDate = item.effects.expiredDate
    }
    return {
        contentDuration,
        mediaType: item.mediaType,
        deviceType: item.deviceType,
        deviceTypeVersion: item.deviceTypeVersion,
        startDate,
        expiredDate,
        playTime,
        isSubPlaylist,
        contentTag,
        effects,
        randomCount,
        playWeight
    }
}

const convertSettingInfoForArray = (item, playlistType, deviceType) => {
    return {
        contentDuration : (playlistType === '4'? 10 : (deviceType.toUpperCase() === IPLAYER ? 30 :  5)),
        mediaType: item.mediaType,
        deviceType: item.deviceType,
        deviceTypeVersion: item.deviceTypeVersion,
        effects : {},
        randomCount: item.every((item)=> item.item.randomCount !== 0) ? item[0].item.randomCount: 0,
        playWeight : '1',
        mode: 'multiple',
    }
}

const PlaylistSettingPopup = ({ item, onSave, onClose, playlistType, deviceType, index}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    let {contentDuration, mediaType, playWeight = 1, randomCount, effects, contentTag, isSubPlaylist, playTime, startDate, expiredDate, mode = 'setting'
    }  = isArray(item) ? convertSettingInfoForArray(item, playlistType, deviceType) : convertSettingInfoByPlaylistType(item, playlistType);

    const [hoursValue, setHoursValue] = useState("0");
    const [minutesValue, setMinutesValue] = useState("0");
    const [secondsValue, setSecondsValue] = useState("5");
    const [tagMatchValue, setTagMatchValue] = useState(contentTag ? contentTag.matchType : 'and');
    const [stateStartDate, setStateStartDate] = useState(startDate);
    const [stateExpiredDate, setExpiredDate] = useState(expiredDate);
    const [startTime, setStartTime] = useState(effects ? effects.startTime: null);
    const [expiredTime, setExpiredTime] = useState(effects? effects.expiredTime: null);
    const [slotDurationValue, setSlotDurationValue] = useState(playlistType === '4' && contentDuration > 0 ? contentDuration : 10);
    const [playWeightValue, setPlayWeightValue] = useState(playWeight);
    const [independentPlayArea, setIndependentPlayArea] = useState(effects ? effects.isIndependentPlay === "Y" ? true: false : null);
    const [contiguous, setContiguous] = useState(effects ? effects.contiguous : null);
    const [randomCountValue, setRandomCountValue] = useState(randomCount);

    const makeSelectedTags = () => {
        const selectedTags = contentTag.tagValues.map((tag, index)=> {
            return { tagId : contentTag.tagIds[index], tagValue : tag}
        })
        return selectedTags;
    }

    const [selectedTags, setSelectedTags] = useState(contentTag ? makeSelectedTags: [])

    const [style, setStyle] = useState({
        endDatePicker: true,
        playbackTimeNumber: true,
        playbackTime: false,
    });

    const neverExpireChk = useRef();
    const slotDurationChk = useRef();
    const isIndependentPlayRef = useRef();
    const everyDayRef = useRef();
    const monRef = useRef();
    const tueRef = useRef();
    const wedRef = useRef();
    const thuRef= useRef();
    const friRef = useRef();
    const satRef = useRef();
    const sunRef = useRef();
    const contiguousRef = useRef();
    const playOnceRef = useRef();

    const getSlotDuration = () => {
        return !slotDurationChk.current.checked ? 0 : (slotDurationValue > 0 ? slotDurationValue: 0);
    };

    const getRandomCountValue = (item) => {
        if (item.randomCount !== 0 && randomCountValue === 0) {
            return item.randomCount
        } else {
            return randomCountValue
        }
    }

    const makeUpdateItem = (changedItem) => {
        if (selectedTags && selectedTags.length > 0) {
            changedItem.contentTag = {
                matchType: tagMatchValue,
                tagValues: selectedTags.map((tag) => {
                    return tag.tagValue
                }),
                tagIds: selectedTags.map((tag) => {
                    return tag.tagId
                })
            }
        } else {
            delete changedItem['contentTag'];
        }

        const contentPlayingTime = playlistType === '4' ? getSlotDuration() :
            parseInt(hoursValue) * 3600 + parseInt(minutesValue) * 60 + parseInt(secondsValue);

        if (isEmpty(changedItem.playTime) && playlistType !== '4') {
            if (playlistType === '0') {
                changedItem.contentDuration = contentPlayingTime;
                changedItem.effects.contentDuration = contentPlayingTime;
            } else if (playlistType === '2') {
                changedItem.contentDuration = contentPlayingTime;
            } else if (playlistType === '5') {
                changedItem.duration = contentPlayingTime;
            } else if (playlistType === '6') {
                changedItem.contentDuration = contentPlayingTime;
            }
        } else if (playlistType === '4') {
            changedItem.contentDuration = contentPlayingTime;
        }

        if (playlistType === '5') {
            return {
                ...changedItem,
                startDate: stateStartDate ? stateStartDate : Moment(new Date()).format('YYYY-MM-DD'),
                expiredDate: stateExpiredDate ? stateExpiredDate : Moment(new Date("2999-12-31")).format('YYYY-MM-DD'),
            };
        } else {
            const startDate = stateStartDate ? stateStartDate : playlistType === '4' ? undefined : Moment(new Date()).format('YYYY-MM-DD');
            const expiredDate = stateExpiredDate ? stateExpiredDate : playlistType === '4' ? undefined : Moment(new Date("2999-12-31")).format('YYYY-MM-DD');

            return {
                ...changedItem,
                playWeight: playWeightValue,
                randomCount: getRandomCountValue(changedItem),
                effects: {
                    ...changedItem.effects,
                    startDate,
                    expiredDate,
                    startTime,
                    expiredTime,
                    isIndependentPlay: independentPlayArea ? 'Y' : 'N',
                    repeatType: makeRepeatType(),
                    contiguous
                },
            };
        }
    };

    const playlistSettingSave = () => {
        const itemMode = playlistType === '5'? TAG_ITEM: '';

        if (mode === 'setting') {
            onSave(makeUpdateItem(item), index, itemMode)
        } else {
            item.map(({item, index})=>{
                onSave(makeUpdateItem(item), index, itemMode)
            })
        }
    };

    const makeRepeatType = () => {
        let repeatType = "";

        if (independentPlayArea) {
            repeatType = monRef.current.checked ? repeatType + "mon" : repeatType +"";
            repeatType = tueRef.current.checked ? repeatType === "" ? repeatType + "tue" : repeatType + ";tue" : repeatType +"";
            repeatType = wedRef.current.checked ? repeatType === "" ? repeatType + "wed" : repeatType + ";wed" : repeatType +"";
            repeatType = thuRef.current.checked ? repeatType === "" ? repeatType + "thu" : repeatType + ";thu" : repeatType +"";
            repeatType = friRef.current.checked ? repeatType === "" ? repeatType + "fri" : repeatType + ";fri" : repeatType +"";
            repeatType = satRef.current.checked ? repeatType === "" ? repeatType + "sat" : repeatType + ";sat" : repeatType +"";
            repeatType = sunRef.current.checked ? repeatType === "" ? repeatType + "sun" : repeatType + ";sun" : repeatType +"";
        }

        return repeatType;
    };

    const updateStyle = () => {
        if (contentDuration === 0 && (mediaType === 'CIFS' || mediaType === 'FTP')) {
            setStyle({...style,playbackTime : true})
        }
        if (playlistType === '4' && contentDuration > 5) {
            if (mode === 'multiple') {
                setStyle({...style,playbackTimeNumber: true})
            } else {
                if (contentDuration > 5) {
                    setStyle({...style,playbackTimeNumber: false})
                } else {
                    setStyle({...style,playbackTimeNumber: true})
                }
            }
        } else {
            if (playlistType !== '4') {
                if (playlistType === '5') {
                    if (isEmpty(startDate) && isEmpty(expiredDate)
                        || startDate === Moment(new Date()).format('YYYY-MM-DD') && expiredDate === Moment(new Date('2999-12-31')).format('YYYY-MM-DD')) {
                        if (playTime != null && playTime != "") {
                            setStyle({...style, playbackTime: true, endDatePicker: true})
                        } else {
                            setStyle({...style, endDatePicker: true});
                        }
                    } else {
                        if (playTime != null && playTime != "") {
                            setStyle({...style, playbackTime: true, endDatePicker: false})
                        } else {
                            setStyle({...style, endDatePicker: false});
                        }
                    }
                } else {
                    if (isEmpty(effects.startDate) && isEmpty(effects.expiredDate)
                       || effects.startDate === Moment(new Date()).format('YYYY-MM-DD') && effects.expiredDate === Moment(new Date('2999-12-31')).format('YYYY-MM-DD')
                    ) {
                        if (playTime != null && playTime != "") {
                            setStyle({...style, playbackTime: true, endDatePicker: true})
                        } else {
                            setStyle({...style, endDatePicker: true});
                        }
                    } else {
                        if (playTime != null && playTime != "") {
                            setStyle({...style, playbackTime: true, endDatePicker: false})
                        } else {
                            setStyle({...style, endDatePicker: false});
                        }
                    }
                }
            }
        }
    }

    useEffect(()=>{
        updateStyle()
    }, item);

    useEffect(() => {
        if (contentDuration === 0 && (mediaType === 'CIFS' || mediaType === 'FTP')) {
            playOnceRef.current.checked = true;
            setStyle({...style, playbackTime : true})
        }
        if (independentPlayArea) {
            isIndependentPlayRef.current.checked = true;
            if (effects.repeatType) {
                const days = effects.repeatType.split(";");
                days.forEach((day) => {
                    switch (day) {
                        case 'mon':
                        monRef.current.checked = true;
                            break;
                        case 'tue':
                        tueRef.current.checked = true;
                            break;
                        case 'wed':
                        wedRef.current.checked = true;
                            break;
                        case 'thu':
                        thuRef.current.checked = true;
                            break;
                        case 'fri':
                        friRef.current.checked = true;
                            break;
                        case 'sat':
                        satRef.current.checked = true;
                            break;
                        case 'sun':
                        sunRef.current.checked = true;
                            break;
                    }
                })

                if (days.length === 7) everyDayRef.current.checked = true;
            }
        }
        if (contiguous) {
            contiguousRef.current.checked = true;
        }
        if (playlistType === '4' && contentDuration) {
            if (mode === 'setting') {
                slotDurationChk.current.checked = true;
            }
            setSecondsValue("10");
            if (!isEmpty(contentDuration)) {
                let hmsTimeArr = changeSecondToHMSTime(Number(contentDuration)).split(":");
                setHoursValue(parseInt(hmsTimeArr[0]))
                setMinutesValue(parseInt(hmsTimeArr[1]));
                setSecondsValue(parseInt(hmsTimeArr[2]));
            }
        } else {
            if (playlistType !== '4' && deviceType.toUpperCase() === IPLAYER) {
                setSecondsValue("30");
            } else if(playlistType === '4') {
                slotDurationChk.current.checked = false;
            }
            if (playlistType !== '4' && deviceType.toUpperCase() !== LPLAYER) {
                if ((isEmpty(startDate) && isEmpty(expiredDate)) ||
                    startDate === Moment(new Date()).format('YYYY-MM-DD') && expiredDate === Moment(new Date('2999-12-31')).format('YYYY-MM-DD')) {
                    neverExpireChk.current.checked = true
                } else {
                    neverExpireChk.current.checked = false
                }
            }
            if (!isNil(contentDuration) && !isSubPlaylist) {
                let hmsTimeArr = changeSecondToHMSTime(Number(contentDuration)).split(":");
                setHoursValue(parseInt(hmsTimeArr[0]))
                setMinutesValue(parseInt(hmsTimeArr[1]));
                setSecondsValue(parseInt(hmsTimeArr[2]));
            }

            if (playTime != null && playTime != "") {
                let arr = playTime.split(":");
                setSecondsValue(arr[2]);
                setMinutesValue(arr[1]);
                setHoursValue(arr[0]);
            }

        }
    },[contentDuration]);

    const setPlaylistItemRunDate = (date, type) => {
        if (type === 'start') {
            setStateStartDate(Moment(date).format('YYYY-MM-DD'))
            if (!Moment(stateExpiredDate).isAfter(Moment(date))) {
                setExpiredDate(Moment(date).format('YYYY-MM-DD'))
            }
        } else {
            setExpiredDate(Moment(date).format('YYYY-MM-DD'))
            if (stateStartDate == "") {
                setStateStartDate(Moment(date).format('YYYY-MM-DD'))
            } else {
                if (!Moment(stateStartDate).isBefore(Moment(date))) {
                    setStateStartDate(Moment(date).format('YYYY-MM-DD'))
                }
            }
        }
    };

    const handleNeverExpire = () => {
        const checked = neverExpireChk.current.checked;
        if (checked) {
            setExpiredDate("");
            setStateStartDate("");
            setStyle({...style,endDatePicker: true})
        } else {
            setStateStartDate(Moment().format('YYYY-MM-DD'));
            setExpiredDate(Moment().format('YYYY-MM-DD'));
            setStyle({...style,endDatePicker: false})
        }
    };

    const handleSlotDurationRef = () => {
        const checked = slotDurationChk.current.checked;
        if (checked) {
            setSlotDurationValue(slotDurationValue);
            setStyle({...style,playbackTimeNumber: false})
        } else {
            setSlotDurationValue("10");
            setStyle({...style,playbackTimeNumber: true})
        }
    };

    const handleIndependence = () => {
        const checked = isIndependentPlayRef.current.checked;
        if (checked) {
            setIndependentPlayArea(true);
        } else {
            setIndependentPlayArea(false);
            setStateStartDate("");
            setExpiredDate("");
        }
    };

    const handleContiguous = () => {
        const checked = contiguousRef.current.checked;
        if (checked) {
            setContiguous(true);
        } else {
            setContiguous(false);
        }
    }

    const handlePlayOnceOption = () => {
        const checked = playOnceRef.current.checked;
        if (checked) {
            setStyle({...style,playbackTime : true})
            setHoursValue(0);
            setMinutesValue(0);
            setSecondsValue(0);
        } else {
            setStyle({...style,playbackTime : false})
            setHoursValue(0);
            setMinutesValue(0);
            setSecondsValue(30);
        }
    };

    const handleEverydayRef = () => {
        const checked = everyDayRef.current.checked;
        if (checked) {
            monRef.current.checked = true;
            tueRef.current.checked = true;
            wedRef.current.checked = true;
            thuRef.current.checked = true;
            friRef.current.checked = true;
            satRef.current.checked = true;
            sunRef.current.checked = true;
        } else {
            monRef.current.checked = false;
            tueRef.current.checked = false;
            wedRef.current.checked = false;
            thuRef.current.checked = false;
            friRef.current.checked = false;
            satRef.current.checked = false;
            sunRef.current.checked = false;
        }
    };

    const handleDayRef = (e) => {
        const monChecked = monRef.current.checked;
        const tueChecked = tueRef.current.checked;
        const wedChecked = wedRef.current.checked;
        const thuChecked = thuRef.current.checked;
        const friChecked = friRef.current.checked;
        const satChecked = satRef.current.checked;
        const sunChecked = sunRef.current.checked;

        if (monChecked && tueChecked && wedChecked && thuChecked && friChecked && satChecked && sunChecked) {
            everyDayRef.current.checked = true;
        } else {
            everyDayRef.current.checked = false;
        }
    }

    const applyMediaTagOnContents = (tags) => {
        setSelectedTags(tags);
        dispatch(popupAction.closePopup(commonConstants.TAG_SETTING))
    }

    const deleteSelectedTags = (tagId) => {
        let tags = [...selectedTags];
        let deletedTagIndex = tags.findIndex((tag) => tag.tagId === tagId);
        tags.splice(deletedTagIndex, 1);
        setSelectedTags(tags);
    }

    const setPlaylistTime  = (event, time, type) => {
        const date = new Date(Moment().format('YYYY-MM-DD')+' '+time);
        if (type === 'start') {
            const end = new Date(Moment().format('YYYY-MM-DD')+' '+ expiredTime);
            if (date.getTime() > end.getTime()) {
                toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                return false;
            } else {
                setStartTime(time);
            }
        } else {
            const start = new Date(Moment().format('YYYY-MM-DD')+' '+ startTime);
            if (start.getTime() > date.getTime()) {
                toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                return false;
            } else {
                setExpiredTime(time);
            }
        }
    }

    return (
        <div className={'playlist_setting_popup'}>
            <MISDialog
                dialog = {{
                    title: mode === 'multiple' ? t("MIS_SID_20_EDIT_MULTIPLE") :  t("COM_SID_SETTINGS"),
                    closeOnEscape : true,
                    modal:true,
                    width :700,
                    height : 'auto' ,
                    position: {x: -300, y: -400},
                    onClose:()=> onClose()
                }}
                buttons ={{
                    leftButtons: [
                    ],
                    rightButtons: [
                        {
                            id: 'APPROVE_SUBMIT',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: (e) => playlistSettingSave()
                        },
                        {
                            id: 'APPROVE_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: () => onClose(),
                        }
                    ]
                }}
            >
                <div className="playlist detail_view setting mini_p" style={{width:700}}>
                    <table>
                        <tbody>
                        <tr>
                            <th>{t("MIS_SID_20_PLAYBACK_TIME")}</th>
                            {
                                playlistType !== '4' &&
                                <td style={{display:'inline-flex'}}>
                                    <TextInput type={"text"} className="light_gray ta_c" width={40} textAlign={'center'} value={hoursValue}
                                        onChange={(e) => {
                                        if (Number.isNaN(parseInt(e.target.value)) && e.nativeEvent.data !== null) return;
                                            setHoursValue(e.target.value);
                                        }}
                                        onBlur={() => {
                                            const hourValue = checkNumberValidation(hoursValue, 0, 23);
                                            setHoursValue(hourValue);
                                        }}
                                        disabled={style.playbackTime}
                                    />
                                    <span className="space" style={{float:'left',margin:'3px 5px'}}>{t("COM_TEXT_HOUR_P")}</span>
                                    <TextInput type={"text"} className="light_gray ta_c" width={40} textAlign={'center'} value={minutesValue}
                                           onChange={(e) => {
                                               if (Number.isNaN(parseInt(e.target.value)) && e.nativeEvent.data !== null) return;
                                               setMinutesValue(e.target.value);
                                           }}
                                           onBlur={(e) =>{
                                               const minuteValue = checkNumberValidation(minutesValue, 0, 59);
                                               setMinutesValue(minuteValue);
                                           }}
                                           disabled={style.playbackTime}
                                    />
                                    <span className="space" style={{float:'left', margin:'3px 5px'}}>{t("COM_SID_MIN")}</span>
                                    <TextInput type={"text"} className="light_gray ta_c" width={40} textAlign={'center'} value={secondsValue}
                                               onChange={(e) => {
                                                   if (Number.isNaN(parseInt(e.target.value)) && e.nativeEvent.data !== null) return;
                                                   setSecondsValue(e.target.value);
                                               }}
                                               onBlur={(e) => {
                                                   const secondValue = checkNumberValidation(secondsValue, 0, 59);
                                                   const contentPlayingTime = parseInt(hoursValue) * 3600 + parseInt(minutesValue) * 60 + parseInt(secondValue);
                                                   if (contentPlayingTime < 5) {
                                                       toastr.error(t("MESSAGE_UPLOADER_ERROR_PLAYTIME_P"))
                                                       setSecondsValue(5);
                                                   } else {
                                                       setSecondsValue(secondValue);
                                                   }
                                               }}
                                               disabled={style.playbackTime}
                                    />
                                    <span className="space" style={{float:'left',margin:'3px 5px'}}>{t("COM_SID_SEC")}</span>
                                </td>
                            }
                            {
                                playlistType === '4' &&
                                <td>
                                    <div className="input_number float_l">
                                        <TextNumberInput type={"text"}
                                             className={"light_gray ta_c"}
                                             value={slotDurationValue}
                                             onChange={(e, value) => setSlotDurationValue(value)}
                                             style={{width:30, float:'left'}}
                                             disabled={style.playbackTimeNumber}
                                             min={5}
                                             max={3599}
                                        />
                                    </div>
                                    <div style={{float:'left', padding:'5px 0 5px 10px'}}>
                                        <Checkbox  style={{lineHeight: '14px'}} onClick={(e) => handleSlotDurationRef(e)} name={t("TEXT_ENABLE2_P")} id="slotDurationCheck"ref={slotDurationChk} />
                                    </div>
                                </td>
                            }
                        </tr>
                        {
                            playlistType !== '4' && (mediaType === 'CIFS' || mediaType === 'FTP') && deviceType !== WPLAYER &&
                            <tr>
                                <th></th>
                                <td>
                                    <Checkbox style={{lineHeight: '14px'}} onClick={(e) => handlePlayOnceOption(e)} name={t("MIS_SID_PLAY_ONCE")} id={"playOnce"} ref={playOnceRef} />
                                </td>
                            </tr>
                        }
                        {
                            playlistType !== '4' && deviceType !== LPLAYER &&
                            <tr>
                                <th>{t("MIS_SID_20_RUN_DATE")}</th>
                                <td>
                                    <div style={{display: 'inline-flex', flexDirection: 'row',}}>
                                        <DateInput
                                            width={168}
                                            date={!isEmpty(stateStartDate) ? Moment(stateStartDate).toDate() : Moment().toDate()}
                                            onChange={(date) => setPlaylistItemRunDate(date, 'start')}
                                            disabled={style.endDatePicker}
                                        />
                                        <span className="space ml10 mr10 float_l">&nbsp;~&nbsp;</span>
                                        <DateInput
                                            width={168}
                                            date={!isEmpty(stateExpiredDate) ? Moment(stateExpiredDate).toDate() : new Date("2999-12-31")}
                                            onChange={(date) => setPlaylistItemRunDate(date, 'end')}
                                            disabled={style.endDatePicker}
                                        />
                                    </div>
                                </td>
                            </tr>
                        }
                        {
                            playlistType !== '4' && deviceType !== LPLAYER &&
                            <tr>
                                <th></th>
                                <td><Checkbox style={{lineHeight: '14px'}} id={'playlistSettingEvery'} name={t("TEXT_EVERYDAY_P")} onClick={(e)=>handleNeverExpire(e)} ref={neverExpireChk} /></td>
                            </tr>
                        }
                        {
                            playlistType === '4' &&
                            <tr>
                                <th>{t("MESSAGE_STATISTICS_LEFT_MENU_SUB_CONTENT_FREQUENCY_P")}</th>
                                <td>
                                    <div className="input_number  float_l">
                                        <NumberInput type={"text"} value={playWeightValue}
                                             className={"light_gray ta_c"}
                                             onChange={(e, value) => {setPlayWeightValue(value)}}
                                             onKeyDown={(e, value) => setPlayWeightValue(value)}
                                             style={{width:20, float:'left'}}
                                             id={"playWeight"}
                                             min={1}
                                             max={100}
                                        />
                                    </div>
                                </td>
                            </tr>
                        }
                        {
                            playlistType === '4' &&
                            <tr>
                                <th>{t("MIS_SID_20_PLAY_INDEPENDENTLY")}</th>
                                <td>
                                    <Checkbox  style={{lineHeight: '14px'}} id={'independentPlay'} name={t("TEXT_ENABLE2_P")} onClick={(e)=>handleIndependence(e)} ref={isIndependentPlayRef} />
                                </td>
                            </tr>
                        }
                        {
                            independentPlayArea &&
                            <tr className="advertisement" style={{borderBottom: 'none', display: 'table-row'}}>
                                <td colSpan="2">
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                        <tr>
                                            <th style={{paddingLeft:30}}>{t("COM_TABLE_START_DATE_P")}</th>
                                            <td>
                                                <div className="input_date float_l mr8">
                                                    <DateInput
                                                        width={168}
                                                        date={!isEmpty(stateStartDate) ? Moment(stateStartDate).toDate() : setStateStartDate(Moment().format('YYYY-MM-DD'))}
                                                        onChange={(date) => setPlaylistItemRunDate(date, 'start')}
                                                    />
                                                </div>
                                            </td>
                                            <th>{t("COM_SID_START_TIME")}</th>
                                            <td>
                                                <div className="input_date mr8">
                                                    <TimeInput
                                                        value={!isEmpty(startTime) ? startTime : '00:00:00'}
                                                        onChange={(event, value) => {
                                                            setPlaylistTime(event, value, 'start');
                                                        }}
                                                        showSeconds={true}
                                                        style={{width: 75}} />
                                                </div>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th style={{paddingLeft:30}}>{t("COM_TABLE_END_DATE_P")}</th>
                                            <td>
                                                <div className="input_date float_l mr8">
                                                    <DateInput
                                                        width={168}
                                                        date={!isEmpty(stateExpiredDate) ? Moment(stateExpiredDate).toDate(): setExpiredDate(Moment().format('YYYY-MM-DD'))}
                                                        onChange={(date) => setPlaylistItemRunDate(date, 'end')}
                                                    />
                                                </div>
                                            </td>
                                            <th>{t("COM_SID_END_TIME")}</th>
                                            <td>
                                                <TimeInput
                                                    value={!isEmpty(expiredTime) ? expiredTime : "23:59:59"}
                                                    onChange={(event, value) => setPlaylistTime(event, value, 'expired')}
                                                    showSeconds={true}
                                                    style={{width: 75}} />
                                            </td>
                                            <td style={{paddingLeft: 5, display:'flex'}}>
                                                <Checkbox  style={{lineHeight: '14px'}} name={t("MIS_SID_CONTIGUOUS")} id={"contiguous"} ref={contiguousRef} onClick={(e) => handleContiguous(e)}  />
                                                <div id="contiguousInfoBtn" className={"info_button"} style={{padding: '0px 12px', float:'right'}} onClick={() =>
                                                    dispatch(popupAction.addPopup({
                                                    id: commonConstants.ADVERTISEMENT_GUIDE,
                                                    type: commonConstants.ADVERTISEMENT_GUIDE,
                                                    onClose: () => dispatch(popupAction.closePopup(commonConstants.ADVERTISEMENT_GUIDE)),
                                                }))
                                                }></div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        }
                        {
                            independentPlayArea &&
                            <tr className="advertisement" style={{borderTop: 'none'}} id="playlistRepeatTypeWrap">
                                <td style={{paddingLeft:30}} colSpan="2">
                                    <span className={"ml10 mr30"}>
                                        <Checkbox  style={{lineHeight: '14px'}}  id={"weeklyEvery"} name={t("TEXT_EVERYDAY_P")} ref={everyDayRef} onClick={()=>{handleEverydayRef()}}/>
                                    </span>
                                    <span className={"ml15"}>
                                        <Checkbox   style={{lineHeight: '14px'}} id={"mon"} name={t("COM_TEXT_DAY_MONDAY_P")} ref={monRef} onClick={() => handleDayRef()}/>
                                    </span>
                                    <span className={"ml15"}>
                                        <Checkbox  style={{lineHeight: '14px'}} id={"tue"} name={t("COM_TEXT_DAY_TUESDAY_P")} ref={tueRef} onClick={() => handleDayRef()}/>
                                    </span>
                                    <span className={"ml15"}>
                                        <Checkbox  style={{lineHeight: '14px'}} id={"wed"} name={t("COM_TEXT_DAY_WEDNESDAY_P")} ref={wedRef} onClick={() => handleDayRef()}/>
                                    </span>
                                    <span className={"ml15"}>
                                        <Checkbox  style={{lineHeight: '14px'}} id={"thu"} name={t("COM_TEXT_DAY_THURSDAY_P")} ref={thuRef} onClick={() => handleDayRef()}/>
                                    </span>
                                    <span className={"ml15"}>
                                        <Checkbox  style={{lineHeight: '14px'}} id={"fri"} name={t("COM_TEXT_DAY_FRIDAY_P")} ref={friRef} onClick={() => handleDayRef()}/>
                                    </span>
                                    <span className={"ml15"}>
                                        <Checkbox  style={{lineHeight: '14px'}} id={"sat"} name={t("COM_TEXT_DAY_SATURDAY_P")} ref={satRef} onClick={() => handleDayRef()}/>
                                    </span>
                                    <span className={"ml15"}>
                                        <Checkbox  style={{lineHeight: '14px'}} id={"sun"} name={t("COM_TEXT_DAY_SUNDAY_P")} ref={sunRef} onClick={() => handleDayRef()}/>
                                    </span>
                                </td>
                            </tr>
                        }
                        {
                            (playlistType === '0' || playlistType === '6') && deviceType !== LPLAYER &&
                            <tr>
                                <th>{t("MIS_SID_MEDIA_TAG")}</th>
                                <td>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td style={{width:110}}>
                                                <div className="select_box float_l mr8">
                                                    <button className="base select" style={{width:'auto',paddingLeft:10,paddingRight:10}}
                                                            onClick={()=> {
                                                                dispatch(popupAction.addPopup({
                                                                    id: commonConstants.TAG_SETTING,
                                                                    type: commonConstants.TAG_SETTING,
                                                                    tagListType: 'media',
                                                                    showDevice: false,
                                                                    showTagValue: false,
                                                                    selectedTags: selectedTags,
                                                                    zIndexChange: true,
                                                                    onClose: () => dispatch(popupAction.closePopup(commonConstants.TAG_SETTING)),
                                                                    onApply: (selectedTags) => applyMediaTagOnContents(selectedTags)
                                                                }));
                                                            }}
                                                    >
                                                    <span>{t("MIS_SID_SELECT_TAG")}<span className="arrow"></span></span></button>
                                                </div>
                                            </td>
                                            {
                                                selectedTags &&
                                                <td>
                                                    <div style={{maxHeight:200, overflowY:'auto',overflowX:'hidden'}}>
                                                        {
                                                            selectedTags.map(({tagId, tagValue})=> {
                                                                return(
                                                                    <div className="tag_input float_l mr8" style={{width:100,height:30}}>
                                                                        <span className="ellipsis2" value={tagId}>{tagValue}<button onClick={()=> deleteSelectedTags(tagId)} />
                                                                        </span>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        }
                        {
                            (playlistType === '0' || playlistType === '6') && deviceType !== LPLAYER &&
                            <tr>
                                <th><span className="popupTh90">{t("MIS_TEXT_TAG_MATCH_TYPE_P")}</span></th>
                                <td>
                                    <RadioGroup
                                        propertyName="tagMatchType"
                                        selects={onOffTagMatchOptions}
                                        value={!isEmpty(tagMatchValue) ? tagMatchValue : 'and'}
                                        onChange={(e) => setTagMatchValue(e.target.getAttribute('data-value'))}
                                    />
                                </td>
                            </tr>
                        }
                        {
                            randomCount > 0 &&
                            <tr>
                                <th><span className="popupTh90">{t("MIS_SID_RANDOM_INSERT_PLAY_COUNT")}</span></th>
                                <td>
                                    <TextInput type={"text"} className="light_gray ta_c" width={150} textAlign={'center'} value={randomCountValue}
                                               wrapperStyle={{display:'inline-block'}}
                                               onChange={(e) => {
                                                   if (Number.isNaN(parseInt(e.target.value)) && e.nativeEvent.data !== null) return;
                                                   setRandomCountValue(e.target.value);
                                               }}
                                               onBlur={(e) => {
                                                   const randomCount = checkNumberValidation(randomCountValue, 1, 20);
                                                   setRandomCountValue(randomCount);
                                               }}
                                    />{t("MIS_SID_TIMS_S")}
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </MISDialog>
        </div>
    );
};
export default PlaylistSettingPopup;

