import { LEDBOX, RLEDBOX, SIGNAGE, RSIGNAGE } from "../../constants";

export function isSupportFeature(deviceType, featureName) {
    switch(deviceType){
        case LEDBOX:
        case RLEDBOX:
            switch(featureName) {
                case "basicVolume":
                case "basicMute":
                case "panelOnTime":
                case "soundSrs":
                case "soundMode":
                case "screenLampSchedule":
                case "safetyScreenRun":
                case "safetyScreenTimer":
                case "pixelShift":
                case "lampControl":   
                case "soundReset":
                    return false;
                default:
                    return true;
            }
        // case SIGNAGE:
        // case RSIGNAGE:
        //     switch(featureName) {
        //         case "pictureReset":
        //             return false;
        //         default:
        //             return true;
        //     }
        default:
            return true;
    }
}