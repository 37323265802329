import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import MISDialog from '../../MISDialog';
import {deviceService} from '../../../../services';
import {useMISOpt} from '../../../misopt';
import DateInput from '../../../input/DateInput';
import TimeSelect from '../../../selectbox/TimeSelect';
import moment from 'moment';
import {dateToString, toMomentDateTimeFormat} from '../../../../helper';
import './EditAppliedStatusPopup.css';

const EditAppliedStatusPopup = ({rsvId, onSave, onClose}) => {
    const {t} = useTranslation();
    const {misopt} = useMISOpt();
    const [software, setSoftware] = useState({});

    const onClickSave = () => {
        const {user: {dateFormat, timeFormat}} = misopt;
        const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
        const {rsvDate, rsvTime} = software;
        const rsvDateTime = moment({
            year: rsvDate.getFullYear(), 
            month: rsvDate.getMonth(), 
            day: rsvDate.getDate(), 
            hour: rsvTime.hour(), 
            minute: rsvTime.minute(),
        });

        deviceService.updateSoftwareForDeploy(software.softwareId, rsvDateTime.format(dateTimeFormat)).then(res => {
            toastr.success(t('COM_BUTTON_SAVE') + ' - ' + t('COM_TEXT_SUCCESS_P'));
            if(onSave) {
                onSave();
            }
        }).catch(error => {
            toastr.error(t('COM_BUTTON_SAVE') + ' - ' + t('TEXT_FAIL_P'));
        });
    };

    useEffect(() => {
        deviceService.fetchSoftwareForDeploy(rsvId).then(res => {
            const sw = res.items;
            let rsvDateTime, rsvDate, rsvTime;
            if(sw.type === 1 && sw.reservationDate) {
                rsvDateTime = moment(sw.reservationDate);
                rsvDate = rsvDateTime.toDate();
                rsvTime = rsvDateTime;
            }
            setSoftware(Object.assign({}, sw, {rsvDate, rsvTime}));
        }).catch(error => console.log(error));
    }, [rsvId]);


    return (
        <div className='edit_applied_status_popup_wrap'>
            <MISDialog 
                dialog={{
                    title: t('TEXT_TITLE_EDIT_SOFTWARE_RESERVATION_P'),
                    modal: true,
                    isDraggable: false,
                    closeOnEscape: true,
                    width: 780,
                    height: 'auto',
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'COM_BUTTON_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: onClickSave,
                            disable: !software.rsvDate,
                            // disable: !isEmpty(error)
                        },
                        {
                            id: 'BUTTON_CANCEL_P',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose,
                        }
                    ]
                }}>
                <div className='devicepop'>
                    <div className="detail_view">
                        <table>
                            <colgroup>
                                <col width="240px"/>
                                <col width=""/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>{t('TABLE_DEVICE_MODEL_NAME_P')}</th>
                                    <td>
                                        {software.deviceModelName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('TEXT_SOFTWARE_TYPE_P')}</th>
                                    <td>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('COM_TEXT_SOFTWARE_NAME_P')}</th>
                                    <td>
                                        {software.softwareName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('TEXT_SOFTWARE_FILE_NAME_P')}</th>
                                    <td>
                                        {software.fileName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('TABLE_SOFTWARE_VERSION_P')}</th>
                                    <td>
                                        {software.softwareVersion}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('TEXT_CRC_INFO_P')}</th>
                                    <td>
                                        {software.mimeType}
                                    </td>
                                </tr>
                                {
                                    software.type === 1 && software.reservationDate &&
                                    <tr>
                                        <th>{t('TEXT_DEPLOYMENT_RESERVATION_P')}</th>
                                        <td>
                                            <DateInput width={120} date={dateToString(software.rsvDate)} onChange={date => setSoftware({...software, rsvDate: date})} />
                                            <TimeSelect width={80} value={software.rsvTime} style={{marginLeft: '8px'}} onChange={val => setSoftware({...software, rsvTime: val})} />
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <th>{t('LIST_TITLE_APPLY_VERSION')}</th>
                                    <td>
                                        {software.deployAppliedVer}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('TEXT_SELECT_APPLICABLE_DEVICE_P')}</th>
                                    <td>
                                        {
                                            software.groupNameList && software.groupNameList.length > 0 ?
                                            t('TEXT_BY_DEVICE_GROUP_P') : t('TEXT_BY_DEVICE_MODEL_P')
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td>
                                        {
                                            software.groupNameList && software.groupNameList.length > 0 &&
                                            software.groupNameList[0]
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </MISDialog>
        </div>
    );
};

export default EditAppliedStatusPopup;