import React from 'react';
import Carousel, {Modal, ModalGateway} from "react-images";
import {contentService} from "../../../services";
import {connect} from "react-redux";
import {popupAction} from "../../../actions/popup.action";

class ZoomInPopup extends React.Component {
    state = { images: [] , lightboxIsOpen: false}
    componentDidMount() {
        const {resolution = "1080 x 720", contentId} = this.props.content;
        const resolutions = resolution.split("x");
        const param = {
            width: parseInt(resolutions[0]) ,
            height:  parseInt(resolutions[1]),
            resolution: 'HD',
        };

        Object.keys(param).forEach(key => param[key] === undefined && delete param[key]);
        contentService.fetchContentThumbnail(contentId, param)
            .then((res) => {
                if (res && res.body && res.body.items) {
                    const {src} = res.body.items;
                    this.setState({images: [{src : src}], lightboxIsOpen: true})
                    this.props.closePopup('FETCH_TAG_CONDITION_LOADING');
                }
            });
    }

    render() {
        const { images, lightboxIsOpen } = this.state;

        return (
            <ModalGateway>
            {lightboxIsOpen ? (
                <Modal allowFullscreen={false} isFullscreen={false} onClose={(e) => {
                        if( e.keyCode !== 27) {
                            e.stopPropagation();
                            e.preventDefault();
                            this.setState({lightboxIsOpen: false})
                        }
                        this.props.onClose(e);
                }}>
                    <Carousel
                        components={{}}
                        currentIndex={0}
                        frameProps={{ autoSize: 'height' }}
                        views={images}
                    />
                </Modal>
            ) : null}
            </ModalGateway>
        );
    }
}

ZoomInPopup.defaultProps = {
    content: {},
    onClose: () => {}
};

export default connect(
    null,
    dispatch => ({
        closePopup : (id)=> dispatch(popupAction.closePopup(id))
    })
)(ZoomInPopup);