import React, {Component} from "react";
import './DashboardPanel.css'
import './StorageSummary.css'
import {SortableHandle} from "react-sortable-hoc";
import {Doughnut} from "react-chartjs-2";
import {withTranslation} from "react-i18next";
import {dashboardService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import {MoonLoader} from "react-spinners";
import {getErrorMessage} from "../../helper/responseHandler";

class StorageSummary extends Component {

    state = {
        datas: [],
        position: 0,
        loading: true,
    };

    constructor(props) {
        super(props);
        this.onRightClickEvent = this.onRightClickEvent.bind(this);
        this.onLeftClickEvent = this.onLeftClickEvent.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderStorageDetail = this.renderStorageDetail.bind(this);
        this.renderChart = this.renderChart.bind(this);
        this.renderChartInfo = this.renderChartInfo.bind(this);
        this.renderLeftButton = this.renderLeftButton.bind(this);
        this.renderRightButton = this.renderRightButton.bind(this);
    }

    componentDidMount() {
        this.fetchGetStorageInfo();
    }

    onRightClickEvent() {
        if (this.state.datas.length - 1 > this.state.position) {
            this.setState({
                position: this.state.position + 1,
            });
        }
    }

    onLeftClickEvent() {
        if (this.state.position > 0) {
            this.setState({
                position: this.state.position - 1,
            });
        }
    }

    fetchGetStorageInfo = () => {
        this.setState({loading: true,});
        dashboardService.fetchStorageInfo().then(res => {
            let datas = [];
            res.items.forEach((storage) => {
                let totalSize = storage.totalSize;
                let freeSize = storage.freeSize;
                let usedSize = storage.usedSize;
                let rootPath = storage.rootPath;
                let percent = storage.percent * 1;
                let dataset = [{
                    data: [100 - percent, percent],
                    backgroundColor: [
                        '#b7bbd9',
                        '#6e78c8',
                    ],
                    hoverBackgroundColor: [
                        '#b7bbd9',
                        '#6e78c8',
                    ],
                }]
                datas.push({
                    path: rootPath,
                    totalSize: totalSize,
                    usedSize: usedSize,
                    freeSize: freeSize,
                    usedPercent: percent,
                    labels: ['Available', 'Used'],
                    datasets: dataset,
                })
            });
            this.setState({
                datas: datas,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally(() => {this.setState({loading: false});});
    };

    render() {
        let {loading} = this.state;
        return (
            <div className='dashboard-widget'>
                {this.renderHeader()}
                <div className='dashboard-widget-body'>
                    {loading && <MoonLoader css={`position: absolute;top: calc(50% - 40px);left: calc(50% - 40px);`} color="#6e78c8"/>}
                    {!loading &&
                    <div className='storage-summary'>
                        {this.renderStorageDetail()}
                        {this.renderChartInfo()}
                        {this.renderChart()}
                        {this.renderLeftButton()}
                        {this.renderRightButton()}
                    </div>
                    }
                </div>
            </div>
        )
    };

    renderLeftButton() {
        let {datas, position} = this.state;
        if (!datas || datas.length < position || position === 0) {
            return (null);
        }
        return (
            <div className='left' onClick={this.onLeftClickEvent}/>
        );
    }

    renderRightButton() {
        let {datas, position} = this.state;
        if (!datas || datas.length < position || position === datas.length - 1) {
            return (null);
        }
        return (
            <div className='right' onClick={this.onRightClickEvent}/>
        );
    }

    renderChart() {
        let {datas, position} = this.state;
        if (!datas || datas.length === 0 || datas.length < position) {
            return (null);
        }
        return (
            <div className='chart'>
                <Doughnut data={datas[position]}
                          options={{
                              legend: {
                                  display: false,
                              },
                              animation: {
                                  duration: 0
                              },
                              tooltips: {
                                  displayColors: false,
                                  callbacks: {
                                      label: function (tooltipItem, data) {
                                          return data.datasets[0].data[tooltipItem.index] + "%";
                                      },
                                  },
                              }
                          }}
                          redraw={true}/>
            </div>
        );
    }

    renderChartInfo() {
        let {datas, position} = this.state;
        if (!datas || datas.length === 0 || datas.length < position) {
            return (null);
        }
        return (
            <div className='chart_info'>
                <div className='root-path'>{datas[position].path}</div>
                <div className='storage_used'>{datas[position].usedPercent}%</div>
            </div>
        );
    }

    renderStorageDetail() {
        let {t} = this.props;
        let {datas, position} = this.state;
        if (!datas || datas.length === 0 || datas.length < position) {
            return (null);
        }
        return (
            <div className="server_detail">
                <ul>
                    <li className="li"><span className="storage_title">{t("TEXT_TOTAL_SIZE_P")}</span><span className="storage_total">{datas[position].totalSize}</span></li>
                    <li className="li"><span className="storage_title">{t("MIS_SID_20_USED_STORAGE_SPACE")}</span><span className="storage_used">{datas[position].usedSize}</span></li>
                    <li className="li"><span className="storage_title">{t("TABLE_DISK_SPACE_AVAILABLE_P")}</span><span className="storage_available">{datas[position].freeSize}</span></li>
                </ul>
            </div>
        );
    }

    renderHeader() {
        let {id, isEditMode, t} = this.props;
        const DragHandle = SortableHandle(() =>
            <div className='title'>{t("MIS_SID_20_SERVER_STORAGE")}</div>
        );
        return (
            <div className='dashboard-widget-header'>
                <DragHandle />
                {
                    isEditMode && <div title="Close" className="remove" onClick={() => this.props.onRemoveLayoutClickEvent(id)}></div>
                }
            </div>
        );
    }
}

export default withTranslation()(StorageSummary);
