import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {get, isNil, set} from 'lodash';
import {deviceService} from '../../../../services';
import MISDialog from '../../MISDialog';
import {popupAction} from '../../../../actions';
import {commonConstants} from '../../../../constants';
import SubTab from '../../../tab/SubTab';
import EditPresetSetup from './EditPresetSetup';
import {useDeviceInfo} from '../edit/EditDevice';
import EditPresetDisplay from './EditPresetDisplay';
import EditPresetSecurity from './EditPresetSecurity';
import EditPresetCustom from './EditPresetCustom';
import EditPresetTime from './EditPresetTime';
import {cloneWithoutNil} from '../../../../helper';
import EditPresetService from './EditPresetService';
import { getErrorMessage } from 'helper/responseHandler';

const presetDetailSubTabs = [
    'TEXT_TIME_P',
    'TEXT_SETUP_P',
    'MIS_TEXT_DISPLAY_CONTROL_P',
    'COM_SID_SECURITY',
    'COM_SID_CUSTOM',
    // 'COM_SID_SERVICE',
];

const isChanged = (changed, props) => {
    return props.some(prop => changed[prop]);
};

const propertyGroups = {
    dayLightSavingManual: ['dstStartMonth', 'dstStartWeek', 'dstStartDay', 'dstStartTime', 'dstEndMonth', 'dstEndWeek', 'dstEndDay', 'dstEndTime', 'dstTimeDifference'],
};

const setChangedData = (presetInfo, dataName, info) => {

    for(let key in info.changed) {
        if(info.changed[key]) {
            set(presetInfo, `deviceControl.${dataName}.${key}`, get(info.data, key));
        }
    }

    if(dataName === 'time') {
        if(info.changed['timerHoliday'] === false) {
            set(presetInfo, `deviceControl.${dataName}.timerHoliday`, '0');
        }
    }

    if(dataName === 'setup') {
        const {dayLightSaving, dstStartMonth, dstStartWeek, dstStartDay, dstStartTime, dstEndMonth, dstEndWeek, dstEndDay, dstEndTime, dstTimeDifference} = info.data;
        const dayLightSavingManualChanged = isChanged(info.changed, propertyGroups.dayLightSavingManual);

        if(info.changed['dayLightSaving'] || dayLightSavingManualChanged) {
            set(presetInfo, `deviceControl.${dataName}.dayLightSaving`, dayLightSaving);
        }

        if(dayLightSaving || dayLightSavingManualChanged) {
            set(presetInfo, `deviceControl.${dataName}.dayLightSavingManual`, `${dstStartMonth};${dstStartWeek};${dstStartDay};${dstStartTime};${dstEndMonth};${dstEndWeek};${dstEndDay};${dstEndTime};${dstTimeDifference}`);
        }
    }

    if(dataName === 'server') {
        const servers = ['popUpload','screenCapture','firmwareDownload','contentsDownload'];
        let result = true;

        servers.forEach( (s) => {
            if(info.changed[s]){
                const [protocol = '', address = '', capturePort = '', capturePath = ''] = (info.data[s] || '').split(';');
                if(!isValid(protocol)||!isValid(address)||!isValid(capturePort)||!isValid(capturePath)) {
                    result = false;
                    return;
                }
            }
        })
        return result;
    }
};


const isValid = (value) =>{
    if (!value || value.trim().length <= 0) {
        return false;
    }
    return true;
}

const createChanged = info => {
    const changed = {};
    for(let key in info) {
        if(!isNil(get(info, key))) {
            set(changed, key, true);
        }
    }
    return changed;
};

const defaultTimer = {
    // timerSeq: 1,
    timerOnH: "12",
    timerOnM: "00",
    timerOnAmpm: "1",
    timerOnStatus: "0",
    timerOffH: "12",
    timerOffM: "00",
    timerOffAmpm: "1",
    timerOffStatus: "0",
    timerRepeat: "0",
    timerManualWeekday: "0000000",
    timerOffRepeat: "0",
    timerOffManualWeekday: "0000000",
    timerVolume: "0",
    timerSource: "33",
    timerHolidayEnable: "0",
    timerDataCount: 0,
};

const onChangeChecked = (e, info, setInfo) => {
    const newInfo = {...info};
    set(newInfo.changed, e.target.getAttribute('data-name'), e.target.checked);
    setInfo(newInfo);
};

const onChangeCustom = (contentInfo, selectMenu, info, setInfo) => {
    const newInfo = {...info};
    if(selectMenu === '') {
        setInfo({...newInfo,
            data: {
                ...newInfo.data,
            }
        });
    } else if(selectMenu === 'customLogo') {
        /*if(contentInfo.contentId === '') {
            newInfo.changed.customLogo = false;
        }*/
        setInfo({...newInfo,
            data: {
                ...newInfo.data,
                customLogo: contentInfo.contentId,
                customLogoName: contentInfo.contentName,
                customLogoThumbnail: contentInfo.thumbFileId,
                customLogoFileName: contentInfo.fileName,
                customLogoFileSize: contentInfo.fileSize,
            }
        });
    } else {
        setInfo({
            ...newInfo,
            data: {
                ...newInfo.data,
                defaultContent: contentInfo.contentId,
                defaultContentName: contentInfo.contentName,
                defaultContentThumbnail: contentInfo.thumbFileId,
                defaultContentFileName: contentInfo.fileName,
                defaultContentFileSize: contentInfo.fileSize,
            }
        });
    }
};

const EditPresetDetail = ({mode = 'create', preset, deviceSecurity = false, onSave, onClose}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [subTab, setSubTab] = useState(presetDetailSubTabs[0]);
    const [presetDetail, setPresetDetail] = useState(preset);
    const [serverConfig, setServerConfig] = useState(false);
    const [timeInfo, setTimeInfo, onChangeTimeInfo] = useDeviceInfo({
        data: {
            timerConfTimer1: {...defaultTimer},
            timerConfTimer2: {...defaultTimer},
            timerConfTimer3: {...defaultTimer},
            timerConfTimer4: {...defaultTimer},
            timerConfTimer5: {...defaultTimer},
            timerConfTimer6: {...defaultTimer},
            timerConfTimer7: {...defaultTimer},
        },
        changed: {},
        errors: {},
    }, t);

    const [setupInfo, setSetupInfo, onChangeSetupInfo] = useDeviceInfo({
        data: {
            timeZoneIndex: "(GMT) Coordinated Universal Time",
            dayLightSaving: false,
            dstStartMonth: 0,
            dstStartWeek: 0,
            dstStartDay: 0,
            dstStartTime: '12:00',
            dstEndMonth: 0,
            dstEndWeek: 0,
            dstEndDay: 0,
            dstEndTime: '12:00',
            dstTimeDifference: 0,
            ftpConnectMode: 'ACTIVE',
            bandwidth: 0,
            protocolPriority: 0,
            smartDownload: 0,
            triggerInterval: 5,
            monitoringInterval: 10,
            screenCaptureInterval: 10,
            systemRestartInterval: ' 00:00'
        },
        changed: {},
        errors: {},
    }, t);

    const [displayInfo, setDisplayInfo, onChangeDisplayInfo] = useDeviceInfo({
        data: {
            basicSource: 96,
            webBrowserUrl: '300;125;0;',
            basicVolume: 0,
            basicMute: 0,
            customLogo: '0;3',
            osdMenuSize: 0,
            miscOsd: 0,
            advancedOsdDisplayType: '0;0;0;0;0',

            diagnosisAlarmTemperature: 77,
            advancedFanControl: 1,

            pvMode: 0, 
            pvContrast: 70, 
            pvBrightness: 45, 
            pvSharpness: 50, 
            pvColor: 50, 
            pvColortone: 80, 
            pvColorTemperature: 253,
            pvTint: 50, 
            pvSize: 1, 
            mntManual: 0, 
            ppcGamma: 5, 
            pvHdmiBlackLevel: 0, 
            pvDigitalnr: 1, 
            pvFilmmode: 0, 
            displayOutputMode: 0,

            soundMode: 0,

            mntAuto: '0;12;0;1;100;12;0;1;100',

            mntSafetyScreenTimer: "0",
            mntSafetyScreenRun: 0,
            mntPixelShift: '0;4;4;4',

            autoSourceSwitching: '0;0;96;33', 
            maxPowerSaving: 0, 
            brightnessLimit: 0, 
            advancedAutoPower: 0, 
            advancedStandBy: 0, 
            networkStandbyMode: 0,
            ecoSensor: 0, 
            liveMode: 0,
        },
        changed: {},
        errors: {},
    }, t);

    const [securityInfo, setSecurityInfo, onChangeSecurityInfo] = useDeviceInfo({
        data: {
            mntSafetyLock: 0, 
            miscRemocon: 1, 
            miscPanelLock: 0, 
            miscAllLock: 0, 
            touchControlLock: 0,

            miscServerNetworkSetting: 0, 
            miscBlockUsbPort: 1, 
            bluetoothLock: 0, 
            wifiLock: 0, 
            miscBlockNetworkConnection: 1
        },
        changed: {},
        errors: {},
    }, t);

    const [customInfo, setCustomInfo, onChangeCustomInfo] = useDeviceInfo({
        data: {
            customLogo: '',
            customLogoThumbnail: '',
            customLogoFileName: '',
            customLogoFileSize: '',
            defaultContent: '',
            defaultContentThumbnail: '',
            defaultContentFileName: '',
            defaultContentFileSize: '',
        },
        changed: {},
        errors: {},
    }, t);

    const [serverInfo, setServerInfo, onChangeServerInfo] = useDeviceInfo({
        data: {
            popUpload: 'HTTP;;;', 
            screenCapture: 'HTTP;;;', 
            firmwareDownload: 'HTTP;;;', 
            contentsDownload: 'HTTP;;;'
        },
        changed: {},
        errors: {},
    }, t);

    const handleSave = groupIds => {
        let presetInfo = groupIds && groupIds.length > 0 ? {...presetDetail, deviceGroupIds: groupIds} : {...presetDetail};

        setChangedData(presetInfo, 'time', timeInfo);
        setChangedData(presetInfo, 'setup', setupInfo);
        setChangedData(presetInfo, 'display', displayInfo);
        setChangedData(presetInfo, 'security', securityInfo);
        setChangedData(presetInfo, 'custom', customInfo);
        if(!setChangedData(presetInfo, 'server', serverInfo)){
            toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P") + "["+t("COM_SID_SERVICE")+"]");
            return;
        }

        dispatch(popupAction.addPopup({id: commonConstants.LOADING_POPUP, type: commonConstants.LOADING_POPUP}));
        if(mode === 'create') {
            deviceService.createDevicePreset(presetInfo).then(res => {
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                onSave();
            }).catch(error => toastr.error(getErrorMessage(error)))
            .finally(() => dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)));
        } else if(mode === 'edit') {
            deviceService.updateDevicePreset(presetInfo).then(res => {
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                onSave();
            }).catch(error => toastr.error(getErrorMessage(error)))
            .finally(() => dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)));
        }
    };

    const onClickSave = () => {
        handleSave();
    };

    const onPublish = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.DEVICE_GROUP_SELECTION,                                     //SF[00214060]
            type: commonConstants.DEVICE_GROUP_SELECTION,                                   //SF[00214060]
            allowSelectOrganization: false,                                                 //SF[00214060]
            allEnabled: true,
            checkbox: true,
            save: groups => {
                handleSave(groups.map(group => group.groupId));
                dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION));               //SF[00214060]
            },
            close: ()=>dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))         //SF[00214060]
        }));
    };

    useEffect(() => {
        if(mode === 'edit') {
            deviceService.fetchDevicePreset(presetDetail.preconfigId).then(res => {
                const {items: {time, setup, display, security, custom, serverConfig, server}} = res;
                setTimeInfo({...timeInfo, data: Object.assign({}, timeInfo.data, cloneWithoutNil(time)), changed: createChanged(time)});

                if(setup.dayLightSavingManual && setup.dayLightSavingManual.length > 0) {
                    const [dstStartMonth, dstStartWeek, dstStartDay, dstStartTime, dstEndMonth, dstEndWeek, dstEndDay, dstEndTime, dstTimeDifference] = setup.dayLightSavingManual.split(';');
                    setSetupInfo({...setupInfo, data: Object.assign({}, setupInfo.data, cloneWithoutNil({...setup, dstStartMonth, dstStartWeek, dstStartDay, dstStartTime, dstEndMonth, dstEndWeek, dstEndDay, dstEndTime, dstTimeDifference})), changed: createChanged(setup)});
                } else {
                    setSetupInfo({...setupInfo, data: Object.assign({}, setupInfo.data, cloneWithoutNil(setup)), changed: createChanged(setup)});
                }

                setDisplayInfo({...displayInfo, data: Object.assign({}, displayInfo.data, cloneWithoutNil(display)), changed: createChanged(display)});
                setSecurityInfo({...securityInfo, data: Object.assign({}, securityInfo.data, cloneWithoutNil(security)), changed: createChanged(security)});
                setCustomInfo({...customInfo, data: Object.assign({}, customInfo.data, custom), changed: createChanged(custom)});
                if(serverConfig) {
                    setServerInfo({...serverInfo, data: Object.assign({}, serverInfo.data, cloneWithoutNil(server)), changed: createChanged(server)});
                }
                setServerConfig(serverConfig);
            }).catch(error => toastr.error(getErrorMessage(error)));
        } else {
            deviceService.fetchDevicePresetSetting().then(res => {
                setServerConfig(res.items.serverConfig || false);
            }).catch(error => toastr.error(getErrorMessage(error)));
        }
    }, [mode, presetDetail.preconfigId]);

    let subTabs = deviceSecurity ? presetDetailSubTabs : presetDetailSubTabs.filter(tab => tab !== 'COM_SID_SECURITY');
    subTabs = serverConfig ? subTabs.concat('COM_SID_SERVICE') : subTabs;

    return (
        <div className='edit_device_pop_wrap'>
            <MISDialog
                dialog={{
                    title: mode === 'create' ? t('MIS_SID_ADD_PRESET') : `${t('MIS_SID_EDIT_PRESET')} - ${presetDetail.name}`,
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 860,
                    height: 600,
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'DEVICE_PUBLISH',
                            title: t('MIS_SID_20_REGISTER_AND_PUBLISH'),
                            onClick: onPublish,
                        },
                        {
                            id: 'DEVICE_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: onClickSave,
                            // disable: saveDisabled,
                        },
                        {
                            id: 'DEVICE_CANCEL',
                            title: t('COM_BUTTON_CLOSE_P'),
                            onClick: onClose,
                        }
                    ]
                }}>
                <div className='edit_device_wrap'>
                    <SubTab tabs={subTabs} selected={subTab} onClick={id => setSubTab(id)} />
                    { subTab === presetDetailSubTabs[0] && 
                        <EditPresetTime preset timeInfo={timeInfo} onChange={onChangeTimeInfo} onChangeChecked={e => onChangeChecked(e, timeInfo, setTimeInfo)} /> }
                    { subTab === presetDetailSubTabs[1] && 
                        <EditPresetSetup preset setupInfo={setupInfo} onChange={onChangeSetupInfo} onChangeChecked={e => onChangeChecked(e, setupInfo, setSetupInfo)} /> }
                    { subTab === presetDetailSubTabs[2] && 
                        <EditPresetDisplay preset displayInfo={displayInfo} onChange={onChangeDisplayInfo} onChangeChecked={e => onChangeChecked(e, displayInfo, setDisplayInfo)} setDisplayInfo={setDisplayInfo} /> }
                    { subTab === presetDetailSubTabs[3] && deviceSecurity &&
                        <EditPresetSecurity preset securityInfo={securityInfo} onChange={onChangeSecurityInfo} onChangeChecked={e => onChangeChecked(e, securityInfo, setSecurityInfo)} /> }
                    { subTab === presetDetailSubTabs[4] && 
                        <EditPresetCustom preset customInfo={customInfo} setInfo={setCustomInfo} onChange={onChangeCustom} onChangeChecked={(e, customInfo) => onChangeChecked(e, customInfo, setCustomInfo)} /> }
                    { subTab === 'COM_SID_SERVICE' &&
                        <EditPresetService preset serverInfo={serverInfo} onChange={onChangeServerInfo} onChangeChecked={e => onChangeChecked(e, serverInfo, setServerInfo)} /> }
                </div>
            </MISDialog>
        </div>
    );
};

export default EditPresetDetail;