import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import classNames from "classnames";
import {useTranslation} from 'react-i18next';
import {DragSource} from "react-dnd";
import {ItemTypes} from "./ItemTypes";
import {contentService} from "../../services";
import PlaylistTooltip from "./PlaylistTooltip";
import isEmpty from "lodash/isEmpty";
import FADE_1 from '../../images/transition/50x50/ext_eff_zoom/zoomfade.png'
import FADE_2 from '../../images/transition/50x50/ext_eff_zoom/zoomfade.png'
import BLIND from '../../images/transition/50x50/ext_eff_reveal/timeslice.png'
import SPIRAL from '../../images/transition/50x50/ext_eff_reveal/spiralin.png'
import CHECKER from '../../images/transition/50x50/ext_eff_reveal/enigma.png'
import LINEAR from '../../images/transition/50x50/ext_eff_reveal/splitblinds.png'
import STAIRS from '../../images/transition/50x50/ext_eff_reveal/peel.png'
import WIPE from '../../images/transition/50x50/ext_eff_zoom/middlestretch.png'
import RANDOM from '../../images/transition/50x50/ext_eff_special/randomalways.png'
import EFFECT_DEFAULT from '../../images/img/animat_img_7.png'
import DEFAULT from '../../images/img/animat_img_6_normal.png'
import TimeSettingWrap from "./TimeSettingWrap";
import ZoomInPopup from "../../components/popup/playlist/ZoomInPopup";
import {APLAYER, commonConstants, WPLAYER} from "../../constants";
import moment from "moment";
import {popupAction} from "../../actions";
import {useDispatch} from "react-redux";
const IPLAYER = 'IPLAYER';

const AddedContent = React.forwardRef(({
                                           content, index, updateItem, effectClick, itemClick, deleteButtonClick, checkList,
                                           connectDragSource, connectDragPreview, settingButtonClick, deviceType, playlistType
                                       }, ref) => {
    if (playlistType === '3') {return;}
    const dispatch = useDispatch();

    const {t} = useTranslation();
    const {effects, mediaType, contentTag, expirationDate, expiredDate} = content;
    const [checkListState, setCheckList] = useState(checkList);
    const elementRef = useRef(null);

    useImperativeHandle(ref, () => ({
        getNode: () => elementRef.current,
    }));

    const [hOverStatus, setHOverStatus] = useState(false);
    const [checkStatus, setCheckStatus] = useState(false);
    const [effectHover, setEffectHover] = useState(false);
    const [showZoomInPopup, setShowZoomInPopup] = useState(false);

    const onHoverStatus = () => {
        setHOverStatus(true);
    };

    const offHoverStatus = () => {
        setHOverStatus(false);
    };

    const onOffCheckStatus = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setCheckStatus(checkStatus ? false : true);
        itemClick(content, index);
    };

    useEffect(()=> {
        const contentIndex = index;
        checkListState.every(({item, index}) => !(item.contentId == content.contentId && index == contentIndex && item.randomCount == content.randomCount)) ? setCheckStatus(false) : setCheckStatus(true)
    }, [content, index]);

    useEffect(() => {
        setCheckList(checkList)
    }, [checkList]);

    const [effectStringTop, setEffectStringTop] = useState(80);
    const [thumbnailUrl, setThumbnailUrl] = useState('');

    const fetchPlaylistItemThumbnail = (id, width, height, resolution) => {
        const param = {
            width: width,
            height: height,
            resolution: resolution
        };
        Object.keys(param).forEach(key => param[key] === undefined && delete param[key]);
        contentService.fetchContentThumbnail(id, param).then((res) => {
            if (res && res.body && res.body.items) {
                const {src} = res.body.items;
                setThumbnailUrl(src);
            }
        });
    };

    const getIconEffect = (effectName) => {
        switch (effectName) {
            case 'FADE_1':
                return FADE_1;
                break;
            case 'FADE_2':
                return FADE_2;
                break;
            case 'BLIND':
                return BLIND;
                break;
            case 'SPIRAL':
                return SPIRAL;
                break;
            case 'CHECKER':
                return CHECKER;
                break;
            case 'LINEAR':
                return LINEAR;
                break;
            case 'STAIRS':
                return STAIRS;
                break;
            case 'WIPE':
                return WIPE;
                break;
            case 'RANDOM':
                return RANDOM;
                break;
            default :
                return EFFECT_DEFAULT;
                break;
        }
    };

    useEffect(() => {
        content.playlistType === '6' ? fetchPlaylistItemThumbnail(content.thumbnailInfo.split("|")[0], 100, 86,)
            : fetchPlaylistItemThumbnail(content.thumbFileId, 100, 86,);
    }, [content]);

    const getEffectDisable = () => {
        if (playlistType == "1" || ((deviceType.toUpperCase() == IPLAYER || deviceType == APLAYER) && mediaType != 'IMAGE' && mediaType != 'MOVIE')
            || ((deviceType.toUpperCase() != IPLAYER && deviceType != APLAYER) && mediaType != 'IMAGE') || deviceType == WPLAYER|| playlistType == '4') {

            return true;
        }
    }

    useEffect(() => {
        const img = new Image();
        img.src = thumbnailUrl;
        img.onload = () => connectDragPreview(img);
    });

    const [isContentExpired, setContentExpired] = useState(false);

    useEffect(() => {
        const momentExpirationDate = moment(expirationDate, 'YYYY-MM-DD');
        const now = moment();
        setContentExpired(momentExpirationDate.isBefore(now, 'day'));
    }, [expirationDate]);

    const getMarginStyle = () => {
        if ((effects.inName || effects.outName) && deviceType.toUpperCase() !== IPLAYER && deviceType.toUpperCase() !== APLAYER) {
            return '26px 0 0 0'
        } else {
            return '26px 3px 0px 22px'
        }
    }

    const getAddedContentDisabled = () => {
        return content.effects && content.effects.startDate && content.effects.expiredDate &&
            moment(content.effects.expiredDate, 'YYYY-MM-DD').isBefore(moment(), 'day') ? true: false
    }

    const [tooltipPosition, setTooltipPosition] = useState({top: 86, left: 100})

    return connectDragSource(
        <li className={"addedContent"} index={index} ref={elementRef}>
            <div className={"addedContent_" + index}
                 style={{textAlign: 'center', position: 'relative'}}
                 onMouseEnter={(e) => {
                     !showZoomInPopup && onHoverStatus(e);
                     if (e.view.innerWidth < e.pageX + e.target.offsetWidth + 320) {
                         setTooltipPosition({left: -50 ,top: 96})
                     } else if (e.view.innerHeight < e.pageY + e.target.offsetHeight + 100) {
                         setTooltipPosition({...tooltipPosition, top: -96})
                     } else {
                        setTooltipPosition({...tooltipPosition, top: 86})
                     }
                 }}
                 onMouseLeave={(e) => {
                     !showZoomInPopup && offHoverStatus(e);
                 }}
                 onClick={(e) => !showZoomInPopup && !content.isSubPlaylist && onOffCheckStatus(e)}>
                {
                    showZoomInPopup &&
                    <ZoomInPopup
                        onClose={()=> {
                            setShowZoomInPopup(false)
                            offHoverStatus()
                        }}
                        content={content}
                    />
                }
                {
                    <div className={classNames('expiredDate', {'disable' : getAddedContentDisabled() || isContentExpired})}></div>
                }
                <img style={{verticalAlign: 'middle'}} src={thumbnailUrl}/>
                <button id="tagInfoBtn" className="tag" style={{display: contentTag ? 'block' : 'none'}}></button>
                <button className={hOverStatus && (content.mediaType === 'IMAGE' || content.mediaType === 'MOVIE') ? "zoomInBtn" : "none"}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            dispatch(popupAction.addPopup({id: 'FETCH_TAG_CONDITION_LOADING', type: commonConstants.LOADING_POPUP}));
                            setShowZoomInPopup(true)
                        }}/>
                <div className="playlist_tag_info"></div>
                <button className={hOverStatus ? "delete" : "none"}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            deleteButtonClick(index)
                        }}
                />
                {
                    isEmpty(content.playlistId) && !content.isSubPlaylist &&
                    <button className={hOverStatus ? "playlist_settings" : "none"} onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        settingButtonClick(content)
                    }}/>
                }
                <p className="name"><span className="name text-overflow"
                                          style={{width: 80}}>{content.contentName || content.playlistName}</span></p>
                <div className={checkStatus ? "check_on" : ""}></div>
                {
                    isContentExpired && <div className="expired_content_settings"></div>
                }
            </div>
            {
                !content.isSubPlaylist &&
                <button className={"playlistImage_effect"} disabled={getEffectDisable()} onClick={(e) => {e.stopPropagation(); effectClick(index)}}
                        onDragStart={(e)=> {
                            e.stopPropagation();
                            e.preventDefault();
                            return null;
                        }}
                        style={{
                            margin: getMarginStyle(),
                            padding: '0'
                        }}
                        onMouseEnter={(e) => {
                            setEffectHover(true);
                        }}
                        onMouseLeave={(e) => {
                            setEffectHover(false)
                        }}>
                <span>
                    <img className={"playlistImage_effect_button"} src={(effects.inName || effects.outName) ? getIconEffect(effects.inName) : DEFAULT}/>
                </span>
                </button>
            }
            <TimeSettingWrap content={content} playlistType={playlistType} updateItem={updateItem} index={index}
                             deviceType={deviceType}/>
            <div className={"playlist_effect_info"} style={{
                display: (effects.inName || effects.outName) && effectHover ? 'block' : 'none',
                top: effectStringTop
            }}>
                {
                    effects.inName && t("TEXT_EFFECT_IN_P") + " : " + effects.inName
                }
                {
                    <br/>
                }
                {
                    effects.outName && t("TEXT_EFFECT_OUT_P") + " : " + effects.outName
                }
            </div>
            {
                hOverStatus && !isContentExpired && !getAddedContentDisabled() &&
                <PlaylistTooltip tooltipTop={tooltipPosition.top} tooltipLeft={tooltipPosition.left} content={content} playlistType={playlistType}/>
            }
        </li>
    )
});

export default DragSource(
    ItemTypes.PlaylistItem,
    {
        beginDrag: (props, monitor, component) => {
            if (props.content && props.content.randomCount === 0) {
                return {dragItem: props.content, index: props.index};
            } else {
                return {};
            }
        },
        endDrag: (props, monitor, component) => {
        },
        isDragging(props, monitor) {
            return monitor.getItem().id === props.id
        },
    },
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
    }),
)(AddedContent)
