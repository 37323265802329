import React, {Component} from "react";
import './DashboardPanel.css'
import './DeviceConnectionHistory.css'
import {SortableHandle} from "react-sortable-hoc";
import {Bar} from 'react-chartjs-2';
import {withTranslation} from "react-i18next";
import {dashboardService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from "../../helper/responseHandler";

class DeviceConnectionHistory extends Component {
    state = {
        deviceConnectionHistory: {},
        today: [],
        yesterday: [],
        loading: true,
    };

    constructor(props) {
        super(props);
        this.onTimer = this.onTimer.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
    }

    componentDidMount() {
        this.fetchGetDeviceConnectionHistory();
        this.deviceConnectionInterval = setInterval(this.onTimer, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.deviceConnectionInterval);
    }

    onTimer() {
        this.fetchGetDeviceConnectionHistory();
    }

    fetchGetDeviceConnectionHistory = () => {
        dashboardService.fetchDeviceConnectionHistory().then(res => {
            this.setState({
                deviceConnectionHistory: res.items,
                today: res.items.today,
                yesterday:  res.items.yesterday,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    render() {
        return (
            <div className='dashboard-widget'>
                {this.renderHeader()}
                <div className='dashboard-widget-body'>
                    <div className='daily-connected-device'>
                        {this.renderGuide()}
                        {this.renderChart()}
                        {this.renderLabel()}
                    </div>
                </div>
            </div>
        )
    };

    renderGuide() {
        let {t} = this.props;
        return (
            <div className='daily-connected-device-guide'>
                <div className='daily-connected-device-guide-color-index' style={{background: '#D0E1F5'}}/>
                <div className='daily-connected-device-guide-title-index'>{t('MIS_TEXT_STATISTICS_POP_YESTERDAY_P')}</div>
                <div className='daily-connected-device-guide-color-index' style={{background: '#70A7E6', marginLeft: '10px'}}/>
                <div className='daily-connected-device-guide-title-index'>{t('TEXT_TODAY_P')}</div>
            </div>
        );
    }

    renderChart() {
        let charData = {
            labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
            datasets: [],
        };
        let tick = {beginAtZero:true, min: 0, max: 10};
        if(this.state.yesterday && this.state.yesterday.length > 0 && this.state.today && this.state.today.length > 0) {
            let yesterdayData = this.state.yesterday.map((item) => item.connect);
            let todayData = this.state.today.map((item) => item.connect);
            let yesterdayDataset = {
                data: yesterdayData,
                borderColor: '#D2E3F3',
                backgroundColor: '#D2E3F3',
            };
            let todayDataset = {
                data: todayData,
                borderColor: '#6BA7E5',
                backgroundColor: '#6BA7E5'
            };
            charData.datasets.push(yesterdayDataset);
            charData.datasets.push(todayDataset);

            let max = Math.max(...yesterdayData, ...todayData);
            if(max > 10) {
                tick = {beginAtZero:true};
            }
        }
        return (
            <div className='daily-connected-device-chart'>
                <Bar data={charData} width={358} height={142} redraw={true}
                     options={{
                         legend: {
                             display: false,
                         },
                         animation: {
                             duration: 0
                         },
                         scales: {
                             xAxes: [{
                                 display: false,
                             }],
                             yAxes: [{
                                 ticks: {...tick, fontSize: 11}
                             }]
                         },
                         elements: {point: {radius: 0}},
                         tooltips: {
                             displayColors: false,
                             callbacks: {
                                 title: function (tooltipItems, data) {
                                     return null;
                                 },
                                 label: function (tooltipItem, data) {
                                     return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                 },
                             },
                         }
                     }}/>
            </div>
        );
    }

    renderLabel() {
        let times = ['0', '2', '4', '6', '8', '10', '12', '14', '16', '18', '20', '22', '24'];
        return (
            <div className='daily-connected-device-label'>
                {
                    times.map((time, index) => <div key={index} className='daily-connected-device-label-time'>{time}</div>)
                }
            </div>
        );
    }

    renderHeader() {
        let {id, isEditMode, t} = this.props;
        const DragHandle = SortableHandle(() =>
            <div className='title'>{t("MIS_SID_CONNECTED_DEVICES_YESTERDAY_TODAY")}</div>
        );
        return (
            <div className='dashboard-widget-header'>
                <DragHandle />
                {
                    isEditMode && <div title="Close" className="remove" onClick={() => this.props.onRemoveLayoutClickEvent(id)}></div>
                }
            </div>
        );
    }
}

export default withTranslation()(DeviceConnectionHistory);
