import {isNil} from 'lodash';
import {authServerConstants} from "../../constants";

export const authServerUtil = {
    isAuthServerUsed,
    isAuthServerAdminLogin,
    getAuthServerExitUrl,
    setAuthServerUse,
    setIsAuthServerAdminLogin,
    setAuthServerExitUrl,
    removeAuthServerInfo
};

function isAuthServerUsed() {
    const useAuthServer = localStorage.getItem(authServerConstants.USE_AUTH_SERVER);
    return (!isNil(useAuthServer) && useAuthServer === "true");
};

function isAuthServerAdminLogin() {
    const isAdminLogin = localStorage.getItem(authServerConstants.IS_AUTH_SERVER_ADMIN_LOGIN);
    return (!isNil(isAdminLogin) && isAdminLogin === "true");
}

function getAuthServerExitUrl() {
    return localStorage.getItem(authServerConstants.AUTH_SERVER_EXIT_URL);
}

function setAuthServerUse(useAuthServer) {
    if(isNil(useAuthServer)) {
        useAuthServer = false;
    }
    localStorage.setItem(authServerConstants.USE_AUTH_SERVER, useAuthServer);
}

function setIsAuthServerAdminLogin(adminLogin) {
    if(isNil(adminLogin)) {
        adminLogin = false;
    }
    localStorage.setItem(authServerConstants.IS_AUTH_SERVER_ADMIN_LOGIN, adminLogin);
}

function setAuthServerExitUrl(exitUrl) {
    if(!isNil(exitUrl)) {
        localStorage.setItem(authServerConstants.AUTH_SERVER_EXIT_URL, exitUrl);
    }
}

function removeAuthServerInfo() {
    localStorage.removeItem(authServerConstants.USE_AUTH_SERVER);
    localStorage.removeItem(authServerConstants.AUTH_SERVER_EXIT_URL);
    localStorage.removeItem(authServerConstants.IS_AUTH_SERVER_ADMIN_LOGIN);
}