import {combineReducers} from 'redux';
import {menu} from './menu.reducer';
import {users} from './user.reducer';
import {authentication} from './authentication.reducer';
import {common} from './popup.reducer';
import {schedules} from './schedule.reducer';
import {playlist} from "./playlist.reducer";
import {rulesets} from "./ruleset.reducer";
import {progress} from './progress.reducer';
import {device} from './device.reducer';
import {content} from './content.reducer';
import {settings} from './setting.reducer';
import {userConstants} from "../constants";

const appReducer  = combineReducers({
    menu,
    common,
    users,
    authentication,
    schedules,
    playlist,
    rulesets,
    progress,
    device,
    content,
    settings
});


const rootReducer = (state, action) => {
    if (action.type == userConstants.LOGOUT) {
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer;