import React, {useEffect, useRef, useState} from "react";
import {contentConstants, playlistConstants} from "../../../constants";
import {contentService} from "../../../services";
import {jsonToTreeForPlaylistByMe} from "../../../helper";
import Tree, {TreeNode} from "rc-tree/es";
import './MyGroups.css';
import PerfectScrollbar from "perfect-scrollbar";
import DefaultImage from '../../../images/icon/file_type_icon_02.png';
import {Loading} from "../../loading/Loading";
import {playlistService} from "../../../services/playlist.service";


const MyGroups =  ({mode, selectGroup, deviceType, deviceTypeVersion, mediaTypes, playlistTypes, isValidExpired}) => {

    const [groups, setGroups] = useState([]);
    const [loadedGroups, setLoadedGroups] = useState([]);
    const [expandedGroups, setExpandedGroups] = useState([]);

    const initScroll = useRef(false);
    const perfectScrollbar = useRef(undefined);

    const fetchMyContentGroup = () => {
        const params = {
            groupType : mode === 'CONTENT' ? contentConstants.MY_CONTENT_GROUP : playlistConstants.MY_PLAYLIST_GROUP,
            deviceType : deviceType,
            deviceTypeVersion : deviceTypeVersion,
            isValidExpired: isValidExpired !== undefined? isValidExpired : true,
            isApproval: true
        };
        if (mode === 'CONTENT') {
            contentService.fetchGroups(params).then(res => {
                const tree = jsonToTreeForPlaylistByMe(res.items);
                setGroups(tree);
            });
        } else {
            playlistService.fetchPlaylistGroups(params).then(res=> {
                const tree = jsonToTreeForPlaylistByMe(res.items);
                setGroups(tree);
            });
        }
    };

    const onExpand = (key, event) => {
        setExpandedGroups(key);
        perfectScrollbar.current.update();
    };

    const Icon = () => {
        return (<img src={DefaultImage}/>)
    };

    const Title = (node) => {
        return (
            <span>
                {node.name}
                <span className={'group_count'}>{node.resourceCount !== undefined ? node.resourceCount : 0}</span>
            </span>
        )
    };

    const loop = (data) => {
        return data.map((item) => {
            if (item.children) {
                return <TreeNode icon={Icon()} title={Title(item)} key={item.key}>{loop(item.children)}</TreeNode>;
            }
            return (
                <TreeNode icon={Icon()} title={Title(item)} key={item.key} isLeaf={item.isLeaf} />
            );
        });
    };

    const onSelect = (groupIds, treeNode) => {
        let groupId = groupIds[0];
        selectGroup(groupId);
    };

    useEffect(() => {
        if (!initScroll.current) {
            const ps = new PerfectScrollbar('#content_popup_my_group_scroll', {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: false,
            });
            perfectScrollbar.current = ps;
            fetchMyContentGroup();
            initScroll.current = true;
        } else {
            perfectScrollbar.current.update();
        }

    }, []);

    useEffect(() => {
        perfectScrollbar && perfectScrollbar.current.update();
    }, [expandedGroups, groups]);

    return (
        <div className={'my_content_wrap'} id={'content_popup_my_group_scroll'} style={{position:'relative', height: 335}}>
        {
            groups !== undefined && groups.length > 0 ?
                <Tree
                    showIcon={true}
                    onSelect={onSelect}
                    loadedKeys={loadedGroups}
                    expandedKeys={expandedGroups}
                    onExpand={(key, event)=>onExpand(key, event)}
                >
                    {loop(groups)}
                </Tree>
                :
                <div style={{display: 'flex', justifyContent: 'center'}}><Loading size={5}/></div>
        }
        </div>

    )

}
MyGroups.defaultProps = {
    deviceType : undefined,
    deviceTypeVersion : undefined,
    mediaTypes : undefined,
    playlistTypes : undefined
}
export default MyGroups;
