import React, {createContext, useContext} from 'react';
import {toUpper} from 'lodash';
import commonOptions from './commonOptions.json';
import acceptFileTypes from './acceptFileTypes.json';
import deviceOptions from './deviceOptions.json';
import misInit from '../../misInit.json';
import preconfigItems from './preconfigItems.json';
import {SIGNAGE} from '../../constants';


export const MISOptContext = createContext();

export {
    commonOptions,
    acceptFileTypes,
    deviceOptions,
    misInit,
    preconfigItems,
};

export const useMISOpt = () => {
    const misopt = useContext(MISOptContext);
    const {devices, supportedIconDevices, authority, misInit, scheduleMaxChannelCount} = misopt;

    const misOptionSchedule = (deviceTypeParam, deviceTypeVersion) => {
        const deviceType = toUpper(deviceTypeParam);
        let scheduleInfo = undefined;
        if (deviceType === 'IPLAYER' || deviceType === 'LPLAYER') {
            const device = devices.find(d=> d.deviceType.toUpperCase() === deviceType);
            if (device !== undefined) {
                scheduleInfo = device.scheduleInfo;
            }
        } else {
            const device = devices.find(
                d=> d.deviceType.toUpperCase() === deviceType && d.deviceTypeVersion === deviceTypeVersion
            );
            if (device !== undefined) {
                scheduleInfo = device.scheduleInfo;
            }
        }
        if (scheduleInfo === undefined) {
            // if there is no a device type in approved devices
            if (deviceType === 'IPLAYER' || deviceType === 'LPLAYER') {
                scheduleInfo = misInit.devices.find(d=> d.deviceType.toUpperCase() === deviceType).scheduleInfo
            } else {
                scheduleInfo = misInit.devices.find(
                    d=> d.deviceType.toUpperCase() === deviceType && d.deviceTypeVersion === deviceTypeVersion
                ).scheduleInfo;
            }
        }
        return scheduleInfo;
    }
    
    const misOptionContent = (deviceTypeParam, deviceTypeVersion) => {
        const deviceType = toUpper(deviceTypeParam);
        let contentInfo = undefined;
        if (deviceType === 'IPLAYER' || deviceType === 'LPLAYER') {
            contentInfo = devices.find(d=> d.deviceType.toUpperCase() === deviceType).contentInfo
        } else {
            contentInfo = devices.find(
                d=> d.deviceType.toUpperCase() === deviceType && d.deviceTypeVersion === deviceTypeVersion
            ).contentInfo;
        }
        return contentInfo;
    }

    const misOption = (deviceTypeParam, deviceTypeVersion = 1) => {
        const deviceType = toUpper(deviceTypeParam);
        if (deviceType === 'IPLAYER' || deviceType === 'LPLAYER') {
            return devices.find(d=> d.deviceType.toUpperCase() === deviceType)
        }
        const device = devices.find(
            d=> d.deviceType.toUpperCase() === deviceType && d.deviceTypeVersion === deviceTypeVersion
        )
        return device !== undefined ? device : undefined;
    }

    const misOptionFindHigherVersion = (deviceTypeParam, deviceTypeVersion = 1) => {
        let device = misOption(deviceTypeParam, deviceTypeVersion);
        if(device === undefined && toUpper(deviceTypeParam) === 'SPLAYER') {
            let d = undefined;
            for(let i = 0; i < devices.length; i++) {
                if(devices[i].deviceType === 'SPLAYER') {
                    if(d === undefined || (d.deviceTypeVersion > devices[i].deviceTypeVersion && devices[i].deviceTypeVersion > deviceTypeVersion)) {
                        d = devices[i];
                    }
                }
            }
            device = d;
        }
        return device !== undefined ? device : undefined;
    }

    const misOptionDevice = (deviceTypeParam, deviceTypeVersion) => {
        const deviceType = toUpper(deviceTypeParam);
        if(deviceType === SIGNAGE && deviceTypeVersion === 1) {
            return (devices.find(d => d.deviceType.toUpperCase() === deviceType && d.deviceTypeVersion === 3) || {}).deviceInfo;
        }
        if (deviceType === 'IPLAYER' || deviceType === 'LPLAYER') {
            return devices.find(d=> d.deviceType.toUpperCase() === deviceType).deviceInfo;
        }
        return (devices.find(
            d=> d.deviceType.toUpperCase() === deviceType && d.deviceTypeVersion === deviceTypeVersion
        ) || {}).deviceInfo;
    }

    const misAcceptFileTypeObj = (extention) => {
        return misopt.acceptFileTypes.find(o => o.value === extention);
    };
    
    const getAuthority = (id) => {
        let auth = {};
        if (authority !== undefined && id !== undefined) {
            auth = authority[id];
        }
        return auth !== undefined ? auth : {};
    }

    const getDevicePriority = (deviceTypeParam, deviceTypeVersion) => {
        const deviceType = toUpper(deviceTypeParam);
        let device = undefined;
        if (deviceType === 'IPLAYER' || deviceType === 'LPLAYER') {
            device = misInit.devices.find(d => d.deviceType.toUpperCase() === deviceType);
        }else{
            device = misInit.devices.find(d => d.deviceType.toUpperCase() === deviceType && d.deviceTypeVersion === deviceTypeVersion);
        }
        return device !== undefined ? device.priority : 99999;
    }
    return {misopt, misAcceptFileTypeObj, misOptionSchedule, misOptionDevice, misOption, misOptionFindHigherVersion, devices, supportedIconDevices, getAuthority, misOptionContent, getDevicePriority, scheduleMaxChannelCount};
};

const withMISOpt = WrappedComponent => {
    return props => {
        const misopt = useMISOpt();
        return (
            <WrappedComponent misopt={misopt} {...props} />
        );
    };
};


export default withMISOpt;