import React from 'react';
import {connect} from 'react-redux';
import {userActions} from '../../actions';
import queryString from 'query-string';
import {withTranslation} from "react-i18next";
import {isNil} from 'lodash';
import {commonService} from "../../services";
import {authServerUtil} from 'helper/authserver/authServerUtil';

class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        const username = localStorage.getItem('magicInfoUserId') || '';

        this.state = {
            username: username,
            password: '',
        };

        this.requestToken();
    }

    requestToken() {
        const {loginWithCode} = this.props;
        let params = queryString.parse(this.props.location.search);
        loginWithCode(params);
        let isAdmin = (!isNil(params) && !isNil(params.isAdmin) && params.isAdmin == 'Y');
        if(isNil(authServerUtil.getAuthServerExitUrl())) {
            commonService.fetchCheckAuth().then(res => {
                if(res && res.exit_uri) {
                    authServerUtil.setAuthServerExitUrl(res.exit_uri);
                }});
        }
        authServerUtil.setAuthServerUse(true);
        authServerUtil.setIsAuthServerAdminLogin(isAdmin);
    }

    render() {
        return (
            <>
            </>
        );
        //<div className="container bg">Loading....</div>
    }
}
export default connect(
    state => ({
        authentication: state.authentication,
        error: state.users.error
    }),
    dispatch => ({
        logout: ()=> dispatch(userActions.logout()),
        login: (userId, password)=> dispatch(userActions.login(userId, password)),
        loginWithCode:(params)=> dispatch(userActions.loginWithCode(params)),
    })
)(withTranslation()(LandingPage));
