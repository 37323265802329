import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import './DashboardPanel.css'
import './MySummary.css'
import {SortableHandle} from "react-sortable-hoc";
import {dashboardService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import {MoonLoader} from "react-spinners";
import {toMomentDateTimeFormat} from "../../helper";
import Moment from "react-moment";
import {getErrorMessage} from "../../helper/responseHandler";

class MyInfoDashboard extends Component {

    state = {
        mine: {},
        loading: true,
    };

    constructor(props) {
        super(props);
        this.renderHeader = this.renderHeader.bind(this);
        this.user = JSON.parse(localStorage.getItem('user'));
    }

    componentDidMount() {
        this.fetchGetMyInfo();
    }

    getFormatDate = (date) => {
        let dateFormat = this.user ? this.user.dateFormat : "YYYY-MM-DD";
        let timeFormat = this.user ? this.user.timeFormat : "HH:mm:ss";
        const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
        return (
            <Moment format={dateTimeFormat}>{date}</Moment>
        )
    }

    fetchGetMyInfo = () => {
        this.setState({loading: true,});
        dashboardService.fetchMyInfo().then(res => {
            this.setState({
                mine: res.items,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally(() => {this.setState({loading: false});});
    };

    render() {
        let {t} = this.props;
        let {mine, loading} = this.state;
        return (
            <div className='dashboard-widget'>
                {this.renderHeader()}
                <div className='dashboard-widget-body'>
                    {loading && <MoonLoader css={`position: absolute;top: calc(50% - 40px);left: calc(50% - 40px);`} color="#6e78c8"/>}
                    {!loading &&
                    <div className='user-info'>
                        <div className='login-info'>
                            <span className='login-id'>{t('COM_TEXT_LOGIN_ID_P')}<span className='id'>{mine.userId}</span></span>
                        </div>
                        <div className='detail-info'>
                            <div className='detail-item'>
                                <div className='detail-title'>{t('COM_TEXT_USER_NAME_P')}</div>
                                <div className='detail-text'>{mine.userName}</div>
                            </div>
                            <div className='detail-item'>
                                <div className='detail-title'>{t('COM_TEXT_ROLE_P')}</div>
                                <div className='detail-text'>{mine.role}</div>
                            </div>
                            <div className='detail-item'>
                                <div className='detail-title'>{t('TEXT_LAST_LOGIN_DATE_P')}</div>
                                <div className='detail-text'>{this.getFormatDate(mine.lastSignInDate)}</div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    };

    renderHeader() {
        let {t} = this.props;
        const DragHandle = SortableHandle(() =>
            <div className='title'>{t("TEXT_TITLE_PORTLET_LOGIN_P")}</div>
        );
        return (
            <div className='dashboard-widget-header'>
                <DragHandle />
            </div>
        );
    }
}

export default withTranslation()(MyInfoDashboard);

