import i18n from './i18n'

//0번의 SID 를 기준으로 1번 index는 <<A>>, 2번 index는 <<B>> 3번 index는 ... 으로 치환한다.
export const getMixString = (arr) => {
    if(!Array.isArray(arr) || arr.length === 0) {
        return "";
    }

    let str = i18n.t(arr[0]);
    for(let i = 1; i < arr.length; i++) {
        let regex = new RegExp("<<" + String.fromCharCode(64 + i) + ">>", "g");
        str = str.replace(regex, i18n.t(arr[i]));
    }

    return str;
}