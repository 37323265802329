import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import ReactTable from "react-table";
import {useTranslation} from 'react-i18next';
import {chunk, floor, isEmpty, isNil, toInteger} from 'lodash';
import {toastr} from 'helper/toastrIntercept';
import {commonConstants} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import CircleButton from '../../components/button/CircleButton';
import SwitchListNImage from "../../components/switch/SwitchListNImage";
import "../../components/table/react-table.css";
import {deviceService} from '../../services';
import {popupAction} from "../../actions";
import Checkbox from "../../components/checkbox/Checkbox";
import {findWithDefault, toFahrenheit, useCheckRefWithSelectedCnt as useCheckRef} from '../../helper';
import './Device.css';
import DeviceRelativeTimeCell from '../../components/device/DeviceRelativeTimeCell';
import './LedCabinet.css';
import SubTab from '../../components/tab/SubTab';
import RefreshIcon from '../../images/icon/refresh_normal.png';
import DevicePowerIcon from '../../components/device/DevicePowerIcon';
import LedCabinetLayoutOpener from '../../components/device/LedCabinetLayoutOpener';
import fileDownload from 'js-file-download';
import DeviceListColumnTool, {useViewColumns} from '../../components/device/DeviceListColumnTool';
import {getErrorMessage} from '../../helper/responseHandler';
import {useTrGroupProps} from '../../helper/tables';
import { DeviceFadeOutTransition, DeviceFadeInTransition } from 'components/device/DeviceTransition';
import { Loading } from 'components/loading/Loading';
import Status6 from 'images/icon/status6.png';
import MagicInfoTable from "../../components/table/MagicInfoTable";

const TemperatureCell = ({value}) => {
    return !isNil(value) ? `${value} ℃ (${floor(toFahrenheit(value || 0), 1)} ℉)` : ''
};

const ICCell = ({values, t}) => {
    if(!values) {
        return null;
    }

    return (
        values.map(val => {
            const [icType, icVal] = val.split(':');
            if(icType && !isNil(icVal) && toInteger(icVal) >= 0) {
                if(icType === '1') {
                    return <div key={icType}>{`FPGA : ${icVal === '1' ? t('COM_TV_SID_AVAILABLE_KR_MIS20') : t('COM_SID_NOT_AVAILABLE_KR_USE')}`}</div>;
                } else if(icType === '2') {
                    return <div key={icType}>{`STM32 : ${icVal === '1' ? t('COM_TV_SID_AVAILABLE_KR_MIS20') : t('COM_SID_NOT_AVAILABLE_KR_USE')}`}</div>;
                } else if(icType === '3') {
                    return <div key={icType}>{`Power Detect IC : ${icVal === '1' ? t('COM_TV_SID_AVAILABLE_KR_MIS20') : t('COM_SID_NOT_AVAILABLE_KR_USE')}`}</div>;
                } else if(icType === '4') {
                    return <div key={icType}>{`13V : ${icVal === '1' ? t('COM_TV_SID_AVAILABLE_KR_MIS20') : t('COM_SID_NOT_AVAILABLE_KR_USE')}`}</div>;
                }
            }
            return null;
        })
    );
};

const ModuleCell = ({values}) => {
    if(!values) {
        return null;
    }

    return (
        <div>
            {
                chunk(values, 2).map(([v1, v2], i) => {
                    let str = '';
                    if(!isNil(v1) && '' !== v1) {
                        str += `M${i * 2 + 1}:${v1}`;
                    }
                    if(!isNil(v2) && '' !== v2) {
                        str += `, M${(i + 1) * 2}:${v2}`;
                    }
                    return <div key={i}>{str}</div>;
                })
            }
        </div>
    );
};

const VoltageStatusCell = ({values = []}) => {
    const {t} = useTranslation();
    return (
        <>
            {values.map((val, i) => <div key={i}>{`${val.voltage}v : ${val.stauts == 1 ? t('COM_TV_SID_AVAILABLE_KR_MIS20') : t('COM_SID_NOT_AVAILABLE_KR_USE')}`}</div>)}
        </>
    );
};

const CabinetMonitoring = ({cabinet, selected, onClick}) => {
    return (
        <span className={classNames({'cabinet_monitoring': true, 'on': selected})} onClick={onClick}>
            {`${cabinet.cabinetGroupId}-${cabinet.cabinetId}`}
            {
                cabinet.warningLevel === 'ERROR' &&
                <span><img src={Status6} class="mr4" alt="Device Error" title="Device Error"/></span>
            }
        </span>
    );
};

const CabinetGroup = ({cabinetGroup, selectedIndex, onClick}) => {
    return (
        <>
        {
            cabinetGroup.cabinets.map((cabinet, i) => <CabinetMonitoring key={cabinet.cabinetId} cabinet={cabinet} selected={selectedIndex === i} onClick={() => onClick(i)} />)
        }
        </>
    );
};

const ImageView = ({deviceId, height, cabinetGroups, setSelected, refresh}) => {
    const {t} = useTranslation();
    const [subTab, setSubTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [rightCabinet, setRightCabinet] = useState({});

    const tabs = cabinetGroups.map(({groupId, cabinetGroupStatus, cabinets = []}, i) => {
        const name = t('COM_SID_SIGNAGE_MIX_CABINET_GROUP').replace('<<A>>', `${groupId} (${cabinets.length})`);
        return {
            id: i,
            name: (
                <>
                    {
                        toInteger(cabinetGroupStatus) > 0 ? <span className="error mr8"></span> : <span className="normal mr8"></span>
                    }
                    {name}
                </>
            ),
            tooltip: name,
        }
    });

    const onClickCabinet = i => {
        if(selectedIndex !== i) {
            const selectedGroup = cabinetGroups[subTab];
            const selected = selectedGroup.cabinets[selectedIndex];
            setSelectedIndex(i);
            setSelected && setSelected(selected);
        }
    };

    useEffect(() => {
        if(!cabinetGroups || cabinetGroups.length === 0) {
            return;
        }

        const selectedGroup = cabinetGroups[subTab];
        const selected = selectedGroup.cabinets[selectedIndex];
        
        setLoading(true);
        deviceService.fetchDeviceLedCabinet(deviceId, `${selectedGroup.groupId}-${selected.cabinetId}`).then(res => {
            setRightCabinet(res.items[0]);
            setSelected && setSelected(selected);
            setTimeout(() => {
                setLoading(false);
            }, 100);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    }, [subTab, selectedIndex, refresh]);

    useEffect(() => {
        setSelectedIndex(0);
    }, [subTab]);

    return (
        <>
            <div style={{padding: '0px 35px'}}>
                <SubTab multiLang={false} tabs={tabs} selected={subTab} onClick={tab => setSubTab(tab)} />
            </div>
            <div style={{height}}>
                <div className='cabinet_left_wrap'>
                {
                    cabinetGroups.map((group, i) => {
                        if(subTab === i) {
                            return <CabinetGroup key={group.groupId} cabinetGroup={group} selectedIndex={selectedIndex} onClick={onClickCabinet} />;
                        } else {
                            return null;
                        }
                    })
                }
                </div>
                <div className='cabinet_right_wrap'>
                    <DeviceFadeOutTransition transition={!loading} duration='.3s' classes={['device_loading_wrap']}>
                        <Loading/>
                    </DeviceFadeOutTransition>
                    <DeviceFadeInTransition transition={!loading} duration='.3s'>
                        <div className="cabinet_info_title">{t('COM_TEXT_GENERAL_P')}</div>
                        <div className='cabinet_info_table'>
                            <div>
                                <div className="cabinet_detail_label">{t('MIS_SID_SERVER_GROUP_ID')}</div>
                                <div className="cabinet_detail_value">{!isNil(rightCabinet.cabinetGroupId) ? `${rightCabinet.cabinetGroupId}-${rightCabinet.cabinetId}` : ''}</div>
                            </div>
                            <div>
                                <div className="cabinet_detail_label">{t('TABLE_FIRMWARE_VERSION_P')}</div>
                                <div className="cabinet_detail_value">{rightCabinet.fwVersion}</div>
                            </div>
                            <div>
                                <div className="cabinet_detail_label">{t('TABLE_RESOLUTION_P')}</div>
                                <div className="cabinet_detail_value">{(rightCabinet.resolution || '').replace(':', 'x')}</div>
                            </div>
                            <div>
                                <div className="cabinet_detail_label">{t('DID_LFD_ASPECT_RATIO')}</div>
                                <div className="cabinet_detail_value">{rightCabinet.aspectRatio}</div>
                            </div>
                            <div>
                                <div className="cabinet_detail_label">{t('MIS_SID_SERVER_MOBULES')}</div>
                                <div className="cabinet_detail_value">{(rightCabinet.modules || '').replace(':', 'x')}</div>
                            </div>
                            <div>
                                <div className="cabinet_detail_label">{t('MIS_SID_SERVER_PHYSICAL_SIZE')}</div>
                                <div className="cabinet_detail_value">{(rightCabinet.phySize || '').replace(':', 'x')} mm</div>
                            </div>
                            <div>
                                <div className="cabinet_detail_label">{t('MIS_SID_SERVER_PITCH')}</div>
                                <div className="cabinet_detail_value">{rightCabinet.pitch}</div>
                            </div>
                            <div>
                                <div className="cabinet_detail_label">{t('COM_LFD_SID_NON_ABL')}</div>
                                <div className="cabinet_detail_value">{!isNil(rightCabinet.abl) ? (rightCabinet.abl === 0 ? t('BUTTON_OFF') : t('TEXT_ON_P')) : ''}</div>
                            </div>
                            <div>
                                <div className="cabinet_detail_label" style={{height:60}}>{t('MIS_TEXT_LAST_CHECK_TIME_P')}</div>
                                <div className="cabinet_detail_value"><DeviceRelativeTimeCell value={rightCabinet.lastScannedTime} useBr={true} /></div>
                            </div>
                        </div>
                        <div className='horizontal_line'></div>
                        <div className="cabinet_info_title">{t('MIS_TEXT_DIAG_MANAGEMENT_P')}</div>
                        <div className='cabinet_info_table'>
                            <div>
                                <div className="cabinet_detail_label">{t('DID_LFD_TEMPERATURE')}</div>
                                <div className="cabinet_detail_value">
                                    <TemperatureCell value={rightCabinet.currentTemperature} />
                                </div>
                            </div>
                            <div>
                                <div className="cabinet_detail_label">{t('COM_LFD_SID_NON_IC')}</div>
                                <div className="cabinet_detail_value">
                                    <ICCell values={rightCabinet.ic} t={t} />
                                </div>
                            </div>
                            <div>
                                <div className="cabinet_detail_label">{t('TABLE_POWER_P')}</div>
                                <div className="cabinet_detail_value">
                                    <VoltageStatusCell values={rightCabinet.voltageStatus} />
                                </div>
                            </div>
                            <div>
                                <div className="cabinet_detail_label">{t('COM_SID_SIGNAGE_NON_LOD')}</div>
                                <div className="cabinet_detail_value">
                                    <ModuleCell values={(rightCabinet.ledCabinetModules || []).map(module => module.openDetection)} />
                                </div>
                            </div>
                            <div>
                                <div className="cabinet_detail_label">{t('MIS_SID_MODULE_PRODUCT_NUMBER')}</div>
                                <div className="cabinet_detail_value">
                                    <ModuleCell values={(rightCabinet.ledCabinetModules || []).map(module => module.productNumber)} />
                                </div>
                            </div>
                            <div>
                                <div className="cabinet_detail_label">{t('MIS_SID_DATE_OF_MODULE_PRODUCTION')}</div>
                                <div className="cabinet_detail_value">
                                    <ModuleCell values={(rightCabinet.ledCabinetModules || []).map(module => module.moduelDate)} />
                                </div>
                            </div>
                        </div>
                    </DeviceFadeInTransition>
                </div>
            </div>
        </>
    );
};

const defaultViewColumns = [
    {value: 'resolution', title: 'TABLE_RESOLUTION_P', checked: true},
    {value: 'phySize', title: 'MIS_SID_SERVER_PHYSICAL_SIZE', checked: true},
    {value: 'aspectRatio', title: 'TEXT_ASPECT_RATIO_P', checked: true},
    {value: 'modules', title: 'MIS_SID_SERVER_MOBULES', checked: true},
    {value: 'pitch', title: 'MIS_SID_SERVER_PITCH', checked: true},
    {value: 'currentTemperature', title: 'DID_LFD_TEMPERATURE', checked: true},
    {value: 'ic', title: 'COM_LFD_SID_NON_IC', checked: true},
    {value: 'voltageStatus', title: 'TABLE_POWER_P', checked: true},
    {value: 'ledCabinetModules1', title: 'COM_LFD_SID_NON_LED_OPEN_DETECTION', checked: true},
    {value: 'fwVersion', title: 'TABLE_FIRMWARE_VERSION_P', checked: true},
    {value: 'fpgaVersion', title: 'COM_LFD_SID_NON_FPGA', checked: true},
    {value: 'lastScannedTime', title: 'MIS_TEXT_LAST_CHECK_TIME_P', checked: true},
    {value: 'ledCabinetModules2', title: 'MIS_SID_MODULE_PRODUCT_NUMBER', checked: true},
    {value: 'ledCabinetModules3', title: 'MIS_SID_DATE_OF_MODULE_PRODUCTION', checked: true},
];

const LedCabinet = ({currContent}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const deviceId = useSelector(({device}) => device.ledCabinetDeviceId);
    const [view, setView] = useState('LIST');
    const [data, setData] = useState({
        device: {},
        items: [],
        groups: [],
        loading: false,
        totalCount: 0,
    });
    const [layoutOpener, setLayoutOpener] = useState({show: false});
    const windowObject = useRef();
    const [imageViewSelected, setImageViewSelected] = useState();
    const [refreshImageView, setRefreshImageView] = useState(false);
    const [height, setHeight] = useState(500);
    const {items = [], device = {}, groups = [], loading = false, totalCount = 0} = data;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);
    const [viewColumns, onChangeViewColumns] = useViewColumns('ledCabinetTableViewColumns', defaultViewColumns);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'device_tr_group');

    const fetchData = () => {
        setData({...data, loading: true});

        Promise.all([
            deviceService.fetchDeviceLedCabinets(deviceId),
            deviceService.fetchDeviceById(deviceId),
        ]).then(values => {
            let cabinets = [];
            values[0].items.every(item => cabinets = cabinets.concat(item.cabinets))
            setData({
                ...data, 
                groups: values[0].items, 
                loading: false, 
                items: cabinets, 
                device: values[1].items,
                totalCount: values[0].totalCount
            });
        }).catch(err => {
            err && err.errorMessage ? toastr.error(err.errorMessage) : toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
            setData({...data, loading: false});
        });
        // deviceService.fetchDeviceLedCabinets(deviceId).then(res => {
        //     let cabinets = [];
        //     res.items.every(item => cabinets = cabinets.concat(item.cabinets))
        //     setData({...data, groups: res.items, loading: false, items: cabinets, totalCount: res.totalCount});
        // });
    };

    const onChangeView = (viewType) => {
        if(view !== viewType) {
            setView(viewType);
            if(viewType === 'IMAGE') {
                // force to rerender for refreshing checkboxes
                setData({...data, items: [...data.items]});
            }
        }
    };

    const updateDimensions = () => {
        setHeight(window.innerHeight - 210);
    };

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [deviceId]);

    const columns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id='AllDevice_all'
                        classname="table"
                        name="check"
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={'AllDevice_'+row.index}
                        classname="table"
                        name="check"
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs} 
                    />
                );
            },
        },
        {
            Header: t('COM_MESSAGE_STATISTICS_LEFT_MENU_SUB_CONNECTION_P'),
            width: 80,
            accessor: 'power',
            sortable: false,
            Cell: ({value, original}) => <DevicePowerIcon power={value == 1} fault={original.fault} />
        },
        {
            Header: t('MIS_SID_SERVER_GROUP_ID'),
            sortable: false,
            width: 120,
            Cell: ({original}) => `${original.cabinetGroupId}-${original.cabinetId}`
        },
        {
            Header: t('TABLE_RESOLUTION_P'),
            accessor: 'resolution',
            sortable: false,
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'resolution').checked,
            Cell: ({value}) => `${(value || '').replace(':', 'x')}`
        },
        {
            Header: t('MIS_SID_SERVER_PHYSICAL_SIZE'),
            accessor: 'phySize',
            sortable: false,
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'phySize').checked,
            Cell: ({value}) => value ? `${(value || '').replace(':', 'x')} mm` : ''
        },
        {
            Header: t('TEXT_ASPECT_RATIO_P'),
            accessor: 'aspectRatio',
            width: 80,
            show: findWithDefault(viewColumns, col => col.value === 'aspectRatio').checked,
            sortable: false,
        },
        {
            Header: t('MIS_SID_SERVER_MOBULES'),
            accessor: 'modules',
            sortable: false,
            width: 80,
            show: findWithDefault(viewColumns, col => col.value === 'modules').checked,
            Cell: ({value}) => `${(value || '').replace(':', 'x')}`
        },
        {
            Header: t('MIS_SID_SERVER_PITCH'),
            accessor: 'pitch',
            width: 80,
            show: findWithDefault(viewColumns, col => col.value === 'pitch').checked,
            sortable: false,
        },
        {
            Header: t('DID_LFD_TEMPERATURE'),
            accessor: 'currentTemperature',
            sortable: false,
            width: 120,
            show: findWithDefault(viewColumns, col => col.value === 'currentTemperature').checked,
            Cell: ({value}) => <TemperatureCell value={value} />
        },
        {
            Header: t('COM_LFD_SID_NON_IC'),
            accessor: 'ic',
            sortable: false,
            width: 180,
            show: findWithDefault(viewColumns, col => col.value === 'ic').checked,
            Cell: ({value}) => <ICCell values={value} t={t} />
        },
        {
            Header: t('TABLE_POWER_P'),
            accessor: 'voltageStatus',
            sortable: false,
            width: 130,
            show: findWithDefault(viewColumns, col => col.value === 'voltageStatus').checked,
            Cell: ({value}) => <VoltageStatusCell values={value} />
        },
        {
            Header: t('COM_LFD_SID_NON_LED_OPEN_DETECTION'),
            sortable: false,
            accessor: 'ledCabinetModules1',
            width: 160,
            show: findWithDefault(viewColumns, col => col.value === 'ledCabinetModules1').checked,
            Cell: ({original}) => {
                if(original.ledCabinetModules && original.ledCabinetModules.length > 0){
                    const values = original.ledCabinetModules.map(m => m.openDetection);
                    return <ModuleCell values={values} />;
                } else {
                    return null;
                }
            }
        },
        {
            Header: t('TABLE_FIRMWARE_VERSION_P'),
            accessor: 'fwVersion',
            sortable: false,
            width: 160,
            show: findWithDefault(viewColumns, col => col.value === 'fwVersion').checked,
        },
        {
            Header: t('COM_LFD_SID_NON_FPGA'),
            accessor: 'fpgaVersion',
            sortable: false,
            width: 160,
            show: findWithDefault(viewColumns, col => col.value === 'fpgaVersion').checked,
        },
        {
            Header: t('MIS_TEXT_LAST_CHECK_TIME_P'),
            accessor: 'lastScannedTime',
            sortable: false,
            width: 180,
            show: findWithDefault(viewColumns, col => col.value === 'lastScannedTime').checked,
            Cell: ({value}) => <DeviceRelativeTimeCell value={value} />
        },
        {
            Header: t('MIS_SID_MODULE_PRODUCT_NUMBER'),
            sortable: false,
            accessor: 'ledCabinetModules2',
            width: 180,
            show: findWithDefault(viewColumns, col => col.value === 'ledCabinetModules2').checked,
            Cell: ({original}) => {
                if(original.ledCabinetModules && original.ledCabinetModules.length > 0){
                    const values = original.ledCabinetModules.map(m => m.productNumber);
                    return <ModuleCell values={values} />;
                } else {
                    return null;
                }
            }
        },
        {
            Header: t('MIS_SID_DATE_OF_MODULE_PRODUCTION'),
            sortable: false,
            accessor: 'ledCabinetModules3',
            minWidth: 160,
            show: findWithDefault(viewColumns, col => col.value === 'ledCabinetModules3').checked,
            Cell: ({original}) => {
                if(original.ledCabinetModules && original.ledCabinetModules.length > 0){
                    const values = original.ledCabinetModules.map(m => m.moduelDate);
                    return <ModuleCell values={values} />;
                } else {
                    return null;
                }
            }
        },
    ], [items, viewColumns]);

    if(isNil(deviceId)) {
        return null;
    }

    const getSelected = () => {
        return view === 'LIST' ? selected.current.filter(s => items[s].power == 1).map(s => `${items[s].cabinetGroupId}-${items[s].cabinetId}`) :
            imageViewSelected && imageViewSelected.power == 1 ? [`${imageViewSelected.cabinetGroupId}-${imageViewSelected.cabinetId}`] : [];
    };

    const onId = command => e => {
        // deviceService.fetchDeviceById(deviceId).then(res => {
        if(device.power) {
            // fe-a1-a3-03-bf-0e_1-2
            const targetDevices = getSelected();

            if(!targetDevices || targetDevices.length === 0) {
                toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
            } else {
                // sendSboxCommand(key, $btn.val(), targetDevices,'', false );
                deviceService.fetchDeviceConnectionCheck([deviceId]).then(res => {
                    deviceService.sendLedCabinetCommand(deviceId, {
                        cabinets: targetDevices,
                        command, //'SBOX_ID_SHOW',
                    }).then(res => {
                        toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                    }).catch(error => toastr.error(getErrorMessage(error)))
                }).catch(error => toastr.error(getErrorMessage(error)));
            }
        } else {
            toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
        }
        // }).catch(error => console.log(error));
    };

    const scanLedCabinet = () => {
        // deviceService.fetchDeviceById(deviceId).then(res => {
        if(device.power) {
            deviceService.fetchDeviceConnectionCheck([deviceId]).then(res => {
                // open popup
                dispatch(popupAction.addPopup({
                    id: commonConstants.EDIT_CONNECTION,
                    type: commonConstants.EDIT_CONNECTION,
                    device,
                    onScan: scanInfos => {
                        deviceService.scanLedCabinet(deviceId, scanInfos).then(res => {
                            dispatch(popupAction.closePopup(commonConstants.EDIT_CONNECTION));
                            toastr.success(t('MIS_SID_SACN_STARTED_TAK_WHILE_DEPENDING_CONNECTION'));
                        });
                    },
                    onClose: () => dispatch(popupAction.closePopup(commonConstants.EDIT_CONNECTION)),
                }));
                
            }).catch(error => toastr.error(getErrorMessage(error)));
        } else {
            toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
        }
    };

    const onEdit = () => {
        const targetDevices = getSelected();

        if(!targetDevices || targetDevices.length === 0) {
            toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
        } else {
            dispatch(popupAction.addPopup({
                id: commonConstants.EDIT_LED_CABINET,
                type: commonConstants.EDIT_LED_CABINET,
                device,
                cabinetIds: targetDevices,
                onClose: () => dispatch(popupAction.closePopup(commonConstants.EDIT_LED_CABINET)),
            }));
        }
    };

    const onEditLayout = () => {
        const targetDevices = getSelected();

        if(!targetDevices || targetDevices.length === 0) {
            toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
        } else {
    //         var url = context+'/device/getDevice.htm';
	// $.getJSON( url, { cmd: "SET_SBOX_LAYOUT", deviceId:selDeviceId} )
	// 	.done(function( json ) {
	// 		  if (json.status == 'success') {
	// 			var userId = json.result.userId;
	// 			var password = json.result.password;
	// 			var token = json.result.token;
	// 			var layoutAuthorPath = json.result.layoutAuthorPath;
	// 			var language = json.result.language;
	// 			var selGroupId = json.result.deviceGroupId;
				
	// 			var layoutauthorForm = $('#openLayoutAuthor');
	// 			$(layoutauthorForm).attr('action', layoutAuthorPath);
	// 			$(layoutauthorForm).attr('method', 'post');
				
	// 			window.open('', 'LayoutauthorWindow');
	// 		  }
	// 	 })
	// 	.fail(function( jqxhr, textStatus, error ) {
	// 	ajaxHandleError(jqxhr, textStatus, error);
    // });
            deviceService.fetchLedCabinetLayout(deviceId).then(res => {
                windowObject.current = window.open('', 'LayoutauthorWindow');
                setLayoutOpener(Object.assign({}, {show: true, deviceId}, res.items));

                setTimeout(() => {
                    setLayoutOpener({show: false});
                }, 2000);
            });
        }
    };

    const onEditWarningRule = () => {
        deviceService.fetchLedCabinetWarningRule(deviceId).then(res => {
            if(isEmpty(res.items)) {
                toastr.error(t('MIS_SID_20_NO_WARNING_RULE_IS_APPLIED'));
                return;
            }

            dispatch(popupAction.addPopup({
                id: commonConstants.APPLIED_WARNING_RULE,
                type: commonConstants.APPLIED_WARNING_RULE,
                warningRule: res.items,
                onClose: () => dispatch(popupAction.closePopup(commonConstants.APPLIED_WARNING_RULE)),
            }));
        }).catch(error => toastr.error(getErrorMessage(error)));
    };

    const onExport = () => {
        deviceService.exportLedCabinets(deviceId).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(error => toastr.error(getErrorMessage(error)));
    };

    const onRefresh = () => {
        if(view === 'IMAGE') {
            setRefreshImageView(!refreshImageView);
        } else {
            fetchData();
        }
    };

    return (
        <div style={{width: '100%', display: currContent === 'LED_CABINET' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"COM_BUTTON_EDIT"} name={selectedCnt > 1 ? t('MIS_SID_20_EDIT_MULTIPLE') : t('COM_BUTTON_EDIT')} disable={view === 'LIST' && selectedCnt <= 0} onClick={onEdit} />
                    <WhiteButton id={"COM_LFD_EDIT_CONNECTION"} name={t('COM_LFD_EDIT_CONNECTION')} onClick={scanLedCabinet} />
                    <WhiteButton id={"COM_MIS_SID_SHOW_ID"} name={t('COM_MIS_SID_SHOW_ID')} onClick={onId('SBOX_ID_SHOW')} disable={view === 'LIST' && selectedCnt <= 0} />
                    <WhiteButton id={"MIS_SID_HIDE_ID"} name={t('MIS_SID_HIDE_ID')} onClick={onId('SBOX_ID_HIDE')} disable={view === 'LIST' && selectedCnt <= 0} />
                    <WhiteButton id={"MIS_SID_20_EDIT_LAYOUT"} name={t('MIS_SID_20_EDIT_LAYOUT')} onClick={onEditLayout} disable={view === 'LIST' && selectedCnt <= 0} />
                    <WhiteButton id={"MIS_SID_20_WARNING_RULE"} name={t('MIS_SID_20_WARNING_RULE')} onClick={onEditWarningRule} />
                    <WhiteButton id={"MIS_SID_20_WARNING_RULE"} name={t('BUTTON_EXPORT_P')} onClick={onExport} style={{borderColor: '#5e5e5e'}} />
                </div>
                <div className="rightButton">
                    <SwitchListNImage view={view} onClick={onChangeView}/>
                    <CircleButton onClick={onRefresh}>
                        <span style={{
                            background: `url(${RefreshIcon}) no-repeat 5px 6px`,
                            display: 'inline-block',
                            height: 28,
                            width: 28,
                        }}></span>
                    </CircleButton>
                </div>
            </div>
            <div className='device_list_view_wrap mt10' style={{width: '100%'}}>
            {
                view === 'IMAGE' &&
                <ImageView deviceId={deviceId} height={height - 50} cabinetGroups={groups} setSelected={setImageViewSelected} refresh={refreshImageView} />
            }
            {
                view === 'LIST' &&
                <>
                    <DeviceListColumnTool selects={viewColumns} onChange={onChangeViewColumns} />
                    <MagicInfoTable
                        data={items}
                        loading={loading}
                        noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                        minRows={0}
                        multiSort={false}
                        getTrGroupProps={getTrGroupPropsType2}
                        columns= {columns}
                        className="-striped -highlight"
                        style={{height}}
                        showPagination={false}
                        manual
                    />
                </>
            }
            {
                layoutOpener.show &&
                <LedCabinetLayoutOpener layoutInfo={layoutOpener} />
            }
            </div>
        </div>
    );
};

export default LedCabinet;
