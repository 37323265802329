import React, {createRef} from 'react';
import {withTranslation} from "react-i18next";
import ReactDOM from "react-dom";
import Checkbox from "../checkbox/Checkbox";
import './MultiSelectButton.css';
import {isNil} from "lodash";

class MultiSelectButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selected : [],
            selectAll: true,
            height: '',
            currContent : this.props.currContent,
            resetContextMenus : this.props.resetContextMenus || [],
        };

        this.checkAll = createRef();
        this.checkBox = [];
    }

    initialValues = () => {
        const {data} = this.props;
        const selected = [undefined];

        if ( typeof data === 'undefined' || data.length === 0 ){
            return;
        }
        let cnt = 0;
        for ( let i = 0 ; i < data.length; i++){
            if(data[i].isChecked){
                cnt++;
                selected.push(data[i].id);
            }
        }
        if(this.props.itemAll){
            if( cnt !== data.length ){
                this.setState({selectAll: false, selected : selected});
            } else {
                this.setState({selected : selected});
            }
        } else {
            this.setState({selected : selected});
        }
    };

    reloadValues = () => {
        const {data} = this.props;
        const {selected} = this.props;
        const reloadSelected = [undefined];

        if ( typeof data === 'undefined' || data.length === 0 ){
            return;
        }

        let cnt = 0;
        if(selected.length === 1 && selected[0] && selected[0].toLowerCase() === 'on'){
            for ( let i = 0 ; i < data.length; i++){
                    cnt++;
                    reloadSelected.push(data[i].id);
                }
        } else {
            for ( let i = 0 ; i < data.length; i++){
                if(selected.includes(data[i].id)){
                    cnt++;
                    reloadSelected.push(data[i].id);
                }
            }
        }

        if(this.props.itemAll){
            if( cnt !== data.length ){
                this.setState({selectAll: false, selected : reloadSelected});
            } else {
                this.setState({selected : reloadSelected});
            }
        } else {
            this.setState({selected : reloadSelected});
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        const {selected} = this.props;

        if(selected){
            this.reloadValues();
        } else {
            this.initialValues();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!isNil(nextProps.currContent) && this.props.currContent !== nextProps.currContent) {

            this.setState({
                currContent: nextProps.currContent
            })
            this.setState({
                selectAll: this.isCheckAll(this.props.data),
                selected : this.props.data.map(el => el.isChecked ? el.id : undefined ).concat(undefined)
            });
        }
    }

    isCheckAll = (arr) => {
        if(typeof  arr === 'undefined' || arr.length === 0 ) return false;
        return arr.every(el => el.isChecked);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                show: false
            });
        }
    };

    toggleView = () => {
        const _show = !this.state.show;
        this.setState({
            show: _show
        })
    };


    toggleSelectAll = (e) => {
        const {data} = this.props;
        if(e.target.checked){
            for ( let i = 0 ; i < data.length; i++){
                this.checkBox[data[i].id].checked = true;
            }
            this.setState({
                selectAll: true,
                selected : this.props.data.map(el => el.id).concat(undefined)
            }, this.onChange);
        } else {
            for ( let i = 0 ; i < data.length; i++){
                this.checkBox[data[i].id].checked = false;
            }
            this.setState({
                selectAll: false,
                selected : [undefined]
            }, this.onChange);
        }
    };

    onSelect = (id) => {
        const {selected} = this.state;
        const {data} = this.props;

        if(this.checkBox[id].checked){
            if(selected.length === data.length){
                this.checkAll.current.checked = true;
                this.setState({selectAll: true});
            }
            this.setState({
                selected : selected.concat(id)
            }, this.onChange);
        } else {
            if(selected.length  === data.length + 1){
                this.checkAll.current.checked = false;
            }
            this.setState({
                selectAll: false,
                selected : selected.filter( i => i != id)
            }, this.onChange);
        }
    };

    onChange = () => {
        const {selected} = this.state;
        this.props.onChange(selected);
    };

    visibleSelectAll = () => {
        const {t} = this.props;
        return (
            <li key={'multiSelectKey'}>
                <Checkbox id={'multiSelect_all'}
                          name={t("TEXT_ALL_P")}
                          defaultChecked={this.state.selectAll}
                          onChange={this.toggleSelectAll}
                          ref={this.checkAll}
                          checked ={this.state.selectAll}
                />
            </li>
        );
    };


    isVisibleItem = (id) =>{
        const {selected} = this.state;
        return selected.some( el => el === id );
    }

    render() {
        const {t} = this.props;
        const {title, style, width, height} = this.props;
        const {data} = this.props;
        const timestamp = new Date().getTime();

        return (
            <div className={"multiSelect float_l mr8"} style={{marginRight: 10, width: width? width : 'auto',}}>
                <button className="base select multiSelect_button" id="multiSelectButton" style={style} onClick={this.toggleView}>
                    <span>{title}<span className="arrow"></span></span>
                </button>
                <div className="option_list" id="multiSelectWrap" style={{'display': this.state.show ? '' : 'none'}}>
                    <div>
                        <span></span>
                    </div>
                    <ul style={{ height: height ? height : 'auto', overflow: 'hidden auto', padding: '10px 0px',}}>
                        {
                            this.props.itemAll && this.visibleSelectAll()
                        }
                        {
                            data.length > 0 &&
                                data.map((el, i) => {
                                    const isChecked = this.isVisibleItem(el.id);
                                    return (<li key={i} style={{display: 'flex'}}>
                                        <Checkbox id={"multiSelect_" +  el.id + "_"+timestamp }
                                                  name={el.name}
                                                  onChange={()=>this.onSelect(el.id)}
                                                  ref={ref => this.checkBox[el.id] = ref}
                                                  defaultChecked={el.isChecked}
                                                  checked={isChecked}
                                        />
                                    </li>
                                )})
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

MultiSelectButton.defaultProps = {
    itemAll: true
}

export default withTranslation()(MultiSelectButton);