import React, {Component} from "react";
import './DashboardPanel.css'
import './ScheduleSummary.css'
import {SortableHandle} from "react-sortable-hoc";
import {connect} from "react-redux";
import {menuAction} from "../../actions";
import {withTranslation} from "react-i18next";
import {dashboardService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import {MoonLoader} from "react-spinners";
import {getErrorMessage} from "../../helper/responseHandler";

class ScheduleSummary extends Component {

    state = {
        schedule: {},
        loading: true,
    };

    constructor(props) {
        super(props);
        this.onAllScheduleClickEvent = this.onAllScheduleClickEvent.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
    }

    componentDidMount() {
        this.fetchGetScheduleInfo();
    }

    onAllScheduleClickEvent() {
        const {removeTab, moveTab, t} = this.props;
        removeTab('SCHEDULE');
        moveTab({id: 'SCHEDULE', title : t("TEXT_TITLE_SCHEDULE_P"), active: true, close: true,});
    }

    fetchGetScheduleInfo = () => {
        this.setState({loading: true,});
        dashboardService.fetchScheduleInfo().then(res => {
            this.setState({
                schedule: res.items,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally(() => {this.setState({loading: false});});
    };

    render() {
        let {t} = this.props;
        let {schedule, loading} = this.state;
        return (
            <div className='dashboard-widget'>
                {this.renderHeader()}
                <div className='dashboard-widget-body'>
                    {loading && <MoonLoader css={`position: absolute;top: calc(50% - 40px);left: calc(50% - 40px);`} color="#6e78c8"/>}
                    {!loading &&
                    <div className='schedule-summary'>
                        <div className='schedule-summary-all' onClick={this.onAllScheduleClickEvent}>
                            {schedule.allListCnt}<span>{t("COM_TEXT_SCHEDULE_ALL_P")}</span>
                        </div>
                        <div className='schedule-summary-running'>
                            {schedule.runningListCnt}<span>{t("TEXT_SCHEDULE_CURRENT_RUNNING_P")}</span>
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    };

    renderHeader() {
        let {id, isEditMode, t} = this.props;
        const DragHandle = SortableHandle(() =>
            <div className='title'>{t("TEXT_AD_SCHEDULE_SUMMARY_VIEW_P")}</div>
        );
        return (
            <div className='dashboard-widget-header'>
                <DragHandle />
                {
                    isEditMode && <div title="Close" className="remove" onClick={() => this.props.onRemoveLayoutClickEvent(id)}></div>
                }
            </div>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        removeTab: (id) => dispatch(menuAction.removeTab(id)),
        moveTab: (tab, id, childrenId) => dispatch(menuAction.moveTab(tab, id, childrenId)),
    })
)(withTranslation()(ScheduleSummary));
