import React, {useEffect, useState} from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import NumberInput from "../../../input/NumberInput";
import {commonConstants, defaultSlot} from "../../../../constants";
import TextInput from "../../../input/TextInput";
import {toastr} from 'helper/toastrIntercept';
import {popupAction, scheduleAction} from "../../../../actions";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {isEmpty, isNil} from 'lodash'
import './SlotSettingPopup.css';

const Slot = SortableElement(({slot, deleteConfirmPopup, updateSlotName}) =>
    <li style={{zIndex:999999}}>
        <TextInput width={200} value={slot.slotName} maxLength={15} onChange={(e)=>updateSlotName(slot.slotId, e)} wrapperStyle={{display: 'block', width: 300}}/>
        <button className={'delete'} onClick={(e)=>deleteConfirmPopup(e, slot.slotId)}></button>
    </li>
);

const SortSlots = SortableContainer(({slots, deleteConfirmPopup, updateSlotName})=>{
    return (
        <ul className={'schedule_slots_setting'}>
            {
                slots.map(
                    (slot, index) => <Slot key={slot.slotId} index={index} slot={slot} deleteConfirmPopup={deleteConfirmPopup} updateSlotName={updateSlotName} sortIndex={index} helperClass='sortableHelper' />
                )
            }
        </ul>
    )
})


const SlotSettingPopup = ({mode, closePopup}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {adProgram} = useSelector(state => ({
        adProgram: state.schedules.contents.advertisement[mode]
    }))
    const [program, setProgram] = useState({})
    const updateSlots = () => {
        const {adSlots} = program.channels[0].frame;

        const isValid = () => {
            return adSlots.every(
                slot => !isEmpty(slot.slotName) && !isNil(slot.slotName)
            )
        }
        if (isValid()) {
            new Promise(()=>
                dispatch(scheduleAction.updateContentADSchedule(mode, adSlots, program.slotCount, program.slotDuration))
            ).then(
                closePopup()
            )
        } else {
            toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
        }
    }
    const updateSlotName = (slotId, e) => {
        const value = e.target.value;
        const {channels} = program;
        const {adSlots} = channels[0].frame;
        const updateSlots = adSlots.map(
            slot => slot.slotId === slotId ? {...slot, slotName: value} : slot
        )
        setProgram({
            ...program,
            channels: channels.map(channel=> ({...channel, frame: {...channel.frame, adSlots: updateSlots}}))
        })
    }

    const updateSlotDuration = (e, value) => {
        setProgram({
            ...program,
            slotDuration: value
        })
    }

    const addSlot = () => {
        const {channels} = program;
        const {adSlots} = channels[0].frame;
        if (adSlots.length + 1 > 12) {
            toastr.warning(t("COM_MIV_CAN_NOT_ADD_ANY_MORE"));
        } else {
            setProgram({
                ...program,
                channels: channels.map(channel=> ({...channel, frame: {...channel.frame, adSlots: adSlots.concat(defaultSlot(program.slotDuration, program.programId, channel.frame.frameId, adSlots.length+1))}})),
                slotCount: program.slotCount+1
            })
        }
    }

    const deleteConfirmPopup = (e, slotId) => {
        e.stopPropagation();
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("TEXT_SCHEDULE_DELETE_P"),
            message: t("MIS_SID_20_DELETE_THE_SLOT_ALL_ITEMS_IN_THIS_SLOT_WILL_BE_DELETED"),
            onClickYes: ()=>deleteSlot(slotId),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    }

    const deleteSlot = (slotId) => {
        const {channels} = program;
        const {adSlots} = channels[0].frame;
        if (adSlots.length === 1) {
            toastr.error(t("COM_MIV_CAN_NOT_DELETE_ANY_MORE"))
        } else {
            setProgram({
                ...program,
                channels: channels.map(channel=> ({...channel, frame: {...channel.frame, adSlots: adSlots.filter(slot=>slot.slotId !== slotId)}})),
                slotCount: program.slotCount-1
            })
        }
        dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        const {channels} = program;
        const {adSlots} = channels[0].frame;
        const updateSlots = arrayMove(adSlots, oldIndex, newIndex)
        setProgram({
            ...program,
            channels: channels.map(channel=> ({...channel, frame: {...channel.frame, adSlots: updateSlots}}))
        })
    }

    useEffect(() => {
        setProgram({
            ...adProgram
        })
    }, [adProgram])
    const dialogProps ={title: t("MIS_SID_20_SLOT_SETTINGS"), closeOnEscape : true, position: {x: -300, y: -400} , modal:true, onClose:()=> closePopup()};
    return (
        <MISDialog
            dialog={dialogProps}
            width={550}
            height={380}
            buttons={{
                rightButtons: [
                    {id: 'SAVE_SCHEDULE', title: t("COM_BUTTON_SAVE"), onClick: ()=>updateSlots()},
                    {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: ()=> closePopup()}
                ]
            }}
        >
            <div className={'slot_setting_popup'}>
                <div className="detail_view mini_p mt18 mb15">
                    <table>
                        <colgroup>
                            <col width="100px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>{t("MIS_SID_20_SLOT_DURATION")}</th>
                            <td>
                                <NumberInput min={5} max={3600} value={program.slotDuration} onChange={updateSlotDuration} onKeyChange/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("MIS_SID_20_SLOTS")}</th>
                            <td>
                                <button className="base" style={{width:'auto',paddingLeft:'10px',paddingRight:'10px'}} onClick={addSlot}>
                                    {t("MIS_SID_20_ADD_SLOT")}
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td><span className="txt">{t("MIS_SID_20_CHANGE_ORDER_OF_SLOTS_BY_DRAGGING_AND_DROPPING_THEM")}</span>
                                <div className="pop_list content_upload mt9" style={{height:180,width:'90%',overflowY:'auto'}}>
                                    {
                                        program.channels !== undefined && program.channels[0].frame !== undefined && program.channels[0].frame.adSlots !== undefined &&
                                        <SortSlots slots={program.channels[0].frame.adSlots} deleteConfirmPopup={deleteConfirmPopup} updateSlotName={updateSlotName} onSortEnd={onSortEnd} helperClass='sortableHelper' />
                                    }
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    )
}

export default SlotSettingPopup;