import React, {useEffect, useState} from 'react';

import './Insight.css';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import {insightService} from '../../services/insight.service';

import {toastr} from 'helper/toastrIntercept';
import {history} from "../../helper";

const Insight = (props) => {
    const {t} = useTranslation();

    const [url, setUrl] = useState([]);
    const width = '100%';
    const height = '100%';
    const frameboarder = 0;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        insightService.getInsightServerInformation()
        .then(data => {
            const header = new Headers({
                'Access-Control-Allow-Origin':'*'
            });
            const option = {
                method: 'GET',
                async: false,
                header: header,
            };
            const {userId, hash, time, lifetime, insightUrl, lang} = data.items;
            // let url = `${insightUrl}?userId=${userId}&hash=${hash}&time=${time}&lifetime=${lifetime}&lang=${lang}`;
            // let url = `${insightUrl}/HttpsEntry`;
            let url = `${insightUrl}/httpsEntry_${lang}.html`;


            if(insightUrl.indexOf("https") >= 0) {
                fetch(url, option)
                    .then((response) => {
                        if (response.status === 200) {
                            setUrl(
                                `${insightUrl}?userId=${userId}&hash=${hash}&time=${time}&lifetime=${lifetime}&lang=${lang}`
                            );
                        }
                    })
                    .catch((error) => {
                        console.log('network error: ' + error);
                        history.go('/');
                        window.open(url, "_blank");
                    })
            } else {
                setUrl(
                    `${insightUrl}?userId=${userId}&hash=${hash}&time=${time}&lifetime=${lifetime}&lang=${lang}`
                );
            }
        })
        .catch(error => {
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
        })
    }

    return (
        <div className="insightWrap" style={{display: props.currContent === 'INSIGHT' ? 'block':'none'}}>
            <iframe title="INSIGHT" src={url} frameBorder={frameboarder} width={width} height={height} ></iframe>
        </div>
    )
}

export default connect(
    state => ({

    }),
    dispatch => ({
    })
)(Insight);