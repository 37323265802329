import React, {useState} from 'react';
import MISDialog from "../MISDialog";
import {useTranslation} from "react-i18next";
import './ResetPopup.css';
import {history} from "../../../helper";
import {userService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from "../../../helper/responseHandler";
import {userActions} from "../../../actions";
import {saveToken} from "../../../helper/fetchIntercept";
import {useDispatch} from "react-redux";
import Checkbox from "../../checkbox/Checkbox";
import "./OtpSetupPopup.css";
import {useCallback} from "react";

import {
    osName, osVersion, browserName, browserVersion
} from "react-device-detect";
import {isNil} from "lodash";
import {hotp} from "otplib";
import {
    getOtpLoginErrorMsg,
    getStoredDevicePeriodInfo,
    isEnableAuthStoredDevice
} from "../../../containers/common/MfaUtil";

const OtpLoginPopup = ({mfa, resetPopup, closePopup}, props) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    let storedDevicePeriodInfo = {};
    let storedDevicePeriod = 30;


    const dialogProps = {
        title: t("MIS_SID_CAJUL_TWO_STEP_VERIFI_OTP"),
        closeOnEscape: true,
        modal: true,
        hasCloseIcon: false
    };

    const [otp, setOtp] = useState('');
    const [deviceRemember, setDeviceRemember] = useState(false);
    const [error1, setError1] = useState('');
    const [error2, setError2] = useState('');

    const isEnableStoredDevice = isEnableAuthStoredDevice();

    const getStoredDeviceMessage = useCallback(()=> {

        if (isEnableStoredDevice) {
            storedDevicePeriodInfo = getStoredDevicePeriodInfo();

            if (!isNil(storedDevicePeriodInfo) && !isNil(storedDevicePeriodInfo.usePeriod) && !storedDevicePeriodInfo.usePeriod) {

                return t('MIS_SID_CASEP_NOT_ASK_AGAIN_THIS_DEVICE');

            }

            if (!isNil(storedDevicePeriodInfo.period)) {
                storedDevicePeriod = storedDevicePeriodInfo.period;
            }

        }

        return t('MIS_SID_CASEP_NOT_ASK_AGAIN_THIS_DEVICE') + ' ' + t('MIS_SID_MIX_CASEP_VALID_NUMBER_DAYS') .replace('<<A>>', storedDevicePeriod + "");
    }, [isEnableStoredDevice]);

    const initErrorMsg = () => {
        setError1('');
        setError2('');

    }

    const registerTrustedDevice = (e, authData) => {

        const data = e.errorDetails.otpAuthInfo;

        const device = {
            userId: mfa.userId,
            id: data.deviceId,
            key: data.deviceKey
        };

        const otp = hotp.generate(device.key, device.id);
        delete authData.totp;
        delete authData.userAuthDevice;
        authData.hotp = otp;

        userService.setupMfaAuth(authData)
            .then(res => {

                localStorage.setItem('device_' + mfa.userId, JSON.stringify(device));

                closePopup();
                saveToken(res);
                dispatch(userActions.success(res));
                history.push('/');

            }).catch(e => {
                if (e.errorCode == 404001) {
                    toastr.error(t("MIS_SID_SERVER_THE_AUTHENTICATION_CODE_IS_INCORRECT_PLEASE_TRY_AGAIN"), true);
                    return;
                }
                toastr.error(getErrorMessage(e), true);
            }
        )
    }

    const okOtpLoginPopup = () => {

        initErrorMsg();

        let userAuthDevice = {};
        if (deviceRemember) {
            userAuthDevice = {
                authEnable: deviceRemember,
                osName: osName,
                osVersion: osVersion,
                browserName: browserName,
                browserVersion: browserVersion
            };
        }

        const authData = {
            username: mfa.userId,
            password: mfa.password,
            totp: otp,
            userAuthDevice: userAuthDevice,
        };

        userService.setupMfaAuth(authData)
            .then(res => {

                closePopup();
                saveToken(res);
                dispatch(userActions.success(res));
                history.push('/');

            }).catch( e => {

                if (e.errorCode == "401011") {

                    registerTrustedDevice(e, authData);
                    return;
                }

                const errorMsg = getOtpLoginErrorMsg(e);
                setError1(errorMsg.error1);
                setError2(errorMsg.error2);

            }
        )
    }
    const closeOtpLoginPopup = () => {
        closePopup();
    }

    const resetOtpPopup = () => {
        closePopup();
        resetPopup();
    }

    const onChangeOtp = e => {
        setOtp(e.target.value);
    };

    const handleRemember = e => {

        const checked = e.target.checked;

        if ("deviceRemember" === e.target.id) {

            setDeviceRemember(checked);

        }

    }

    return (
        <>

            {
                <MISDialog
                    dialog={dialogProps}
                    width={464}
                    height={191}
                    buttons={{
                        leftButtons: [
                            {
                                id: 'RESET_OTP_POPUP',
                                title: t("MIS_SID_CAJUL_RESET_OTP"),
                                onClick: () => resetOtpPopup()
                            },
                        ],
                        rightButtons: [
                            {id: 'OK_POPUP', title: t("BUTTON_OK_P"), onClick: () => okOtpLoginPopup()},
                            {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: () => closeOtpLoginPopup()}

                        ]
                    }}
                >
                    <div>
                            <div className="info_txt_otp" style={{display: mfa.isRemoveTrustedDevice ? '':'none'}}>
                                {t('MIS_SID_CASEP_INFO_TRUSTED_DEVICE_NOT_EXIT')}
                            </div>
                            <div className="info_txt_otp">
                                    {t('MIS_SID_CAJUL_ENTER_OPT_CODE_AFTER_CHECK_APP_ON_UR_MOBILE')}
                            </div>
                            <div className="info_txt_otp">
                                    <span style={{marginRight:20}}>{t('MIS_SID_CAJUL_OTP_CODE')}</span>

                                    <input
                                        value={otp}
                                        type="text"
                                        data-name="otp"
                                        maxLength="6"
                                        placeholder={t('MIS_SID_CAJUL_ENTER_OTP_CODE')}
                                        style={{width: '197px'}} onChange={onChangeOtp}
                                    />
                            </div>
                            <div style={{height:55}}>
                                    <span className="error_txt_otp">{error1}</span><br/>
                                    <span className="error_txt_otp">{error2}</span>
                            </div>
                            <div style={{display: isEnableStoredDevice ? '':'none'}}>

                                        <Checkbox id="deviceRemember"
                                                  name={getStoredDeviceMessage()}
                                                  onChange={(e) => handleRemember(e)} checked={deviceRemember}/>



                            </div>

                        </div>

                </MISDialog>
            }
        </>
    )
}
export default OtpLoginPopup;
