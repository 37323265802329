import React, { useState, useEffect, useRef } from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {commonConstants} from "../../../constants";
import {popupAction} from "../../../actions";
import MISDialog from '../MISDialog';
import './UserPopup.css';
import classNames from "classnames";

const RemoveUserPopup = ({onClickYes, onClose}) => {
    const {t} = useTranslation();

    const [filter, setFilter] = useState({
        reasonText: ""
    });

    const onClickSave = () => {
        onClickYes(filter.reasonText);
        onClose();
    };

    const onTextAreaChange = reasonText => {
        setFilter({...filter, reasonText});
    };

    return (
        <MISDialog
            classname={'removeUser'}
            dialog={{
                title: t('COM_BUTTON_DELETE'),
                closeOnEscape: true,
                modal: true,
                width: 410,
                height: 150,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'DEVICE_SAVE',
                        title: t('BUTTON_OK_P'),
                        onClick: onClickSave,
                        disable: false,
                    },
                    {
                        id: 'DEVICE_CANCEL',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>

            <div>
                <div className="notify_wrap ft13">{t('TEXT_WITHDRAWAL_REASON_P')}</div>
                <div>
                    <textarea style={{width: '383px', radius: '3px', height: '82px'}}
                              className={classNames('mt20')}
                              placeholder={t('TEXT_WITHDRAWAL_REASON_P')}
                              onChange={(e) => onTextAreaChange(e.target.value)}
                              maxLength={2000}>
                    </textarea>
                </div>
            </div>

        </MISDialog>
    );
};

RemoveUserPopup.defaultProps = {
    onSave: () => {},
};

export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id))
    })
)(RemoveUserPopup);
