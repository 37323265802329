import React from 'react';
import {toInteger} from 'lodash';
import Size from "../utils/Size";
import {connect} from "react-redux";
import {deviceAction, menuAction, popupAction} from "../../actions";
import {useTranslation, withTranslation} from "react-i18next";
import {APLAYER, commonConstants, FLIP, IPLAYER, RSPLAYER, SIGNAGE, WPLAYER, SIGNAGE_CHILD} from "../../constants";
import classNames from 'classnames';
import DeviceIcons from './DeviceIcons';
import PowerThumb from '../../images/img/thumb_img_power.png';
import DeviceAvOnImg from '../../images/device/device_av_on.png';
import DeviceBncOnImg from '../../images/device/device_bnc_on.png';
import DeviceComponentOnImg from '../../images/device/device_component_on.png';
import DeviceDisplayPortOnImg from '../../images/device/device_display_port_on.png';
import DeviceDviOnImg from '../../images/device/device_dvi_on.png';
import DeviceFlipOnImg from '../../images/device/device_flip_on.png';
import DeviceHdmi1OnImg from '../../images/device/device_hdmi1_on.png';
import DeviceHdmi2OnImg from '../../images/device/device_hdmi2_on.png';
import DevicePcOnImg from '../../images/device/device_pc_on.png';
import DeviceSVideoOnImg from '../../images/device/device_s_video_on.png';
import DeviceTvOnImg from '../../images/device/device_tv_on.png';
import DeviceDTvOnImg from '../../images/device/device_dtv_on.png';
import DeviceSamsungWorkspaceImg from '../../images/device/device_samsungworkspace.png';
import DeviceNetworkStandbyImg from '../../images/device/device_network_standby.png';
import DeviceOnImg from '../../images/device/device_on.png';
import PanelOffImg from '../../images/device/panel_off.png';
import {deviceService} from '../../services';
import './Device.css';

const ratio = 0.5625;

const getDeviceImage = (url, defaultImg) => {
    let image = defaultImg ? defaultImg : DeviceOnImg;

    if(url && url.startsWith('/MagicInfo/restapi')) {
        return deviceService.fetchDeviceImage(url).then(res => {
            if(res.items)
                return res.items.src;
            else
                return '';
        });
    }

    return new Promise((resolve, reject) => resolve(image));
};

// input source 가 magicinfo 가 아니면, 해당 input source 이미지
export const getImageUrls = (device) => {
    let captureUrl = PowerThumb, thumbUrl = PowerThumb;

    if(device.power) {
        if(device.deviceType === FLIP) {
            thumbUrl = DeviceFlipOnImg;
            captureUrl = DeviceFlipOnImg;
        } else if(device.panelStatus === false) {
            thumbUrl = PanelOffImg;
            captureUrl = PanelOffImg;
        } else {
            switch(toInteger(device.basicSource)) {
                case 4 :
                    return getDeviceImage(device.captureUrl, DeviceSVideoOnImg).then(res => [DeviceSVideoOnImg, res]);
                case 8 :
                    return getDeviceImage(device.captureUrl, DeviceComponentOnImg).then(res => [DeviceComponentOnImg, res]);
                case 12 :
                    return getDeviceImage(device.captureUrl, DeviceAvOnImg).then(res => [DeviceAvOnImg, res]);
                case 20 :
                    return getDeviceImage(device.captureUrl, DevicePcOnImg).then(res => [DevicePcOnImg, res]);
                case 24 :
                case 31 :
                    return getDeviceImage(device.captureUrl, DeviceDviOnImg).then(res => [DeviceDviOnImg, res]);
                case 30 :
                    return getDeviceImage(device.captureUrl, DeviceBncOnImg).then(res => [DeviceBncOnImg, res]);
                case 33 :
                case 34 :
                    return getDeviceImage(device.captureUrl, DeviceHdmi1OnImg).then(res => [DeviceHdmi1OnImg, res]);
                case 35 :
                case 36 :
                    return getDeviceImage(device.captureUrl, DeviceHdmi2OnImg).then(res => [DeviceHdmi2OnImg, res]);
                case 37 :
                    return getDeviceImage(device.captureUrl, DeviceDisplayPortOnImg).then(res => [DeviceDisplayPortOnImg, res]);
                case 48 :
                    return getDeviceImage(device.captureUrl, DeviceTvOnImg).then(res => [DeviceTvOnImg, res]);
                case 64 :
                    return getDeviceImage(device.captureUrl, DeviceDTvOnImg).then(res => [DeviceDTvOnImg, res]);
                case 102 :
                    return getDeviceImage(device.captureUrl, DeviceSamsungWorkspaceImg).then(res => [DeviceSamsungWorkspaceImg, res]);
                case 1000:
                    return getDeviceImage(device.captureUrl, DeviceNetworkStandbyImg).then(res => [DeviceNetworkStandbyImg, res]);
                default:
                    return new Promise(resolve => {
                        let ret = [];
                        getDeviceImage(device.thumbUrl).then(res => {
                            ret.push(res);
                        }).catch(() => {
                            ret.push(thumbUrl);
                        }).finally(() => {
                            getDeviceImage(device.captureUrl).then(res => {
                                ret.push(res);
                            }).catch(() => {
                                ret.push(captureUrl);
                            }).finally(() => {
                                resolve(ret);
                            });
                        })
                    });
            }
        }
    }

    return new Promise((resolve, reject) => resolve([thumbUrl, captureUrl]));
};

const TagInfo = ({show, device}) => {
    const {t} = useTranslation();
    let tagTitle = '';
    const display = show && ((device.mediaTagValueList && device.mediaTagValueList.length > 0) || (device.varTagValueList && device.varTagValueList.length > 0));

    if(device.mediaTagValueList && device.mediaTagValueList.length > 0) {
        tagTitle += `*${t('MIS_SID_MEDIA_TAG')}*\n`;
        device.mediaTagValueList.forEach(tag => {
            tagTitle += tag.tagName + '\n';
        });
    }

    if(device.varTagValueList && device.varTagValueList.length > 0) {
        tagTitle += `*${t('MIS_SID_VARIABLE_TAG')}*\n`;
        device.varTagValueList.forEach(tag => {
            tagTitle += tag.tagName += '\n';
        });
    }

    return (
        <div className="device_tag_info add" style={{textAlign:'left',lineHeight:'20px', display: display ? 'block' : 'none'}} title={tagTitle}>
            <div>
                {
                    device.mediaTagValueList && device.mediaTagValueList.length > 0 &&
                    <span>
                        <i>MED</i>
                        {
                            device.mediaTagValueList.map((tag, i) => (i === device.mediaTagValueList.length - 1) ? tag.tagName : tag.tagName + ',')
                        }
                    </span>
                }
                <br/>
                {
                    device.varTagValueList && device.varTagValueList.length > 0 &&
                    <span>
                        <i>VAR</i>
                        {
                            device.varTagValueList.map((tag, i) => (i === device.varTagValueList.length - 1) ? tag.tagName : tag.tagName + ',')
                        }
                    </span>
                }
            </div>
        </div>
    );
};

const getDeviceVersion = device => {
    switch(device.deviceType) {
        case IPLAYER:
        case APLAYER:
            return (device.applicationVersion || '').split(';')[0];
        case WPLAYER:
            return (device.playerVersion || '');
        case FLIP:
        case SIGNAGE:
        case RSPLAYER:
        default:
            return (device.firmwareVersion || '').split(';')[0];
    }
};

class Device extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mouseOver: false,
            thumbUrl: PowerThumb, 
            captureUrl: PowerThumb,
        }
    }

    componentDidMount() {
        getImageUrls(this.props.device).then(res => {
            this.setState({thumbUrl: res[0], captureUrl: res[1]});
        });
    }

    componentDidUpdate(prevProps) {
        const {device} = this.props;
        if(prevProps.device.thumbUrl !== device.thumbUrl || prevProps.device.captureUrl !== device.captureUrl) {
            getImageUrls(this.props.device).then(res => {
                this.setState({thumbUrl: res[0], captureUrl: res[1]});
            });
        }
    }

    openPopup = (e, deviceId) => {
        e.stopPropagation();
        const {openDetailView, hasDeviceSecurity} = this.props;
        openDetailView({type: commonConstants.COMMON_DETAIL_VIEW, viewType: 'DEVICE', id: deviceId, hasDeviceSecurity});
    }

    onMouseOver = () => {
        this.setState({mouseOver: true});
    }

    onMouseOut = () => {
        this.setState({mouseOver: false});
    }

    onClickLedCabinets = () => {
        const {device, addTab, loadContent, loadLedCabinet, t} = this.props;
        addTab({
            id: 'LED_CABINET',
            title: t('TEXT_TITLE_DEVICE_P'),
            tabTitle: device.deviceName,
            active: true,
            close: true,
        });
        loadContent('LED_CABINET', true);
        loadLedCabinet(device.deviceId);
    };

    renderCapture = thumb => {
        const {device} = this.props;

        if(device.landscape !== 'landscape') {
            return (
                <div className="dev_img_capture" style={{
                    background: `url('${thumb}') no-repeat`,
                    backgroundPosition:'center',
                    backgroundSize:'contain',
                    display:'inline-block',
                    MsTransform: 'rotate(90deg)',
                    WebkitTransform: 'rotate(90deg)',
                    transform: 'rotate(90deg)'
                }}></div>
            );
        } else {
            return (
                <div className="dev_img_capture" style={{
                    // background: `url('${thumb}') no-repeat center / contain`,
                    backgroundImage: `url('${thumb}')`,
                    backgroundRepeat: 'none',
                    backgroundPosition:'center',
                    backgroundSize:'contain',
                    display:'inline-block'
                }}></div>
            );
        }
    }
    
    renderThumb = thumb => {
        const {device} = this.props;

        return (
            <div className="dev_img_thumb" style={{
                // background: `url('${thumb}') no-repeat center / contain`,
                backgroundImage: `url('${thumb}')`,
                backgroundRepeat: 'none',
                backgroundPosition:'center',
                backgroundSize:'contain',
                display:'inline-block'
            }}></div>
        );
    }

    render() {
        const {device, showScreenCapture, showInformation, showTagInformation, onClick, index, width, t, onClickMemo, selected} = this.props;
        const {mouseOver, thumbUrl, captureUrl} = this.state;
        const height = width * ratio;

        if(device.deviceType === SIGNAGE_CHILD) {
            return null;
        }

        return (
            <li className="thumbnail_view_wrap" style={{width}}>
                <div ref={this.props.setRef} className={classNames("thumbview_box", "device_thumb", "landscape", {'on' : selected.includes(index)})}
                    style={{width,height}} onClick={()=>onClick(index)} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
                    <div className="dev_thumb_img_wrapper">
                        {
                            showScreenCapture ? this.renderCapture(captureUrl) : this.renderThumb(thumbUrl)
                        }
                    </div>
                    <div className="device_info add" style={{height, display: (mouseOver || showInformation) && width >= 150 ? 'block' : 'none'}}>
                        <div>
                            <span><i>MAC</i>{device.deviceId}</span>
                            <br />
                            <span><i>IP</i>{device.ipAddress}</span>
                            <br />
                            <span><i>HDD</i><Size size={device.diskSpaceRepository} /></span>
                            <br />
                            <span style={{whiteSpace:'nowrap',overflow:'hidden',maxWidth:150}} title={getDeviceVersion(device)}>
                                <i>VER</i>
                                {getDeviceVersion(device)}
                            </span>
                        </div>
                    </div>
                    <TagInfo show={showTagInformation && width >= 150} device={device} />
                    <div className="check"><span></span></div>
                    <div className="ov" style={{display: width < 150 && mouseOver ? 'block' : 'none'}}>
                        <div className="overlay_info_box">
                            <span className='link_cell' onClick={e => this.openPopup(e, device.deviceId)}>{device.deviceName}</span>
                            <span className="float_r">
                                <DeviceIcons device={device} />
                            </span>
                        </div>
                        <div className="info">
                            <p>{t('DID_ADMIN_LICENSE_MAC')} <span>{device.deviceId}</span></p>
                            <p>{t('TABLE_IP_ADDR_P')} <span>{device.ipAddress}</span></p>
                            <p>{t('COM_TABLE_AVAILABLE_MEMORY_P')} <span><Size size={device.diskSpaceRepository} /></span></p>
                            <p>{t('TABLE_FIRMWARE_VERSION_P')} <span>{getDeviceVersion(device)}</span></p>
                        </div>
                    </div>
                </div>
                {
                    width >= 150 &&
                    <div>
                        <div className="status_wrap clearfix" style={{
                            position:'relative', 
                            background: device.power ? '#98c1eb' : '#afb3b8',
                        }}>
                            <span className="dev_name"
                                  style={
                                      device.hasChild && (device.deviceType === 'LEDBOX' || device.deviceType === 'RLEDBOX') ?
                                          {maxWidth: '70%'} : {maxWidth: '100%'}}
                            >{device.deviceName}</span>
                            {
                                // device.hasChild && 
                                device.hasChild && (device.deviceType === 'LEDBOX' || device.deviceType === 'RLEDBOX') &&
                                <span className="child_info" style={{cursor: 'pointer'}} onClick={this.onClickLedCabinets}>{device.childCount || 0}</span>
                            }
                            <div>
                                <span className="float_l">
                                    <DeviceIcons device={device} onClickMemo={onClickMemo} />
                                </span>
                                <div className="float_r info_button" style={{cursor:'pointer'}} onClick={(e)=>this.openPopup(e, device.deviceId)}>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                }
            </li>
        )
    }
}

Device.defaultProps = {
    width: 150,
};

export default connect(
    state => ({
        menu: state.menu
    }),
    dispatch => ({
        openDetailView : (type)=> dispatch(popupAction.openDetailView(type)),
        addTab: tabInfo => dispatch(menuAction.addTab(tabInfo)),
        loadContent: (id, isShowSubmenu) => dispatch(menuAction.loadContent(id, isShowSubmenu)),
        loadLedCabinet: deviceId => dispatch(deviceAction.loadLedCabinet(deviceId)),
    })
)(withTranslation()(Device));