import React, {Component} from "react";
import './DashboardPanel.css'
import './Notice.css'
import {SortableHandle} from "react-sortable-hoc";
import EditNoticePopup from "./popup/EditNoticePopup";
import {withTranslation} from "react-i18next";
import NewNoticePopup from "./popup/NewNoticePopup";
import {dashboardService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import {MoonLoader} from "react-spinners";
import Moment from "moment";
import {getErrorMessage} from "../../helper/responseHandler";

class NoticeDashboard extends Component {

    state = {
        hover: false,
        isShowEditNoticeDialog: false,
        isShowNewNoticeDialog: false,
        selectId: null,
        notices: [],
    };

    constructor(props) {
        super(props);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderNoticeList = this.renderNoticeList.bind(this);
        this.renderEditNoticeDialog = this.renderEditNoticeDialog.bind(this);
        this.onNewClickEvent = this.onNewClickEvent.bind(this);
        this.onEditClickEvent = this.onEditClickEvent.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onCloseEditNoticeDialog = this.onCloseEditNoticeDialog.bind(this);
        this.onCloseNewNoticeDialog = this.onCloseNewNoticeDialog.bind(this);
        this.onCreateNoticeEvent = this.onCreateNoticeEvent.bind(this);
        this.onUpdateNoticeEvent = this.onUpdateNoticeEvent.bind(this);
        this.onDeleteNoticeEvent = this.onDeleteNoticeEvent.bind(this);
        this.getString = this.getString.bind(this);

        this.user = JSON.parse(localStorage.getItem('user'));
    }

    componentDidMount() {
        this.fetchGetNoticeList();
    }

    onNewClickEvent(e) {
        this.setState({
            isShowNewNoticeDialog: true,
        });
    }

    onEditClickEvent(id) {
        this.setState({
            selectId: id,
            isShowEditNoticeDialog: true,
        });
    }

    onMouseEnter() {
        this.setState({
            hover: true
        });
    }

    onMouseLeave() {
        this.setState({
            hover: false
        });
    }

    onCreateNoticeEvent() {
        this.setState({
            isShowNewNoticeDialog: false,
        });
        this.fetchGetNoticeList();
    }

    onUpdateNoticeEvent() {
        this.setState({
            isShowEditNoticeDialog: false,
        });
        this.fetchGetNoticeList();
    }

    onDeleteNoticeEvent() {
        this.setState({
            isShowEditNoticeDialog: false,
        });
        this.fetchGetNoticeList();
    }

    onCloseNewNoticeDialog() {
        this.setState({
            isShowNewNoticeDialog: false,
        });
    }

    onCloseEditNoticeDialog() {
        this.setState({
            isShowEditNoticeDialog: false,
        });
    }

    getString(value) {
        let textArea = document.createElement("textarea");
        textArea.innerHTML = value;
        return textArea.value;
    }

    fetchGetNoticeList = () => {
        this.setState({loading: true,});
        dashboardService.fetchNoticeList().then(res => {
            this.setState({
                notices: res.items.noticeList,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally(() => {this.setState({loading: false});});
    };

    render() {
        let {hover, loading} = this.state;
        return (
            <div className='dashboard-widget'>
                {this.renderHeader()}
                <div className='dashboard-widget-body' style={hover ? {overflowY: 'overlay'} : {overflowY: 'hidden'}} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                    {loading && <MoonLoader css={`position: absolute;top: calc(50% - 40px);left: calc(50% - 40px);`} color="#6e78c8"/>}
                    {!loading &&
                    <div className='notice-info'>
                        {this.renderNoticeList()}
                    </div>
                    }
                </div>
                {this.renderEditNoticeDialog()}
                {this.renderNewNoticeDialog()}
            </div>
        )
    };

    renderHeader() {
        let {isEditMode, t} = this.props;
        const DragHandle = SortableHandle(() =>
            <div className='title'>{t("COM_DID_MAIN_NOTICE")}</div>
        );
        return (
            <div className='dashboard-widget-header'>
                <DragHandle />
                {
                    !isEditMode && <div className='edit' onClick={this.onNewClickEvent}/>
                }
            </div>
        );
    }

    renderNoticeList() {
        const {notices} = this.state;
        if(!(notices && notices.length > 0)) {
            return (null);
        }
        let dateFormat = this.user ? this.user.dateFormat.replace('yyyy', 'YYYY').replace('dd', 'DD') : "YYYY-MM-DD";
        return notices.map((notice, index) => {
            return (
                <div key={index} className='notice-row' onClick={() => this.onEditClickEvent(notice.noticeId)}>
                    {
                        notice.noticeImportant === 0 ?
                            <div className='import'/> : <div className='import' style={{visibility: 'hidden'}}/>
                    }
                    <div className='title' title={this.getString(notice.noticeTitle)}>{this.getString(notice.noticeTitle)}</div>
                    <div className='id' title={notice.noticeUserId}>{notice.noticeUserId}</div>
                    <div className='date' title={Moment(notice.noticeWriteDate).format(dateFormat)}>{Moment(notice.noticeWriteDate).format(dateFormat)}</div>
                </div>
            );
        });
    }

    renderEditNoticeDialog() {
        if (!this.state.isShowEditNoticeDialog)
            return (null);
        return (
            <EditNoticePopup selectId={this.state.selectId} onSave={this.onUpdateNoticeEvent} onDelete={this.onDeleteNoticeEvent} onClose={this.onCloseEditNoticeDialog}/>
        );
    }

    renderNewNoticeDialog() {
        if (!this.state.isShowNewNoticeDialog)
            return (null);
        return (
            <NewNoticePopup onSave={this.onCreateNoticeEvent} onClose={this.onCloseNewNoticeDialog}/>
        );
    }
}

export default withTranslation()(NoticeDashboard);