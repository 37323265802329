/* eslint-disable default-case */
import React, {useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import MISDialog from "../MISDialog";
import Select from "../../selectbox/Select";
import {popupAction} from "../../../actions";
import {commonConstants, rulesetConstants} from "../../../constants";
import uuid from "uuid";
import "./ConditionPopup.css";
import TextInput from "../../input/TextInput";
import Checkbox from "../../checkbox/Checkbox";
import CurrentTime from "./condition/CurrentTime";
import IpAddress from "./condition/IpAddress";
import DeviceName from "./condition/DeviceName";
import ScreenRotation from "./condition/ScreenRotation";
import ScreenSize from "./condition/ScreenSize";
import DeviceModelName from "./condition/DeviceModelName";
import CurrentDate from "./condition/CurrentDate";
import MediaTag from "./condition/MediaTag";
import {changeConditionTypeToCamel, validTime} from "../../../helper/ruleset/rulesetUtils";
import Datalink from "./condition/Datalink";
import {snakeCase} from "lodash";
import {toastr} from 'helper/toastrIntercept';
import {getMixString} from "../../../language/languageUtils";
import TooltipIcon from "../../icon/TooltipIcon";


const isSupportPreview = (type) => {
    switch(type) {
        case 'device_nm':
        case 'dev_mdnm':
        case 'screen_rotation':
        case 'screen_size':
            return false;            
        default :
            return true;                
    }
}

const ConditionPopup = ({ t, save, mode, close, condition, isLibrary }) => {
    const conditionTypeList = [];
    const dispatch = useDispatch();

    Object.entries(rulesetConstants.CONDITION_TYPES).forEach(([key, value]) => {
        conditionTypeList.push({
            value: key,
            sign: value.sign,
            title: value.sid
        });
    });

    const [name, setName] = useState(condition ? condition.conditionName : "");
    const [type, setType] = useState(
        condition ? changeConditionTypeToCamel(condition.type) : conditionTypeList[0].value
    );
    const [description, setDescription] = useState(condition ? condition.description : "");
    const [invert, setInvert] = useState(condition ? condition.isInvert : false);
    const [repeatType, setRepeatType] = useState(
        condition && condition.type === "curr_date" ? condition.repeatType : "once"
    );
    const [values, setValues] = useState(
        condition && condition.values != null
            ? condition.values
            : [
                  {
                      start: "00:00:00",
                      end: "23:59:59"
                  }
              ]
    );
    const [sign, setSign] = useState(condition ? condition.sign : "rg");
    const [valueType, setValueType] = useState(condition ? condition.valueType : "text");

    const [datalinkConfig, setDatalinkConfig] = useState(
        condition ? condition.datalinkConfig : undefined
    );
    const [addToLibrary, setAddToLibrary] = useState(
        isLibrary === undefined && condition ? condition.isPublic : false
    );

    const [disablePreview, setDisablePreview] = useState(condition ? isSupportPreview(condition.type): true);

    const handleValues = value => {
        setValues(value);
    };

    const handleSign = value => {
        setSign(value);
    };

    const handleRepeatType = value => {
        setRepeatType(value);
    };

    const handleDatalinkConfig = config => {        
        setDatalinkConfig(config);
    };

    const handleValueType = value => {
        setValueType(value);
    };

    const verifyValue = (condition,preview) => {
        if (condition.conditionName === "" && preview === undefined) {
            toastr.error(t(getMixString(["MIS_SID_MIX_ENTER_A_NAME", "MIS_SID_CONDITION_RULE"])));
            return false;
        }else if (condition.conditionName.trim() === "") {
            toastr.error(t("MESSAGE_COMMON_CANT_NAME_SPACE_P"));
            return false;
        }

        switch (condition.type) {
            case "curr_date":
                if (condition.values.length === 0) {                    
                    toastr.error(t("MESSAGE_SCHEDULE_SELECT_PERIOD_P"));
                    return false;
                }
                break;
            case "device_nm":
                if (condition.values.length === 0) {
                    toastr.error(t("MESSAGE_DEVICE_INPUT_DEVICE_NAME_P"));
                    return false;
                }
                break;
            case "dev_mdnm":
                if (condition.values.length === 0) {
                    toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
                    return false;
                }
                break;
            case "dev_ip":
                if (condition.sign === "eq") {
                    const reg = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]|[*])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]|[*])$/gi;    
                    if (condition.values.length === 0) {
                        toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
                        return false;
                    } else if (condition.values[0].value === "" || condition.values[0].value === undefined) {
                        toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
                        return false;
                    } else if(!reg.test(condition.values[0].value)){
                        toastr.error(t('COM_TV_SID_MIX_INVALID_VALUE_CHECK_AGAIN'));
                        return false;
                    }                                     
                } else if (condition.sign === "rg") {
                    const regForRange = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
                    const regForRange2 = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
                    if (condition.values[0].start === ""  || condition.values[0].start === undefined || condition.values[0].end === "" || condition.values[0].end === undefined) {
                        toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
                        return false;
                    }else if(!regForRange.test(condition.values[0].start)){                                     
                        toastr.error(t('COM_TV_SID_MIX_INVALID_VALUE_CHECK_AGAIN'));
                        return false;
                    }else if(!regForRange2.test(condition.values[0].end)){                        
                        toastr.error(t('COM_TV_SID_MIX_INVALID_VALUE_CHECK_AGAIN'));
                        return false;
                    }
                }
                break;
            case "screen_size":
                if (condition.values[0].value === "" || condition.values[0].value === undefined) {
                    toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
                    return false;
                }
                break;
            case "media_tag":                
                if(condition.values.length > 0 ){
                    if (condition.values[0].value === "" || condition.values[0].value === undefined ) {
                        toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
                        return false;
                    }
                }else{
                    toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
                    return false;
                }               
                break;
            case "datalink":              
                if(condition.values.length > 0){
                    if (condition.values[0].value === "" || condition.values[0].value === undefined) {
                        toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
                        return false;
                    }
                }else{
                    if (condition.values.value === "" || condition.values.value === undefined) {
                        toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
                        return false;
                    }
                }

                if(condition.datalinkConfig.valueLocationText === '' ||condition.datalinkConfig.valueLocationText === undefined){
                    toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
                    return false;
                }
                  break;
            case "curr_time":
                const length = values.length;
                const value = values[length-1];                 
                if (!validTime(value)){
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                    return false;
                }
                break;
            default:
                return true;
        }
        return true;
    };

    const onTypeChange = (e, value) => {
        setType(value);
        switch (value) {
            case "deviceNm":
                setSign("eq");
                setValues([]);
                setDisablePreview(false);
                break;
            case "devIp":
                setSign("eq");
                setValues([]);
                setDisablePreview(true);
                break;
            case "devMdnm":
                setSign("eq");
                setValues([]);
                setDisablePreview(false);
                break;
            case "screenRotation":
                setSign("eq");
                setValues([{ value: 0 }]);
                setDisablePreview(false);
                break;
            case "screenSize":
                setSign("eq");
                setValues([{ value: 0 }]);
                setDisablePreview(false);
                break;
            case "mediaTag":
                setSign("ia");
                setValues([]);
                setDisablePreview(true);
                break;
            case "currDate":
                setSign("rg");
                setValues([]);
                setDisablePreview(true);
                break;
            case "currTime":
                setSign("rg");
                setValues([
                    {
                        start: "00:00:00",
                        end: "23:59:59"
                    }
                ]);
                setDisablePreview(true);
                break;
            case "datalink":
                setSign("eq");
                setValues([]);
                setValueType("text");
                setDatalinkConfig({   
                    serverAddress : "", 
                    tagMatchColumn : "", 
                    tags: [], 
                    valueLocationText : "", 
                    valueLocationView : "v",
                    tagMatchType : "and",
                    pollingInterval : 30}
                );
                setDisablePreview(true);
                break;
        }
    };

    const onInvertClick = () => {
        setInvert(!invert);
    };

    const getCondition = condition => {

        if (mode === "NEW") {
            condition = JSON.parse(JSON.stringify(rulesetConstants.CONDITION_TYPES[type]));
            condition.conditionId = uuid();
            condition.isPublic = isLibrary ? true : addToLibrary;
        } else if (mode === "EDIT") {
            condition.isPublic = addToLibrary;
        }

        if (type === "currDate") {
            condition.repeatType = repeatType;
        }

        if (type === "datalink"){
            condition.datalinkConfig = datalinkConfig;
            condition.valueType = valueType;        
        }

        condition.type = snakeCase(type);
        condition.conditionName = name;
        condition.description = description;
        condition.values = values;
        condition.sign = sign;
        condition.isInvert = invert;        
        return condition;
    };

    const openPreviewPopup = (condition) => {
        dispatch(popupAction.openDetailView({
            type: commonConstants.RULESET_CONDITION_PREVIEW_POPUP,      
            id:   commonConstants.RULESET_CONDITION_PREVIEW_POPUP,    
            condition : condition,
            onClose: () => {                
                dispatch(popupAction.closePopup(commonConstants.RULESET_CONDITION_PREVIEW_POPUP));
            }
        }));
    }

    const previewButton = {   
        title: t("COM_DID_VWL_PREVIEW"),
        onClick: () => {
            const newCondition = getCondition(condition);            
            if (newCondition) {                            
                if (verifyValue(newCondition, true)) {                     
                    openPreviewPopup(newCondition);
                }
            }
        },
        disable : disablePreview
    };

    let rightButton = [];
    const saveAsButton = {
        title: t("BUTTON_SAVE_AS_P"),
        disable : mode==="NEW"?true:false,
        onClick: () => {
            const newCondition = getCondition(condition);                                                    
            newCondition.conditionId = uuid();            
            if (newCondition) {                            
                if (verifyValue(newCondition)) {                          
                    save(newCondition,'save_as');
                    close();
                }
            }
        }
    }
    const saveButton = {
        title: t("COM_BUTTON_SAVE"),
        onClick: () => {
            const newCondition = getCondition(condition);          
            if (newCondition) {                            
                if (verifyValue(newCondition)) {                            
                    save(newCondition);
                    close();
                }
            }
        }
    };

    const closeButton =  {
        title: t("BUTTON_CANCEL_P"),
        onClick: () => close()
    };

    rightButton.push(previewButton);

    if(isLibrary){
        rightButton.push(saveAsButton);        
    }
    rightButton.push(saveButton);
    rightButton.push(closeButton); 

    return (
        <MISDialog
            dialog={{
                title: t("MIS_SID_CONDITION_RULE"),
                modal: true,                
                closeOnEscape: true,             
                width: 700,     
                position: {Y: 130},
                onClose: () => close()
            }}
            buttons={{
                rightButtons: rightButton
            }}
        >
            <div className="ruleset_setting_condition">
                <div className="ruleset_condition_detail_view">
                    <table>
                        <tbody>                            
                            <tr>
                            <th style={{ width: "150px" }}>{t("LIST_TITLE_NAME")}</th>
                                <td>
                                    <TextInput
                                        width={500}
                                        value={name}
                                        maxLength={100}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </td>
                            </tr>                    
                            <tr>
                                <th>{t("TABLE_TYPE_P")}</th>
                                <td>
                                    <Select
                                        width={200}
                                        value={type}
                                        selects={conditionTypeList}
                                        onChange={onTypeChange}
                                    />
                                </td>
                            </tr>
                            <tr id="rulesetConditionTypeDiv" style={{ backgroundColor: "#f0f2f5" }}>
                                <td colSpan={2}>
                                    {type === "devIp" && (
                                        <IpAddress
                                            handleValues={handleValues}
                                            handleSign={handleSign}
                                            orgValues={values}
                                            orgSign={sign}
                                        />
                                    )}
                                    {type === "deviceNm" && (
                                        <DeviceName
                                            handleValues={handleValues}
                                            orgValues={values}
                                        />
                                    )}
                                    {type === "currTime" && (
                                        <CurrentTime
                                            handleValues={handleValues}
                                            orgValues={values}
                                        />
                                    )}
                                    {type === "currDate" && (
                                        <CurrentDate
                                            handleValues={handleValues}
                                            handleRepeatType={handleRepeatType}
                                            handleSign={handleSign}
                                            orgSign={sign}
                                            orgValues={values}
                                            orgRepeatType={repeatType}
                                        />
                                    )}
                                    {type === "devMdnm" && (
                                        <DeviceModelName
                                            handleValues={handleValues}
                                            orgValues={values}
                                        />
                                    )}
                                    {type === "screenRotation" && (
                                        <ScreenRotation
                                            handleValues={handleValues}
                                            orgValues={values}
                                        />
                                    )}
                                    {type === "screenSize" && (
                                        <ScreenSize
                                            handleValues={handleValues}
                                            handleSign={handleSign}
                                            orgValues={values}
                                            orgSign={sign}
                                        />
                                    )}
                                    {type === "mediaTag" && (
                                        <MediaTag
                                            handleValues={handleValues}
                                            handleSign={handleSign}
                                            orgValues={values}
                                            orgSign={sign}
                                        />
                                    )}
                                    {type === "datalink" && (
                                        <Datalink
                                            datalinkConfig={datalinkConfig}
                                            handleDatalinkConfig={handleDatalinkConfig}
                                            orgValueType={valueType}
                                            orgValues={values}
                                            handleValues={handleValues}
                                            orgSign={sign}
                                            handleSign={handleSign}
                                            handleValueType={handleValueType}
                                        />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <span onClick={onInvertClick}>
                                        <Checkbox
                                            name={t("MIS_SID_INVERT_CONDITION")}
                                            checked={invert}
                                            onClick={onInvertClick}
                                        />
                                    </span>
                                    <TooltipIcon message={"MIS_SID_EXECUTE_WHEN_CONDITION_NOT_MET"}/>
                                </td>                               
                            </tr>
                            {!isLibrary && (
                                <tr>
                                    <td colspan="3">
                                        <span onClick={() => {setAddToLibrary(!addToLibrary);}}>                                    
                                        <Checkbox
                                            name={t("MIS_SID_ADD_TO_LIBRARY_IMMEDIATELY")}
                                            checked={addToLibrary}
                                            onClick={() => {
                                                setAddToLibrary(!addToLibrary);
                                            }}
                                        />
                                        </span>
                                        <TooltipIcon message={"MIS_SID_YOU_CAN_ADD_TO_RULE_LIBRARY_IMMEDIATELY_WHEN_SAVING"}/>
                                    </td>                            
                                </tr>
                            )}
                            <tr>
                                <th>{t("TEXT_DESCRIPTION_P")}</th>
                                <td>
                                    <TextInput
                                        width={300}
                                        value={description}
                                        maxLength={200}
                                        placeholder={t("MIS_SID_CAFEB_ENTER_DESCRIPTION")}
                                        onChange={e => {
                                            setDescription(e.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    );
};

export default connect(
    state => ({}),
    dispatch => ({
        closePopup: id => dispatch(popupAction.closePopup(id)),
        openDetailView: type => dispatch(popupAction.openDetailView(type))
    })
)(withTranslation()(ConditionPopup));
