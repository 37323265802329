import React from 'react';
import APlayer from "./APlayer";
import IPlayer from "./IPlayer";
import SPlayer from "./SPlayer";
import Flip from "./Flip";
import LitePlayer from "./LitePlayer";
import RMPlayer from "./RMPlayer";
import SignagePlayer from "./SignagePlayer";
import WPlayer from "./WPlayer";
import LEDPlayer from './LEDPlayer';
import './PlayerIcon.css';

const PlayerIcon = ({deviceType, deviceTypeVersion, text}) => {

    const getPlayer = (deviceType, text) => {
        switch (deviceType) {
            case 'IPLAYER':
            case 'iPLAYER':
                return <IPlayer text={text}/>
            case 'SPLAYER':
                return <SPlayer text={text} deviceTypeVersion={deviceTypeVersion}/>
            case 'FLIP':
                return <Flip text={text} deviceTypeVersion={deviceTypeVersion}/>
            case 'LPLAYER':
                return <LitePlayer text={text} />
            case 'RMI':
            case 'RMS':
            case 'RSPLAYER':
            case 'RIPLAYER':
            case 'RSIGNAGE':
            case 'RMSIGNAGE':
            case 'RLEDBOX':
                return <RMPlayer text={text} deviceType={deviceType} deviceTypeVersion={deviceTypeVersion}/>
            case 'SIGNAGE':
                return <SignagePlayer text={text} deviceTypeVersion={deviceTypeVersion}/>
            case 'WPLAYER':
                return <WPlayer text={text} deviceTypeVersion={deviceTypeVersion}/>
            case 'LEDBOX':
                return <LEDPlayer text={text} deviceTypeVersion={deviceTypeVersion} />
            case 'APLAYER':
                return <APlayer text={text} deviceTypeVersion={deviceTypeVersion} />
            default :
                return '';

        }
    }
    const showText = (text === null || text === undefined) ? true : text;
    const player = getPlayer(deviceType, showText);
    return (
        player
    )
}
export default PlayerIcon;