import React from 'react';
import './SelectBox.css';
import ReactDOM from "react-dom";
import classNames from 'classnames';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {popupAction} from "../../actions/popup.action";
import {menuAction} from "../../actions";

class SelectBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            value: this.props.value,
            subMenuShow: [],
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                show: false,
                subMenuShow: []
            });
        }
    }

    openSelect = () => {
        this.setState({
            show: !this.state.show
        })
    }

    toggleSubMenu = (index) => {
        const {subMenuShow} = this.state;

        if(subMenuShow.indexOf(index) < 0) {
            this.setState({subMenuShow: [...subMenuShow, index]});
        } else {
            this.setState({subMenuShow: []});
        }
    }

    hideSubMenu = () => {
        this.setState({subMenuShow: []});
    }

    onPopup = (id) => {
    
        const {activeNode, addPopup, activeChildrenNode} = this.props;

        switch(id){
            case 'CREATE_CONTENT_SCHEDULE' : 
                activeChildrenNode('CONTENT', 'ALL_CONTENT_SCHEDULE');
                break;
            case 'CREATE_MESSAGE_SCHEDULE' : 
                activeChildrenNode('MESSAGE', 'ALL_MESSAGE_SCHEDULE');
                break;
        }

        addPopup({type: id, id: id})
        this.setState({
            show: false,
            subMenuShow: [],
        })
    }

    onClick = (val, onClick) => {
        this.setState({
            show: false,
            subMenuShow: [],
            value: val
        });
        if (onClick) {
            onClick(val);
        }
    }

    getTitle = (type, selects, value, defaultTitle, multiLang) => {
        const {t} = this.props;
        if (type === 'SUB') {
            return t(this.props.title);
        } else {
            if (selects !== undefined && selects.length > 0) {
                const select = selects.find(select => select.value === value);
                if (select != null){
                    if (multiLang === undefined || multiLang) {
                        return t(select.title);
                    } else {
                        return select.title;
                    }
                } else {
                    if (defaultTitle !== undefined) {
                        return defaultTitle;
                    }
                }
            } else {
                if (defaultTitle !== undefined) {
                    return defaultTitle;
                }
            }
        }
        return '';
    }

    propsOnClick = (value) => {
        this.props.onClick(value);
        this.setState({
            show: false,
            subMenuShow: [],
            value: value
        });
    }

    render() {
        const {id, selects, width, type, classname, defaultTitle, t, multiLang, disabled, whiteButton, zIndex, optionWidth = undefined, authority = true} = this.props;
        const onClick = this.props.onClick !== undefined && this.propsOnClick || this.onClick;
        const {value, show, subMenuShow} = this.state;

        const title = this.getTitle(type, selects, value, defaultTitle, multiLang);

        const getStyle = {'display': (show ? 'block': 'none'), 'zIndex' : zIndex!=undefined ? zIndex : ''};
        if (optionWidth) {
            getStyle.width = optionWidth
        }
        if(!authority){
            return (<></>);
        }
        
        return (
            <div className={classNames(classname !== undefined ? classname:'', "menu_select_box")}>
                <button key={id} id={id} className={classNames({'base': true, 'noline': type === 'SUB', 'un_radius' : type !== 'SUB', 'select' :type !== 'SUB', 'on' : type !== 'SUB', 'white' : whiteButton !== undefined && whiteButton })} style={{width: width, textAlign: 'center'}} value={value} onClick={()=>this.openSelect()} disabled={disabled}>
                    <span>
                        <span style={{width: width - 40}}>{title}</span>
                        <span className="arrow"/>
                    </span>
                </button>
                <div className="option_list" style={getStyle} >

                    <div><span></span>
                        <ul style={{width: optionWidth? optionWidth: 150}}>
                            {
                                selects != null && selects.length > 0 ?
                                    selects.map(
                                        (select, index) =>
                                            <li key={index}
                                                onClick={(select.sub && select.sub.length > 0) ? () => this.toggleSubMenu(index) : (select.popup ? ()=>this.onPopup(select.key) : ()=>onClick(select.value, select.onClick))}
                                                onMouseOver={(select.sub && select.sub.length > 0) ? () => this.toggleSubMenu(index) : undefined}
                                                onMouseOut={(select.sub && select.sub.length > 0) ? () => this.hideSubMenu() : undefined}
                                            >
                                                <input type="hidden" value={select.value}/>
                                                <a href="#" className={select.sub && select.sub.length > 0 ? 'extend_sub' : ''}>
                                                    {multiLang ===undefined || multiLang ? t(select.title) : select.title}
                                                </a>
                                                {
                                                    select.sub && select.sub.length > 0 &&
                                                    <div className="sub_option_list" style={{'display':(subMenuShow.indexOf(index) > -1 ? 'block': 'none')}}>
                                                        <div><span></span>
                                                            <ul>
                                                                {
                                                                    select.sub.map((sub, index) =>
                                                                        <li key={'sub_' + index}
                                                                            onClick={() => onClick(sub.value)}
                                                                        >
                                                                            <input type="hidden" value={select.sub.value}/>
                                                                            <a href="#">
                                                                                {multiLang ===undefined || multiLang ? t(sub.title) : sub.title}
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                }

                                            </li>
                                    )
                                    : ''
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        common : state.common
    }),
    dispatch => ({
        addPopup: (popup)=> dispatch(popupAction.addPopup(popup)),
        activeNode: id=> dispatch(menuAction.activeNode(id)),
        activeChildrenNode: (parent, submenu) => dispatch(menuAction.activeChildrenNode(parent, submenu))
    })

)(withTranslation()(SelectBox));