import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {snakeCase, toInteger} from 'lodash';
import Moment from "react-moment";
import {toastr} from '../../helper/toastrIntercept';
import fileDownload from 'js-file-download';
import {commonConstants, DEVICE_PAGE_SIZE_OPTIONS, DEVICE_SCHEDULES_TO_EXPIRES_SUB_TABS} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {deviceService, scheduleService, userService} from '../../services';
import {menuAction, popupAction, scheduleAction} from "../../actions";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import SubTab from '../../components/tab/SubTab';
import Pagination from '../../components/table/Pagination';
import DeviceRelativeTimeCell from '../../components/device/DeviceRelativeTimeCell';
import {useMISOpt} from '../../components/misopt';
import {useCheckRefWithSelectedCnt as useCheckRef, useDeviceDetail, useFilter, useResizeWindow} from '../../helper';
import {convertContentScheduleFromServer} from '../../helper/schedule/scheduleUtils';
import PlayerIcon from '../../components/icon/players';
import {useTrGroupProps} from '../../helper/tables';
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {updateCache} from "../../helper/cache/tableCache";
import {getErrorMessage} from "../../helper/responseHandler";

const heightOffset = 263;

const SchedulesToExpire = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat} = {}} = {}, getAuthority} = useMISOpt();

    const [authority, setAuthority] = useState(getAuthority('SCHEDULE'));

    const momentDateFormat = (dateFormat || 'yyyy-MM-dd').toUpperCase();

    const [subTab, setSubTab] = useState(props.cache.scheduleToExpiredSubTab ? props.cache.scheduleToExpiredSubTab : DEVICE_SCHEDULES_TO_EXPIRES_SUB_TABS[0]);
    const defaultSort = subTab === DEVICE_SCHEDULES_TO_EXPIRES_SUB_TABS[0] ? {id: 'expirationDate', desc: true} : {id: 'endStopDate', desc: true};

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
        deviceSecurity: props.cache.deviceSecurity ? props.cache.deviceSecurity : false
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;

        setData({...data, loading: true});

        if(subTab === DEVICE_SCHEDULES_TO_EXPIRES_SUB_TABS[0]) {

            let convertedSortColumn = id;
            if(id === 'lastModifiedDate') {
                convertedSortColumn = 'modifyDate';
            }

            deviceService.fetchDeviceScheduleUpcomingExpires({
                startIndex: page * pageSize + 1,
                pageSize,
                searchText: keyword,
                sortColumn: snakeCase(convertedSortColumn),
                sortOrder: desc ? 'desc' : 'asc',
            }).then(res => {
                setData({...data, loading: false, items: res.items, totalCount: res.totalCount});
                updateCache('DEVICE', {items: res.items, filter: filter, totalCount: res.totalCount, scheduleToExpiredSubTab: subTab}, props.currContent);
            }).catch(err => {
                if(err.errorCode === 408900)
                    return;
                toastr.error(getErrorMessage(err, err.errorMessage))
                setData({...data, loading: false})
            })
        } else {
            Promise.all([
                deviceService.fetchDeviceUpcomingExpires({
                    startIndex: page * pageSize + 1,
                    pageSize,
                    searchText: keyword,
                    sortColumn: id === 'endStopDate' ? 'stop_date' : snakeCase(id),
                    sortOrder: desc ? 'desc' : 'asc',
                }),
                userService.fetchUserLoginInformation()
            ]).then(res => {
                setData({...data, loading: false, items: res[0].items, totalCount: res[0].totalCount,
                    deviceSecurity: res[1].items.hasDeviceSecurity
                });
                updateCache('DEVICE', {items: res[0].items, filter: filter, totalCount: res[0].totalCount, deviceSecurity : res[1].items.hasDeviceSecurity, scheduleToExpiredSubTab: subTab}, props.currContent);
            }).catch(err => {
                if(err.errorCode === 408900)
                    return;
                toastr.error(getErrorMessage(err, err.errorMessage))
                setData({...data, loading: false})
            })
        }
    };


    const onKeywordChange = value => {
        setFilter({...filter, keyword: value ? value : '', page: 0, sorted: [subTab === DEVICE_SCHEDULES_TO_EXPIRES_SUB_TABS[0] ? {id: 'expirationDate', desc: true} : {id: 'endStopDate', desc: true}]});
    };

    const onClickSubTab = tab => {
        setSubTab(tab);
        setData({...data, items: []});
        setFilter({...filter, page: 0, sorted: [tab === DEVICE_SCHEDULES_TO_EXPIRES_SUB_TABS[0] ? {id: 'expirationDate', desc: true} : {id: 'endStopDate', desc: true}]});
    };

    const [renderDeviceNameCell, renderMACCell] = useDeviceDetail(true, data.deviceSecurity);

    const onClickSchedule = programId => {
        dispatch(popupAction.openDetailView({type: commonConstants.COMMON_DETAIL_VIEW, viewType: 'SCHEDULE_CONTENT', id: programId}));
    };

    const onEdit = () => {
        if(selectedCnt.length === 0){
            return;
        }

        const programId = items[selected.current[0]].programId;
        scheduleService.fetchContentScheduleById(programId).then(
            res => {
                const tab = {id: 'EDIT_SCHEDULE', title: t("LAYERTITLE_EDIT_SCHEDULE"), active: true, close: true};
                const program = convertContentScheduleFromServer(res.items);
                new Promise(()=> {
                    dispatch(scheduleAction.initContentSchedule('edit', res.items.deviceType, res.items.deviceTypeVersion, program));
                }).then(
                    dispatch(menuAction.addTab(tab))
                ).then(
                    dispatch(menuAction.loadContent('EDIT_SCHEDULE'))
                )
            }
        );
    };

    const onExport = exportType => {
        const {keyword, sorted: [{id, desc}]} = filter;
        exportType = 'EXCEL';
        if(subTab === DEVICE_SCHEDULES_TO_EXPIRES_SUB_TABS[0]) {

            let convertedSortColumn = id;
            if(id === 'lastModifiedDate') {
                convertedSortColumn = 'modifyDate';
            }

            deviceService.exportDeviceScheduleUpcomingExpires({
                exportType,
                searchText: keyword,
                sortColumn: snakeCase(convertedSortColumn),
                sortOrder: desc ? 'desc' : 'asc',
            }).then(res => {
                fileDownload(res.blob, res.fileName);
            }).catch(error => console.log(error));
        } else {
            deviceService.exportDeviceUpcomingExpires({
                exportType,
                searchText: keyword,
                sortColumn: id === 'endStopDate' ? 'stop_date' : snakeCase(id),
                sortOrder: desc ? 'desc' : 'asc',
            }).then(res => {
                fileDownload(res.blob, res.fileName);
            }).catch(error => console.log(error));
        }
    };

    const updateDimensions = () => setStyle({height: window.innerHeight - heightOffset});
    useResizeWindow(updateDimensions);
    useEffect(updateDimensions, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const searchPlaceHolder = subTab === DEVICE_SCHEDULES_TO_EXPIRES_SUB_TABS[0] ? t('TEXT_SCHEDULE_NAME_P') : t('TABLE_DEVICE_NAME_P');

    const scheduleColumns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'schedule_expire_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={'schedule_expire_'+row.index}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs} 
                    />
                );
            },
        },
        {
            Header: t('TEXT_SCHEDULE_NAME_P'),
            accessor: 'programName',
            width: 300,
            Cell: ({original: {programId}, value}) => <span className='link_cell' onClick={() => onClickSchedule(programId)}>{value}</span>
        },
        {
            Header: t('BUTTON_DETAIL_P'),
            accessor: 'programType',
            width: 250,
            sortable: false,
            Cell: data => {
                if(data.value === 'VWL') {
                    return <span>{t('TEXT_TITLE_VIDEOWALL_P')}</span>;
                } else if(data.value === 'SYNC') {
                    return <span>{t('MIS_SID_SYNC_PLAY')}</span>
                } else if(data.value === 'ADV') {
                    return <span>{t('COM_DID_LFD_ADVERTISEMENT')}</span>
                } else {
                    return <span>{t('COM_TEXT_GENERAL_P')}</span>
                }
            }
        },
        {
            Header: t('COM_MIS_TEXT_SUPPORTED_DEVICE_TYPE_P'),
            accessor: 'supportedDeviceType',
            width: 150,
            sortable: false,
            Cell: ({original}) => <PlayerIcon text={false} deviceType={original.deviceType} deviceTypeVersion={toInteger(original.deviceTypeVersion)} />
        },
        {
            Header: t('MIS_SID_PUBLISH_STATUS'),
            sortable: false,
            width: 300,
            accessor: 'downloadStatus',
        },
        {
            Header: t('COM_EXPIRATION_DATE_KR_DATE'),
            accessor: 'expirationDate',
            width: 300,
            Cell: data => <Moment format={momentDateFormat}>{data.value}</Moment>
        },
        {
            Header: t('COM_TEXT_MODIFY_DATE_P'),
            accessor: 'lastModifiedDate',
            width: 200,
            Cell: data => <DeviceRelativeTimeCell value={data.value} />
        },
    ], [items]);

    const deviceColumns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'schedule_expire_device_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={'schedule_expire_device_'+row.index}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs} 
                    />
                );
            },
        },
        {
            Header: t('TABLE_DEVICE_NAME_P'),
            accessor: 'deviceName',
            width: 300,
            Cell: renderDeviceNameCell
        },
        {
            Header: t('DID_ADMIN_LICENSE_MAC'),
            accessor: 'deviceId',
            Cell: renderMACCell,
            width: 200,
        },
        {
            Header: t('TABLE_IP_ADDR_P'),
            accessor: 'ipAddress',
            width: 200,
        },
        {
            Header: t('TABLE_DEVICE_MODEL_NAME_P'),
            accessor: 'deviceModelName',
            width: 200,
        },
        {
            Header: t('COM_TABLE_GROUP_NAME_P'),
            accessor: "deviceGroupName",
            width: 250,
            align: 'center',
        },
        {
            Header: t('TEXT_SCHEDULE_NAME_P'),
            accessor: 'programName',
            width: 250,
        },
        {
            Header: t('COM_EXPIRATION_DATE_KR_DATE'),
            accessor: 'endStopDate',
            width: 200,
            Cell: data => <Moment format={momentDateFormat}>{data.value}</Moment>
        }
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'device_tr_group');

    return (
        <div style={{width: '100%',display: props.currContent === 'SCHEDULES_TO_EXPIRE' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    {
                        (authority.CREATE || authority.MANAGE) && <WhiteButton id={"SCHEDULE_EDIT"} name={t('COM_BUTTON_EDIT')} disable={selectedCnt !== 1} onClick={onEdit} />
                    }
                    <WhiteButton id={"DEVICE_EXPORT"} name={t('BUTTON_EXPORT_P')} onClick={onExport} style={{borderColor: '#5e5e5e'}} />
                </div>
                <div className="rightButton">
                    <SearchBar id="deviceSearch" placeholder={searchPlaceHolder} onClickSearch={onKeywordChange} keyword={filter.keyword}/>
                </div>
            </div>
            <div style={{marginLeft: '25px', marginRight: '25px', marginBottom: '20px'}}>
                <SubTab tabs={DEVICE_SCHEDULES_TO_EXPIRES_SUB_TABS} selected={subTab} style={{width: '650px'}} onClick={onClickSubTab} />
            </div>
            <div className='device_list_view_wrap'>
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns= {subTab === DEVICE_SCHEDULES_TO_EXPIRES_SUB_TABS[0] ? scheduleColumns : deviceColumns}
                    showPagination={false}
                    className="-striped -highlight"
                    style={style}
                    manual
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} divide={props.divide} />
            </div>
        </div>
    );
};

export default SchedulesToExpire;
