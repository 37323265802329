import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Checkbox from "../../../checkbox/Checkbox";
import WhiteButton from "../../../button/WhiteButton";
import TextInput from "../../../input/TextInput";
import NumberInput from "../../../input/NumberInput";
import {settingService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import {validateSpecialCharForExternalServerName, validateWhiteSpace} from "../../../../helper";

const RemoteServerInformation = ({rmInfo, updateRmInfo, mode, setNameChecked}) =>{
    const {t} = useTranslation();
    const [nameCheckError, setNameCheckError] = useState('');
    const [rmPopupInfo, setRmPopupInfo] = useState(rmInfo?rmInfo:{
        'serverName':'',
        'ipAddress':'',
        'port':7001,
        'useSsl':false,
        'privateMode':false,
        'privateIpAddress':'',
        'privatePort':7001,
        'privateSsl':false
    });

    useEffect(()=>{
        updateRmInfo(rmPopupInfo);
    }, []);

    const checkServerName = (name_to_check) => {
        if(name_to_check == ''){
            toastr.error(t('MIS_MESSAGE_SETUP_ENTER_SERVERNAME_P'));
            setNameChecked(false);
            return;
        }

        if(name_to_check.length > 60){
            toastr.error(t('COM_SID_SCHEDULE_MIX_MAX_LENGTH').replace("<<A>>",60));
            setNameChecked(false);
            return;
        }

        if(!validateSpecialCharForExternalServerName(name_to_check)){
            toastr.error(t('MIS_SID_ONLY_ALPHANUMERIC_CHARACTERS_AND_FOLLOWING_ALLOWED')  + " ( ( ) - )");
            setNameChecked(false);
            return;
        }

        if(validateWhiteSpace(name_to_check)){
            toastr.error(t('MIS_SID_BLANK_SPACE_NOT_ALLOW_MSG'));
            setNameChecked(false);
            return;
        }

        settingService.checkRemoteControlName(name_to_check)
            .then(res=> {
                if(res.items === false){
                    toastr.success(t('MIS_TEXT_SETUP_CHECK_SERVERNAME_P'));
                    setNameChecked(true);
                }else{
                    setNameChecked(false);
                }
                setNameCheckError(res.items);
            })
            .catch(err=>{

            })
    };

    const getRmInfo = (e, v) => {
        let key = e.target.getAttribute('data-name');
        let value = v?parseInt(v):e.target.value;

        if(key === 'port' || key === 'privatePort')
            value = isNaN(value)?'':value;

        if(key === 'privateSsl' || key === 'useSsl' || key === 'privateMode'){
            value = e.target.checked;
        }
        const updatedObj = {
            ...rmPopupInfo,
            [key]:value
        };

        (key === 'serverName') && setNameChecked(false);

        setRmPopupInfo(updatedObj);
        updateRmInfo(updatedObj);
    };

    return(
        <div className='detail_view'>
            <table>
                <tbody>
                {mode === 'ADD' ?
                    <tr>
                        <th style={{width: '142px'}}><span>{t('TABLE_SERVER_NAME_P')}</span></th>

                        <td style={{display: "flex"}}>
                            <TextInput
                                propertyName={'serverName'}
                                width={165}
                                placeholder={t('TABLE_SERVER_NAME_P')}
                                onChange={e => getRmInfo(e)}
                                maxLength={50}
                                value={rmPopupInfo.serverName ? rmPopupInfo.serverName : ''}
                                error={nameCheckError ? t('MESSAGE_UPLOADER_ALREADY_EXIST_P') : ''}
                                errorWidth={160}/>
                            <span style={{margin: '0 0 0 25px', height: '27px'}}>
                            <WhiteButton
                                name={t('MIS_TEXT_CHECK_P')}
                                onClick={() => checkServerName(rmPopupInfo.serverName)}
                            />
                        </span>
                        </td>
                    </tr>:
                    <tr>
                        <th style={{width: '142px'}}><span>{t('TABLE_SERVER_NAME_P')}</span></th>
                        <td style={{display: "flex"}}>
                            <span>{rmPopupInfo.serverName}</span>
                        </td>
                    </tr>
                }
                <tr>
                    <th style={{width:'142px'}}><span>{t('TABLE_IP_ADDR_P')}</span></th>
                    <td>
                        <TextInput
                            propertyName={'ipAddress'}
                            width={250}
                            maxLength={200}
                            placeholder={t('TABLE_IP_ADDR_P') + ', ' + t('COM_LIST_TITLE_URL')}
                            onChange={e=>getRmInfo(e)}
                            value={rmPopupInfo.ipAddress?rmPopupInfo.ipAddress:''}
                        />
                    </td>
                </tr>
                <tr>
                    <th style={{width:'142px'}}><span>{t('TABLE_WEB_PORT_P')}</span></th>
                    <td>
                        <NumberInput propertyName={'port'} width={84} min={1} max={65535}
                            value={rmPopupInfo.port} 
                            onChange={(e, v)=>getRmInfo(e, v)} />                        
                    </td>
                </tr>
                <tr>
                    <th style={{width:'142px'}}><span>SSL</span></th>
                    <td>
                        <Checkbox
                            propertyName='useSsl'
                            id='use_ssl_check'
                            name={t('TEXT_ENABLE2_P')}
                            onChange={e=>{getRmInfo(e)}}
                            checked={rmPopupInfo.useSsl}
                        />
                    </td>
                </tr>
                <tr>
                    <th style={{width:'142px'}}><span>{t('MIS_SID_PRIVATE_MODE')}</span></th>
                    <td>
                        <Checkbox
                            propertyName='privateMode'
                            id='private_mode_check'
                            name={t('TEXT_ENABLE2_P')}
                            onChange={e=>getRmInfo(e)}
                            checked={rmPopupInfo.privateMode}
                        />
                    </td>
                </tr>
                {rmPopupInfo.privateMode?
                <tr>
                    <td colSpan="2">
                <table>
                <tbody>
                <tr>
                    <th style={{width:'142px'}}><span>{t('MIS_SID_PRIVATE_IIP_ADDRESS')}</span></th>
                    <td>
                        <TextInput
                            propertyName={'privateIpAddress'}
                            width={250}
                            maxLength={200}
                            placeholder={t('MIS_SID_PRIVATE_IIP_ADDRESS') + ', ' + t('COM_LIST_TITLE_URL')}
                            onChange={e=>getRmInfo(e)}
                            value={rmPopupInfo.privateIpAddress}
                        />
                    </td>
                </tr>
                <tr>
                        <th style={{width:'142px'}}><span>{t('MIS_SID_PRIVATE_MODE') + ' '+t('TABLE_WEB_PORT_P')}</span></th>
                        <td>
                            <NumberInput propertyName={'privatePort'} width={84} min={1} max={65535}
                                value={rmPopupInfo.privatePort} 
                                onChange={(e, v)=>getRmInfo(e, v)} />
                        </td>
                    </tr>
                    <tr>
                        <th style={{width:'142px'}}><span>{t('COM_SID_PRIVATE') + ' ' + 'SSL'}</span></th>
                        <td>
                            <Checkbox
                                classname='table'
                                propertyName={'privateSsl'}
                                id='private_ssl_check'
                                onChange={e=>getRmInfo(e)}
                                checked={rmPopupInfo.privateSsl}
                            />
                        </td>
                    </tr>
                </tbody>
                </table>
                </td>
                </tr>
                :''}
                </tbody>
            </table>
        </div>
    );
};

export default RemoteServerInformation;