import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import classNames from 'classnames';
import Slide from 'rc-slider';
import {toastr} from 'helper/toastrIntercept';
import {get, isEmpty, isNil, map, toInteger, toString} from 'lodash';
import {deviceOptions, useMISOpt} from '../../../misopt';
import RadioGroup from '../../../radio/RadioGroup';
import Chip from '../../../chip/Chip';
import DateInput from '../../../input/DateInput';
import Checkbox from '../../../checkbox/Checkbox';
import Select from '../../../selectbox/Select';
import PlusButton from '../../../button/PlusButton';
import TimeSelect from '../../../selectbox/TimeSelect';
import {dateToString, time12hhTo24hh, time24hhTo12hh, toMomentTimeFormat, isDDMMFormat, is12HourFormat} from '../../../../helper';
import moment from 'moment';
import './EditDevice.css';
import './EditTime.css';
import DateSelectInput from "../../../input/DateSelectInput";

// if(startDaysBinary & 1 == 1){ isCheckedOnSun = 'checked';} startDaysBinary >>= 1;
// 					if(startDaysBinary & 1 == 1){ isCheckedOnMon = 'checked';} startDaysBinary >>= 1;
// 					if(startDaysBinary & 1 == 1){ isCheckedOnTue = 'checked';} startDaysBinary >>= 1;
// 					if(startDaysBinary & 1 == 1){ isCheckedOnWed = 'checked';} startDaysBinary >>= 1;
// 					if(startDaysBinary & 1 == 1){ isCheckedOnThu = 'checked';} startDaysBinary >>= 1;
// 					if(startDaysBinary & 1 == 1){ isCheckedOnFri = 'checked';} startDaysBinary >>= 1;
// 					if(startDaysBinary & 1 == 1){ isCheckedOnSat = 'checked';}

// timerSeq; ; ; onTimerStatus; ; ; ; offTimerStatus; 
const timeFormat = 'hh:mm A';

export const days = [
    'COM_TEXT_DAY_SUNDAY_P',
    'COM_TEXT_DAY_MONDAY_P',
    'COM_TEXT_DAY_TUESDAY_P',
    'COM_TEXT_DAY_WEDNESDAY_P',
    'COM_TEXT_DAY_THURSDAY_P',
    'COM_TEXT_DAY_FRIDAY_P',
    'COM_TEXT_DAY_SATURDAY_P',
];

export const getTimerValue = (repeat) => {
    if(repeat == 0) {
        return 0; //'Once';
    } else if(repeat == 1) {
        return 127; //'Everyday';
    } else if(repeat == 2) {
        return 62; //'Mon ~ Fri';
    } else if(repeat == 3) {
        return 63; //'Mon ~ Sat';
    } else if(repeat == 4) {
        return 65; //'Sat ~ Sun';
    }
    return -1;
};

export const getRepeatValue = str => {
    if(str === '0000000') {
        return 0;
    } else if(str === '1111111') {
        return 1;
    } else if(str === '0111110') {
        return 2;
    } else if(str === '0111111') {
        return 3;
    } else if(str === '1000001') {
        return 4;
    }
    return 5;
};

export const createRepeatStrings = (timerConfTimerList, repeatName, weekdayName) => map(timerConfTimerList, timer => {
    const repeatValue = [];
    if(timer) {
        let value = getTimerValue(timer[repeatName]);
        if(value < 0) {
            return timer[weekdayName];
        }
        
        for(let i = 0; i < 7; i++) {
            repeatValue.push(value & 1);
            value = value >> 1;
        }
    }
    return repeatValue.reverse().join('');
});

const mf = m => m < 10 ? '0' + m : m;

const Timer = ({type, index, usage, repeat, time, volume, source, inputSources, holidayEnable, changed, onChange, use12Hours}) => {
    const {t} = useTranslation();
    const [holidayChanged, setHolidayChanged] = useState(false);
    const {misopt: {user: {timeFormat}}} = useMISOpt();

    const onChangeDay = (e, i) => {
        if(isNil(repeat) || repeat.length < 7) {
            return;
        }

        let str = repeat.substr(0, i) + (e.target.checked ? '1' : '0') + repeat.substr(i + 1);
        const repeatValue = getRepeatValue(str);

        if(type === 'on') {
            onChange({'data-name': `timerConfTimerList[${index}].timerManualWeekday`}, str);
            onChange({'data-name': `timerConfTimerList[${index}].timerRepeat`}, repeatValue);
        } else {
            onChange({'data-name': `timerConfTimerList[${index}].timerOffManualWeekday`}, str);
            onChange({'data-name': `timerConfTimerList[${index}].timerOffRepeat`}, repeatValue);
        }
    };

    const onChangeTime = value => {

        let hh24 = value.hour(); // parseInt(e.target.value.substr(0, 2));
        let mm = value.minute(); //parseInt(e.target.value.substr(3, 5));
        const time = time24hhTo12hh(hh24, mm);

        if(type === 'on') {
            onChange({'data-name': `timerConfTimerList[${index}].timerOnH`}, toString(time.hh));
            onChange({'data-name': `timerConfTimerList[${index}].timerOnM`}, toString(mf(time.mm)));
            onChange({'data-name': `timerConfTimerList[${index}].timerOnAmpm`}, toString(time.ampm));
        } else {
            onChange({'data-name': `timerConfTimerList[${index}].timerOffH`}, toString(time.hh));
            onChange({'data-name': `timerConfTimerList[${index}].timerOffM`}, toString(mf(time.mm)));
            onChange({'data-name': `timerConfTimerList[${index}].timerOffAmpm`}, toString(time.ampm));
        }
    };

    const onChangeVolume = value => {
        onChange({'data-name': `timerConfTimerList[${index}].timerVolume`}, value);
    };

    const onChangeHoliday = e => {
        if(type === 'on') {
            if(e.target.checked) {
                if(holidayEnable == 0) {
                    onChange({'data-name': `timerConfTimerList[${index}].timerHolidayEnable`}, 2);
                } else if(holidayEnable == 3) {
                    onChange({'data-name': `timerConfTimerList[${index}].timerHolidayEnable`}, 1);
                }
            } else {
                if(holidayEnable == 1) {
                    onChange({'data-name': `timerConfTimerList[${index}].timerHolidayEnable`}, 3);
                } else if(holidayEnable == 2) {
                    onChange({'data-name': `timerConfTimerList[${index}].timerHolidayEnable`}, 0);
                }
            }
        } else {
            if(e.target.checked) {
                if(holidayEnable == 0) {
                    onChange({'data-name': `timerConfTimerList[${index}].timerHolidayEnable`}, 3);
                } else if(holidayEnable == 2) {
                    onChange({'data-name': `timerConfTimerList[${index}].timerHolidayEnable`}, 1);
                }
            } else {
                if(holidayEnable == 1) {
                    onChange({'data-name': `timerConfTimerList[${index}].timerHolidayEnable`}, 2);
                } else if(holidayEnable == 3) {
                    onChange({'data-name': `timerConfTimerList[${index}].timerHolidayEnable`}, 0);
                }
            }
        }
        setHolidayChanged(true);
    };

    const postfix = type + index;

    return (
        <>
            <tr>
                <td width='100px' className={classNames({
                    'changed': type === 'on' ? get(changed, `timerConfTimerList[${index}].timerOnStatus`) : get(changed, `timerConfTimerList[${index}].timerOffStatus`)
                })}>
                    {type === 'on' ? t('COM_IDS_TXT_ON_TIMER') : t('COM_IDS_TXT_OFF_TIMER')}
                </td>
                <td colSpan='2'>
                    <RadioGroup 
                        propertyName={type === 'on' ? `timerConfTimerList[${index}].timerOnStatus` : `timerConfTimerList[${index}].timerOffStatus`} 
                        selects={deviceOptions.reverseUsageOptions} value={usage} onChange={onChange} />
                </td>
            </tr>
            {
                usage === '1' &&
                <>
                    <tr className="time_detail_day">
                        <td></td>
                        <td className={classNames({
                            'changed': type === 'on' ? get(changed, `timerConfTimerList[${index}].timerManualWeekday`) : get(changed, `timerConfTimerList[${index}].timerOffManualWeekday`)
                        })}>
                            {t('TEXT_DAY_P')}
                        </td>
                        <td>
                            {
                                repeat && repeat.length > 0 &&
                                days.map((day, i) => {
                                    const key = `timer_day_${type}_${index}_${i}`;
                                    return <Checkbox id={key} key={key} name={t(day)} checked={repeat.charAt(i) === '1'} onChange={e => onChangeDay(e, i)} />
                                })
                            }
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className={classNames({
                            'changed': type === 'on' ? get(changed, `timerConfTimerList[${index}].timerOnH`) : get(changed, `timerConfTimerList[${index}].timerOffH`)
                        })}>
                            {type === 'on' ? t('TABLE_ON_TIMER_SETTING_P') : t('TABLE_OFF_TIMER_SETTING_P')}
                        </td>
                        <td>
                            {
                                time &&
                                <TimeSelect width={130} showSecond={false} value={time} onChange={onChangeTime} format={toMomentTimeFormat(timeFormat)} use12Hours={use12Hours}/>
                            }
                        </td>
                    </tr>
                    {
                        type === 'on' &&
                        <tr>
                            <td></td>
                            <td className={classNames({
                                'changed': get(changed, `timerConfTimerList[${index}].timerVolume`)
                            })}>
                                {t('TABLE_VOLUME_P')}
                            </td>
                            <td>
                                <div className='mr8' style={{width: 370, paddingLeft: '10px', display: 'inline-block'}}>
                                    <Slide min={0} value={toInteger(volume)} max={100} onChange={onChangeVolume} />
                                </div>
                                <span>{volume}</span>
                            </td>
                        </tr>
                    }
                    {
                        type === 'on' &&
                        <tr>
                            <td></td>
                            <td className={classNames({
                                'changed': get(changed, `timerConfTimerList[${index}].timerSource`)
                            })}>
                                {t('COM_DID_LFD_SOURCE')}
                            </td>
                            <td>
                                <Select width={165} propertyName={`timerConfTimerList[${index}].timerSource`} value={source} selects={inputSources} onChange={onChange} />
                            </td>
                        </tr>
                    }
                    <tr>
                        <td></td>
                        <td className={classNames({'changed': holidayChanged})}>
                            {t('DID_LAYOUTS_COMMON_HOLIDAY')}
                        </td>
                        <td>
                            <Checkbox id={`holidayEnable_${postfix}`} name={t('BUTTON_APPLY')} propertyName={`timerConfTimerList[${index}].timerHolidayEnable`} checked={holidayEnable == 1 || (type === 'on' ? holidayEnable == 2 : holidayEnable == 3)} onChange={onChangeHoliday} />
                        </td>
                    </tr>
                </>
            }
        </>
    );
};

const getTimeStr = (hh, mm, ampm) => {
    if(ampm != 1) {
        return `${parseInt(hh) + 12}:${mm}`;
    }
    return `${hh}:${mm}`;
};

const timeInputStyle = {
    textAlign: 'center',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    boxSizing: 'border-box'
};

const EditTime = ({
    timeInfo,
    power,
    onChange,
}) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const today = new Date();
    const [unsupported, setUnsupported] = useState(false);
    const [showTimer, setShowTimer] = useState([false, false, false, false, false, false, false]);
    const [holidayPeriod, setHolidayPeriod] = useState([today, today]);
    const {data: {support, modelKind, timeClockConf, timerConfTimerList, deviceTimeHolidayList, deviceTimeHolidayHistory = [], inputSourceList} = {}, changed} = timeInfo || {};
    const {clockY1, clockY2, clockMon, clockDay, clockH, clockM, clockAmpm} = timeClockConf || {};
    const ddMM = isDDMMFormat(dateFormat);
    let date, time;

    useEffect(() => {
        if(!unsupported && modelKind === 'OLD' && (isNil(timeClockConf) || isEmpty(clockMon))) {
            toastr.info(t('COM_TV_SID_UNSUPPORTED_VERSION'));
            setUnsupported(true);
        }
    }, [modelKind, timeClockConf]);

    if(!unsupported && timeClockConf) {
        // dateTime = new Date(toInteger(clockY1) * 256 + toInteger(clockY2), clockMon - 1, clockDay, clockAmpm == 0 ? clockH + 12 : clockH, clockM);
        if(toInteger(clockMon) > 0 && toInteger(clockDay) > 0) {
            date = `${toInteger(clockY1) * 256 + toInteger(clockY2)}-${clockMon}-${clockDay}`;
        } else {
            date = dateToString(today);
        }

        // time = `${clockAmpm == 0 ? clockH + 12 : clockH}:${clockM}`;
        //time = moment({hour: clockAmpm == 0 ? toInteger(clockH) + 12 : toInteger(clockH), minute: toInteger(clockM)});

        const cvtTime = time12hhTo24hh(toInteger(clockH), toInteger(clockM), clockAmpm );

        time = moment({hour: cvtTime.hh , minute: cvtTime.mm });

        // time.hour(clockAmpm == 0 ? clockH + 12 : clockH);
        // time.minute(clockM);
    }

    if(unsupported) {
        date = dateToString(today);
        time = moment();
    }

    // if(timer_clock == null || timer_clock == ''){
    //     if((deviceIds != undefined && $('#mac_'+deviceIds+' .status_wrap').attr('power')!='true') || deviceIds.split(",").length > 1){
    //         return;
    //     }
        
    //     /* timer will be initialized automatically by getting Cuttent Status */
    //     toastr.info($.i18n.prop('MIS_TEXT_INITIALIZING_P'));
    //     requestCurrentStatus(tabName, deviceIds, true, null);
    //     return;
    // }else if(timer_clock == 'null'){
    //     toastr.info($.i18n.prop('COM_TV_SID_UNSUPPORTED_VERSION'));
    //     $('#time_current_day').attr('disabled','disabled');
    //     $('#time_current_time').attr('disabled','disabled');
    //     return;
    // }

    const onRepeat = createRepeatStrings(timerConfTimerList, 'timerRepeat', 'timerManualWeekday');
    const offRepeat = createRepeatStrings(timerConfTimerList, 'timerOffRepeat', 'timerOffManualWeekday');
    const inputSources = map(inputSourceList, source => {
        return {value: source.code, title: source.text};
    });

    const onClickTimer = i => {
        const newArr = [...showTimer];
        newArr[i] = !newArr[i];
        setShowTimer(newArr);
    };

    const getTimerStr = timerWeekday => {
        if(timerWeekday === '0000000') {
            return t('TEXT_ONCE_P');
        } else if(timerWeekday === '1111111') {
            return t('TEXT_EVERYDAY_P');
        } else if(timerWeekday === '0111110') {
            return `${t('COM_TEXT_DAY_MONDAY_P')} ~ ${t('COM_TEXT_DAY_FRIDAY_P')}`;
        } else if(timerWeekday === '0111111') {
            return `${t('COM_TEXT_DAY_MONDAY_P')} ~ ${t('COM_TEXT_DAY_SATURDAY_P')}`;
        } else if(timerWeekday === '1000001') {
            return `${t('COM_TEXT_DAY_SATURDAY_P')} ~ ${t('COM_TEXT_DAY_SUNDAY_P')}`;
        } else {
            let str = ''
            for(let a = 0; a < 7; a++) {
                if(timerWeekday.charAt(a) === '1') {
                    if(str.length > 0) {
                        str += '/';
                    }
                    str += t(days[a]);
                }
            }
            return str;
        }
    };

    const onChangeTime = value => {

        const time = time24hhTo12hh(value.hour(), value.minute());
        onChange({'data-name': 'timeClockConf.clockAmpm'}, toString(time.ampm));
        onChange({'data-name': 'timeClockConf.clockH'}, toString(time.hh )); // -12
        onChange({'data-name': 'timeClockConf.clockM'}, toString(time.mm));

        // if(value.hour() >= 12) {
        //     onChange({'data-name': 'timeClockConf.clockAmpm'}, '0');
        //     onChange({'data-name': 'timeClockConf.clockH'}, toString(value.hour() )); // -12
        // } else {
        //     onChange({'data-name': 'timeClockConf.clockAmpm'}, '1');
        //     onChange({'data-name': 'timeClockConf.clockH'}, toString(value.hour()));
        // }
        // onChange({'data-name': 'timeClockConf.clockM'}, toString(value.minute()));
    };

    const onChangeHolidayStart = value => {
        const newHolidayPeriod = [...holidayPeriod];

        newHolidayPeriod[0] = value;
        if(newHolidayPeriod[0] > newHolidayPeriod[1]) {
            newHolidayPeriod[1] = value;
        }
        setHolidayPeriod(newHolidayPeriod);
    };

    const onChangeHolidayEnd = value => {
        const newHolidayPeriod = [...holidayPeriod];
        newHolidayPeriod[1] = value;
        setHolidayPeriod(newHolidayPeriod);
    };

    const onAddHoliday = () => {
        const convertFormat = (dateFormat) => {
            return dateFormat.toUpperCase().replace('YYYY-', '').replace('/YYYYY', '');
        }
        const dateFormatForMoment = convertFormat(dateFormat);
        const now = moment();
        const momentStart = moment(addStartHoliday, dateFormatForMoment);
        const momentEnd = moment(addEndHoliday, dateFormatForMoment);

        momentStart.set('year', now.get('year'));
        momentEnd.set('year', now.get('year'));

        const holidayStart = momentStart.toDate();
        const holidayEnd = momentEnd.toDate();

        if (isNaN(holidayStart.getTime()) || isNaN(holidayEnd.getTime())) {
            toastr.error(t('MESSAGE_SCHEDULE_CHECK_DATE_VALUES_P'));
            return;
        }

        if (momentStart.isAfter(momentEnd) ) {
            toastr.error(t('MESSAGE_SCHEDULE_CHECK_DATE_VALUES_P'));
            return;
        }

        if(!deviceTimeHolidayList.every(holiday => {
            const hStart = new Date(today.getFullYear(), toInteger(holiday.month1) - 1, toInteger(holiday.day1));
            const hEnd = new Date(today.getFullYear(), toInteger(holiday.month2) - 1, toInteger(holiday.day2), 23, 59, 59);
            
            if((holidayStart >= hStart && holidayStart <= hEnd) || (holidayEnd >= hStart && holidayEnd <= hEnd)) {
                return false;
            }
            return true;
        })) {
            toastr.error(t('MESSAGE_DEVICE_ALREADY_EXIST_HOLIDAY_P'));
            return;
        };

        if(((holidayEnd - holidayStart)/86400000 + 1) > 15) {
            toastr.error(t('MIS_MESSAGE_DEVICE_NOT_EXCEED_HOLIDAY_P'));
            return;
        }

        onChange({'data-name': `deviceTimeHolidayList[${deviceTimeHolidayList.length}]`}, {
            month1: holidayStart.getMonth() + 1, 
            day1: holidayStart.getDate(), 
            month2: holidayEnd.getMonth() + 1, 
            day2: holidayEnd.getDate()
        });
        onChange({'data-name': `deviceTimeHolidayHistory[${deviceTimeHolidayHistory.length}]`}, {
            holidaySeq: 0,
            month1: holidayStart.getMonth() + 1, 
            day1: holidayStart.getDate(), 
            month2: holidayEnd.getMonth() + 1, 
            day2: holidayEnd.getDate()
        });
    };

    const onDeleteHoliday = index => {
        const newHolidayList = [...deviceTimeHolidayList];
        const [deletedHoliday] = newHolidayList.splice(index, 1);
        onChange({'data-name': 'deviceTimeHolidayList'}, newHolidayList);

        if(deletedHoliday) {
            onChange({'data-name': `deviceTimeHolidayHistory[${deviceTimeHolidayHistory.length}]`}, {
                ...deletedHoliday,
                holidaySeq: 1,
            });
        }
    };

    const onChangeDateConf = dateValue => {
        onChange({'data-name': 'timeClockConf.clockY1'}, toString(Math.floor(dateValue.getFullYear() / 256)));
        onChange({'data-name': 'timeClockConf.clockY2'}, toString(dateValue.getFullYear() % 256));
        onChange({'data-name': 'timeClockConf.clockMon'}, toString(dateValue.getMonth() + 1));
        onChange({'data-name': 'timeClockConf.clockDay'}, toString(dateValue.getDate()));
    };

    const scrollRef = useRef(null);
    const handleCalendarOpen = (isOpen) => {
        if(isOpen) {
            scrollToBottom(isOpen);
        }
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }, 30);
    };

    const [addStartHoliday, setAddStartHoliday] = useState();
    const [addEndHoliday, setAddEndHoliday] = useState();

    const startDate = new Date(today.getFullYear(), 0, 1);
    const endDate = new Date(today.getFullYear(), 11, 31);

    return (
        <div className="device_edit_panel_wrap device_time_edit mt28" ref={scrollRef}>
            <div className="devicepop timer mb26">
                <h3>{t('TEXT_CLOCK_SET_P')}</h3>
                <div className="detail_view mini_p mt14 mb15">
                    <table>
                        <colgroup>
                            <col width="203px" />
                            <col width="" />
                        </colgroup>
                        <tbody>
                            {
                                timeClockConf &&
                                <>
                                    <tr>
                                        <th className={classNames({'changed': get(changed, `timeClockConf.clockY1`)})}>{t('TEXT_DATE_P')}</th>
                                        <td>
                                            {
                                                date &&
                                                <DateInput date={date} dateFormat={dateFormat} width={130} className='device_timer_date' disabled={support != true || unsupported} onChange={onChangeDateConf} />
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className={classNames({'changed': get(changed, `timeClockConf.clockH`)})}>{t('TEXT_TIME_P')}</th>
                                        <td>
                                            {
                                                time &&
                                                <TimeSelect width={130} value={time} format={toMomentTimeFormat(timeFormat)} disabled={support != true || unsupported} style={timeInputStyle} showSecond={false} onChange={onChangeTime} />
                                            }
                                        </td>
                                    </tr>
                                </>
                            }
                            {
                                !support && modelKind === 'NEW' &&
                                <tr>
                                    <td colSpan="2">
                                        <span className="warning">
                                            {t('MIS_MESSAGE_COMMON_REJECT_CURRENT_TIME_SET_P')}
                                        </span>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="devicepop timer mb26">
                <h3>{t('COM_TV_SID_ONOFF_TIMER')}</h3>
                <div className="timer_wrap mt14 mb20">
                    <table>
                        <colgroup>
                            <col width="91px" />
                            <col width="74px" />
                            <col width="139px" />
                            <col width="74px" />
                            <col width="139px" />
                            <col width="71px" />
                            <col width="108px" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{t('DID_ADMIN_URGENT_TIMER')}</th>
                                <th colSpan="2">{t('TABLE_ON_TIMER_SETTING_P')}</th>
                                <th colSpan="2">{t('TABLE_OFF_TIMER_SETTING_P')}</th>
                                <th>{t('TABLE_VOLUME_P')}</th>
                                <th>{t('COM_DID_LFD_SOURCE')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                timerConfTimerList && timerConfTimerList.length > 0 && timerConfTimerList.map((timerConf, i) => {
                                    const timer = timerConf || {};
                                    const source = inputSources.find(s => s.value == timer.timerSource);
                                    const cvtOnTime  = time12hhTo24hh(timer.timerOnH,  timer.timerOnM,  timer.timerOnAmpm );
                                    const cvtOffTime = time12hhTo24hh(timer.timerOffH, timer.timerOffM, timer.timerOffAmpm );
                                    const onTime  = moment({hour:  cvtOnTime.hh,  minute: cvtOnTime.mm});
                                    const offTime = moment({hour:  cvtOffTime.hh, minute: cvtOffTime.mm});
                                    const momentTimeFormat = toMomentTimeFormat(timeFormat);
                                    const use12Hours = is12HourFormat(timeFormat);

                                    if(modelKind !== 'NEW' && i > 0) {
                                        return null;
                                    }

                                    return (
                                        <React.Fragment key={i}>
                                            <tr className="timer_line_info" style={{display: 'table-row'}} onClick={() => !isNil(timerConf) && onClickTimer(i)}>
                                                <td>{t('DID_ADMIN_URGENT_TIMER') + (i + 1)}</td>
                                                <td className="t_info_onhourmin">
                                                    {
                                                        timer.timerOnStatus == 1 ? onTime.format(momentTimeFormat) : '-'
                                                    }
                                                </td>
                                                <td className="t_info_repeat_on">
                                                    {
                                                        timer.timerOnStatus == 1 ? getTimerStr(onRepeat[i]) : '-'
                                                    }
                                                </td>
                                                <td className="t_info_offhourmin">
                                                    {
                                                        timer.timerOffStatus == 1 ? offTime.format(momentTimeFormat) : '-'
                                                    }
                                                </td>
                                                <td className="t_info_repeat_off">
                                                    {
                                                        timer.timerOffStatus == 1 ? getTimerStr(offRepeat[i]) : '-'
                                                    }
                                                </td>
                                                <td className="t_info_volume">
                                                    {timer.timerOnStatus == 1 ? timer.timerVolume : '-'}
                                                </td>
                                                <td className="t_info_is">
                                                    {timer.timerOnStatus == 1 ? source && source.title : '-'}
                                                </td>
                                            </tr>
                                            {
                                                showTimer[i] &&
                                                <tr className="time_detail">
                                                    <td colSpan="7">
                                                        <div className="time_detail_wrap">
                                                            <table className="time_detail_table">
                                                                <colgroup>
                                                                    <col width="100px" />
                                                                    <col width="100px" />
                                                                    <col width="" />
                                                                </colgroup>
                                                                <tbody>
                                                                    <Timer type='on' 
                                                                        index={i}
                                                                        usage={timer.timerOnStatus} 
                                                                        repeat={onRepeat[i]} 
                                                                        time={onTime}
                                                                        volume={timer.timerVolume} 
                                                                        source={timer.timerSource}
                                                                        inputSources={inputSources} 
                                                                        holidayEnable={timer.timerHolidayEnable}
                                                                        changed={changed}
                                                                        onChange={onChange} 
                                                                        use12Hours={use12Hours}/>
                                                                    <Timer type='off' 
                                                                        index={i}
                                                                        usage={timer.timerOffStatus} 
                                                                        repeat={offRepeat[i]} 
                                                                        time={offTime}
                                                                        inputSources={inputSources}
                                                                        holidayEnable={timer.timerHolidayEnable}
                                                                        changed={changed}
                                                                        onChange={onChange} 
                                                                        use12Hours={use12Hours}/>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </React.Fragment>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {
                modelKind === 'NEW' &&
                <div className="devicepop timer mb26">
                    <h3>{t('TEXT_HOLIDAY_MANAGEMENT_P')}</h3>
                    <div className="detail_view mini_p mt14 mb15">
                        <table>
                            <colgroup>
                                <col width="203px"/>
                                <col width=""/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className={classNames({'changed': get(changed, `deviceTimeHolidayList`)})}>{t('DID_LAYOUTS_COMMON_HOLIDAY')}</th>
                                    <td style={{display:'flex'}}>
                                        <DateSelectInput setDate={setAddStartHoliday}/> ~ <DateSelectInput setDate={setAddEndHoliday}/>
                                        <PlusButton onClick={onAddHoliday} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <div className="device_time_holiday autoline">
                                            {
                                                deviceTimeHolidayList && deviceTimeHolidayList.length > 0 &&
                                                deviceTimeHolidayList.map((holiday, i) => {
                                                    let title = `${holiday.month1}.${holiday.day1}~${holiday.month2}.${holiday.day2}`;
                                                    if(ddMM) {
                                                        title = `${holiday.day1}.${holiday.month1}~${holiday.day2}.${holiday.month2}`;
                                                    }
                                                    return (
                                                        <Chip key={i} width={80} title={title} onDelete={() => onDeleteHoliday(i)} />
                                                    );
                                                })
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    );
};

export default EditTime;