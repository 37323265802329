import React from 'react';
import {useTranslation} from 'react-i18next';
import Moment from "react-moment";
import {isNil} from 'lodash';
import {deviceOptions, useMISOpt} from '../../components/misopt';
import DeviceRelativeTimeCell from '../../components/device/DeviceRelativeTimeCell';
import DeviceListColumnTool, {useViewColumns} from '../../components/device/DeviceListColumnTool';
import {findWithDefault, toMomentDateTimeFormat} from '../../helper';
import {useTrGroupProps} from '../../helper/tables';
import {
    APLAYER,
    FLIP,
    IPLAYER,
    LEDBOX,
    LPLAYER,
    RSPLAYER,
    SIGNAGE,
    SPLAYER,
    WPLAYER,
    SIGNAGE_CHILD,
    rulesetConstants
} from "../../constants";
import MagicInfoTable from "../../components/table/MagicInfoTable";

const defaultInfoTableViewColumns = [
    {value: 'groupName', title: 'COM_TABLE_GROUP_NAME_P', checked: true},
    {value: 'ipAddress', title: 'TABLE_IP_ADDR_P', checked: true},
    {value: 'deviceModelName', title: 'TABLE_DEVICE_MODEL_NAME_P', checked: true},
    {value: 'serialDecimal', title: 'TABLE_DEVICE_SERIAL_P', checked: true},
    {value: 'location', title: 'TABLE_LOCATION_P', checked: true},
    {value: 'screenSize', title: 'TABLE_SCREEN_SIZE_P', checked: true},
    {value: 'resolution', title: 'TABLE_RESOLUTION_P', checked: true},
    {value: 'firmwareVersion', title: 'COM_DID_LFD_FIRMWARE_VER', checked: true},
    {value: 'playerVersion', title: 'MIS_SID_SERVER_PLAYER_VERSION', checked: true},
    {value: 'osImageVersion', title: 'TABLE_OS_IMAGE_VERSION_P', checked: true},
    {value: 'ewfState', title: 'TABLE_EWF_STATE_P', checked: true},
    {value: 'createDate', title: 'TABLE_APPROVAL_DATE_P', checked: true},
];

const getTrGroupPropsForInfo = openSignageList => (state, rowInfo) => {
    if(rowInfo) {
        const original = rowInfo.row._original;
        return original.deviceType !== SIGNAGE_CHILD || openSignageList.some(el => original.deviceId.startsWith(el)) ? {
            className: 'device_tr_group'
        } : {
            style: {
                display: 'none',
            },
        };
    }
    return {};
};

const getTrProps = (checkBoxRefs, toggleRow) => (state, rowInfo) => {
    if(rowInfo && rowInfo.row) {
        return {
            onClick: e => {
                if(e.target.nodeName === 'INPUT' || e.target.nodeName === 'LABEL' || e.target.nodeName === 'BUTTON') {
                    return;
                }

                checkBoxRefs[rowInfo.index].checked = !checkBoxRefs[rowInfo.index].checked;
                toggleRow(rowInfo.index);
            }
        };
    }
    return {};
};


const dateDiff = (_date1, _date2) => {
    let diffDate_1 = _date1 instanceof Date ? _date1 :new Date(_date1);
    let diffDate_2 = _date2 instanceof Date ? _date2 :new Date(_date2);

    diffDate_1 =new Date(diffDate_1.getFullYear(), diffDate_1.getMonth()+1, diffDate_1.getDate());
    diffDate_2 =new Date(diffDate_2.getFullYear(), diffDate_2.getMonth()+1, diffDate_2.getDate());

    let diff = Math.abs(diffDate_2.getTime() - diffDate_1.getTime());
    diff = Math.ceil(diff / (1000 * 3600 * 24));
    return diff;
};



export const DeviceInfoTable = ({
                                    height,
                                    data,
                                    sorted,
                                    loading,
                                    openSignageList,
                                    renderCheckAllCell,
                                    renderCheckCell,
                                    renderNameCell,
                                    renderMACCell,
                                    onSortedChange,
                                    checkBoxRefs,
                                    toggleRow,
                                }) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
    const [viewColumns, onChangeViewColumns] = useViewColumns('deviceInfoTableViewColumns', defaultInfoTableViewColumns);

    const columns = [
        {
            accessor: "deviceId",
            show: false
        },
        {
            id: "checkbox",
            Header: renderCheckAllCell,
            Cell: renderCheckCell,
            width : 40,
            sortable: false,
            resizable: false,
        },
        {
            Header: t('TABLE_DEVICE_NAME_P'),
            accessor: "deviceName",
            width: 300,
            Cell: renderNameCell,
        },
        {
            Header: t('TABLE_MAC_ADDR_P'),
            accessor: "deviceId",
            width: 150,
            Cell: renderMACCell,
        },
        {
            Header: t('COM_TABLE_GROUP_NAME_P'),
            accessor: "groupName",
            width: 100,
            align: 'center',
            show: findWithDefault(viewColumns, col => col.value === 'groupName').checked
        },
        {
            Header: t('TABLE_IP_ADDR_P'),
            accessor: "ipAddress",
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'ipAddress').checked
        },
        {
            Header: t('TABLE_DEVICE_MODEL_NAME_P'),
            accessor: "deviceModelName",
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'deviceModelName').checked
        },
        {
            Header: t('TABLE_DEVICE_SERIAL_P'),
            accessor: "serialDecimal",
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'serialDecimal').checked
        },
        {
            Header: t('TABLE_LOCATION_P'),
            accessor: "location",
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'location').checked
        },
        {
            Header: t('TABLE_SCREEN_SIZE_P'),
            accessor: 'screenSize',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'screenSize').checked
        },
        {
            Header: t('TABLE_RESOLUTION_P'),
            accessor: 'resolution',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'resolution').checked
        },
        {
            Header: t('COM_DID_LFD_FIRMWARE_VER'),
            accessor: "firmwareVersion",
            width: 180,
            show: findWithDefault(viewColumns, col => col.value === 'firmwareVersion').checked,
            Cell: data => {
                return (
                    <div style={{overflow: 'hidden', height: '100%'}}>
                        { data.value && data.value.split(';').map(v => <div key={v}>{v}</div>) }
                    </div>
                );
            },
        },
        {
            Header: t('MIS_SID_SERVER_PLAYER_VERSION'),
            accessor: 'playerVersion',
            width: 180,
            show: findWithDefault(viewColumns, col => col.value === 'playerVersion').checked
        },
        {
            Header: t('TABLE_OS_IMAGE_VERSION_P'),
            accessor: 'osImageVersion',
            width: 140,
            show: findWithDefault(viewColumns, col => col.value === 'osImageVersion').checked
        },
        {
            Header: t('TABLE_EWF_STATE_P'),
            accessor: 'ewfState',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'ewfState').checked,
            Cell: data => `${data.value}`
        },
        {
            Header: t('TABLE_APPROVAL_DATE_P'),
            accessor: 'createDate',
            width: 140,
            show: findWithDefault(viewColumns, col => col.value === 'createDate').checked,
            Cell: data => <Moment format={dateTimeFormat}>{data.value}</Moment>
        }
    ];

    return (
        <>
            <DeviceListColumnTool selects={viewColumns} onChange={onChangeViewColumns} />
            <MagicInfoTable
                data={data}
                loading={loading}
                sorted={sorted}
                onSortedChange={onSortedChange}
                getTrGroupProps={getTrGroupPropsForInfo(openSignageList)}
                getTrProps={getTrProps(checkBoxRefs, toggleRow)}
                columns={columns}
                style={{height}}
            />
        </>
    );
};

const defaultTimeTableViewColumns = [
    {value: 'currentTime', title: 'TEXT_CLOCK_SET_P', checked: true},
    {value: 'mdcUpdateTime', title: 'MIS_TEXT_UPDATE_TIME_P', checked: true},
    {value: 'timerTimer1Table', title: 'TEXT_TIMER1_P', checked: true},
    {value: 'timerTimer2Table', title: 'TEXT_TIMER2_P', checked: true},
    {value: 'timerTimer3Table', title: 'TEXT_TIMER3_P', checked: true},
    {value: 'timerTimer4Table', title: 'MIS_TEXT_TIMER4', checked: true},
    {value: 'timerTimer5Table', title: 'MIS_TEXT_TIMER5', checked: false},
    {value: 'timerTimer6Table', title: 'MIS_TEXT_TIMER6', checked: false},
    {value: 'timerTimer7Table', title: 'MIS_TEXT_TIMER7', checked: false},
    {value: 'timerHoliday', title: 'TEXT_HOLIDAY_CNT_P', checked: false},
];

export const DeviceTimeTable = ({
                                    height,
                                    data,
                                    sorted,
                                    loading,
                                    renderCheckAllCell,
                                    renderCheckCell,
                                    renderNameCell,
                                    renderMACCell,
                                    onSortedChange,
                                    checkBoxRefs,
                                    toggleRow,
                                }) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
    const [viewColumns, onChangeViewColumns] = useViewColumns('deviceTimeTableViewColumns', defaultTimeTableViewColumns);

    const columns = [
        {
            id: "checkbox",
            Header: renderCheckAllCell,
            Cell: renderCheckCell,
            width : 40,
            sortable: false,
            resizable: false,
        },
        {
            Header: t('TABLE_DEVICE_NAME_P'),
            accessor: "deviceName",
            width: 300,
            Cell: renderNameCell,
        },
        {
            Header: t('TABLE_MAC_ADDR_P'),
            accessor: "deviceId",
            width: 150,
            Cell: renderMACCell,
        },
        {
            Header: t('TEXT_CLOCK_SET_P'),
            accessor: 'currentTime',
            width: 160,
            show: findWithDefault(viewColumns, col => col.value === 'currentTime').checked,
            Cell: data => data.value ? <Moment format={dateTimeFormat}>{data.value}</Moment> : '-'
        },
        {
            Header: t('MIS_TEXT_UPDATE_TIME_P'),
            accessor: 'mdcUpdateTime',
            width: 160,
            show: findWithDefault(viewColumns, col => col.value === 'mdcUpdateTime').checked,
            Cell: data => data.value ? <DeviceRelativeTimeCell value={data.value} /> : '-'
        },
        {
            Header: t('TEXT_TIMER1_P'),
            accessor: 'timerTimer1Table',
            width: 160,
            show: findWithDefault(viewColumns, col => col.value === 'timerTimer1Table').checked,
        },
        {
            Header: t('TEXT_TIMER2_P'),
            accessor: 'timerTimer2Table',
            width: 160,
            show: findWithDefault(viewColumns, col => col.value === 'timerTimer2Table').checked,
        },
        {
            Header: t('TEXT_TIMER3_P'),
            accessor: 'timerTimer3Table',
            width: 160,
            show: findWithDefault(viewColumns, col => col.value === 'timerTimer3Table').checked,
        },
        {
            Header: t('MIS_TEXT_TIMER4'),
            accessor: 'timerTimer4Table',
            width: 160,
            show: findWithDefault(viewColumns, col => col.value === 'timerTimer4Table').checked,
        },
        {
            Header: t('MIS_TEXT_TIMER5'),
            accessor: 'timerTimer5Table',
            width: 160,
            show: findWithDefault(viewColumns, col => col.value === 'timerTimer5Table').checked,
        },
        {
            Header: t('MIS_TEXT_TIMER6'),
            accessor: 'timerTimer6Table',
            width: 160,
            show: findWithDefault(viewColumns, col => col.value === 'timerTimer6Table').checked,
        },
        {
            Header: t('MIS_TEXT_TIMER7'),
            accessor: 'timerTimer7Table',
            width: 160,
            show: findWithDefault(viewColumns, col => col.value === 'timerTimer7Table').checked,
        },
        {
            Header: t('TEXT_HOLIDAY_CNT_P'),
            accessor: 'timerHoliday',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'timerHoliday').checked,
        },
    ];

    const [getTrGroupPropsType1] = useTrGroupProps('device_tr_group');

    return (
        <>
            <DeviceListColumnTool selects={viewColumns} onChange={onChangeViewColumns} />
            <MagicInfoTable
                data={data}
                loading={loading}
                sorted={sorted}
                onSortedChange={onSortedChange}
                getTrGroupProps={getTrGroupPropsType1}
                getTrProps={getTrProps(checkBoxRefs, toggleRow)}
                columns={columns}
                style={{height}}
            />
        </>
    );
};

const defaultSetupTableViewColumns = [
    {value: 'magicinfoServerUrl', title: 'TABLE_MAGICINFO_SERVER_URL_P', checked: true},
    {value: 'proxySetting', title: 'COM_TEXT_PROXY_SETTING_P', checked: true},
    {value: 'tunnelingServer', title: 'TABLE_TUNNELING_SERVER_P', checked: true},
    {value: 'triggerInterval', title: 'TABLE_TRIGGER_INTERVAL_P', checked: true},
    {value: 'monitoringInterval', title: 'TABLE_MONITORING_INTERVAL_P', checked: true},
    {value: 'connectionLimitTime', title: 'TEXT_CONNECTION_LIMIT_TIME_P', checked: true},
    {value: 'ftpConnectMode', title: 'TABLE_FTP_CONNECT_MODE_P', checked: false},
    {value: 'repositoryPath', title: 'MIS_SID_20_STORAGE_PATH', checked: false},
    {value: 'mntFolderPath', title: 'TEXT_MANAGEMENT_FOLDER_PATH_P', checked: false},
    {value: 'timeZoneIndex', title: 'COM_TABLE_TIME_ZONE_INDEX_P', checked: false},
    {value: 'dayLightSaving', title: 'TABLE_DAY_LIGHT_SAVING_P', checked: true},
    {value: 'autoTimeSetting', title: 'TABLE_AUTO_TIME_SETTING_P', checked: false},
    {value: 'onTimerSetting', title: 'TABLE_ON_TIMER_SETTING_P', checked: false},
    {value: 'offTimerSetting', title: 'TABLE_OFF_TIMER_SETTING_P', checked: false},
    {value: 'screenCaptureInterval', title: 'TABLE_SCREEN_CAPTURE_INTERVAL_P', checked: false},
    {value: 'systemRestartInterval', title: 'TEXT_SYSTEM_RESTART_INTERVAL_P', checked: true},
    {value: 'bgColor', title: 'COM_DID_ADMIN_DEVICE_FBASIC_BACKCOLOR', checked: false},
    // {value: 'autoTimeSetting', title: 'COM_DID_ADMIN_DEVICE_FBASIC_BACKCOLOR', checked: false},
    {value: 'logLevel', title: 'TEXT_LOG_MANAGEMENT_P', checked: false},
    {value: 'playRecordTerm', title: 'TEXT_PROOF_OF_PLAY_MANAGEMENT_P', checked: false},
    {value: 'contentTerm', title: 'TEXT_CONTENT_MANAGEMENT_P', checked: false},
    {value: 'screenRotation', title: 'TEXT_SCREEN_ROTATION_P', checked: false},
    {value: 'tagList', title: 'MIS_SID_MEDIA_TAG', checked: false},
    {value: 'expirationDate', title: 'COM_EXPIRATION_DATE_KR_DATE', checked: false},
    {value: 'edgeServer', title: 'MIS_SID_EDGE_SERVER', checked: false},
    {value: 'contentDownloadProtocol', title: 'MIS_SID_CONTENT_DOWNLOAD_PROTOCOL', checked: false},
    {value: 'smartDownload', title: 'MIS_SID_SMART_DOWNLOAD', checked: false},
];


export const DeviceSetupTable = ({
                                     height,
                                     data,
                                     sorted,
                                     loading,
                                     openSignageList,
                                     renderCheckAllCell,
                                     renderCheckCell,
                                     renderNameCell,
                                     renderMACCell,
                                     onSortedChange,
                                     checkBoxRefs,
                                     toggleRow,
                                 }) => {
    const {t} = useTranslation();
    const [viewColumns, onChangeViewColumns] = useViewColumns('deviceSetupTableViewColumns', defaultSetupTableViewColumns);
    const {misopt: {user: {dateFormat}}} = useMISOpt();

    const columns = [
        {
            id: "checkbox",
            Header: renderCheckAllCell,
            Cell: renderCheckCell,
            width : 40,
            sortable: false,
            resizable: false,
        },
        {
            Header: t('TABLE_DEVICE_NAME_P'),
            accessor: "deviceName",
            width: 300,
            Cell: renderNameCell,
        },
        {
            Header: t('TABLE_MAC_ADDR_P'),
            accessor: "deviceId",
            width: 150,
            Cell: renderMACCell,
        },
        {
            Header: t('TABLE_MAGICINFO_SERVER_URL_P'),
            accessor: 'magicinfoServerUrl',
            width: 200,
            show: findWithDefault(viewColumns, col => col.value === 'magicinfoServerUrl').checked,
        },
        {
            Header: t('COM_TEXT_PROXY_SETTING_P'),
            accessor: 'proxySetting',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'proxySetting').checked,
            Cell: ({original}) => {
                let proxyString;
                if(original.proxySetting && original.proxySetting !== 'null' && original.proxySetting !== 'Do not use'){
                    proxyString = original.proxySetting;
                } else {
                    proxyString = t('COM_SETUP_NEW_STRING17_P');
                }
                return (<div>{proxyString}</div>)
            }
        },
        {
            Header: t('TABLE_TUNNELING_SERVER_P'),
            accessor: 'tunnelingServer',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'tunnelingServer').checked,
        },        
        {
            Header: t('TABLE_TRIGGER_INTERVAL_P'),
            accessor: 'triggerInterval',
            width: 130,
            show: findWithDefault(viewColumns, col => col.value === 'triggerInterval').checked,
            Cell: ({original}) => <div>{(original.triggerInterval && original.triggerInterval !== 'null') ? original.triggerInterval + t("COM_SID_SEC") : ''}</div>
        },
        {
            Header: t('TABLE_MONITORING_INTERVAL_P'),
            accessor: 'monitoringInterval',
            width: 130,
            show: findWithDefault(viewColumns, col => col.value === 'monitoringInterval').checked,
            Cell: ({original}) => <div>{(original.monitoringInterval && original.monitoringInterval !== 'null') ? original.monitoringInterval + t("COM_SID_MIN") : ''}</div>

        },
        {
            Header: t('TEXT_CONNECTION_LIMIT_TIME_P'),
            accessor: 'connectionLimitTime',
            width: 130,
            show: findWithDefault(viewColumns, col => col.value === 'connectionLimitTime').checked,
            Cell: ({original}) => <div>{(original.connectionLimitTime && original.connectionLimitTime !== 'null') ? original.connectionLimitTime + t("COM_SID_SEC") : ''}</div>
        },
        {
            Header: t('TABLE_FTP_CONNECT_MODE_P'),
            accessor: 'ftpConnectMode',
            width: 120,
            show: findWithDefault(viewColumns, col => col.value === 'ftpConnectMode').checked,
        },
        {
            Header: t('MIS_SID_20_STORAGE_PATH'),
            accessor: 'repositoryPath',
            width: 150,
            show: findWithDefault(viewColumns, col => col.value === 'repositoryPath').checked,
        },
        {
            Header: t('TEXT_MANAGEMENT_FOLDER_PATH_P'),
            accessor: 'mntFolderPath',
            width: 150,
            show: findWithDefault(viewColumns, col => col.value === 'mntFolderPath').checked,
        },
        {
            Header: t('COM_TABLE_TIME_ZONE_INDEX_P'),
            accessor: 'timeZoneIndex',
            width: 140,
            show: findWithDefault(viewColumns, col => col.value === 'timeZoneIndex').checked,
        },
        {
            Header: t('TABLE_DAY_LIGHT_SAVING_P'),
            accessor: 'dayLightSaving',
            width: 130,
            show: findWithDefault(viewColumns, col => col.value === 'dayLightSaving').checked,
        },
        {
            Header: t('TABLE_AUTO_TIME_SETTING_P'),
            accessor: 'autoTimeSetting',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'autoTimeSetting').checked,
        },
        {
            Header: t('TABLE_ON_TIMER_SETTING_P'),
            accessor: 'onTimerSetting',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'onTimerSetting').checked,
        },
        {
            Header: t('TABLE_OFF_TIMER_SETTING_P'),
            accessor: 'offTimerSetting',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'offTimerSetting').checked,
        },
        {
            Header: t('TABLE_SCREEN_CAPTURE_INTERVAL_P'),
            accessor: 'screenCaptureInterval',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'screenCaptureInterval').checked,
            Cell: ({original}) => <div>{(original.screenCaptureInterval && original.screenCaptureInterval !== 'null') ? original.screenCaptureInterval + t("COM_SID_MIN") : ''}</div>
        },
        {
            Header: t('TEXT_SYSTEM_RESTART_INTERVAL_P'),
            accessor: 'systemRestartInterval',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'systemRestartInterval').checked,
            Cell : ({original}) => {
                if(!original.systemRestartInterval){
                    return (<div></div>);
                }
                const arr = original.systemRestartInterval.split(' ');
                let str = "";

                if (arr !== undefined && arr.length == 1) {
                    str = arr[0];
                } else if (arr !== undefined && arr.length == 2) {
                    const days = arr[0].split(";")
                    days.forEach( (el) => {
                        if (el) {
                            str = str + t( (rulesetConstants.CONDITION_DATE_WEEK_TYPES[el.toLowerCase()]).sid) + " ";
                        }
                    })
                    str = str + arr[1];
                }

                return (<div>{str}</div>);
            }
        },
        {
            Header: t('COM_DID_ADMIN_DEVICE_FBASIC_BACKCOLOR'),
            accessor: 'bgColor',
            width: 80,
            show: findWithDefault(viewColumns, col => col.value === 'bgColor').checked,
            Cell: data => <span style={{display: 'inline-block', backgroundColor: `#${data.value}`, width: 40, height: 20}}>&nbsp;</span>
        },
        // {
        //     Header: t('COM_DID_ADMIN_DEVICE_FBASIC_BACKCOLOR'),
        //     accessor: 'dayLightSaving',
        //     show: findWithDefault(viewColumns, col => col.value === 'dayLightSaving').checked,
        // },
        {
            Header: t('TEXT_LOG_MANAGEMENT_P'),
            accessor: 'logLevel',
            width: 150,
            show: findWithDefault(viewColumns, col => col.value === 'logLevel').checked,
            Cell: ({original}) => {
                return (
                    <>
                        <div>{`${t('TEXT_LOG_LEVEL_P')} : ${original.logLevel ? original.logLevel : ''}`}</div>
                        <div>{`${t('TEXT_VALIDITY_PERIOD_P')} : ${original.logTerm ? original.logTerm : ''}`} {t('TEXT_DAY_P')}</div>
                        <div>{`${t('TEXT_VALIDITY_SIZE_P')} : ${original.logSize ? original.logSize : ''}`} (MB)</div>
                    </>
                );
            }
        },
        {
            Header: t('TEXT_PROOF_OF_PLAY_MANAGEMENT_P'),
            accessor: 'playRecordTerm',
            width: 150,
            show: findWithDefault(viewColumns, col => col.value === 'playRecordTerm').checked,
            Cell: ({original}) => {
                return (
                    <>
                        <div>{`${t('TEXT_VALIDITY_PERIOD_P')} : ${original.playRecordTerm}`} {t('TEXT_DAY_P')}</div>
                        <div>{`${t('TEXT_VALIDITY_SIZE_P')} : ${original.playRecordSize}`} (MB)</div>
                    </>
                );
            }
        },

        {
            Header: t('TEXT_CONTENT_MANAGEMENT_P'),
            accessor: 'contentTerm',
            width: 150,
            show: findWithDefault(viewColumns, col => col.value === 'contentTerm').checked,
            Cell: ({original}) => {
                return (
                    <>
                        <div>{`${t('TEXT_VALIDITY_PERIOD_P')} : ${original.contentTerm ? original.contentTerm : ''}`} {t('TEXT_DAY_P')}</div>
                        <div>{`${t('TEXT_VALIDITY_SIZE_P')} : ${original.contentSize ? original.contentSize : ''}`} (MB)</div>
                    </>
                );
            }
        },
        {
            Header: t('TEXT_SCREEN_ROTATION_P'),
            accessor: 'screenRotation',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'screenRotation').checked,
            Cell: ({original}) => <div>{(original.screenRotation && original.screenRotation !== 'null') ? original.screenRotation + "°" : ''}</div>
        },
        {
            Header: t('MIS_SID_MEDIA_TAG'),
            accessor: 'tagList',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'tagList').checked,
            Cell: ({original}) => original.tagList && original.tagList.length > 0 ? original.tagList.join(',') : ''
        },
        {
            Header: t('COM_EXPIRATION_DATE_KR_DATE'),
            accessor: 'expirationDate',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'expirationDate').checked,
            Cell: props => expirationDate(props),
        },
        {
            Header: t('MIS_SID_EDGE_SERVER'),
            accessor: 'edgeServer',
            width: 120,
            show: findWithDefault(viewColumns, col => col.value === 'edgeServer').checked,
        },
        {
            Header: t('MIS_SID_CONTENT_DOWNLOAD_PROTOCOL'),
            accessor: 'contentDownloadProtocol',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'contentDownloadProtocol').checked,
            Cell: ({original}) => <div>{(original.contentDownloadProtocol && original.contentDownloadProtocol !== 'null') ? original.contentDownloadProtocol : '-'}</div>
        },
        {
            Header: t('MIS_SID_SMART_DOWNLOAD'),
            accessor: 'smartDownload',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'smartDownload').checked,
            Cell: ({original}) => <div>{(original.smartDownload && original.smartDownload !== 'null') ? original.smartDownload : '-'}</div>
        },
    ];

    const expirationDate = ({original}) => {
        if(original.expirationDate && original.expirationDate !== 'null'){
            const expDate = new Date(original.expirationDate);
            const days = dateDiff(expDate, new Date());
            let color = '#000000';
            if(days <8){
                color = '#FF0000';
            }
            return (<span style={{color:color}}>{days}{t('LIST_TITLE_DAY')}
                <div>(<Moment format={dateFormat.toUpperCase()}>{original.expirationDate}</Moment>)</div></span>);
        } else {
            return(<div>∞</div>)
        }
    }

    const [getTrGroupPropsType1] = useTrGroupProps('device_tr_group');
    return (
        <>
            <DeviceListColumnTool selects={viewColumns} onChange={onChangeViewColumns} />
            <MagicInfoTable
                data={data}
                loading={loading}
                sorted={sorted}
                onSortedChange={onSortedChange}
                getTrGroupProps={getTrGroupPropsForInfo(openSignageList)}
                getTrProps={getTrProps(checkBoxRefs, toggleRow)}
                columns={columns}
                style={{height}}
            />
        </>
    );
};





const defaultDisplayTableViewColumns = [
    {value: 'basicVolume', title: 'TABLE_VOLUME_P', checked: true},
    {value: 'basicMute', title: 'TABLE_MUTE_P', checked: true},
    {value: 'basicSource', title: 'COM_DID_LFD_SOURCE', checked: true},
    {value: 'basicPanelStatus', title: 'TABLE_PANEL_STATUS_P', checked: true},
    {value: 'diagnosisMonitorTemperature', title: 'COM_IDS_TXT_CURRENT_TEMPERATURE', checked: true},
    {value: 'diagnosisAlarmTemperature', title: 'COM_IDS_TXT_TEMPERATURE_CONTROL', checked: true},
    {value: 'miscOsd', title: 'DID_LFD_OSD', checked: true},
];

export const DeviceDisplayTable = ({
                                       height,
                                       data,
                                       sorted,
                                       loading,
                                       openSignageList,
                                       renderCheckAllCell,
                                       renderCheckCell,
                                       renderNameCell,
                                       renderMACCell,
                                       onSortedChange,
                                       checkBoxRefs,
                                       toggleRow,
                                   }) => {
    const {t} = useTranslation();
    const [viewColumns, onChangeViewColumns] = useViewColumns('deviceDisplayTableViewColumns', defaultDisplayTableViewColumns);

    const columns = [
        {
            id: "checkbox",
            Header: renderCheckAllCell,
            Cell: renderCheckCell,
            width : 40,
            sortable: false,
            resizable: false,
        },
        {
            Header: t('TABLE_DEVICE_NAME_P'),
            accessor: "deviceName",
            width: 300,
            Cell: renderNameCell,
        },
        {
            Header: t('TABLE_MAC_ADDR_P'),
            accessor: "deviceId",
            width: 200,
            Cell: renderMACCell,
        },
        {
            Header: t('TABLE_VOLUME_P'),
            accessor: 'basicVolume',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'basicVolume').checked,
            Cell: data => {
                if(data.value < 0) {
                    return t('TEXT_FAIL_P');
                }
                return data.value || '0';
            },
        },
        {
            Header: t('TABLE_MUTE_P'),
            accessor: 'basicMute',
            width: 100,
            show: findWithDefault(viewColumns, col => col.value === 'basicMute').checked,
            Cell: data => {
                const found = deviceOptions.muteOptions.find(m => m.value == data.value);
                if(found) {
                    return t(found.title) || '';
                }
                return t('TEXT_FAIL_P');
            },
        },
        {
            Header: t('COM_DID_LFD_SOURCE'),
            accessor: 'basicSource',
            width: 150,
            show: findWithDefault(viewColumns, col => col.value === 'basicSource').checked,
            Cell: data => {
                const found = deviceOptions.inputSource.find(m => m.value == data.value);
                if(found) {
                    return found.title || '';
                }
                return t('TEXT_FAIL_P');
            }
        },
        {
            Header: t('TABLE_PANEL_STATUS_P'),
            accessor: 'basicPanelStatus',
            width: 150,
            show: findWithDefault(viewColumns, col => col.value === 'basicPanelStatus').checked,
            Cell: data => {
                const found = deviceOptions.panelStatus.find(m => m.value == data.value);
                if(found) {
                    return t(found.title) || '';
                }
                return t('TEXT_FAIL_P');
            },
        },
        {
            Header: t('COM_IDS_TXT_CURRENT_TEMPERATURE'),
            accessor: 'diagnosisMonitorTemperature',
            width: 150,
            show: findWithDefault(viewColumns, col => col.value === 'diagnosisMonitorTemperature').checked,
        },
        {
            Header: t('COM_IDS_TXT_TEMPERATURE_CONTROL'),
            accessor: 'diagnosisAlarmTemperature',
            width: 150,
            show: findWithDefault(viewColumns, col => col.value === 'diagnosisAlarmTemperature').checked,
        },
        {
            Header: t('DID_LFD_OSD'),
            accessor: 'miscOsd',
            width: 120,
            show: findWithDefault(viewColumns, col => col.value === 'miscOsd').checked,
            Cell: data => data.value == 1 ? t('COM_TEXT_SETTING_P') : t('TEXT_DISABLE_P')
        },
    ];

    const [getTrGroupPropsType1] = useTrGroupProps('device_tr_group');
    return (
        <>
            <DeviceListColumnTool selects={viewColumns} onChange={onChangeViewColumns} />
            <MagicInfoTable
                data={data}
                loading={loading}
                sorted={sorted}
                onSortedChange={onSortedChange}
                getTrGroupProps={getTrGroupPropsForInfo(openSignageList)}
                getTrProps={getTrProps(checkBoxRefs, toggleRow)}
                columns={columns}
                style={{height}}
            />
        </>
    );
};

const defaultSecurityTableViewColumns = [
    {value: 'mntSafetyLock', title: 'COM_IDS_TXT_DIRECT_SAFETY_LOCK', checked: true},
    {value: 'miscRemocon', title: 'MIS_SID_REMOTE_CONTROL_LOCK', checked: true},
    {value: 'miscPanelLock', title: 'COM_IDS_TXT_BUTTON_LOCK', checked: true},
    {value: 'miscAllLock', title: 'TABLE_ALL_KEY_LOCK_P', checked: true},
    {value: 'miscBlockUsbPort', title: 'MIS_SID_USB_LOCK', checked: true},
    {value: 'miscBlockNetworkConnection', title: 'MIS_SID_NW_LOCK', checked: true},
    {value: 'miscServerNetworkSetting', title: 'MIS_SID_SERVER_NW_SETTINGS_LOCK', checked: true},
    {value: 'bluetoothLock', title: 'MIS_SID_BLUETOOTH_LOCK', checked: true},
    {value: 'wifiLock', title: 'MIS_SID_WIFI_LOCK', checked: true},
];

export const DeviceSecurityTable = ({
                                        height,
                                        data,
                                        sorted,
                                        loading,
                                        renderCheckAllCell,
                                        renderCheckCell,
                                        renderNameCell,
                                        renderMACCell,
                                        onSortedChange,
                                        checkBoxRefs,
                                        toggleRow,
                                    }) => {
    const {t} = useTranslation();
    const [viewColumns, onChangeViewColumns] = useViewColumns('deviceSecurityTableViewColumns', defaultSecurityTableViewColumns);

    const renderLockCell = data => {
        if(!isNil(data.value)) {
            return data.value == '0' ? t('DID_LFD_UNLOCK') : t('BUTTON_CHECK_OUT');
        }
        return '-';
    };

    const columns = [
        {
            id: "checkbox",
            Header: renderCheckAllCell,
            Cell: renderCheckCell,
            width : 40,
            sortable: false,
            resizable: false,
        },
        {
            Header: t('TABLE_DEVICE_NAME_P'),
            accessor: "deviceName",
            width: 300,
            Cell: renderNameCell,
        },
        {
            Header: t('TABLE_MAC_ADDR_P'),
            accessor: "deviceId",
            width: 150,
            Cell: renderMACCell,
        },
        {
            Header: t('COM_IDS_TXT_DIRECT_SAFETY_LOCK'),
            accessor: 'mntSafetyLock',
            width: 140,
            show: findWithDefault(viewColumns, col => col.value === 'mntSafetyLock').checked,
            Cell: renderLockCell
        },
        {
            Header: t('MIS_SID_REMOTE_CONTROL_LOCK'),
            accessor: 'miscRemocon',
            width: 140,
            show: findWithDefault(viewColumns, col => col.value === 'miscRemocon').checked,
            Cell: renderLockCell
        },
        {
            Header: t('COM_IDS_TXT_BUTTON_LOCK'),
            accessor: 'miscPanelLock',
            width: 140,
            show: findWithDefault(viewColumns, col => col.value === 'miscPanelLock').checked,
            Cell: renderLockCell
        },
        {
            Header: t('TABLE_ALL_KEY_LOCK_P'),
            accessor: 'miscAllLock',
            width: 140,
            show: findWithDefault(viewColumns, col => col.value === 'miscAllLock').checked,
            Cell: renderLockCell
        },
        {
            Header: t('MIS_SID_USB_LOCK'),
            accessor: 'miscBlockUsbPort',
            width: 140,
            show: findWithDefault(viewColumns, col => col.value === 'miscBlockUsbPort').checked,
            Cell: data => data.value === 'OFF' ? t('BUTTON_CHECK_OUT') : (data.value === 'ON' ? t('DID_LFD_UNLOCK') : data.value || '-')
        },
        {
            Header: t('MIS_SID_NW_LOCK'),
            accessor: 'miscBlockNetworkConnection',
            width: 140,
            show: findWithDefault(viewColumns, col => col.value === 'miscBlockNetworkConnection').checked,
            Cell: data => data.value === 'OFF' ? t('BUTTON_CHECK_OUT') : (data.value === 'ON' ? t('DID_LFD_UNLOCK') : data.value || '-')
        },
        {
            Header: t('MIS_SID_SERVER_NW_SETTINGS_LOCK'),
            accessor: 'miscServerNetworkSetting',
            width: 140,
            show: findWithDefault(viewColumns, col => col.value === 'miscServerNetworkSetting').checked,
            Cell: renderLockCell
        },
        {
            Header: t('MIS_SID_BLUETOOTH_LOCK'),
            accessor: 'bluetoothLock',
            width: 140,
            show: findWithDefault(viewColumns, col => col.value === 'bluetoothLock').checked,
            Cell: renderLockCell
        },
        {
            Header: t('MIS_SID_WIFI_LOCK'),
            accessor: 'wifiLock',
            width: 140,
            show: findWithDefault(viewColumns, col => col.value === 'wifiLock').checked,
            Cell: renderLockCell
        },
    ];
    const [getTrGroupPropsType1] = useTrGroupProps('device_tr_group');
    return (
        <>
            <DeviceListColumnTool selects={viewColumns} onChange={onChangeViewColumns} />
            <MagicInfoTable
                data={data}
                loading={loading}
                sorted={sorted}
                onSortedChange={onSortedChange}
                getTrGroupProps={getTrGroupPropsType1}
                getTrProps={getTrProps(checkBoxRefs, toggleRow)}
                columns={columns}
                style={{height}}
            />
        </>
    );
};
