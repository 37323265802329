import React, {Component} from "react";
import './DashboardPanel.css'
import './PlaylistSummary.css'
import {SortableHandle} from "react-sortable-hoc";
import {connect} from "react-redux";
import {menuAction} from "../../actions";
import {withTranslation} from "react-i18next";
import {dashboardService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import {MoonLoader} from "react-spinners";
import {getErrorMessage} from "../../helper/responseHandler";

class PlaylistSummary extends Component {

    state = {
        playlist: {},
        loading: true,
    };

    constructor(props) {
        super(props);
        this.onAllPlaylistClickEvent = this.onAllPlaylistClickEvent.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
    }

    componentDidMount() {
        this.fetchGetPlayListInfo();
    }

    onAllPlaylistClickEvent() {
        const {moveTab, removeTab, t} = this.props;
        removeTab('PLAYLIST');
        moveTab({id: 'PLAYLIST', title : t("TEXT_TITLE_PLAYLIST_P"), active: true, close: true});
    }

    fetchGetPlayListInfo = () => {
        this.setState({loading: true,});
        dashboardService.fetchPlaylistInfo().then(res => {
            this.setState({
                playlist: res.items,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally(() => {this.setState({loading: false});});
    };

    render() {
        let {t} = this.props;
        let {playlist, loading} = this.state;
        return (
            <div className='dashboard-widget'>
                {this.renderHeader()}
                <div className='dashboard-widget-body'>
                    {loading && <MoonLoader css={`position: absolute;top: calc(50% - 40px);left: calc(50% - 40px);`} color="#6e78c8"/>}
                    {!loading &&
                    <div className='playlist-summary'>
                        <div className='playlist-summary-all' onClick={this.onAllPlaylistClickEvent}>
                            {playlist.allListCnt}<span>{t("TEXT_TITLE_ALL_PLAYLIST_P")}</span>
                        </div>
                        <div className='playlist-summary-running'>
                            {playlist.runningListCnt}<span>{t("MIS_SID_20_RUNNING_PLAYLISTS")}</span>
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    };

    renderHeader() {
        let {id, isEditMode, t} = this.props;
        const DragHandle = SortableHandle(() =>
            <div className='title'>{t("MIS_SID_20_PLAYLIST_SUMMARY")}</div>
        );
        return (
            <div className='dashboard-widget-header'>
                <DragHandle />
                {
                    isEditMode && <div title="Close" className="remove" onClick={() => this.props.onRemoveLayoutClickEvent(id)}></div>
                }
            </div>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        removeTab: (id) => dispatch(menuAction.removeTab(id)),
        moveTab: (tab, id, childrenId) => dispatch(menuAction.moveTab(tab, id, childrenId)),
    })
)(withTranslation()(PlaylistSummary));