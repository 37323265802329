import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import translationEng from "./en_US";
import translationKor from "./ko_KR";
import translationAr from "./ar_AE";
import translationDe from "./de_DE";
import translationEs from "./es_ES";
import translationFa from "./fa_IR";
import translationFr from "./fr_FR";
import translationIt from "./it_IT";
import translationJa from "./ja_JP";
import translationPt from "./pt_PT";
import translationRu from "./ru_RU";
import translationSv from "./sv_SE";
import translationTr from "./tr_TR";
import translationZhCn from "./zh_CN";
import translationZhTw from "./zh_TW";
import translationPl from "./pl_PL";
import translationVi from "./vi_VN";
import translationFi from "./fi_FI";

import {withCookies} from 'react-cookie';

const resources = {
    en: {
        translation: translationEng
    },
    ko: {
        translation: translationKor
    },
    ar: {
        translation: translationAr
    },
    de: {
        translation: translationDe
    },
    es: {
        translation: translationEs
    },
    fa: {
        translation: translationFa
    },
    fr: {
        translation: translationFr
    },
    it: {
        translation: translationIt
    },
    ja: {
        translation: translationJa
    },
    pt: {
        translation: translationPt
    },
    ru: {
        translation: translationRu
    },
    sv: {
        translation: translationSv
    },
    tr: {
        translation: translationTr
    },
    zh_CN: {
        translation: translationZhCn
    },
    zh_TW: {
        translation: translationZhTw
    },
    pl: {
        translation: translationPl
    },
    vi: {
        translation: translationVi
    },
    fi: {
        translation: translationFi
    }
}
const detectionOptions = {
    order: ['querystring','localStorage'],
    lookupQuerystring: 'locale',
    lookupLocalStorage: 'MagicInfoPremiumLanguage',
    lookupFromPathIndex: 0
}


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: detectionOptions,
        resources: resources
    })

export default withCookies(i18n);
