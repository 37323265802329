import React from 'react';
import './Button.css';
import SelectBox from "../selectbox/SelectBox";




const renderButton = (id, onClick, onMouseDown, onMouseUp, title, style, disabled) => {
    return <button id={id} className="base noline" disabled={disabled} onClick={onClick} onMouseDown={onMouseDown} onMouseUp={onMouseUp} style={style}>{title}</button>
}

const Button = ({selects, id, title, disabled, onClick, onMouseDown, onMouseUp, style, width = 100, zIndex}) => {
    let selectButton = false;
    if (selects != null && selects.length > 0) {
        selectButton = true;
    }
    return (
        selectButton ? <SelectBox key={id} selects={selects} id={id} title={title} type={"SUB"} width={width} zIndex={zIndex}/> : renderButton(id, onClick, onMouseDown, onMouseUp, title, style, disabled)
    )
}
export default Button;