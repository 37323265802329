import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import MISDialog from "../../MISDialog";
import {popupAction} from "../../../../actions/popup.action";
import './ContentScheduleProgressPopup.css';
import ProgressBar from "../../../progressbar/ProgressBar";
import {scheduleService, userService} from "../../../../services";
import {useInterval} from "../../../../helper";
import classNames from 'classnames';
import {commonConstants} from "../../../../constants";
import {Loading} from "../../../loading/Loading";
import {updatePublishStatus} from "../../../../helper/schedule/scheduleUtils";


const ContentScheduleProgressPopup = ({program}) => {

    const interval = 1500;
    const [percentage, setPercentage] = useState(0);
    const [isPolling, setIsPolling] = useState(false);
    const [loading, setLoading] = useState(true);
    const [devices, setDevices] = useState([]);
    const [showDevices, setShowDevices] = useState(true);
    const [style, setStyle] = useState({
        width: 700,
        height: 450
    })
    const [completeDeviceCount, setCompleteDeviceCount] = useState(program.publishedDeviceUntilNow ? program.publishedDeviceUntilNow : program.completeDeviceCount);

    const totalDeviceCount = program.deviceCount ? program.deviceCount : program.publishTotalCount;

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [count, setCount] = useState(0);

    useInterval(() => {
        startPolling();
    }, percentage > 99 ? null : interval);

    const onClose = () => {
        dispatch(popupAction.closePopup(`CONTENT_SCHEDULE_PROGRESS_${program.programId}`))
    }

    const startPolling = () => {
        setIsPolling(true);
        runPolling();
    }

    const runPolling = () => {
        fetchContentScheduleProgressInfo();
    }

    const fetchContentScheduleProgressInfo = () => {

        scheduleService.fetchContentScheduleProgressById(program.programId).then(
            res=> {
                setCount(count+1);
                if (res) {
                    const percent = parseInt(res.items.completePercentage, 10) > 99 ? 100  : parseInt(res.items.completePercentage,10);
                    setPercentage(percent);
                    setDevices(res.items.deviceStatuses);
                    setCompleteDeviceCount(res.items.completeDeviceCount);
                    updatePublishStatus(program.programId, percent, res.items.completeDeviceCount, res.items.totalDeviceCount);
                } else {
                    return Promise.reject({status: res.error, res});
                }
            }
        );
    }

    const openDeviceView = (deviceId) => {
        userService.fetchUserLoginInformation().then(
            res => {
                if(res.status === 'Success') {
                    dispatch(popupAction.openDetailView({
                        type: commonConstants.COMMON_DETAIL_VIEW,
                        viewType: 'DEVICE',
                        id: deviceId,
                        hasDeviceSecurity: res.items.hasDeviceSecurity
                    }));
                }
            }
        ).catch(error => {
            console.log(error);
        });
    }

    const showDeviceInfo = () => {
        if (showDevices) {
            setStyle({...style, height: 150});
        } else {
            setStyle({...style, height: 450});
        }
        setShowDevices(!showDevices);
    }

    useEffect(()=> {
        if (percentage >= 100) {
            setIsPolling(false);
        }
    }, [percentage])

    useEffect(()=> {
        if (count  === 1) {
            setLoading(false);
        }
    }, [count])


    const dialogProps ={title: t("COM_ID_SCHEDULE_SCHEDULE_PUBLISH"), closeOnEscape : true, modal:true, onClose:()=> onClose()};
    
    return (
        <MISDialog
            dialog={dialogProps}
            width={style.width}
            height={style.height}
            classname={"schedule_publish_popup"}
        >
            {
                loading &&
                    <div style={{display: 'flex', justifyContent: 'center'}}><Loading /></div>
            }
            {
                !loading &&
                <div className={'content_schedule_progress_popup'}>
                    <div className="popup_contents">
                        <div>
                            <table>
                                <colgroup>
                                    <col width="184px"/>
                                    <col width=""/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>{t("TEXT_SCHEDULE_NAME_P")}</th>
                                    <td>{program.programName}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="uploading mb19" style={{marginTop: 45}}>
                            <span className={"total_status_txt"}>{t("MIS_SID_TOTAL_STATUS")}</span>
                            { totalDeviceCount &&
                                <span className={"file_total"}>{completeDeviceCount}/{totalDeviceCount} {t("TEXT_TITLE_DEVICE_P")}</span>
                            }
                            <ProgressBar width={'100%'} height={20} background={'#e8e8e8'} percentage={percentage}
                                         fillerColor={'#4be48c'} useLabel={true}/>

                        </div>
                    </div>
                    <div className="clearfix noline">
                        <div className="float_l">
                            <button className="detail_btn on" id="detail" onClick={showDeviceInfo}>
                                <span></span>{t('BUTTON_DETAIL_P')}</button>
                        </div>
                    </div>
                    {
                        showDevices &&
                        <div className="detail_view pb25" id="detailList">
                            <div className="pt31">
                                <div className="pop_list content_upload" style={{height: 201, overflowX: 'auto'}}>
                                    <table className={'content_schedule_progress_device_table'}>
                                        <colgroup>
                                            <col width="187px"/>
                                            <col width="161px"/>
                                            <col width="0"/>
                                            <col width="0"/>
                                            <col width="0"/>
                                        </colgroup>
                                        <thead>
                                        <tr>
                                            <th style={{width: 169}}>{t("TEXT_DEVICE_GROUP_P")}</th>
                                            <th style={{width: 140}}>{t("TABLE_DEVICE_NAME_P")}</th>
                                            <th colSpan="3" className="brnone" style={{width: 292}}>{t("TEXT_STATUS_P")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            devices !== undefined && devices.length > 0 &&
                                            devices.map(
                                                device =>{

                                                    const percentage = device.completeCount === 0 ? 0 : Math.round(device.completeCount / device.totalCount * 100);
                                                    return (
                                                        <tr>
                                                            <td className="device_group_name">{device.deviceGroupName}</td>
                                                            <td className="device_name">
                                                                <div style={{display: 'flex'}}>
                                                            <span
                                                                className={classNames('bul', 'status', {on: device.isPowerOn})}></span>
                                                                    <span className="data_name" style={{flexGrow: 1}}
                                                                          onClick={() => openDeviceView(device.deviceId)}>{device.deviceName}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="content_upload_status">
                                                                    <ProgressBar width={180} height={4} background={'#e8e8e8'}
                                                                                 percentage={percentage}
                                                                                 fillerColor={'#4be48c'} useLabel={false}/>
                                                                </div>
                                                            </td>
                                                            <td className="status" id="status">{percentage}%</td>
                                                            <td style={{width: 19}}></td>
                                                        </tr>
                                                    )
                                                }
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </MISDialog>
    )

}
export default ContentScheduleProgressPopup;