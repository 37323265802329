import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import MISDialog from "../../MISDialog";
import SelectSupportedDevice from "../../../utils/SelectSupportedDevice";
import {connect} from "react-redux";
import {menuAction, popupAction, scheduleAction} from "../../../../actions";
import {toastr} from 'helper/toastrIntercept';
import {useMISOpt} from "../../../misopt";
import TooltipIcon from '../../../icon/TooltipIcon';

const CreateMessageSchedulePopup = ({id, closePopup, addTab, loadContent, initNewMessage}) => {

    const {t} = useTranslation();
    const closeCreateMessagePopup = () => {
        closePopup(id);
    }

    const {misOptionSchedule} = useMISOpt();

    const [selectedSupportDevice, setSelectedSupportDevice] = useState({
        devices: [],
        selected: []
    })

    const createContentSchedule = () => {
        const {devices, selected} = selectedSupportDevice;
        const tab = {id: 'NEW_MESSAGE', title: t("MIS_SID_20_NEW_MESSAGE"), active: true, close: true};
        const add = () => addTab(tab);

        if ((devices !== undefined && devices.length > 0) && (selected !== undefined && selected.length > 0)) {
            const maxPriorityIndex = Math.max(...selected);
            const device = devices[maxPriorityIndex];
            const {scheduleInfo : {messageSchedule}} = device;
            let multiple = undefined
            if (messageSchedule !== undefined && messageSchedule.multiple !== undefined) {
                multiple = messageSchedule.multiple;
            }
            new Promise((resolve) => {
                add();
                resolve();
            }).then(
                initNewMessage(device)
            ).then(
                loadContent('NEW_MESSAGE',multiple)
            ).then(
                closePopup(id)
            )
        } else {
            toastr.error(t("MESSAGE_DEVICE_SELECT_DEVICE_GROUP_P"));
        }

    }

    const selectedDevice = (devices, selected) => {
        setSelectedSupportDevice({devices: devices, selected: selected});
    }

    const dialogProps ={title: t("MIS_SID_20_NEW_MESSAGE"), closeOnEscape : true, position: {x: -300, y: -400} , modal:true, onClose:()=> closeCreateMessagePopup()};

    return(
        <MISDialog
            dialog = {dialogProps}
            width={782}
            height={173}
            buttons = {{
                rightButtons: [
                    {title : t("COM_TEXT_CREATE_P"), id: 'createContentScheduleBtn', onClick: ()=> createContentSchedule()},
                    {title : t("BUTTON_CANCEL_P"), id: 'closeCreateContentSchedulePopup', onClick: ()=> closeCreateMessagePopup()}
                ]
            }}
        >
            <div className={"create_content_Schedule_popup_wrap"}>
            <div className="quest_img_noti">
                    {t("COM_MAPP_SID_SUPPORTED_DEVICES")}
                    <TooltipIcon message={"MIS_SID_20_WHEN_YOU_SELECT_A_PLAYER_ALL_HIGHER_VERSIONS_ARE_AUTOMATICALLY_SELECTED"}/>                    
                </div>
                <div className="device_type_btn mt15" style={{display:'inline-block'}}>
                    <SelectSupportedDevice type={"message"} selectedDevice={selectedDevice}/>
                </div>
                <span className="error_txt2" id="supportedDeviceErrorMsg"></span>

            </div>
        </MISDialog>
    )


}
export default connect(
    null,
    dispatch => ({
        initNewMessage: (device)=> dispatch(scheduleAction.initNewMessage(device)),
        closePopup: (id)=>dispatch(popupAction.closePopup(id)),
        addTab: (tab)=>dispatch(menuAction.addTab(tab)),
        loadContent: (id, isShowSubmenu)=>dispatch(menuAction.loadContent(id, isShowSubmenu))
    })
)(CreateMessageSchedulePopup);