import React, {useEffect, useState} from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import {SearchButton} from "../../../button/SearchButton";
import './SaveContentSchedulePopup.css';
import SelectedDevices from "../../../input/SelectedDevices";
import Checkbox from "../../../checkbox/Checkbox";
import GroupsPopup from "../../GroupsPopup";
import {scheduleService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import RadioGroup from "../../../radio/RadioGroup";
import DateInput from "../../../input/DateInput";
import Moment from "moment";
import WhiteButton from "../../../button/WhiteButton";
import {useDispatch} from "react-redux";
import ContentPopup from "../ContentPopup";
import {cloneDeep, isEmpty, isNil, isUndefined, snakeCase, toUpper} from "lodash";
import Chip from "../../../chip/Chip";
import TimeInput from "../../../input/TimeInput";
import WeeklyContainer from "../../../../containers/schedule/content/WeeklyContainer";
import MonthlyContainer from "../../../../containers/schedule/content/MonthlyContainer";
import Select from "../../../selectbox/Select";
import {menuAction, popupAction} from "../../../../actions";
import {
    commonConstants,
    CONTENT_SCHEDULE_PROGRAM_TYPE_AD,
    CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC,
    playlistConstants,
    scheduleConstants
} from "../../../../constants";
import {progressAction} from "../../../../actions/progress.action";
import {ProgressConstants} from "../../../../constants/ProgressConstants";
import {convertSchedule, createUUID, getTotalContentSize} from "../../../../helper/schedule/scheduleUtils";
import {useMISOpt} from "../../../misopt";
import {getErrorMessage} from "../../../../helper/responseHandler";
import {isValidExpirationDate} from "../../../../helper/schedule/scheduleUtils";

const SaveContentSchedulePopup = ({closePopup, stateProgram, mode}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {misOption} = useMISOpt();

    const [program, setProgram] = useState({
        ...stateProgram
    });

    useEffect(()=> {
        if (mode === scheduleConstants.CONTENT_SCHEDULE_SAVE_AS_MODE) {
            const programId = createUUID();
            const {channels} = program;
            channels.map(
                (channel, index) => {
                    if (channel.frame !== undefined && channel.frame.events !== undefined) {
                        const {events} = channel.frame;
                        events.map(
                            event => event.scheduleId = createUUID()
                        )
                    }
                    if (channel.frame !== undefined && channel.frame.frames !== undefined) {
                        const {frames} = channel.frame;
                        for (const frame of frames) {
                            frame.events.map(
                                event => event.scheduleId = createUUID()
                            )
                        }
                    }
                    if (index < 1) {
                        if (channel.frame !== undefined && channel.frame.adSlots !== undefined && channel.frame.adSlots.length > 0) {
                            // for advertisement schedule
                            const frameId = createUUID();
                            channel.frame.frameId = frameId;
                            channel.frame.adSlots.map(
                                slot => {
                                    const slotId = createUUID();
                                    slot.programId = programId;
                                    slot.slotId = slotId;
                                    slot.frameId = frameId;
                                    if (slot.events !== undefined && slot.events.length > 0) {
                                        slot.events.map(
                                            event => {
                                                event.scheduleId = createUUID();
                                                event.programId =  programId;
                                                event.slotId = slotId;
                                            }
                                        )
                                    }
                                }
                            )

                        }
                    }
                }
            )

            setProgram({
                ...program,
                programId: programId,
                programName : '',
                programGroupId : '',
                programGroupName : '',
                deviceGroups : [],
                description : '',
                deployReservation : {},
                isDeployReserved: false,
                channels: channels
            })
        }

        if(program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC) {
            program.isContentSyncOn = true;
            program.isPlayFromLastPlaybackPoint = false;
        }
    }, [])

    const deviceInfo = misOption(program.deviceType,program.deviceTypeVersion);
    const scheduleInfo = deviceInfo !== undefined ? deviceInfo.scheduleInfo : undefined;

    const supportContentMute = scheduleInfo !== undefined && scheduleInfo.contentSchedule !== undefined && scheduleInfo.contentSchedule.supportContentMute !== undefined ? scheduleInfo.contentSchedule.supportContentMute : false;
    const supportResumePlay = scheduleInfo !== undefined && scheduleInfo.contentSchedule !== undefined && scheduleInfo.contentSchedule.resumePlay !== undefined ? scheduleInfo.contentSchedule.resumePlay : false;

    const [popups, setPopups] = useState({
        scheduleGroupPopup: false,
        deviceGroupPopup: false,
        contentsPopup: false
    })

    const [moreFeature, setMoreFeature] = useState({
        show: false
    });

    const [popupStyle, setPopupStyle] = useState({
        height: 300
    })

    const showScheduleGroupPopup = () => {
        setPopups({...popups, scheduleGroupPopup: !popups.scheduleGroupPopup});
    }

    const showDeviceGroupPopup = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.DEVICE_GROUP_SELECTION,
            type: commonConstants.DEVICE_GROUP_SELECTION,
            allowSelectOrganization: false,
            allEnabled: false,
            checkbox: true,
            checkedKeys : getCheckedDeviceGroupKeys(),
            priority : getPriority(program.deviceType, program.deviceTypeVersion),
            selected : program.deviceGroups,
            save: setDeviceGroup,
            close: ()=>dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
        }));
    }

    const showMoreFeature = () => {
        if (!moreFeature.show) {
            setPopupStyle({height: 450});
        }
        setMoreFeature({show: !moreFeature.show});
    }

    const removeDeviceGroup = id => {
        setProgram({...program, deviceGroups: program.deviceGroups.filter(group=>group.groupId !== id )});
    }

    const setDeviceGroup = groups => {
        const deviceGroups = []
        if (groups !== undefined && groups.length > 0) {
            for (const group of groups) {
                deviceGroups.push({groupId: group.groupId, groupName: group.groupNameText !== undefined ? group.groupNameText : group.groupName})
            }
        }
        setProgram({...program, deviceGroups: deviceGroups});
        //showDeviceGroupPopup();
        dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
    }

    const setScheduleGroup = (groups) => {
        if (groups !== undefined && groups.length > 0) {
            const {groupId, groupName, parentGroupId} = groups[0];
            if (parentGroupId > 0) {
                setProgram({...program, programGroupId:  groupId, programGroupName: groupName});
                showScheduleGroupPopup();
            }
        }
    }

    const handleProgramName = e => {
        setProgram({...program, programName: e.target.value})
    }

    const onUpdateDescription = e => {
        const value =  e.target.value;
        setProgram({...program, description: value})
    }

    const getPriority = (deviceType, deviceTypeVersion) => {
        const device = misOption(deviceType, deviceTypeVersion);
        return device !== undefined ? device.priority : 999999;
    }

    const validProgram = () => {
        if (isNil(program.programName) || isUndefined(program.programName) || program.programName === '') {
            toastr.error(t("MESSAGE_SCHEDULE_ENTER_SCH_NAME_P"));
            return false;
        }
        if(program.programName.trim() === ''){                                  
            toastr.error(t("MESSAGE_COMMON_CANT_NAME_SPACE_P"));
            return;
        }
        if (isUndefined(program.programGroupId) || program.programGroupId < 1) {
            toastr.error(t("MESSAGE_SCHEDULE_SELECT_SCH_GROUP_P"));
            return false;
        }

        if (program.deployReservation !== undefined && program.deployReservation !== '') {
            const {startDate, endDate} = program.deployReservation;            
            if (startDate !== undefined && startDate !== '' && endDate !== undefined && endDate !== '') {
                if (startDate === endDate) {
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_DATE_VALUES_P"));
                    return false;
                }
                if (Moment(startDate, 'YYYY-MM-DD').toDate() > Moment(endDate, 'YYYY-MM-DD').toDate()) {
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_DATE_VALUES_P"));
                    return false;
                }
            }

            if(program.deployReservation.repeatType === "WEEKLY"){          
                if(program.deployReservation.repeatedDayOfWeekList === undefined){
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_DATE_VALUES_P"));
                    return false;
                }else if(!program.deployReservation.repeatedDayOfWeekList.length) {
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_DATE_VALUES_P"));
                    return false;
                }
            }

            if(program.deployReservation.repeatType === "MONTHLY"){  
                if(program.deployReservation.repeatedDateOfMonthList === undefined){
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_DATE_VALUES_P"));
                    return false;
                }else if(!program.deployReservation.repeatedDateOfMonthList.length) {
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_DATE_VALUES_P"));
                    return false;
                }
            }
        }
        return true;
    }

    const isExpirationContents = () => {
        const expirationList = [];
        const {channels} = program;
        if (channels) {
            for (const channel of channels) {
                const {frame} = channel;
                if (frame !== undefined && frame.events !== undefined && frame.events.length > 0) {
                    for (const event of frame.events) {
                        if (isValidExpirationDate(event)) {
                            expirationList.push({contentName: event.contentName, expiredDate: event.expirationDate});
                        }
                    }
                }
                if (frame !== undefined && frame.frames !== undefined && frame.frames.length > 0) {
                        for(const tmpframe of frame.frames){
                            if(tmpframe.events != undefined && tmpframe.events.length > 0){
                                for (const event of tmpframe.events) {
                                    if (isValidExpirationDate(event)) {
                                        expirationList.push({contentName: event.contentName, expiredDate: event.expirationDate});
                                    }
                                }
                            }
                            
                        }
                    
                }
            }
        }
        if (expirationList.length > 0) {
            // show popup
            dispatch(popupAction.addPopup({
                type: playlistConstants.INCLUDE_EXPIRED_CONTENT,
                id: playlistConstants.INCLUDE_EXPIRED_CONTENT,
                expiredContentList: expirationList,
                onClose: ()=> dispatch(popupAction.closePopup(playlistConstants.INCLUDE_EXPIRED_CONTENT))
            }))
            return false
        } else {
            return true;
        }

    }

    const convertProgramData = () => {

        //const saveProgram = Object.assign({}, program);
        const saveProgram = cloneDeep(program);
        if (mode !== scheduleConstants.CONTENT_SCHEDULE_COPY_MODE) {
            saveProgram.channels.map(
                channel => {
                    channel.frame.lineData = toUpper(snakeCase(channel.frame.lineData));
                    if (channel.frame.events !== undefined && channel.frame.events.length > 0) {
                        const events = [];
                        channel.frame.events.map(event => events.push(convertSchedule(event)))
                        channel.frame.events = events;
                    }
                    if (channel.frame.frames !== undefined && channel.frame.frames.length > 0) {
                        channel.frame.frames.map(
                            frame => {
                                if (frame.lineData !== undefined && frame.lineData !== '') {
                                    if (frame.lineData === 'CUSTOM') {
                                        frame.lineData = 'CUSTOM_LAYOUT';
                                    } else {
                                        frame.lineData = toUpper(snakeCase(frame.lineData));
                                    }
                                }
                                const events = [];
                                frame.events !== undefined && frame.events.length > 0 && frame.events.map(event => events.push(convertSchedule(event)))
                                frame.events = events;
                            }
                        )
                    }
                }
            );
        }
        return saveProgram;
    }

    const saveContentSchedule = () => {
        const updateGroupCounter = () => {
            dispatch(menuAction.reloadGroup('CONTENT_SCHEDULE_BY_GROUP'));
        }
        const isExceedDiskSize  = () => {
            let checked = false;
            const {deviceGroups} = program;
            if (deviceGroups !== undefined && deviceGroups.length > 0){
                const totalSize = getTotalContentSize(program.programType, program.channels);
                const groups = getCheckedDeviceGroupKeys(deviceGroups);
                return scheduleService.checkDiskSizeByDeviceGroups(totalSize, groups).then(
                    res=> {
                        if (res) {
                            if (res.items.didDiskSpaceCheck) {
                                checked = !res.items.hasEnoughDiskSpace;
                            }
                        }
                        return checked;
                    })
                    .catch(e=> toastr.error(getErrorMessage(e)))
            } else {
                return checked;
            }
        }
        const deployContentSchedule = (mode) => {
            dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
            dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}))
            const saveProgram = convertProgramData();
            const programId = saveProgram.programId;
            if (mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE || mode === scheduleConstants.CONTENT_SCHEDULE_SAVE_AS_MODE) {
                scheduleService.createContentSchedule(saveProgram).then(
                    res=>{
                        if (res) {
                            if (program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD) {
                                mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE ? dispatch(menuAction.removeTab('NEW_ADVERTISEMENT')) : dispatch(menuAction.removeTab('EDIT_ADVERTISEMENT'))
                            } else {
                                mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE ? dispatch(menuAction.removeTab('NEW_SCHEDULE')) : dispatch(menuAction.removeTab('EDIT_SCHEDULE'))
                            }
                            if (saveProgram.deviceGroups !== undefined && saveProgram.deviceGroups.length > 0) {
                                dispatch(progressAction.addProgress(ProgressConstants.MODE_CONTENT_SCHEDULE, res.items.programId, saveProgram.programName));
                            }
                            updateGroupCounter();
                            toastr.success(t("MIS_MESSAGE_SCHEDULE_SAVE_SUCCESS_P"));
                        }
                    }
                ).catch(e=>{toastr.error(getErrorMessage(e))}
                ).finally(() => {
                        closePopup(true);
                        dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                        dispatch(menuAction.updateSubMenuCounter('SCHEDULE'))
                    }
                )
            } else if (mode === scheduleConstants.CONTENT_SCHEDULE_EDIT_MODE) {
                scheduleService.updateContentSchedule(programId, saveProgram).then(
                    res=>{
                        if (program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD) {
                            dispatch(menuAction.removeTab('EDIT_ADVERTISEMENT'));
                        } else {
                            dispatch(menuAction.removeTab('EDIT_SCHEDULE'));
                        }
                        if (saveProgram.deviceGroups !== undefined && saveProgram.deviceGroups.length > 0) {
                            dispatch(progressAction.addProgress(ProgressConstants.MODE_CONTENT_SCHEDULE, programId, saveProgram.programName));
                        }
                        updateGroupCounter();
                        toastr.success(t("MIS_MESSAGE_SCHEDULE_SAVE_SUCCESS_P"));
                    }
                ).catch(e=>{toastr.error(getErrorMessage(e))}
                ).finally(() => {
                        closePopup(true);
                        dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP))
                        dispatch(menuAction.updateSubMenuCounter('SCHEDULE'))
                    }
                )
            } else if (mode === scheduleConstants.CONTENT_SCHEDULE_COPY_MODE) {
                scheduleService.copyContentSchedule(programId, saveProgram).then(
                    res=>{
                        if (saveProgram.deviceGroups !== undefined && saveProgram.deviceGroups.length > 0) {
                            dispatch(progressAction.addProgress(ProgressConstants.MODE_CONTENT_SCHEDULE, programId, saveProgram.programName));
                        }
                        updateGroupCounter();
                        toastr.success(t("MIS_MESSAGE_SCHEDULE_SAVE_SUCCESS_P"));
                    }
                ).catch(e=>{toastr.error(getErrorMessage(e));}
                ).finally(
                    ()=> {
                        closePopup(true);
                        dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                        dispatch(menuAction.updateSubMenuCounter('SCHEDULE'));
                    }
                )
            }
        }
        const showDiskWarningPopup = (mode) => {
            const message = t('MIS_MESSAGE_DELETE_WARNING_P')+' '+t('COM_ID_SCENEEDITOR_SAVE_CHANGE');
            dispatch(popupAction.addPopup({
                type: commonConstants.COMMON_CONFIRM_POPUP,
                id: commonConstants.COMMON_CONFIRM_POPUP,
                title: t("COM_BUTTON_CONFIRM"),
                message: message,
                onClickYes: () => deployContentSchedule(mode),
                onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
            }))
        }
        
        
        
        if (isExpirationContents() && validProgram()) {
            new Promise((resolve) => {
                const hasEnoughDiskSpace = isExceedDiskSize();
                resolve(hasEnoughDiskSpace)
            }).then(
                (hasEnoughDiskSpace) => {
                    if (hasEnoughDiskSpace) {
                        showDiskWarningPopup(mode);
                    } else {
                        deployContentSchedule(mode);
                    }
                }
            )
        }
    }

    const onChangeUsage = (e, value) => {
        const dataName = e.target.getAttribute('data-name');
        const updateProgram = program;
        updateProgram[dataName] = value;
        setProgram({...program, ...updateProgram});
    }

    const onChangeCheckbox = e => {
        const value = e.target.checked;
        const dataName = e.target.getAttribute('data-name');
        const updateProgram = program;
        if (dataName === 'isDeployReserved' && value === true) {
            // init date
            updateProgram.deployReservation = {
                deployTime: '00:00:00',
                startDate: Moment().format('YYYY-MM-DD'),
                endDate: Moment().format('YYYY-MM-DD'),
                repeatType: 'DAILY',
                repeatedDateOfMonthList: [],
                repeatedDayOfWeekList: []
            }
        } else if (dataName === 'isDeployReserved' && value === false) {
            // reset  for deployReservation
            updateProgram.deployReservation = {};
        }
        updateProgram[dataName] = value;
        setProgram({...program, ...updateProgram});
    }

    const onChangeExpired = e => {
        if (e.target.checked) {
            setProgram({...program, deployReservation: {...program.deployReservation, endDate: '2999-12-31' }})
        } else {
            setProgram({...program, deployReservation: {...program.deployReservation, endDate: Moment().format('YYYY-MM-DD') }})
        }

    }


    const showSelectBgmPopup = () => {
        setPopups({...popups, contentsPopup: !popups.contentsPopup});
    }

    const handelContentMute = (e) => {
        const checked = e.target.checked;
        setProgram({...program, isBgmContentMute: checked})
    }

    const selectBgm = (contentId, contentName) => {
        setProgram({...program, bgmContentId: contentId, bgmContentName: contentName});
        showSelectBgmPopup();
    }

    const onClickDeleteBgm = () => {
        setProgram({...program, bgmContentId: '', bgmContentName: ''});
    }

    const onChangeDate = (name, date) => {
        const updateDate = Moment(date).format('YYYY-MM-DD');
        if (name === 'start') {
            setProgram({...program, deployReservation: {...program.deployReservation, startDate: updateDate }})
        } else if (name === 'end') {
            setProgram({...program, deployReservation: {...program.deployReservation, endDate: updateDate }})
        }
    }

    const onChangeDeployTime = (e, value) => {
        setProgram({...program, deployReservation: {...program.deployReservation, deployTime: value }})
    }

    const onChangeRepeatType = (e, value) => {
        setProgram({...program, deployReservation: {...program.deployReservation, repeatType: value }})
    }

    const onChangeReserveWeekly = e => {
        const value = e.target.getAttribute('data-name');
        if (e.target.checked) {
            setProgram({...program, deployReservation: {...program.deployReservation, repeatedDayOfWeekList: program.deployReservation.repeatedDayOfWeekList !== undefined ? program.deployReservation.repeatedDayOfWeekList.concat(value) : [].concat(value) }})
        } else {
            setProgram({...program, deployReservation: {...program.deployReservation, repeatedDayOfWeekList: program.deployReservation.repeatedDayOfWeekList.filter(day => day !== value) }})
        }
    }

    const onChangeReserveMonthly = e => {
        const value = parseInt(e.target.getAttribute('data-value'), 10);
        if (program.deployReservation.repeatedDateOfMonthList !== undefined && program.deployReservation.repeatedDateOfMonthList.find(day=> day === value) !== undefined) {
            setProgram({...program, deployReservation: {...program.deployReservation, repeatedDateOfMonthList: program.deployReservation.repeatedDateOfMonthList !== undefined ? program.deployReservation.repeatedDateOfMonthList.filter(day => day !== value) : []}})
        } else {
            setProgram({...program, deployReservation: {...program.deployReservation, repeatedDateOfMonthList: program.deployReservation.repeatedDateOfMonthList !== undefined ? program.deployReservation.repeatedDateOfMonthList.concat(value) : [].concat(value)}})
        }
    }

    const getCheckedDeviceGroupKeys = () => {
        let selectedGroupIds = [];
        if(!isEmpty(program.deviceGroups)){
            program.deviceGroups.map((group) => {
                selectedGroupIds.push(group.groupId);
            })
        }
        return selectedGroupIds;
    }


    const getReservationDate = (date) => {
        try {
            let year = Moment(date + ' 00:00:00').toDate().getFullYear();
            if(year > 9999 || year < 0) {
                return new Date();
            }
        }catch {
            return new Date();
        }
        return Moment(date+' 00:00:00').toDate();
    }


    useEffect(()=> {
        if (mode === 'new') {
            scheduleService.fetchContentScheduleDefaultGroup().then(
                res => {
                    if (res) {
                        const {groupId, groupName} = res.items;
                        setProgram({...program, programGroupId:  groupId, programGroupName: groupName})

                    }
                }
            )
        }
    }, [])

    const dialogProps ={title: t("COM_BUTTON_SAVE"), closeOnEscape : true, position: {x: 570/2*-1, y: 500/2*-1} , modal:true, onClose:()=> closePopup()};

    return (
        <MISDialog
            dialog={dialogProps}
            width={450}
            height={popupStyle.height}
            buttons={{
                rightButtons: [
                    {id: 'SAVE_N_PUBLISH_SCHEDULE', title: t("MIS_SID_SERVER_SAVE_AND_PUBLISH"), onClick: ()=>saveContentSchedule()},
                    {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: ()=>closePopup()}]
            }}
        >
            {
                popups.scheduleGroupPopup &&
                <GroupsPopup
                    mode={"content_schedule"}
                    checkbox={false}
                    save={(groups)=>setScheduleGroup(groups)}
                    close={()=>showScheduleGroupPopup()}
                />
            }

            {
                popups.contentsPopup &&
                <ContentPopup
                    mode={"bgm"}
                    closeContentPopup={showSelectBgmPopup}
                    selectContent={(contentId, contentName)=>selectBgm(contentId, contentName)}
                />
            }

            <div className={"save_content_schedule_wrap"} onMouseDown={e => {
                const rect = e.target.getBoundingClientRect();
                const x = e.clientX - rect.left;

                if(x >= e.target.clientWidth) {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }
            }}>
                <div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="101px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr className="va_t">
                            <th>
                                <span className="popupTh">{t("TEXT_SCHEDULE_NAME_P")}</span>
                            </th>
                            <td>
                                <input type="text" style={{width: 218}} maxLength="90" value={program.programName} onChange={handleProgramName}/>
                            </td>
                        </tr>
                        <tr>
                            <th><span className="popupTh">{t("TEXT_SCHEDULE_GROUP_P")}</span></th>
                            <td>
                                <div className="float_l mr10">
                                    <SearchButton onClick={showScheduleGroupPopup}/>
                                </div>
                                <div className="tag_input type2 float_l" style={{width: 190, background: 'none'}}>
                                    <input type="text"
                                           style={{
                                               width: '100%',
                                               background: 'none',
                                               overflow: 'hidden',
                                               textOverflow: 'ellipsis',
                                               whiteSpace: 'nowrap'
                                           }}
                                           value={program.programGroupName}
                                           title={program.programGroupName}
                                           readOnly
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th><span className="popupTh">{t("MIS_SID_20_PUBLISH_TO")}</span></th>
                            <td>
                                <div className="float_l mr10">
                                    <SearchButton onClick={showDeviceGroupPopup} disabled={program.programType === 'VWL' || program.programType === 'SYNC'}/>
                                </div>
                                <div className="float_l" style={{width: 200}}>
                                    <SelectedDevices groups={program.deviceGroups} removeGroup={(groupId) => removeDeviceGroup(groupId)} disabled={program.programType === 'VWL' || program.programType === 'SYNC'}/>
                                </div>
                            </td>
                        </tr>
                        <tr className="va_t">
                            <th><span className="popupTh">{t("TEXT_DESCRIPTION_P")}</span></th>
                            <td>
                                <textarea id="scheudleDesText" maxLength="350"
                                          style={{width: 250, minHeight: 40}} onChange={onUpdateDescription} value={program.description}></textarea>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div>
                        <button className="detail_btn mt4 mb9" id="scheudleSaveMore" onClick={()=>showMoreFeature()}><span></span>
                            {t("TEXT_LIMIT_OF_SCH_P")}
                        </button>
                        <div id="moreFeature" style={{'display': moreFeature.show ? '' : 'none'}}>
                            <table>
                                <colgroup>
                                    <col width="101px"/>
                                    <col width=""/>
                                </colgroup>
                                <tbody>
                                {
                                    program.programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                                    <tr>
                                        <th>{t("TEXT_BGMUSIC_P")}</th>
                                        <td>
                                            <div style={{display: 'inherit'}}>
                                                <div>
                                                    <WhiteButton id={"scheduleAddBgmBtn"} name={t("TEXT_BGMUSIC_P")} onClick={showSelectBgmPopup}/>
                                                    {
                                                        supportContentMute &&
                                                        <span style={{marginLeft: 10}}><Checkbox id={'content_schedule_content_mute'} name={t('COM_IDS_MUTE_CONTENTS_SCH')} onChange={handelContentMute} checked={program.isBgmContentMute}/></span>
                                                    }
                                                </div>
                                                <div style={{marginTop: 5}}>
                                                    {
                                                        !isNil(program.bgmContentId) && program.bgmContentId.length > 0&& !isNil(program.bgmContentName) && program.bgmContentName.length > 0 &&
                                                        <Chip title={program.bgmContentName} showDelete={true} onDelete={()=>onClickDeleteBgm()} width={150}/>
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                }
                                {
                                    program.programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                                    program.programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC &&
                                    <tr>
                                        <th><span className="popupTh">{t("MIS_TEXT_SYCHRONIZATION_P")}</span></th>
                                        <td>
                                            <RadioGroup
                                                propertyName={"isContentSyncOn"}
                                                selects={[
                                                    {title: "BUTTON_OFF", value: false},
                                                    {title: "TEXT_ON_P", value: true}
                                                ]} value={program.isContentSyncOn} onChange={onChangeUsage} multiLang={true}/>
                                        </td>
                                    </tr>
                                }
                                {
                                    program.programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                                    program.programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC &&
                                    supportResumePlay &&
                                    <tr>
                                        <th><span className="popupTh">{t("MIS_SID_RESUME_PLAY")}</span></th>
                                        <td>
                                            <RadioGroup
                                                propertyName={"isPlayFromLastPlaybackPoint"}
                                                selects={[
                                                    {title: "BUTTON_OFF", value: false},
                                                    {title: "TEXT_ON_P", value: true}
                                                ]} value={program.isPlayFromLastPlaybackPoint} onChange={onChangeUsage} multiLang={true}/>
                                        </td>
                                    </tr>
                                }

                                <tr>
                                    <th><span className="popupTh">{t("TEXT_DEPLOY_RESERVE_P")}</span></th>
                                    <td>
                                        <div>
                                            <Checkbox propertyName={'isDeployReserved'} id={"content_schedule_reservation"} name={t("TEXT_RESERVATION_P")} checked={program.isDeployReserved} onChange={onChangeCheckbox} />
                                        </div>
                                    </td>
                                </tr>
                                <tr className="reservationTr" style={{display: program.isDeployReserved === true ? '':'none'}}>
                                    <th></th>
                                    <td>
                                        <div>
                                            <div className="input_date">
                                                {
                                                    !isNil(program.deployReservation) &&
                                                    <DateInput date={program.deployReservation.startDate} 
                                                                onChange={(date)=>onChangeDate('start', date)}                                                                
                                                                width={120}
                                                                maxDate={getReservationDate(program.deployReservation.endDate)}
                                                                position="top"/>
                                                }

                                            </div>
                                            -
                                            <div className="input_date">
                                                {
                                                    !isNil(program.deployReservation) &&
                                                    <DateInput date={program.deployReservation.endDate} 
                                                    disabled={program.deployReservation.endDate === '2999-12-31'}                                                     
                                                    onChange={(date)=>onChangeDate('end', date)} 
                                                    width={120} 
                                                    minDate={getReservationDate(program.deployReservation.startDate)}
                                                    position="top"/>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <Checkbox id={"content_schedule_expired"} name={t("MIS_SID_20_NEVER_EXPIRE")} checked={!isNil(program.deployReservation) && program.deployReservation.endDate === '2999-12-31'} onChange={onChangeExpired}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="reservationTr" style={{display: program.isDeployReserved === true ? '':'none'}}>
                                    <th><span className="popupTh">{t("MIS_SID_PUBLICATION_TIME")}</span></th>
                                    <td>
                                        {
                                            !isNil(program.deployReservation) && !isNil(program.deployReservation.deployTime) &&
                                            <TimeInput value={program.deployReservation.deployTime} onChange={onChangeDeployTime}/>
                                        }

                                    </td>
                                    <td></td>
                                </tr>
                                <tr className="reservationTr" style={{display: program.isDeployReserved === true ? '':'none'}}>
                                    <th><span className="popupTh">{t("TEXT_REPEAT_P")}</span></th>
                                    <td>
                                        <Select
                                            width={200}
                                            onChange={onChangeRepeatType}
                                            value={!isNil(program.deployReservation) && program.deployReservation.repeatType}
                                            selects={[
                                                {title: 'TEXT_DAILY_P', value: 'DAILY'},
                                                {title: 'TEXT_WEEKLY_P', value: 'WEEKLY'},
                                                {title: 'TEXT_MONTHLY_P', value: 'MONTHLY'}
                                            ]}
                                        />

                                        {
                                            !isNil(program.deployReservation) && program.deployReservation.repeatType === 'WEEKLY' &&
                                            <WeeklyContainer checked={program.deployReservation.repeatedDayOfWeekList?program.deployReservation.repeatedDayOfWeekList:[]} onClick={onChangeReserveWeekly}/>
                                        }

                                        <div id="repeatTypeWrap" className="mt10" style={{display: !isNil(program.deployReservation) && program.deployReservation.repeatType === 'MONTHLY' ? '' : 'none'}}>

                                            {
                                                !isNil(program.deployReservation) && program.deployReservation.repeatType === 'MONTHLY' &&
                                                <MonthlyContainer checked={program.deployReservation.repeatedDateOfMonthList?program.deployReservation.repeatedDateOfMonthList:[]} onClick={onChangeReserveMonthly}/>
                                            }
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </MISDialog>
    )
}
export default SaveContentSchedulePopup;