import TextInput from "../../components/input/TextInput";
import React, {useState, useRef, useEffect} from "react";
import {useTranslation} from "react-i18next";
import isEmpty from 'lodash/isEmpty';
import {changeSecondToHMSTime} from './playlistUtil';
import {checkNumberValidation} from "../../helper";
const IPLAYER = 'IPLAYER';

const TimeSettingWrap = ({content, playlistType, deviceType,  index, updateItem, type}) => {
    const {t} = useTranslation();
    const {isSubPlaylist, playTime, contentDuration, mediaType, duration} = content;
    const [defaultPlaySec, setDefaultPlaySec] = useState("5")
    const [defaultPlayMin, setDefaultPlayMin] = useState("0");
    const [defaultPlayHour, setDefaultPlayHour] = useState("0");
    const [disabled, setDisabled] = useState("");
    const [durationSec, setDurationSec] = useState("");

    useEffect(() => {
        if (playTime != null && playTime != "" && playlistType !== "4") {
            let arr = playTime.split(":");
            setDefaultPlaySec(arr[2]);
            setDefaultPlayMin(arr[1]);
            setDefaultPlayHour(arr[0]);
            setDisabled("disabled")

            setDurationSec(changeSecondToHMSTime(playTime));
        } else {
            if (deviceType && deviceType.toUpperCase() == IPLAYER) {
                if (playlistType == "4") {
                    setDurationSec("");
                } else {
                    setDurationSec("30");
                }
            } else {
                setDurationSec("5");
            }

            if (contentDuration && !isSubPlaylist) {
                let hmsTimeArr = changeSecondToHMSTime(Number(contentDuration)).split(":");
                setDefaultPlayHour(parseInt(hmsTimeArr[0]))
                setDefaultPlayMin(parseInt(hmsTimeArr[1]));
                setDefaultPlaySec(parseInt(hmsTimeArr[2]));
            } else {
                if (playlistType == "4") {
                    setDefaultPlaySec("10")
                    if (!isEmpty(contentDuration)) {
                        let hmsTimeArr = changeSecondToHMSTime(Number(contentDuration)).split(":");
                        setDefaultPlayHour(parseInt(hmsTimeArr[0]))
                        setDefaultPlayMin(parseInt(hmsTimeArr[1]));
                        setDefaultPlaySec(parseInt(hmsTimeArr[2]));
                    }else{
                        setDefaultPlayMin("-");
                        setDefaultPlaySec("-");
                        setDefaultPlayHour("-")
                    }
                    setDisabled("disabled")
                } else if (playlistType === "5") {
                    let hmsTimeArr = changeSecondToHMSTime(Number(duration)).split(":");
                    setDefaultPlayHour(parseInt(hmsTimeArr[0]))
                    setDefaultPlayMin(parseInt(hmsTimeArr[1]));
                    setDefaultPlaySec(parseInt(hmsTimeArr[2]));
                } else {
                    if (contentDuration === 0 && ( mediaType === 'CIFS' || mediaType === 'FTP')) {
                        setDefaultPlayMin("0");
                        setDefaultPlayHour("0");
                        setDefaultPlaySec("0");
                    } else if (deviceType && deviceType.toUpperCase() === IPLAYER) {
                        setDefaultPlayMin("0");
                        setDefaultPlayHour("0");
                        setDefaultPlaySec("30");
                    }  else {
                        setDefaultPlayMin("0");
                        setDefaultPlayHour("0");
                        setDefaultPlaySec("5");
                    }
                }
            }
        }
    }, [contentDuration, duration]);

    const timeSettingDisabled = () => {
        return (!isEmpty(playTime)|| (contentDuration === 0 && ( mediaType === 'CIFS' || mediaType === 'FTP')) || playlistType === '4') ? true : false
    };

    const className = type === 'tag' ? 'time_setting_wrap tagplaylist' : 'time_setting_wrap';

    const wrappingDuration = (contentPlayingTime) => {

        return playlistType === '5' ? {
            duration: contentPlayingTime
        } : {
            contentDuration: contentPlayingTime
        }
    }

    return (
        <div className={className} style={{left: (content.isSubPlaylist|| playlistType === '5') ? -96 : -140}}>
            {
                isSubPlaylist ? <TextInput type={"text"} className="light_gray ta_c" width={100} textAlign={'center'}
                                           value={content.playTime} disabled={disabled}/>
                    :
                    <div style={{width:'inherit'}}>
                        <TextInput type={"text"} className="light_gray ta_c" width={35} textAlign={'center'} value={defaultPlayHour}
                            onChange={(e) => {
                                if (Number.isNaN(parseInt(e.target.value)) && e.nativeEvent.data !== null) return;
                                setDefaultPlayHour(e.target.value);
                            }}
                            onBlur={(e) => {
                                const hourValue = checkNumberValidation(defaultPlayHour, 0, 23);
                                const contentPlayingTime = parseInt(hourValue * 3600) + parseInt(defaultPlayMin) * 60 + parseInt(defaultPlaySec);
                                setDefaultPlayHour(hourValue);
                                updateItem({...content, ...wrappingDuration(contentPlayingTime)}, index)
                            }}
                            disabled={timeSettingDisabled()}
                        />
                        <span className="space">:</span>
                        <TextInput type={"text"} className="light_gray ta_c" width={35} textAlign={'center'} min={0} max={59} value={defaultPlayMin}
                           onChange={(e) => {
                               if (Number.isNaN(parseInt(e.target.value)) && e.nativeEvent.data !== null) return;
                                setDefaultPlayMin(e.target.value);
                            }}
                            onBlur={(e) =>{
                                const minuteValue = checkNumberValidation(defaultPlayMin, 0, 59);
                                const contentPlayingTime = parseInt(defaultPlayHour) * 3600 + parseInt(minuteValue) * 60 + parseInt(defaultPlaySec);
                                setDefaultPlayMin(minuteValue);
                                updateItem({...content, ...wrappingDuration(contentPlayingTime)}, index)
                            }}
                            disabled={timeSettingDisabled()}
                        />
                        <span className="space">:</span>
                        <TextInput type={"text"} className="light_gray ta_c" width={35} textAlign={'center'} min={0} max={59} value={defaultPlaySec}
                           onChange={(e) => {
                                if (Number.isNaN(parseInt(e.target.value)) && e.nativeEvent.data !== null) return;
                                setDefaultPlaySec(e.target.value);
                            }}
                            onBlur={(e) => {
                                const secondValue = checkNumberValidation(defaultPlaySec, 0, 59);
                                const contentPlayingTime = parseInt(defaultPlayHour) * 3600 + parseInt(defaultPlayMin) * 60 + parseInt(secondValue);
                                if (contentPlayingTime < 5) {
                                    setDefaultPlaySec(5);
                                    updateItem({...content, ...wrappingDuration(5)}, index)
                                } else {
                                    setDefaultPlaySec(secondValue);
                                    updateItem({...content, ...wrappingDuration(contentPlayingTime)}, index)
                                }
                            }}
                            disabled={timeSettingDisabled()}
                        />
                    </div>
            }
        </div>

    )
}

export default TimeSettingWrap;