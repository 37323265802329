import React from 'react';
import AddedContent from "./AddedContent";
import DroppableSpace from "./DroppableSpace";
import {ItemTypes} from "./ItemTypes";
import {convertAddedContent} from "./playlistUtil";
import {DropTarget} from "react-dnd";

import EmptyImage from '../../images/img/empty_img_4.png';
import {useTranslation} from "react-i18next";

const PlaylistEditor = React.forwardRef(({contents, playlistType, deviceType, onItemClick, moveItem, updateItem,
    effectClick, onDelete, onSettingPopup, onDrop, connectDropTarget, generalHeight, checkList}, ref) => {

    const {t} = useTranslation();

    return connectDropTarget(
        <div className={"animated"} style={{overflowY: 'auto', height: generalHeight, width: 'calc(100% - 27px)',  overflowX: 'visible', display: 'flex'}}>
            <ul className="clearfix" style={{width:'100%', display: 'flex', flexFlow: 'wrap', alignContent: 'flex-start',paddingRight: 20}}>
                {
                    contents != null && contents.length > 0 ?
                        contents.map(
                            (content) => {
                                return (
                                    <div style={{display: 'flex', height:'fit-content'}}>
                                        <DroppableSpace index={content.orderIndex} onDrop={onDrop} position={"prev"} moveItem={moveItem} />
                                        <AddedContent
                                            content={content}
                                            index={content.orderIndex}
                                            effectClick={()=> {effectClick(content.orderIndex)}}
                                            deleteButtonClick={()=> onDelete(content.orderIndex)}
                                            settingButtonClick={(item) => onSettingPopup(item, content.orderIndex)}
                                            itemClick={(content)=> {onItemClick(content, content.orderIndex)}}
                                            updateItem={updateItem}
                                            mode={'GENERAL'}
                                            playlistType={playlistType}
                                            deviceType={deviceType}
                                            checkList={checkList}
                                        />
                                        <DroppableSpace index={content.orderIndex} onDrop={onDrop} position={"post"} moveItem={moveItem}/>
                                    </div>
                                )}
                        ) : (
                            <div className="list_wrap_empty list_wrap_empty2" style={{position: 'relative', width: '100%'}}>
                                <img src={EmptyImage} />
                                <span>{t("MIS_SID_20_ADD_CONTENT_TO_THE_PLAYLIST_BY_DRAGGING_IT_HERE")}</span>
                            </div>
                        )
                }
            </ul>
        </div>
    );
})

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
        dropResult: monitor.getDropResult(),
    }
}
export default DropTarget(
    ItemTypes.ContentItem,
{
    drop(props, monitor, component) {
        monitor.getItem().dragItem && monitor.isOver() && monitor.canDrop() && props.onDrop(convertAddedContent(monitor.getItem().dragItem), monitor.getItem().id, props.id);
    },
}, collect)(PlaylistEditor);