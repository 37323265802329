import React, {useEffect, useRef} from 'react';

const RMServerOpener = ({url, token}) => {
    const formRef = useRef();

    useEffect(() => {
        formRef.current.submit();
    }, []);

    return (
        <form action={url} target='RMServer' method='post' ref={formRef} style={{display: 'none'}}>
            {
                token &&
                <input type='hidden' name='token' value={token} />
            }
        </form>
    );
};

export default RMServerOpener;