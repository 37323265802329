import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {isNil} from 'lodash';
import MISDialog from '../../MISDialog';
import TextInput from '../../../input/TextInput';
import {deviceService} from '../../../../services';

const EditCustomizePopup = ({initSoftware, onSave, onClose}) => {
    const {t} = useTranslation();
    const [software, setSoftware] = useState(initSoftware);
    const [disableSave, setDisableSave] = useState(false);

    const onClickSave = () => {
        if(isNil(software.softwareName) || software.softwareName == "") {
            toastr.error(t("MESSAGE_COMMON_ENTER_NAME_P"));
            return;
        }

        if(software.softwareName.trim() == ""){
            toastr.error(t("MIS_MESSAGE_COMMON_CANT_TEXT_SPACE_P"));
            return;
        }

        setDisableSave(true);
        deviceService.updateSoftware({
            softwareId: software.softwareId, softwareName: software.softwareName
        }).then(res => {
            toastr.success(t('COM_TEXT_SUCCESS_P'));
            if(onSave) {
                onSave();
            }
        }).catch(error => {
            console.log(error);
            setDisableSave(false);
        });
    };

    const getMultiLang = (sid, swtype) => {
        switch(swtype){
            case '03':
                if(sid === 0)
                    return t('MIS_SID_PREMIUM_LOGO_NAME');
                else if(sid === 1)
                    return t('MIS_SID_ENTER_LOGO_NAME');
            case '04':
                if(sid === 0) // Name
                    return t('TEXT_CONTENT_NAME_P');
                else if(sid === 1) // Enter Name
                    return t('COM_IDS_ERR_CONTENTS_NAME');
            case '06':
                if(sid === 0) // Name
                    return t('COM_TV_SID_REMOTE_WORKSAPCE_CERTIFICATE_NAME');
                else if(sid === 1) // Enter Name
                    return t('MIS_SID_CASEP_ENTER_CERTIFICATE_NAME');
        }
    };

    return (
        <MISDialog 
            dialog={{
                title: ((type) => {
                    switch(type){
                        case '03':
                            return t('MIS_SID_PREMIUM_EDIT_CUSTOM_LOGO');
                        case '04':
                            return t('MIS_SID_20_EDIT_DEFAULT_CONTENT');
                        case '06':
                            return t('MIS_SID_CASEP_EDIT_CERTIFICATE');
                    }
                })(initSoftware.softwareType),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 420,
                height: 54,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: onClickSave,
                        disable: disableSave,
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className='devicepop'>
                <div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="140px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>
                                    {getMultiLang(0, initSoftware.softwareType)}
                                </th>
                                <td>
                                    <TextInput width={240} placeholder={getMultiLang(1, initSoftware.softwareType)} value={software.softwareName} onChange={e => setSoftware({...software, softwareName: e.target.value})} maxLength={50} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    );
};

export default EditCustomizePopup;