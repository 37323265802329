import React from 'react';
import SPLAYER_1 from '../../../images/icon/SPLAYER1.0.png';
import SPLAYER_2 from '../../../images/icon/SPLAYER2.0.png';
import SPLAYER_3 from '../../../images/icon/SPLAYER3.0.png';
import SPLAYER_4 from '../../../images/icon/SPLAYER4.0.png';
import SPLAYER_5 from '../../../images/icon/SPLAYER5.0.png';
import SPLAYER_6 from '../../../images/icon/SPLAYER6.0.png';
import SPLAYER_7 from '../../../images/icon/SPLAYER7.0.png';
import SPLAYER_9 from '../../../images/icon/SPLAYER9.0.png';
import SPLAYER_10 from '../../../images/icon/SPLAYER10.0.png';

const SPlayer = ({deviceTypeVersion, text, disabled = false}) => {
    let playerName = '';
    if (text) {
        playerName = 'S Player';
        if (deviceTypeVersion > 0) {
            playerName += ' '+deviceTypeVersion;
        }
    }
    return(
        <span className={"device_icon"}>
            {(
                ()=>{
                    switch(deviceTypeVersion) {
                        case 1:
                            return <img src={SPLAYER_1} style={{opacity : disabled ? 0.7 : 1, WebkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-device-type={'SPLAYER'} data-device-type-version={deviceTypeVersion}/>
                        case 2:
                            return <img src={SPLAYER_2} style={{opacity : disabled ? 0.7 : 1, WebkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-device-type={'SPLAYER'} data-device-type-version={deviceTypeVersion}/>
                        case 3:
                            return <img src={SPLAYER_3} style={{opacity : disabled ? 0.7 : 1, WebkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-device-type={'SPLAYER'} data-device-type-version={deviceTypeVersion}/>
                        case 4:
                            return <img src={SPLAYER_4} style={{opacity : disabled ? 0.7 : 1, WebkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-device-type={'SPLAYER'} data-device-type-version={deviceTypeVersion}/>
                        case 5:
                            return <img src={SPLAYER_5} style={{opacity : disabled ? 0.7 : 1, WebkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-device-type={'SPLAYER'} data-device-type-version={deviceTypeVersion}/>
                        case 6:
                            return <img src={SPLAYER_6} style={{opacity : disabled ? 0.7 : 1, WebkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-device-type={'SPLAYER'} data-device-type-version={deviceTypeVersion}/>
                        case 7:
                            return <img src={SPLAYER_7} style={{opacity : disabled ? 0.7 : 1, WebkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-device-type={'SPLAYER'} data-device-type-version={deviceTypeVersion}/>
                        case 9:
                            return <img src={SPLAYER_9} style={{opacity : disabled ? 0.7 : 1, WebkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-device-type={'SPLAYER'} data-device-type-version={deviceTypeVersion}/>
                        case 10:
                            return <img src={SPLAYER_10} style={{opacity : disabled ? 0.7 : 1, WebkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-device-type={'SPLAYER'} data-device-type-version={deviceTypeVersion}/>
                    }
                }
            )()}
            {playerName}
        </span>
    )
}
export default SPlayer
