import React, {useEffect, useState} from 'react';
import {withTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import './ContentMultiEdit.css';
import {commonConstants, contentConstants} from "../../../constants";
import SubTab from "../../tab/SubTab";
import Category from "../../category/Category";
import Checkbox from "../../checkbox/Checkbox";
import DateInput from "../../input/DateInput";
import Moment from "moment";
import TagSettingView from "../tag/TagSettingView";
import {contentService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import {useDispatch} from "react-redux";
import {contentAction, popupAction} from "../../../actions";
import {getErrorMessage} from "../../../helper/responseHandler";

const ContentMultiEdit = ({contentIds: selectedContentIds, showExpirationDateTab: showExpirationDateTab, onClose, t}) => {
    const EDIT_MULTI_SUB_TABS = [];
    EDIT_MULTI_SUB_TABS.push('DID_MAIN_CATEGORY');
    EDIT_MULTI_SUB_TABS.push('MIS_SID_MEDIA_TAG');
    if (showExpirationDateTab) {
        EDIT_MULTI_SUB_TABS.push('COM_EXPIRATION_DATE_KR_DATE');
    }

    const dispatch = useDispatch();
    const [data, setData] = useState({
        selectedCategories: [],
        selectedTags: [],
        isCategoryClicked: false,
        isExpiredTabClicked: false,
        updatedSelectedTags: []
    });
    const [subTab, setSubTab] = useState(EDIT_MULTI_SUB_TABS[0]);

    const [isExpirationDateActivated, setIsExpirationDateActivated] = useState(false);
    const [expirationDate, setExpirationDate] = useState('2999-12-31');

    const onChangeTagInfo = (tagList) => {
        data.updatedSelectedTags = tagList;
    };

    useEffect(() => {
        if (selectedContentIds.length === 1) {
            let currentContentId = selectedContentIds[0];

            contentService.fetchContentById(currentContentId).then(res => {
                let finalSelectedTags = [];
                let selectedTags = res.items.tags === undefined ? [] : res.items.tags;

                selectedTags.map((tag) => {
                    finalSelectedTags.push({
                        ...tag,
                        showCondition: false,
                        tagConditionTitle: makeTagTitle(t, tag.contentTagConditions ? tag.contentTagConditions : tag.tagConditions, tag.contentTagConditions),
                        checkAll: false,
                    });
                });

                setData({
                    ...data,
                    selectedTags: finalSelectedTags,
                    updatedSelectedTags: selectedTags,
                    selectedCategories: res.items.categories === undefined ? [] : res.items.categories.map(item => item.groupId)
                });

                if (res.items.expirationDate !== '29991231') {
                    onChangeExpirationDate(res.items.expirationDate);
                    setIsExpirationDateActivated(true);
                }
            });
        }
    }, []);

    const makeTagTitle = (t, conditionList, isChange) => {
        let conditionTitle = '';

        if(conditionList === undefined || conditionList === null || conditionList === '') {
            return '';
        }

        if(isChange) {
            let selectedCount = 0;
            conditionList.map((tagCondition, index) => {
                if (tagCondition.checkCondition || tagCondition.checkCondition === undefined) {
                    selectedCount++;
                    if (selectedCount > 1) {
                        conditionTitle += ',';
                    }
                    conditionTitle += tagCondition.tagCondition === 'NOT_ASSIGN' ? t('COM_DID_VWL_NOT_ASSIGNED') : tagCondition.tagCondition;
                }
                if (index === (conditionList - 1)) {
                    conditionTitle = conditionTitle.substring(0, conditionTitle.lastIndexOf(','));
                }
            });
        } else {
            conditionList.map((tagCondition, index) => {
                conditionTitle += tagCondition.conditionValue === 'NOT_ASSIGN' ? t('COM_DID_VWL_NOT_ASSIGNED') : tagCondition.conditionValue;
                if(index < (conditionList.length -1)) {
                    conditionTitle += ',';
                }
            });
        }

        return conditionTitle;
    };


    const editSave = () => {
        let assignTagRequest = data.updatedSelectedTags.map(tagItem => {
            let tagId = tagItem.tagId;
            let tagType = tagItem.tagType;
            let tagConditions = [];

            if (tagItem.contentTagConditions === undefined) {
                if (tagItem.tagType === contentConstants.TAG_TYPE_TEXT) {
                    if (tagItem.tagConditions === undefined) {
                        tagConditions.push(-1);
                    } else{
                        tagConditions = tagItem.tagConditions.map(item => item.conditionId);
                    }
                } else if (tagItem.tagType === contentConstants.TAG_TYPE_BOOLEAN) {
                    tagConditions.push(tagItem.tagConditions === undefined ? -1 : tagItem.tagConditions.map(item => item.conditionValue)[0])
                }
            } else {
                if (tagItem.tagType === contentConstants.TAG_TYPE_TEXT) {
                    tagConditions = tagItem.contentTagConditions.filter(item => item.checkCondition === true).map(result => result.tagConditionId);
                } else if (tagItem.tagType === contentConstants.TAG_TYPE_BOOLEAN || tagItem.tagType === contentConstants.TAG_TYPE_NUMBER) {
                    if(tagItem.contentTagConditions.length > 0){
                        tagConditions.push(tagItem.contentTagConditions.map(result => result.tagCondition)[0]);
                    }
                }
            }

            return {tagId : tagId, tagType: tagType, tagConditions: tagConditions};
        });

        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));

        Promise.all([
            contentService.updateContentsExpireDate({
                contentIds: selectedContentIds,
                expirationDate: Moment(expirationDate, "YYYY-MM-DD").format("YYYYMMDD")
            }),
            contentService.updateContentCategory({
                contentIds: selectedContentIds,
                categoryIds: data.selectedCategories
            }),
            contentService.updateContentsAssignTags({
                contentIds: selectedContentIds,
                tags: assignTagRequest
            })
        ]).then(values => {
            toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
            dispatch(contentAction.reloadContent(true));
        }).catch(
            error => {
                console.log(error);
                toastr.error(getErrorMessage(error, error.errorMessage))
            }
        ).finally(() => {
            onClose();
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
        });
    };

    const onClickSubTab = (tab) => {
        setSubTab(tab);
    };

    const onCheckCategory = (a, treeNode) => {
        setData({
            ...data,
            selectedCategories: treeNode.checkedNodes.map(item => item.key),
            isCategoryClicked: true
        })
    };

    const onChangeExpirationDate = (date) => {
        const updateDate = Moment(date).format('YYYY-MM-DD');
        setExpirationDate(updateDate);
    };

    const onExpirationDateClick = () => {
        if(isExpirationDateActivated) {
            setExpirationDate('2999-12-31');
        }else {
            onChangeExpirationDate(new Date());
        }
        setIsExpirationDateActivated(!isExpirationDateActivated);
    };

    const renderCategory = () => {
        return <div className='pop_list_2 category_wrap'>
                  <Category type={'tab'}
                            onCategoryCheck={onCheckCategory}
                            defaultExpandAll={true}
                            innerStyle={{width:'100%', height:'100%', padding:'0px'}}
                            checkStrictly={true}
                            defaultCheckedKeys={data.selectedCategories}/>
               </div>;
    };

    const renderMediaTag = () => {
        return  <div className='pop_list_2 tag_wrap'>
                    <TagSettingView showDevice={false}
                                    showTagValue={true}
                                    selectedTags={data.selectedTags}
                                    size = {'small'}
                                    onChangeTagInfo={onChangeTagInfo}
                    />
                </div>
    };

    const renderExpirationDate = () => {
        return (
            <div className='pop_list_2 expiration_date_wrap'>
                <div style={{display:'flex', justifyContent:'normal', alignItems:'center'}}>
                    <span className="title">{t('COM_EXPIRATION_DATE_KR_DATE')}&nbsp;</span>
                    <DateInput
                        date={expirationDate}
                        width={118}
                        className={'expiration_date'}
                        onChange={(date) => onChangeExpirationDate(date)}
                        disabled={!isExpirationDateActivated}/>
                    <span style={{marginLeft:10}} onClick={onExpirationDateClick} >
                        <Checkbox name={t('TEXT_ENABLE2_P')} checked={isExpirationDateActivated} />
                    </span>
                </div>
                <div style={{color: '#f79394', paddingTop: '2px', lineHeight: '15px', whiteSpace: 'normal'}}>
                    {t('MIS_SID_SERVER_EXPIRED_CONTENT_REMOVED_PLAYLIST')}
                </div>
            </div>
        );
    };

    return (
        <div className='content_multi_edit_popup'>
            <MISDialog
                dialog={{
                    title: t('MIS_SID_20_EDIT_MULTIPLE') ,
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 506,
                    height: 490,
                    position: {x: -150, y: -250},
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'EDIT_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: editSave,
                        },
                        {
                            id: 'EDIT_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose,
                        }
                    ],
                }}>
                <div className="detail_wrap">
                    <div style={{marginBottom: '20px'}}>
                        <SubTab tabs={EDIT_MULTI_SUB_TABS} selected={subTab} style={{width: '530px'}} onClick={onClickSubTab} />
                    </div>
                    <div style={{height:'440px'}}>
                        {
                            subTab === EDIT_MULTI_SUB_TABS[0]
                            && renderCategory()
                        }
                        {
                            subTab === EDIT_MULTI_SUB_TABS[1]
                            && renderMediaTag()
                        }
                        {
                            subTab === EDIT_MULTI_SUB_TABS[2]
                            && renderExpirationDate()
                        }
                    </div>

                    <div className="t_line mt10"></div>
                </div>
            </MISDialog>
        </div>
    );
};

export default withTranslation()(ContentMultiEdit);