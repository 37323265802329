import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { popupAction } from "../../../actions";
import MISDialog from "../MISDialog";
import ReactTable from "react-table";

const VerificationDeviceListPopup = ({id, devices}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [style, setStyle] = useState({
        height: '250px'
    });

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const getScreenRotationString = (screenRotation) => {
        let str = "";

        switch(parseInt(screenRotation)) {
            case 0:
                str = t("COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE");
                break;
            case 90:
            case 270:
                str = t("COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT");
                break;
        }

        return str;
    }

    const dialogProps ={
        title: t("COM_MRMS_CON_MENU_DEVICE_LIST"),
        width :700,
        height :250,
        position: {x: -300, y: -200},
        modal:true,
        isDraggable: false,
        closeOnEscape: true,
        allowMinimize: false,
        onClose:() => closePopup()
    };

    return (
        <MISDialog
            dialog={dialogProps}
            buttons= {{
                rightButtons: [
                    {title: t("COM_BUTTON_CLOSE_P"), id: 'closeSaveRulesetBtn', onClick: ()=> closePopup()}
                ]
        }}>
            <ReactTable
                data={devices}
                minRows={0}
                showPagination={false}
                resizable={false}
                columns={[
                    {
                        Header: t("TABLE_DEVICE_NAME_P"),
                        width: 250,
                        accessor: 'deviceName'
                    },
                    {
                        Header: t("COM_SID_IP_ADDRESS"),
                        width: 150,
                        accessor: 'ipAddress'
                    },
                    {
                        Header: t("TABLE_SCREEN_SIZE_P"),
                        width: 120,
                        accessor: 'screenSize'
                    },
                    {
                        Header: t("TEXT_SCREEN_ROTATION_P"),
                        width: 150,
                        accessor: 'screenRotation',
                        Cell: props => <div><span>{getScreenRotationString(props.original.screenRotation)}</span></div>
                    }
                ]}
                className="-striped -highlight"
                manual
                style={style}
            />
        </MISDialog>
    )
}

export default VerificationDeviceListPopup;