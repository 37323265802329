import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toInteger} from 'lodash';
import {popupAction} from "../../../../actions";
import {commonConstants, WPLAYER} from '../../../../constants';
import NoDataRow from './NoDataRow';
import DeviceRelativeTimeCell from '../../../device/DeviceRelativeTimeCell';
import {messageLine} from "../../../../helper";
import { useMISOpt } from '../../../misopt';

const PlayingContentDetail = ({ device }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {misOption} = useMISOpt();

    const onClickContentSchedule = scheduleId => {
        dispatch(popupAction.addPopup({
            id: scheduleId,
            type: commonConstants.COMMON_DETAIL_VIEW,
            viewType: 'SCHEDULE_CONTENT',
        }));
    };

    const onClickContent = contendId => {
        dispatch(popupAction.addPopup({
            id: contendId,
            type: commonConstants.COMMON_DETAIL_VIEW,
            viewType: 'CONTENT',
        }));
    };

    const onClickMessageSchedule = scheduleId => {
        dispatch(popupAction.addPopup({
            id: scheduleId,
            type: commonConstants.COMMON_DETAIL_VIEW,
            viewType: 'SCHEDULE_MESSAGE',
        }));
    };

    const {contentScheduleId, contentScheduleName, messageScheduleId, messageScheduleName, messageScheduleCreateDate, eList, result = {}} = device;
    const {playingContentScheduleList = []} = result;
    const option = misOption(device.deviceType.toUpperCase(), toInteger(device.deviceTypeVersion)) || {};

    return (
        <div className='detail_view mini_2 mt15'>
            <span className='detail_table_title'>{t('TEXT_CONTENT_SCHEDULE_P')}</span>
            <div
                id='schDownloadTableList'
                className='none_scrolling detail_table mt9'
                style={{ width: 450, overflowY: 'hidden', overflowX: 'auto' }}>
                <table>
                    <colgroup>
                        <col width='57px' />
                        <col />
                        <col width='120px' />
                        <col width='61px' />
                        <col width='60px' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t('TEXT_FRAME_NAME_P')}</th>
                            <th>{t('TEXT_SCHEDULE_NAME_P')}</th>
                            <th>{t('TEXT_CONTENT_NAME_P')}</th>
                            <th>{t('TABLE_TYPE_P')}</th>
                            <th>{t('TEXT_LAST_MODIFIED_P')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <NoDataRow show={playingContentScheduleList.length === 0} colSpan={5} />
                        {
                            playingContentScheduleList.map(schedule => {
                                return (
                                    <tr>
                                        <td>
                                            <div className='autoline'>{schedule.playingSchFrameName}</div>
                                        </td>
                                        <td>
                                            <div className='autoline'>
                                                <span className='link_cell' onClick={() => onClickContentSchedule(contentScheduleId)}>
                                                    {contentScheduleName || '-'}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='autoline'>
                                                <span className='link_cell' onClick={() => onClickContent(schedule.playingSchContentId)}>
                                                    {schedule.playingSchContentName}
                                                </span>
                                            </div>
                                        </td>
                                        <td>{schedule.playingSchMediaType}</td>
                                        <td>
                                            <DeviceRelativeTimeCell value={schedule.playingSchCreateDate} />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
            <span className='detail_table_title mt25'>{t('COM_TEXT_TITLE_MESSAGE_SCHEDULE_P')}</span>
            <div className='none_scrolling detail_table mt9'>
                <table>
                    <colgroup>
                        <col width='237px' />
                        <col width='140px' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t('COM_TEXT_MESSAGE_NAME_P')}</th>
                            <th>{t('TEXT_LAST_MODIFIED_P')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <NoDataRow show={!messageScheduleName} colSpan={2} />
                        {
                            !!messageScheduleName &&
                            <tr>
                                <td>
                                    <span className='link_cell' onClick={() => onClickMessageSchedule(messageScheduleId)}>{messageScheduleName}</span>
                                </td>
                                <td>{messageLine(messageScheduleCreateDate)}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            {
                option.eventSchedule && <>
                    <span className='detail_table_title mt25'>{t('MIS_TEXT_EVENT_SCHEDULE_P')}</span>
                    <div className='none_scrolling detail_table mt9'>
                        <table>
                            <colgroup>
                                <col width='237px' />
                                <col width='140px' />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{t('COM_ADMIN_DEVICEEVENT_ALARMSEARCH_CONTENTS_EVENT_NAME')}</th>
                                    <th>{t('TEXT_LAST_MODIFIED_P')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <NoDataRow show={!eList || eList.length === 0} colSpan={2} />
                                {
                                    eList && eList.map(event => {
                                        return (
                                            <tr>
                                                <td>{event.eventName}</td>
                                                <td>{messageLine(event.modifyDate)}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </>
                }
        </div>
    );
};

export default PlayingContentDetail;
