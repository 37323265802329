import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import MISDialog from '../../MISDialog';
import './MemoPopup.css';
import {deviceService} from '../../../../services';
import {getErrorMessage} from '../../../../helper/responseHandler';
import {deviceAction} from '../../../../actions';
import moment from "moment";

const MemoPopup = ({ deviceId, onSave, onDelete, onClose }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [memoData, setMemoData] = useState({});
    const {userId, memo, regDate} = memoData;
    const textareaRef = useRef();
    const convertTime = regDate ? moment(regDate).format('YYYY-MM-DD HH:mm:ss') : '';

    const onSaveMemo = () => {
        deviceService.updateDeviceMemo(deviceId, memo).then(res => {
            dispatch(deviceAction.refreshDevices([deviceId]));
            onSave && onSave();
        }).catch(error => toastr.error(getErrorMessage(error)));
    };

    const onDeleteMemo = () => {
        deviceService.deleteDeviceMemo(deviceId).then(res => {
            dispatch(deviceAction.refreshDevices([deviceId]));
            onDelete && onDelete(res);
        }).catch(error => toastr.error(getErrorMessage(error)));
    };

    useEffect(() => {
        deviceService.fetchDeviceMemoById(deviceId).then(res => {
            setMemoData(res.items);
        }).catch(error => toastr.error(getErrorMessage(error)));
    }, [deviceId]);

    useEffect(() => {
        textareaRef.current.focus();
    }, []);

    return (
        <div className='device_memo_popup'>
            <MISDialog
                dialog={{
                    title: t('COM_IDS_MEMO'),
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 490,
                    height: 265,
                    onClose: onClose,
                }}
                buttons={{
                    leftButtons: [
                        {
                            id: 'MEMO_DELETE',
                            title: t('COM_BUTTON_DELETE'),
                            onClick: onDeleteMemo,
                            disable: !regDate,
                        }
                    ],
                    rightButtons: [
                        {
                            id: 'MEMO_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: onSaveMemo,
                        },
                        {
                            id: 'MEMO_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose,
                        }
                    ],
                }}>
                    <div className="detail_view mini_p" style={{height: 250}}>
                        <div>{userId}</div>
                        <textarea maxLength={1000} value={memo} onChange={e => setMemoData({...memoData, memo: e.target.value})} ref={textareaRef}></textarea>
                        <br/>
                        <div>{convertTime}</div>
                    </div>
            </MISDialog>
        </div>
    );
};

export default MemoPopup;