import {mergeWith} from 'lodash';
import {contentConstants, menuConstants} from '../constants';
import {getTabWidth, pickByName} from "../helper";
import Stack from '../components/utils/Stack';
import {getCache, isCacheTable, updateCache} from "../helper/cache/tableCache";

const initialState  = {
    menus: [],
    tabs : [],
    currentMenu: '',
    submenu: [],
    currentContentId: '',
    tabWidth: 169,
    submenuWidth: 253,
    reloadGroup: {

    },
    selectGroup: {},
    reloadKey:0,
    forceReload: false
}

const tabHistories = new Stack();

export const menu = (state = initialState, action) => {
    const {tabs} = state;
    switch (action.type) {
        case menuConstants.INIT_MENUS:
            return {
                ...state,
                menus: action.menus
            }
        case menuConstants.UPDATE_SUBMENU_COUNTER:
            if(state.submenu.id === action.menu.id) {
                return {
                    ...state,
                    submenu: mergeWith({}, state.submenu, pickByName(action.menu, 'number'))
                }
            } else {
                return state;
            }
        case menuConstants.ADD_TAB:
            tabHistories.push(action.tab.id);
            const updateTabs = tabs.map(
                tab => {
                    if(tab.id === action.tab.id && tab.id === 'LED_CABINET') {
                        tab.tabTitle = action.tab.tabTitle;
                    }
                    return tab.id === action.tab.id ? {...tab, active : true} : {...tab, active : false}
                }
            );
            let index = tabs.findIndex(tab => tab.id === action.tab.id);
            if (index < 0) {
                action.tab.active = true;
                updateTabs.push(action.tab);
                let width = getTabWidth(updateTabs, state.tabWidth, state.submenuWidth);
                return {
                    ...state,
                    tabs: updateTabs,
                    currentContentId: action.tab.id === 'LED_CABINET' ? action.tab.id : getContentId(action.submenu),
                    submenu: action.submenu,
                    groupId: undefined,
                    tabWidth: width
                }
            } else {
                return {
                    ...state,
                    tabs: updateTabs,
                    currentContentId: action.tab.id === 'LED_CABINET' ? action.tab.id : getContentId(action.submenu),
                    submenu: action.submenu,
                    groupId: undefined,
                    reloadKey: state.reloadKey+1, //if already exist tab and try to add tab, update reloadKey and use it as component Key
                    /*[action.tab.id] : undefined*/
                }
            }
        case menuConstants.ACTIVE_TAB:
            const {tabId} = action;
            tabHistories.push(tabId);
            let currentContentId = undefined;
            let cache = undefined;
            if (isCacheTable(tabId)) {
                cache = getCache(tabId);
                if (cache && cache.lastSubmenuId) {
                    currentContentId = cache.lastSubmenuId;
                }
            }
            if (currentContentId === undefined) {
                currentContentId = tabId === 'LED_CABINET' ? tabId : getContentId(action.submenu);
            }
            if (currentContentId === undefined) {
                currentContentId = action.tabId;
            }

            const {submenu} = state;
            menuConstants[submenu.id] = submenu;

            return {
                ...state,
                tabs: tabs.map(
                    tab => tab.id === action.tabId
                        ? {...tab, ...tab.active = true}
                        : {...tab, ...tab.active = false}
                ),
                submenu: action.submenu,
                currentContentId: currentContentId,
                groupId: undefined
            }
        case menuConstants.LOAD_CONTENT:
            if (action.isShowSubmenu !== undefined && action.isShowSubmenu === false) {
                action.submenu.hasSubmenu = false;
            }

            return {
                ...state,
                currentContentId: action.contentId,
                submenu: action.submenu,
                groupId: undefined,                 // initialize group,userId,organizationId   - BY PLAYLIST
                userId: undefined,
                organizationId: undefined,
                forceReload: action.forceReload !== undefined ? action.forceReload : state.forceReload
            }
        case menuConstants.LOAD_GROUP_CONTENT:
            return {
                ...state,
                currentContentId : action.contentId,
                submenu: action.submenu,
                groupId: action.groupId,
                userId : action.userId,
                organizationId: action.organizationId,
                forceReload : action.forceReload !== undefined ? action.forceReload : state.forceReload,
                /*[action.selected.tabId] : action.selected*/
            }
        case menuConstants.REMOVE_TAB:
            const dataStore = [...tabHistories.dataStore];
            const orderStatusChanged = JSON.stringify(tabs.map((tab) => tab.id)) !== JSON.stringify(dataStore)
            let lastTabId = tabHistories.pop();
            if (orderStatusChanged && lastTabId === action.tabId) {
                lastTabId = tabHistories.pop();
            }
            const findTab = tabs.filter(
                tab => tab.id !== action.tabId
            )
            let checked = false;
            findTab.map(tab => {
                if (tab.id === lastTabId) {
                    checked = true;
                    tab.active = true;
                } else {
                    tab.active = false;
                }
            });
            if (!checked) {
                lastTabId = findTab[findTab.length-1].id;
                findTab[findTab.length-1].active = true;
            }
            let contentId = getContentId(menuConstants[lastTabId]);
            if (!contentId && isCacheTable(lastTabId)) {
                const curCache = getCache(lastTabId);
                if (curCache && curCache.lastSubmenuId) {
                    contentId = curCache.lastSubmenuId;
                }
            }
            let width = getTabWidth(findTab, state.tabWidth, state.submenuWidth);

            const isReloadPage = checkReloadPage(action.tabId);
            if (isReloadPage) {
                let reloadTable = '';
                switch (action.tabId) {
                    case 'NEW_PLAYLIST':
                    case 'EDIT_PLAYLIST':
                        reloadTable =  'PLAYLIST';
                        break;
                    case 'NEW_SCHEDULE':
                    case 'EDIT_SCHEDULE':
                    case 'NEW_MESSAGE':
                    case 'EDIT_MESSAGE':
                    case 'NEW_EVENT':
                    case 'EDIT_EVENT':
                    case 'NEW_EVENT_CONDITION':
                    case 'EDIT_EVENT_CONDITION':
                        reloadTable = 'SCHEDULE';
                        break;
                    case 'NEW_RULESET':
                    case 'EDIT_RULESET':
                        reloadTable = 'RULESET';
                        break;
                }
                const cache = getCache(reloadTable);
                updateCache(reloadTable, {...cache, isLoaded: false})
            }
            tabHistories.push(contentId);
            return {
                ...state,
                tabs: findTab,
                tabWidth: width,
                currentContentId: contentId,
                submenu: menuConstants[lastTabId],
            }
        case menuConstants.ACTIVE_GROUP_NODE:
            if(action.noContentUpdate) {
                return {
                    ...state,
                    submenu: action.submenu
                }
            }
            return {
                ...state,
                currentContentId : action.contentId,
                submenu: action.submenu
            }
        case menuConstants.ACTIVE_CHILD_GROUP_NODE:
            return {
                ...state,
                submenu: action.submenu
            }
        case contentConstants.GET_CONTENT_GROUP :
            if(action.noContentUpdate) {
                return {
                    ...state,
                    submenu: action.submenu,
                    loadingGroup: {
                        ...state.loadingGroup,
                        loading : false
                    }
                }
            }
            return {
                ...state,
                currentContentId : action.contentId,
                submenu: action.submenu,
                loadingGroup: {
                    ...state.loadingGroup,
                    loading : false
                }
            }
        case contentConstants.GET_CONTENT_SCHEDULE :
            const id = 'NEW_SCHEDULE';
            const title = 'Create Schedule';
            let contentSubmenu = menuConstants['NEW_SCHEDULE'];
            contentSubmenu.contents = action.contents;
            tabs.map(
              tab => tab.active = false
            );
            return {
                ...state,
                tabs: tabs.concat({id: id, title : title, active: true, close: true}),
                currentContentId : action.contentId,
                submenu: contentSubmenu
            }

        case menuConstants.UPDATE_SUBMENU:
            return {
                ...state,
                submenu: action.submenu
            }
        case menuConstants.LOAD_SIMPLE_SCHEDULE:
            return {
                ...state,
                submenu: action.submenu
            }
        case menuConstants.UPDATE_TAB:
            const updateWidth = getTabWidth(tabs, state.tabWidth, state.submenuWidth);
            return {
                ...state,
                tabWidth: updateWidth
            }
        case menuConstants.SHOW_SUBMENU:
            return {
                ...state,
                submenu: {
                    ...state.submenu,
                    showSubmenu: !state.submenu.showSubmenu
                }
            }
        case menuConstants.UPDATE_SUBMENU_WIDTH:
            const newTabWidth = getTabWidth(tabs, state.tabWidth, action.width);
            return {
                ...state,
                submenuWidth: action.width,
                tabWidth: newTabWidth
            }
        case menuConstants.RELOAD_GROUP:
            return {
                ...state,
                reloadGroup: Object.assign({}, {
                    id: action.submenuId,
                    groupId: action.groupId
                })
            }
        case menuConstants.DESTROY_RELOAD_GROUP:
            return {
                ...state,
                reloadGroup: {}
            }
        case menuConstants.SELECT_GROUP:
            return {
                ...state,
                selectGroup: {id: action.submenuId, groupIds: action.groupIds},
            }
        case menuConstants.FORCE_RELOAD_CONTENT:
            return {
                ...state,
                reloadKey: state.reloadKey+1
            }
        case menuConstants.REQUEST_GROUP:
            return {
                ...state,
                loadingGroup : {
                    id : action.id,
                    dom: action.dom,
                    loading: true
                }
            }
        default :
            return state;
    }
}

const reloadPages = ['NEW_PLAYLIST', 'EDIT_PLAYLIST', 'NEW_SCHEDULE', 'EDIT_SCHEDULE', 'NEW_MESSAGE', 'EDIT_MESSAGE', 'NEW_EVENT', 'EDIT_EVENT', 'NEW_RULESET', 'EDIT_RULESET', 'NEW_EVENT_CONDITION', 'EDIT_EVENT_CONDITION']
const checkReloadPage = (id) => {
    return reloadPages.find(tab => tab === id) !== undefined;
}

const getContentId = submenu => {
    let id = undefined;
    if (submenu.nodes.length === 0) {
        return submenu.id;
    } else {
        submenu.nodes.find(
            node => {
                if (node.active) {
                    id = node.id
                }
                if (node.children !=undefined && node.children.length > 0) {
                    node.children.find(
                        child => {
                            if (child.active) {
                                id = child.id;
                            }
                        }
                    )
                }
            }
        )
    }

    if(id === undefined && submenu.id === 'RULESET') {
        return "RULESET_BY_GROUP";
    }

    return id;
}