import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../components/react-dialog';
import {deviceOptions} from '../../misopt';
import Button from '../../button/Button';
import './QuickControlPopup.css';
import Select from '../../selectbox/Select';
import NumberInput from '../../input/NumberInput';
import DeviceNumberInput from '../../device/DeviceNumberInput';
import MISDialog from "../MISDialog";

const menus = [
    'power',
    'panel',
    'remote',
    'restart',
    'save_source',
    'save_sch_channel',
    'save_sound',
    'save_mute',
    'clean_storage',
];

// if($.misopt.get('misUIInfo') != null){
//     if($.misopt.get('misUIInfo').RMS_MODE){
//         rmsMode = true;
//     }
//     if(!$.misopt.get('misUIInfo').DEVICE_POWER_CONTROL){
//         devicePowerControl = false
//     }
// }
const QuickControlPopup = ({onControl, onClose}) => {
    const {t} = useTranslation();
    const [channel, setChannel] = useState({value: undefined, changed: false});
    const [volume, setVolume] = useState({value: undefined, changed: false});
    const [source, setSource] = useState({value: undefined, changed: false});
    const [disabled, setDisabled] = useState((new Array(12)).fill(false));
    const [quickControlResult, setQuickControlResult] = useState(undefined);

    const onResult = (result) => {
        if (result === 'fail') {
            setQuickControlResult('fail');
        } else {
            setQuickControlResult(undefined);
        }
    }

    const onQuickControl = (e, menu, value, btnIdx) => {
        if(menu === menus[4]) {
            setSource({...source, changed: false});
        } else if(menu === menus[5]) {
            setChannel({...channel, changed: false});
        } else if(menu === menus[6]) {
            setVolume({...volume, changed: false});
        } else {
            const newDisabled = [...disabled];
            newDisabled[btnIdx] = true;
            setDisabled(newDisabled);
        }

        onControl(menu, value, btnIdx !== 1, onResult);

        if(menu !== menus[4] && menu !== menus[5] && menu !== menus[6]) {
            setTimeout(() => {
                const newDisabled = [...disabled];
                newDisabled[btnIdx] = false;
                setDisabled(newDisabled);
            }, 3000);
        }
    };

    return (
        <div className='device_quick_control_popup_wrap'>
            <MISDialog
                dialog={{title: t('MIS_SID_20_QUICK_CONTROL'), isDraggable: true, closeOnEscape: true, onClose: onClose, position: {X: 'right', Y: 'center'}, width:205, height: 770}}
            >
                <div className='quick_control_wrap'>
                    <ul>
                        <li>
                            <div>{t('TABLE_POWER_P')}</div>
                            <div className='two_btn_control'>
                                <Button title={t('TEXT_ON_P')} onClick={e => onQuickControl(e, menus[0], '1', 0)} disabled={disabled[0]} />
                                <Button title={t('BUTTON_OFF')} onClick={e => onQuickControl(e, menus[0], '0', 1)} disabled={disabled[1]} />
                            </div>
                        </li>
                        <li>
                            <div>{t('TABLE_PANEL_STATUS_P')}</div>
                            <div className='two_btn_control'>
                                <Button title={t('TEXT_ON_P')} onClick={e => onQuickControl(e, menus[1], '0', 2)} disabled={disabled[2]} />
                                <Button title={t('BUTTON_OFF')} onClick={e => onQuickControl(e, menus[1], '1', 3)} disabled={disabled[3]} />
                            </div>
                        </li>
                        <li>
                            <div>{t('COM_ID_DEVICEDISPLAY_REMOTE_CONTROL')}</div>
                            <div className='one_btn_control'>
                                <Button title={t('COM_ID_DEVICEDISPLAY_REMOTE_CONTROL')} onClick={e => onQuickControl(e, menus[2], menus[2], 4)} disabled={disabled[4]} />
                            </div>
                        </li>
                        <li>
                            <div>{t('TEXT_RESTART_P')}</div>
                            <div className='one_btn_control'>
                                <Button title={t('TEXT_RESTART_P')} onClick={e => onQuickControl(e, menus[3], 'restart', 5)} disabled={disabled[5]} />
                            </div>
                        </li>
                        <li>
                            <div>{t('COM_DID_LFD_SOURCE')}</div>
                            <div>
                                <Select width={130} value={source.value} selects={deviceOptions.inputSource} onChange={(e, value) => setSource({value, changed: true})} />
                            </div>
                            {
                                (source.changed || quickControlResult === 'fail') &&
                                <div className='one_btn_control'>
                                    <Button title={t('BUTTON_OK_P')} onClick={e => onQuickControl(e, menus[4], source.value, 6)} disabled={disabled[6]} />
                                </div>
                            }
                        </li>
                        <li>
                            <div>{t('MIS_SCHEDULE_CHANNEL')}</div>
                            <div className='number_control'>
                                <DeviceNumberInput width={70} min={1} max={99} value={channel.value} onChange={(e, value) => setChannel({value, changed: true})} />
                            </div>
                            {
                                channel.changed &&
                                <div className='one_btn_control'>
                                    <Button title={t('BUTTON_OK_P')} onClick={e => onQuickControl(e, menus[5], channel.value, 7)} />
                                </div>
                            }
                        </li>
                        <li>
                            <div>{t('TABLE_VOLUME_P')}</div>
                            <div className='number_control'>
                                <DeviceNumberInput width={70} max={100} value={volume.value} onChange={(e, value) => setVolume({value, changed: true})} />
                            </div>
                            {
                                volume.changed &&
                                <div className='one_btn_control'>
                                    <Button title={t('BUTTON_OK_P')} onClick={e => onQuickControl(e, menus[6], volume.value, 8)} />
                                </div>
                            }
                        </li>
                        <li>
                            <div>{t('TABLE_MUTE_P')}</div>
                            <div className='two_btn_control'>
                                <Button title={t('TEXT_ON_P')} onClick={e => onQuickControl(e, menus[7], '1', 9)} disabled={disabled[9]} />
                                <Button title={t('BUTTON_OFF')} onClick={e => onQuickControl(e, menus[7], '0', 10)} disabled={disabled[10]} />
                            </div>
                        </li>
                        <li>
                            <div>{t('MIS_SID_EMPTY_STORAGE')}</div>
                            <div className='one_btn_control'>
                                <Button title={t('MIS_SID_EMPTY_STORAGE')} onClick={e => onQuickControl(e, menus[8], 'clean', 11)} disabled={disabled[11]} />
                            </div>
                        </li>
                    </ul>
                </div>
            </MISDialog>
        </div>
    );
};

export default QuickControlPopup;