import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import MISDialog from '../../MISDialog';
import TextInput from '../../../input/TextInput';
import {deviceService} from '../../../../services';
import Select from '../../../selectbox/Select';

const EditSoftwarePopup = ({softwareId, onSave, onClose}) => {
    const {t} = useTranslation();
    const [software, setSoftware] = useState({});
    const {deviceModelNameList = []} = software;

    const deviceModelNames = deviceModelNameList.map(name => {
        return {value: name, title: name};
    });

    const onClickSave = () => {
        deviceService.updateSoftware(software).then(res => {
            toastr.success(t('COM_TEXT_SUCCESS_P'));
            if(onSave) {
                onSave();
            }
        }).catch(error => console.log(error));
    };

    useEffect(() => {
        deviceService.fetchSoftwareForEdit(softwareId).then(res => {
            setSoftware(res.items);
        }).catch(error => console.log(error));
    }, [softwareId]);

    return (
        <MISDialog 
            dialog={{
                title: t('TEXT_TITLE_SW_EDIT_P'),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 420,
                height: 160,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: onClickSave,
                        // disable: !isEmpty(error)
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className="detail_view">
                <table>
                    <colgroup>
                        <col width="140px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{t('MIS_TEXT_DEVICE_TYPE_P')}</th>
                            <td>
                                {software.deviceType}
                            </td>
                        </tr>
                        {
                            software.deviceModelNameList && software.deviceModelNameList.length > 0 &&
                            <tr>
                                <th>{t('LIST_TITLE_APPLIED_MODEL')}</th>
                                <td>
                                    <Select width={245}
                                            optionStyle={{
                                                display: 'block',
                                                width: '243px',
                                                maxHeight: '200px',
                                                top: '170px'
                                            }}
                                            selects={deviceModelNames} value={software.deviceModelName}
                                            onChange={(e, value) => setSoftware({...software, deviceModelName: value})}
                                    />
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>{t('COM_TEXT_SOFTWARE_NAME_P')}</th>
                            <td>
                                <TextInput width={245} value={software.softwareName} maxLength={50} onChange={e => setSoftware({...software, softwareName: e.target.value})} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_LIST_TITLE_VERSION')}</th>
                            <td>
                                {software.softwareVersion}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </MISDialog>
    );
};

export default EditSoftwarePopup;