import React from 'react';
import {useTranslation} from 'react-i18next';
import NoDataRow from './NoDataRow';

const DeviceHealthDetail = ({ device }) => {
    const {t} = useTranslation();
    const {result: {errorList = []} = {}} = device || {};

    return (
        <div className='detail_view mini_5 mt15'>
            <span className='detail_table_title'></span>
            <div className='none_scrolling detail_table'>
                <table>
                    <colgroup>
                        <col width='217px' />
                        <col width='80px' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t('TEXT_DESCRIPTION_P')}</th>
                            <th>{t('MIS_SID_DEVICE_TIME')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            errorList && errorList.length > 0 &&
                            errorList.map(({bodyFormat, clientTime}, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{bodyFormat}</td>
                                        <td>{clientTime}</td>
                                    </tr>
                                );
                            })
                        }
                        <NoDataRow show={!errorList || errorList.length === 0} colSpan={2} />
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DeviceHealthDetail;
