import React, {useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import classNames from 'classnames';
import Slide from 'rc-slider';
import {toastr} from 'helper/toastrIntercept';
import {get, isNil, map, toInteger, toString} from 'lodash';
import 'rc-time-picker/assets/index.css';
import {deviceOptions} from '../../../misopt';
import RadioGroup from '../../../radio/RadioGroup';
import Chip from '../../../chip/Chip';
import DateInput from '../../../input/DateInput';
import Checkbox from '../../../checkbox/Checkbox';
import Select from '../../../selectbox/Select';
import PlusButton from '../../../button/PlusButton';
import {createRepeatStrings, days, getRepeatValue} from '../edit/EditTime';
import '../edit/EditTime.css';
import TimeSelect from '../../../selectbox/TimeSelect';
import moment from 'moment';
import '../edit/EditDevice.css';
import {time12hhTo24hh, time24hhTo12hh} from "../../../../helper";

// if(startDaysBinary & 1 == 1){ isCheckedOnSun = 'checked';} startDaysBinary >>= 1;
// 					if(startDaysBinary & 1 == 1){ isCheckedOnMon = 'checked';} startDaysBinary >>= 1;
// 					if(startDaysBinary & 1 == 1){ isCheckedOnTue = 'checked';} startDaysBinary >>= 1;
// 					if(startDaysBinary & 1 == 1){ isCheckedOnWed = 'checked';} startDaysBinary >>= 1;
// 					if(startDaysBinary & 1 == 1){ isCheckedOnThu = 'checked';} startDaysBinary >>= 1;
// 					if(startDaysBinary & 1 == 1){ isCheckedOnFri = 'checked';} startDaysBinary >>= 1;
// 					if(startDaysBinary & 1 == 1){ isCheckedOnSat = 'checked';}
// timerSeq; ; ; onTimerStatus; ; ; ; offTimerStatus;
const timeFormat = 'hh:mm A';
const mf = m => m < 10 ? '0' + m : m;

const Timer = ({type, index, usage, repeat, time, volume, source, inputSources, holidayEnable, changed, onChange}) => {
    const {t} = useTranslation();
    const timerIndex = index + 1;

    const onChangeDay = (e, i) => {
        if(isNil(repeat) || repeat.length < 7) {
            return;
        }

        let str = repeat.substr(0, i) + (e.target.checked ? '1' : '0') + repeat.substr(i + 1);
        const repeatValue = getRepeatValue(str);

        if(type === 'on') {
            onChange({'data-name': `timerConfTimer${timerIndex}.timerManualWeekday`}, str);
            onChange({'data-name': `timerConfTimer${timerIndex}.timerRepeat`}, repeatValue);
        } else {
            onChange({'data-name': `timerConfTimer${timerIndex}.timerOffManualWeekday`}, str);
            onChange({'data-name': `timerConfTimer${timerIndex}.timerOffRepeat`}, repeatValue);
        }
    };

    const onChangeTime = value => {

        /*
        let hh = value.hour(); // parseInt(e.target.value.substr(0, 2));
        let mm = value.minute(); //parseInt(e.target.value.substr(3, 5));
        let am = hh <= 12 ? 1 : 0;

        if(am === 0) {
            hh -= 12;
        }
        */

        const time = time24hhTo12hh(value.hour(), value.minute());

        if(type === 'on') {
            onChange({'data-name': `timerConfTimer${timerIndex}.timerOnH`}, toString(time.hh));
            onChange({'data-name': `timerConfTimer${timerIndex}.timerOnM`}, toString(mf(time.mm)));
            onChange({'data-name': `timerConfTimer${timerIndex}.timerOnAmpm`}, toString(time.ampm));
        } else {
            onChange({'data-name': `timerConfTimer${timerIndex}.timerOffH`}, toString(time.hh));
            onChange({'data-name': `timerConfTimer${timerIndex}.timerOffM`}, toString(mf(time.mm)));
            onChange({'data-name': `timerConfTimer${timerIndex}.timerOffAmpm`}, toString(time.ampm));
        }
    };

    const onChangeVolume = value => {
        onChange({'data-name': `timerConfTimer${timerIndex}.timerVolume`}, value);
    };

    const onChangeHoliday = e => {
        if(type === 'on') {
            if(e.target.checked) {
                if(holidayEnable == 0) {
                    onChange({'data-name': `timerConfTimer${timerIndex}.timerHolidayEnable`}, 2);
                } else if(holidayEnable == 3) {
                    onChange({'data-name': `timerConfTimer${timerIndex}.timerHolidayEnable`}, 1);
                }
            } else {
                if(holidayEnable == 1) {
                    onChange({'data-name': `timerConfTimer${timerIndex}.timerHolidayEnable`}, 3);
                } else if(holidayEnable == 2) {
                    onChange({'data-name': `timerConfTimer${timerIndex}.timerHolidayEnable`}, 0);
                }
            }
        } else {
            if(e.target.checked) {
                if(holidayEnable == 0) {
                    onChange({'data-name': `timerConfTimer${timerIndex}.timerHolidayEnable`}, 3);
                } else if(holidayEnable == 2) {
                    onChange({'data-name': `timerConfTimer${timerIndex}.timerHolidayEnable`}, 1);
                }
            } else {
                if(holidayEnable == 1) {
                    onChange({'data-name': `timerConfTimer${timerIndex}.timerHolidayEnable`}, 2);
                } else if(holidayEnable == 3) {
                    onChange({'data-name': `timerConfTimer${timerIndex}.timerHolidayEnable`}, 0);
                }
            }
        }
    };

    const postfix = type + index;

    return (
        <>
            <tr>
                <td width='100px' className={classNames({
                    'changed': type === 'on' ? get(changed, `timerConfTimer${timerIndex}.timerOnStatus`) : get(changed, `timerConfTimer${timerIndex}.timerOffStatus`)
                })}>
                    {type === 'on' ? t('COM_IDS_TXT_ON_TIMER') : t('COM_IDS_TXT_OFF_TIMER')}
                </td>
                <td colSpan='2'>
                    <RadioGroup 
                        propertyName={type === 'on' ? `timerConfTimer${timerIndex}.timerOnStatus` : `timerConfTimer${timerIndex}.timerOffStatus`} 
                        selects={deviceOptions.reverseUsageOptions} value={usage} onChange={onChange} />
                </td>
            </tr>
            {
                usage === '1' &&
                <>
                    <tr className="time_detail_day">
                        <td></td>
                        <td className={classNames({
                            'changed': type === 'on' ? get(changed, `timerConfTimer${timerIndex}.timerManualWeekday`) : get(changed, `timerConfTimer${timerIndex}.timerOffManualWeekday`)
                        })}>
                            {t('TEXT_DAY_P')}
                        </td>
                        <td>
                            {
                                repeat && repeat.length > 0 &&
                                days.map((day, i) => {
                                    const key = `timer_day_${type}_${index}_${i}`;
                                    return <Checkbox id={key} key={key} name={t(day)} checked={repeat.charAt(i) === '1'} onChange={e => onChangeDay(e, i)} />
                                })
                            }
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className={classNames({
                            'changed': type === 'on' ? get(changed, `timerConfTimer${timerIndex}.timerOnH`) : get(changed, `timerConfTimer${timerIndex}.timerOffH`)
                        })}>
                            {type === 'on' ? t('TABLE_ON_TIMER_SETTING_P') : t('TABLE_OFF_TIMER_SETTING_P')}
                        </td>
                        <td>
                            {
                                time &&
                                <TimeSelect width={130} showSecond={false} value={time} onChange={onChangeTime} format={timeFormat} />
                                // <TimeInput width={130} showSeconds={false} value={time} style={{textAlign: 'center'}} onChange={onChangeTime} />
                            }
                        </td>
                    </tr>
                    {
                        type === 'on' &&
                        <tr>
                            <td></td>
                            <td className={classNames({
                                'changed': get(changed, `timerConfTimer${timerIndex}.timerVolume`)
                            })}>
                                {t('TABLE_VOLUME_P')}
                            </td>
                            <td>
                                <div className='mr8' style={{width: 370, paddingLeft: '10px', display: 'inline-block'}}>
                                    <Slide min={0} value={toInteger(volume)} max={100} onChange={onChangeVolume} />
                                </div>
                                <span>{volume}</span>
                            </td>
                        </tr>
                    }
                    {
                        type === 'on' &&
                        <tr>
                            <td></td>
                            <td className={classNames({
                                'changed': get(changed, `timerConfTimer${timerIndex}.timerSource`)
                            })}>
                                {t('COM_DID_LFD_SOURCE')}
                            </td>
                            <td>
                                <Select width={165} propertyName={`timerConfTimer${timerIndex}.timerSource`} value={source} selects={inputSources} onChange={onChange} />
                            </td>
                        </tr>
                    }
                    <tr>
                        <td></td>
                        <td className={classNames({
                            'changed': get(changed, `timerConfTimer${timerIndex}.timerHolidayEnable`)
                        })}>
                            {t('DID_LAYOUTS_COMMON_HOLIDAY')}
                        </td>
                        <td>
                            <Checkbox id={`holidayEnable_${postfix}`} name={t('BUTTON_APPLY')} propertyName={`timerConfTimer${timerIndex}.timerHolidayEnable`} checked={holidayEnable == 1 || (type === 'on' ? holidayEnable == 2 : holidayEnable == 3)} onChange={onChangeHoliday} />
                        </td>
                    </tr>
                </>
            }
        </>
    );
};

const getTimeStr = (hh, mm, ampm) => {
    if(ampm != 1) {
        return `${parseInt(hh) + 12}:${mm}`;
    }
    return `${hh}:${mm}`;
};

const convDate = date => {
    if (date === undefined || date === {} || date === null) {
        const d = new Date();
        return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    }
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

const EditPresetTime = ({
    timeInfo,
    onChange,
    onChangeChecked,
}) => {
    const {t} = useTranslation();
    const today = new Date();
    const [showTimer, setShowTimer] = useState([false, false, false, false, false, false, false]);
    const [holidayPeriod, setHolidayPeriod] = useState([today, today]);
    const {data: {timerConfTimer1, timerConfTimer2, timerConfTimer3, timerConfTimer4, timerConfTimer5, timerConfTimer6, timerConfTimer7, 
        timerHoliday, inputSourceList = []} = {}, changed} = timeInfo || {};
    const timerConfTimerList = [timerConfTimer1, timerConfTimer2, timerConfTimer3, timerConfTimer4, timerConfTimer5, timerConfTimer6, timerConfTimer7];
    const deviceTimeHolidayList = (timerHoliday && timerHoliday.split(',')) || [];
    const scrollRef = useRef(null);

    const onRepeat = createRepeatStrings(timerConfTimerList, 'timerRepeat', 'timerManualWeekday');
    const offRepeat = createRepeatStrings(timerConfTimerList, 'timerOffRepeat', 'timerOffManualWeekday');
    const inputSources = inputSourceList.length > 0 ? map(inputSourceList, source => {
        return {value: source.code, title: source.text};
    }) : deviceOptions.inputSource;

    const onClickTimer = i => {
        const newArr = [...showTimer];
        newArr[i] = !newArr[i];
        setShowTimer(newArr);
    };

    const getTimerStr = timerWeekday => {
        if(timerWeekday === '0000000') {
            return t('TEXT_ONCE_P');
        } else if(timerWeekday === '1111111') {
            return t('TEXT_EVERYDAY_P');
        } else if(timerWeekday === '0111110') {
            return `${t('COM_TEXT_DAY_MONDAY_P')} ~ ${t('COM_TEXT_DAY_FRIDAY_P')}`;
        } else if(timerWeekday === '0111111') {
            return `${t('COM_TEXT_DAY_MONDAY_P')} ~ ${t('COM_TEXT_DAY_SATURDAY_P')}`;
        } else if(timerWeekday === '1000001') {
            return `${t('COM_TEXT_DAY_SATURDAY_P')} ~ ${t('COM_TEXT_DAY_SUNDAY_P')}`;
        } else {
            let str = ''
            for(let a = 0; a < 7; a++) {
                if(timerWeekday.charAt(a) === '1') {
                    if(str.length > 0) {
                        str += '/';
                    }
                    str += t(days[a]);
                }
            }
            return str;
        }
    };

    const onChangeHolidayStart = value => {
        const newHolidayPeriod = [...holidayPeriod];
        newHolidayPeriod[0] = value;
        setHolidayPeriod(newHolidayPeriod);
    };

    const onChangeHolidayEnd = value => {
        const newHolidayPeriod = [...holidayPeriod];
        newHolidayPeriod[1] = value;
        setHolidayPeriod(newHolidayPeriod);
    };

    const onAddHoliday = () => {
        const [holidayStart, holidayEnd] = holidayPeriod;
        if(!holidayStart || !holidayEnd) {
            return;
        }

        if(!deviceTimeHolidayList.every(holiday => {
            const [, startMonth, startDay, endMonth, endDay] = holiday.split(';');
            const hStart = new Date(today.getFullYear(), toInteger(startMonth) - 1, toInteger(startDay));
            const hEnd = new Date(today.getFullYear(), toInteger(endMonth) - 1, toInteger(endDay), 23, 59, 59);
            
            if((holidayStart >= hStart && holidayStart <= hEnd) || (holidayEnd >= hStart && holidayEnd <= hEnd)) {
                return false;
            }
            return true;
        })) {
            toastr.error(t('MESSAGE_DEVICE_ALREADY_EXIST_HOLIDAY_P'));
            return;
        };

        if(((holidayEnd - holidayStart)/86400000 + 1) > 15) {
            toastr.error(t('MIS_MESSAGE_DEVICE_NOT_EXCEED_HOLIDAY_P'));
            return;
        }

        const newHoliday = `0;${holidayStart.getMonth() + 1};${holidayStart.getDate()};${holidayEnd.getMonth() + 1};${holidayEnd.getDate()}`;
        onChange({'data-name': 'timerHoliday'}, (timerHoliday && timerHoliday.length > 0) ? `${timerHoliday},${newHoliday}` : newHoliday);
    };

    const onDeleteHoliday = index => {
        if(changed['timerHoliday']){
            const newHolidayList = deviceTimeHolidayList.filter((h, i) => i !== index).join(',');
            onChange({'data-name': 'timerHoliday'}, newHolidayList);
        }
    };

    const handleCalendarOpen = (isOpen) => {
        if(isOpen) {
            scrollToBottom(isOpen);
        }
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }, 30);
    };

    const startDate = new Date(today.getFullYear(), 0, 1);
    const endDate = new Date(today.getFullYear(), 11, 31);

    return (
        <div className="device_edit_panel_wrap device_time_edit mt28" ref={scrollRef}>
            <div className="devicepop timer mb26">
                <h3>{t('COM_TV_SID_ONOFF_TIMER')}</h3>
                <div className="timer_wrap mt14 mb20">
                    <table>
                        <colgroup>
                            <col width="91px" />
                            <col width="74px" />
                            <col width="139px" />
                            <col width="74px" />
                            <col width="139px" />
                            <col width="71px" />
                            <col width="108px" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{t('DID_ADMIN_URGENT_TIMER')}</th>
                                <th colSpan="2">{t('TABLE_ON_TIMER_SETTING_P')}</th>
                                <th colSpan="2">{t('TABLE_OFF_TIMER_SETTING_P')}</th>
                                <th>{t('TABLE_VOLUME_P')}</th>
                                <th>{t('COM_DID_LFD_SOURCE')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                timerConfTimerList && timerConfTimerList.length > 0 && timerConfTimerList.map((timerConf, i) => {
                                    const timer = timerConf || {};
                                    const source = inputSources.find(s => s.value == timer.timerSource);
                                    /*const onTime = moment({hour: timer.timerOnAmpm == 0 ? timer.timerOnH + 12 : timer.timerOnH, minute: timer.timerOnM}); // getTimeStr(timer.timerOnH, timer.timerOnM, timer.timerOnAmpm);
                                    const offTime = moment({hour: timer.timerOffAmpm == 0 ? timer.timerOffH + 12 : timer.timerOffH, minute: timer.timerOffM}); // getTimeStr(timer.timerOffH, timer.timerOffM, timer.timerOffAmpm);
*/
                                    const cvtOnTime  = time12hhTo24hh(timer.timerOnH,  timer.timerOnM,  timer.timerOnAmpm );
                                    const cvtOffTime = time12hhTo24hh(timer.timerOffH, timer.timerOffM, timer.timerOffAmpm );
                                    const onTime  = moment({hour:  cvtOnTime.hh,  minute: cvtOnTime.mm});
                                    const offTime = moment({hour:  cvtOffTime.hh, minute: cvtOffTime.mm});

                                    return (
                                        <React.Fragment key={i}>
                                            <tr className="timer_line_info" style={{display: 'table-row'}} onClick={() => onClickTimer(i)}>
                                                <td>
                                                    <Checkbox id={`timerConfTimer${i + 1}Enable`} name={`${t('DID_ADMIN_URGENT_TIMER')}${i + 1}`} checked={changed[`timerConfTimer${i + 1}`] || false}
                                                        propertyName={`timerConfTimer${i + 1}`} onChange={onChangeChecked} />
                                                </td>
                                                <td className="t_info_onhourmin">
                                                    {
                                                        timer.timerOnStatus == 1 ?
                                                        `${timer.timerOnH}:${timer.timerOnM} ${timer.timerOnAmpm == 0 ? 'PM' : 'AM'}`
                                                        : '-'
                                                    }
                                                </td>
                                                <td className="t_info_repeat_on">
                                                    {
                                                        timer.timerOnStatus == 1 ? getTimerStr(onRepeat[i]) : '-'
                                                    }
                                                </td>
                                                <td className="t_info_offhourmin">
                                                    {
                                                        timer.timerOffStatus == 1 ?
                                                        `${timer.timerOffH}:${timer.timerOffM} ${timer.timerOffAmpm == 0 ? 'PM' : 'AM'}`
                                                        : '-'
                                                    }
                                                </td>
                                                <td className="t_info_repeat_off">
                                                    {
                                                        timer.timerOffStatus == 1 ? getTimerStr(offRepeat[i]) : '-'
                                                    }
                                                </td>
                                                <td className="t_info_volume">
                                                    {timer.timerOnStatus == 1 ? timer.timerVolume : '-'}
                                                </td>
                                                <td className="t_info_is">
                                                    {timer.timerOnStatus == 1 ? source && source.title : '-'}
                                                </td>
                                            </tr>
                                            {
                                                showTimer[i] && changed[`timerConfTimer${i + 1}`] &&
                                                <tr className="time_detail">
                                                    <td colSpan="7">
                                                        <div className="time_detail_wrap">
                                                            <table className="time_detail_table">
                                                                <colgroup>
                                                                    <col width="100px" />
                                                                    <col width="100px" />
                                                                    <col width="" />
                                                                </colgroup>
                                                                <tbody>
                                                                    <Timer type='on'
                                                                        index={i}
                                                                        usage={timer.timerOnStatus}
                                                                        repeat={onRepeat[i]}
                                                                        time={onTime}
                                                                        volume={timer.timerVolume}
                                                                        source={timer.timerSource}
                                                                        inputSources={inputSources}
                                                                        holidayEnable={timer.timerHolidayEnable}
                                                                        changed={changed}
                                                                        onChange={onChange} />
                                                                    <Timer type='off'
                                                                        index={i}
                                                                        usage={timer.timerOffStatus}
                                                                        repeat={offRepeat[i]}
                                                                        time={offTime}
                                                                        inputSources={inputSources}
                                                                        holidayEnable={timer.timerHolidayEnable}
                                                                        changed={changed}
                                                                        onChange={onChange} />
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </React.Fragment>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="devicepop timer mb26">
                <h3>{t('TEXT_HOLIDAY_MANAGEMENT_P')}</h3>
                <div className="detail_view mini_p mt14 mb15">
                    <table>
                        <colgroup>
                            <col width="203px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>
                                    <Checkbox id='deviceTimeHolidayListEnable' name={t('DID_LAYOUTS_COMMON_HOLIDAY')} checked={changed['timerHoliday'] || false}
                                        propertyName='timerHoliday' onChange={onChangeChecked} />
                                </th>
                                <td style={{display:'flex'}}>
                                    <DateInput dateFormat='MM.dd'
                                               width={120}
                                               className='device_timer_holiday'
                                               date={convDate(holidayPeriod[0])}
                                               minDate={startDate}
                                               maxDate={holidayPeriod[1]}
                                               onChange={onChangeHolidayStart}
                                               onChangeCalendar={(isOpen) => handleCalendarOpen(isOpen)}
                                               disabled={!changed['timerHoliday']} />
                                    ~
                                    <DateInput dateFormat='MM.dd'
                                               width={120}
                                               className='device_timer_holiday'
                                               date={convDate(holidayPeriod[1])}
                                               minDate={holidayPeriod[0]}
                                               maxDate={endDate}
                                               onChange={onChangeHolidayEnd}
                                               onChangeCalendar={(isOpen) => handleCalendarOpen(isOpen)}
                                               disabled={!changed['timerHoliday']} />
                                    <PlusButton onClick={onAddHoliday} disabled={!changed['timerHoliday']} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <div className="device_time_holiday autoline">
                                        {
                                            deviceTimeHolidayList && deviceTimeHolidayList.length > 0 &&
                                            deviceTimeHolidayList.map((holiday, i) => {
                                                const holidayInfo = holiday.split(';');
                                                return (
                                                    <Chip key={i} width={80} title={`${holidayInfo[1]}.${holidayInfo[2]}~${holidayInfo[3]}.${holidayInfo[4]}`} onDelete={() => onDeleteHoliday(i)} />
                                                );
                                            })
                                        }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EditPresetTime;