import React from 'react'
import {Trans, useTranslation} from "react-i18next";
import {isNil} from 'lodash';
import WhiteButton from "../../components/button/WhiteButton";
import Checkbox from "../../components/checkbox/Checkbox";
import NumberInput from "../../components/input/NumberInput";
import SettingInformation from "../../components/setting/SettingInformation";
import RadioGroup from "../../components/radio/RadioGroup"
import TextInput from "../../components/input/TextInput";
import TimeInput from "../../components/input/TimeInput";
import {getMixString} from "../../language/languageUtils";
import {connect} from "react-redux";
import {popupAction} from "../../actions";
import {commonConstants, settingConstants} from "../../constants";
import {settingService} from '../../services';
import {getErrorMessage} from "../../helper/responseHandler";
import {toastr} from 'helper/toastrIntercept';

import './ServerSettings.css';

import {
    alarmMailingWeekly,
    onChangeCheckBox,
    onChangeRadioGroup,
    onChangeTextInput,
    onChangeTimeInput,
    saveNotificationsSetting
} from './ServerSettings';

const ServerSettingsOrgan  = ({settings, onChange, organInfo, ...props}) => {
    const {t} = useTranslation();

    const data = {...settings.data};
    const changed = {...settings.changed};
    const { addPopup, closePopup } = props;

    const smtpServerSettings = (items) => {
        const type = "smtpServer";
        const mainSmtpServer = {...data.smtpServerMain};

        const smtpServerAddress = items.useSmtpServerSetting?mainSmtpServer.smtpServerAddress:items.smtpServerAddress;
        const useSmtpAuth = items.useSmtpServerSetting?mainSmtpServer.useSmtpAuth:items.useSmtpAuth;
        const smtpSingInId = items.useSmtpServerSetting?mainSmtpServer.smtpSingInId:items.smtpSingInId;
        const smtpPassword = items.useSmtpServerSetting?mainSmtpServer.smtpPassword:items.smtpPassword;
        const smtpServerPort = items.useSmtpServerSetting?mainSmtpServer.smtpServerPort:items.smtpServerPort;
        const useSmtpSSL = items.useSmtpServerSetting?mainSmtpServer.useSmtpSSL:items.useSmtpSSL;

        const checkMainSettings = (e) => {
            if(e.target.checked){
                if(isNil(mainSmtpServer) || !mainSmtpServer.useSmtpServer){
                    toastr.error(t("MIS_SID_20_NO_SMTP_INFORMATION_IN_THE_SERVER_SETTINGS"));
                    return;
                }
            }
            onChangeCheckBox(e, type, onChange);
        }

        const onChangePort = (e) => {
            let value = e.target.value;

            let pNumber = /^[0-9]*$/; 
            if(pNumber.test(value)){
                onChangeTextInput(e, type, onChange)
            } else {
                toastr.error(t("COM_SID_SIGANGE_CAN_ONLY_USE_NUMBER"));
                onChange(type, "smtpServerPort", "");
            }
        }

        let list = [
            {id: 'SERVER_SETTING_SMTP_ALARM_MAILING', name: <Trans i18nKey={"TABLE_IS_MAILING_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].useSmtpServer),
            value: <Checkbox id={'useSmtpServer'} propertyName={'useSmtpServer'} name={t("MESSAGE_SETUP_SEND_MAIL_TO_ADMIN_WHEN_ALARM_P")} 
            onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useSmtpServer} />},
            {id: 'SERVER_SETTING_SMTP_APPLY_COMMON_SETTING', name: <Trans i18nKey={"MIS_SID_20_APPLY_SERVER_MANAGEMENT_SETTINGS"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].useSmtpServerSetting),
            value: <Checkbox id={'useSmtpServerSetting'} propertyName={'useSmtpServerSetting'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>checkMainSettings(e)} 
                    checked={items.useSmtpServerSetting} disabled={!items.useSmtpServer}/> },
            {id: 'SERVER_SETTING_SMTP_SERVER_ADDRESS', name: <Trans i18nKey={"MIS_TEXT_SERVER_ADDRESS_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].smtpServerAddress),
            value: <TextInput propertyName={'smtpServerAddress'} value={smtpServerAddress} onChange={(e)=>onChangeTextInput(e, type, onChange)}  maxLength={150} disabled={!(items.useSmtpServer&&!items.useSmtpServerSetting)}/> },
            {id: 'SERVER_SETTING_SMTP_AUTHENTICATION', name: <Trans i18nKey={"MIS_TEXT_AUTHENTICATION_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].useSmtpAuth),
            value: <Checkbox id={'useSmtpAuth'} propertyName={'useSmtpAuth'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={useSmtpAuth} disabled={!(items.useSmtpServer&&!items.useSmtpServerSetting)}/> },
            {id: 'SERVER_SETTING_SMTP_LOGIN_ID', name: <Trans i18nKey={"COM_TEXT_LOGIN_ID_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].smtpSingInId),
            value: <TextInput propertyName={'smtpSingInId'} value={smtpSingInId} onChange={(e)=>onChangeTextInput(e, type, onChange)} maxLength={30} disabled={!(items.useSmtpServer&&items.useSmtpAuth&&!items.useSmtpServerSetting)}/> },
            {id: 'SERVER_SETTING_SMTP_PASSWORD', name: <Trans i18nKey={"TEXT_PASSWORD_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].smtpPassword),
            value: <TextInput propertyName={'smtpPassword'} value={smtpPassword} type={"password"} onChange={(e)=>onChangeTextInput(e, type, onChange)} maxLength={100} disabled={!(items.useSmtpServer&&items.useSmtpAuth&&!items.useSmtpServerSetting)}/> },
            {id: 'SERVER_SETTING_SMTP_PORT', name: <Trans i18nKey={"TABLE_PORT_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].smtpServerPort),
            value: <TextInput propertyName={'smtpServerPort'} value={smtpServerPort} onChange={onChangePort} maxLength={10} disabled={!(items.useSmtpServer&&!items.useSmtpServerSetting)} /> },
            {id: 'SERVER_SETTING_SMTP_SSL', name: "SSL", 
            changed: !isNil(changed[type])&&!isNil(changed[type].useSmtpSSL),
            value: <Checkbox id={'useSmtpSSL'} propertyName={'useSmtpSSL'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} 
                    checked={useSmtpSSL} disabled={!(items.useSmtpServer&&!items.useSmtpServerSetting)}/> }
        ];
        return list;
    }

    const generalSettings = (items) => {
        const type = "general";
        const optionApprovalType = [
            {value: "all", title: "MIS_SID_20_ALL_APPROVED"},
            {value: "part", title: "MIS_SID_20_PARTIALLY_APPROVED"}
        ];

        const handleApprovals = (e) => {
            let selected = e.target.getAttribute('data-name');
            let list = [...items.contentApprovers];
            list.map((approval) => {
                if(approval.userId == selected){
                    approval.contentApprover = (e.target.checked?"Y":"N");
                }
            });
            onChange(type, "contentApprovers", list);
        }

        const contentApprovalType = () => {
            return (
                <>
                    <div>
                        <RadioGroup propertyName='contentsApprovalType' selects={optionApprovalType} value={items.contentsApprovalType} onChange={(e, value)=>onChangeRadioGroup(e, value, type, onChange)} />
                    </div>
                    <div>
                        <table className="setting_sub_table">
                            <colgroup>
                                <col width="5px"></col>
                                <col width="25%"></col>
                                <col width="25%"></col>
                                <col width="25%"></col>
                                <col width=""></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>{t('COM_DID_ADMIN_USER_USERID')}</th>
                                    <th>{t('TABLE_ROLE_NAME_P')}</th>
                                    <th>{t('TABLE_ORGANIZATION_P')}</th>
                                    <th>{t('MIS_SID_SYNC_LDAP_ORGANIZATION')}</th>
                                </tr>
                                {
                                    isNil(items.contentApprovers) || items.contentApprovers.length == 0 &&
                                    <tr rowSpan="5">No data</tr>
                                }
                                {
                                    !isNil(items.contentApprovers) && items.contentApprovers.length > 0 &&
                                    items.contentApprovers.map((approval, index) => 
                                        <tr key={approval.userId}>
                                            <td>
                                                <Checkbox id={approval.userId} propertyName={approval.userId} onChange={handleApprovals} checked={approval.contentApprover=='Y'}/>
                                            </td>
                                            <td>{approval.userId}</td>
                                            <td>{approval.roleName}</td>
                                            <td>{approval.organizationName}</td>
                                            <td>{approval.isSyncLdap?t("TEXT_YES_P"):t("BUTTON_NO_P")}</td>
                                        </tr>
                                        
                                    )
                                }
                                
                            </tbody>
                            
                        </table>

                    </div>
                </>
            )
        }

        const getGeneralItems = () => {
            let list = [
                {id: 'SERVER_SETTING_CONTENT_APPROVAL', name: <Trans i18nKey={"MIS_SID_CONTENT_APPROVAL"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useContentApproval),
                value: <Checkbox id={'useContentApproval'} propertyName={'useContentApproval'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useContentApproval}/>},
                {id: 'SERVER_SETTING_DEVICE_GROUP_PERMISSION', name: <Trans i18nKey={"MIS_TEXT_DEVICE_GROUP_PERMISSION_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useDeviceGroupPermission),
                value: <Checkbox id={'useDeviceGroupPermission'} propertyName={'useDeviceGroupPermission'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useDeviceGroupPermission}/>},
                {id: 'SERVER_SETTING_NUMBER_OF_DEVICE', name: <Trans i18nKey={"MIS_SID_NUM_OF_DEVICE_GROUP"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useDeviceGroupCount),
                value: <Checkbox id={'useDeviceGroupCount'} propertyName={'useDeviceGroupCount'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useDeviceGroupCount}/>},
                {id: 'SERVER_SETTING_EXTERNAL_LINK', name: <Trans i18nKey={"MIS_TEXT_TITLE_EXTERNAL_LINK_P"} />,
                changed: !isNil(changed[type])&&!isNil(changed[type].useExternalLink), 
                value: <Checkbox id={'useExternalLink'} propertyName={'useExternalLink'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useExternalLink}/>},
            ];

            if(items.useContentApproval){
                list.splice(1, 0, {id: 'SERVER_SETTING_CONTENT_APPROVAL_TYPE', name: <Trans i18nKey={"MIS_SID_20_APPROVAL_TYPE"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].contentsApprovalType),
                value: contentApprovalType()});
            }
            return list;
        }

        return getGeneralItems();
    }

    const alarmMailingSettings = (items) => {
        const type = 'alarmMailing';

        const mailRetentionPeriod = [
            {"value": "0", "title": "COM_LFD_SID_UNLIMITED_KR_HOTEL"},
            {"value": "7", "title": "MIS_SID_ONE_WEEK"},
            {"value": "15", "title": getMixString(['COM_TV_SID_MIX_DAYS_KR_BLANK', '15'])},
            {"value": "30", "title": getMixString(['COM_TV_SID_MIX_DAYS_KR_BLANK', '30'])},
            {"value": "60", "title": getMixString(['COM_TV_SID_MIX_DAYS_KR_BLANK', '60'])}
        ];

        const optionAlarmTarget = [
            {"value": "0", "title": "COM_SID_ALL"},
            {"value": "1", "title": "TEXT_GROUP_P"}
        ];

        const optionAlarmInterver = [
            {"value": "30", "title": "0.5" + t("COM_SID_HOURS")},
            {"value": "60", "title": "1" + t("COM_SID_HOURS")},
            {"value": "360", "title": "6" + t("COM_SID_HOURS")},
            {"value": "720", "title": "12" + t("COM_SID_HOURS")},
            {"value": "1440", "title": "24" + t("COM_SID_HOURS")}
        ];

        const dDayView = (name, value) => {
            let optionDDay = [
                {"value": "2", "title": "2"},
                {"value": "3", "title": "3"},
                {"value": "7", "title": "7"}
            ]
            return (
                <>
                    <span className={'mr20'}>D-Day</span> 
                    <RadioGroup propertyName={name} selects={optionDDay} value={value} onChange={(e, value)=>onChangeRadioGroup(e, value, type, onChange)} disabled={!items.useAlarmMailing}/>
                </>
            )
        }

        const optionDisconnectionInterval = [
            {"value": "6", "title": "6" + t("COM_SID_HOURS")},
            {"value": "12", "title": "12" + t("COM_SID_HOURS")},
            {"value": "24", "title": "24" + t("COM_SID_HOURS")},
            {"value": "custom", "title": t("COM_SID_CUSTOM_KR_MIS")}
        ];

        const deviceDisconnectionIntervalView = () => {        
            let itemValue = items.deviceDisconnectDuration + "";
            let customValue = "";
            let radioValue = itemValue=="0"?"6":itemValue;
            if(radioValue != "6" && radioValue != "12" && radioValue != "24"){
                radioValue = "custom";
                if(radioValue == "custom"){
                    if(isNil(items.customDeviceDisconnectDuration)){
                        customValue = items.deviceDisconnectDuration=="custom"?"":items.deviceDisconnectDuration;
                    } else {
                        customValue = items.customDeviceDisconnectDuration;
                    }
                }else{
                    customValue = items.deviceDisconnectDuration;
                }
            }

            const handleDisconnectionIntervalCheck = (e) => {
                let enable = e.target.checked;
                if(!enable){
                    onChange(type, "deviceDisconnectDuration", "0");
                }else{
                    onChange(type, "deviceDisconnectDuration", "6");
                }
            }
            const handleDisconnectionInterval = (e, value) => {
                onChange(type, "deviceDisconnectDuration", value);
            }

            const handleCustomDisconnectionInterval = (e, value) => {
                onChange(type, "customDeviceDisconnectDuration", value);
            }

            let useInterval = itemValue=="0"?false:true;
            
            return (
                <>
                    <div className="float_l mr20"> 
                        <Checkbox id={'useDisconnectionInterval'} propertyName={'useDisconnectionInterval'} name={t("TEXT_ENABLE2_P")} onChange={handleDisconnectionIntervalCheck} checked={useInterval} disabled={!items.useAlarmMailing}/>
                    </div>
                    <div className="float_l">
                        <RadioGroup propertyName={'disconnectionInterval'} selects={optionDisconnectionInterval} value={radioValue} onChange={handleDisconnectionInterval} disabled={!(items.useAlarmMailing&&useInterval)}/>
                    </div>
                    <div className="float_l">
                        <NumberInput propertyName={'customDisconnectionInterval'} min={1} rangeLabel={true} value={!isNil(customValue)&&customValue!=""?parseInt(customValue):""} onChange={handleCustomDisconnectionInterval} disabled={!items.useAlarmMailing||!useInterval||radioValue!="custom"}/>
                    </div>
                </>
            )
        }

        const setAlarmDeviceGroups = (groups) => {
            if(isNil(groups) || groups.length == 0){
                toastr.success(t("MIS_MESSAGE_STATISTICS_SAVE_P"))
                return;
            }

            let groupIds = [];
            groups.map((group) => {
                if(group != undefined){
                    groupIds.push(group.groupId);
                }
            });

            const orgId = organInfo.selected;
            settingService.updateAlarmDeviceGroups(orgId, {ids: groupIds}).then(res => {
                toastr.success(t("MIS_MESSAGE_STATISTICS_SAVE_P"));
                onChange(type, "alarmTargetGroupIds", groupIds);
                closePopup(commonConstants.COMMON_GROUP_POPUP);
            }).catch(e => {
                getErrorMessage(e);
            })
        }

        const openDeviceGroupPopup = () => {
            const orgId = organInfo.selected;
            addPopup({
                id: commonConstants.COMMON_GROUP_POPUP,
                type: commonConstants.COMMON_GROUP_POPUP,
                mode: 'device',
                organizationId: orgId,
                allEnabled: true,
                checkbox: true,
                checkedKeys: items.alarmTargetGroupIds,
                save: groups => {
                    setAlarmDeviceGroups(groups);
                }, 
                close: () => closePopup(commonConstants.COMMON_GROUP_POPUP),
            });
        }

        const mailingTargerView = () => {
            
            return (
                <>
                    <RadioGroup propertyName='alarmTarget' selects={optionAlarmTarget} value={items.alarmTarget} onChange={(e, value)=>onChangeRadioGroup(e, value, type, onChange)} disabled={!items.useAlarmMailing}/>
                    <WhiteButton id={'alarmTargerGroup'} name={t("TEXT_SEL_GROUP_P")} disable={!(items.useAlarmMailing&&items.alarmTarget=='1')} onClick={()=>openDeviceGroupPopup()}/>
                </>
            )
        }

        const onChangeWeekly = (week) => {
            onChange(type, "alarmActivatedDays", week);
        }

        const changeRunTime = (e, value, startOrEnd) => {
            if(startOrEnd == 'start'){
                onChange(type, "alarmEndTime", items.alarmEndTime);
            }else{
                onChange(type, "alarmStartTime", items.alarmStartTime);
            }
            onChangeTimeInput(e, value, type, onChange)
            
        }

        return (
            [ 
                {id: 'SERVER_SETTING_ALARM_MAILING_USE', name: <Trans i18nKey={"COM_SETUP_NEW_STRING16_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useAlarmMailing),
                value: <Checkbox id={'useAlarmMailing'} propertyName={'useAlarmMailing'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useAlarmMailing}/>},
                {id: 'SERVER_SETTING_ALARM_TARGET',
                changed: !isNil(changed[type])&&!isNil(changed[type].alarmTarget),
                value: mailingTargerView() },
                {id: 'SERVER_SETTING_ALARM_INTERVAL', name: <Trans i18nKey={"MIS_TEXT_ALARM_INTERVAL_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].alarmInterval),
                value: <RadioGroup propertyName={'alarmInterval'} selects={optionAlarmInterver} value={items.alarmInterval} onChange={(e, value)=>onChangeRadioGroup(e, value, type, onChange)} disabled={!items.useAlarmMailing}/>},
                {id: 'SERVER_SETTING_ALARM_RUNTIME', name: <Trans i18nKey={"COM_MIS_TEXT_RUN_TIME_P"} />, 
                changed: !isNil(changed[type])&&(!isNil(changed[type].alarmStartTime)||!isNil(changed[type].alarmEndTime)),
                value: <><TimeInput propertyName={'alarmStartTime'} value={items.alarmStartTime} showSeconds={false} onChange={(e, value)=>changeRunTime(e, value, 'start')} disabled={!items.useAlarmMailing}/>
                        -<TimeInput propertyName={'alarmEndTime'} value={items.alarmEndTime} showSeconds={false} onChange={(e, value)=>changeRunTime(e, value, 'end')} disabled={!items.useAlarmMailing}/></>},
                {id: 'SERVER_SETTING_ALARM_WEEKDAYS', name: <Trans i18nKey={"TEXT_REMOTE_JOB_WEEKDAYS_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].alarmActivatedDays),
                value: alarmMailingWeekly(items.alarmActivatedDays, onChangeWeekly, !items.useAlarmMailing)},
                {id: 'SERVER_SETTING_ALARM_SCHEDULE_EXPIRE', name: <Trans i18nKey={"MIS_SID_SERVER_SCHEDULES_TO_EXPIRE"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].expireScheduleDay),
                value: dDayView('expireScheduleDay', items.expireScheduleDay)},
                {id: 'SERVER_SETTING_ALARM_PLAYLIST_EXPIRE', name: <Trans i18nKey={"MIS_SID_SERVER_PLAYLISTS_TO_EXPIRE"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].expirePlaylistDay),
                value: dDayView('expirePlaylistDay', items.expirePlaylistDay)},
                {id: 'SERVER_SETTING_ALARM_DISCONNECTION_INTERVAL', name: <Trans i18nKey={"MIS_SID_SERVER_DEVICE_DISCONNECTION_INTERVAL"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].deviceDisconnectDuration),
                value: deviceDisconnectionIntervalView()},
                {id: 'SERVER_SETTING_ALARM_DEVICE_HEALTH', name: <Trans i18nKey={"COM_SID_DEVICE_HEALTH"} />, 
                value: <WhiteButton id={'alarmDeviceHealthSetting'} name={t("MIS_SID_ALARM_SETTINGS")} disable={!items.useAlarmMailing} onClick={()=>openNotificationSetting2DivPopup("device-status")}/>},
                {id: 'SERVER_SETTING_ALARM_NOTIFICATIONS', name: <Trans i18nKey={"COM_TV_NOTIFICATIONS_KR_VOICE"} />, 
                value: <WhiteButton id={'alarmNotificationsSetting'} name={t("MIS_SID_ALARM_SETTINGS")} disable={!items.useAlarmMailing} onClick={()=>openNotificationSetting2DivPopup("etc")}/>}
            ]
        )
    }

    const openNotificationSetting2DivPopup = (category) => {
        let title = (category=="device-status")?t("COM_SID_DEVICE_HEALTH"):t("COM_TV_NOTIFICATIONS_KR_VOICE");
        const orgId = organInfo.selected;
        Promise.all([
            settingService.fetchUsersForNotifications(orgId),
            settingService.fetchNotificationTypes(orgId, category)
        ]).then(res => {
            const users = res[0].items;
            const notiTypes = res[1].items;
            addPopup({
                id: settingConstants.NOTIFICATIONS_SETTING_2DIV_POPUP,
                type: settingConstants.NOTIFICATIONS_SETTING_2DIV_POPUP,
                mode: category,
                users: users,
                notis: notiTypes,
                title: title,
                onSave: userNotifications => {
                    const callback = () => {
                        toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"));
                        closePopup(settingConstants.NOTIFICATIONS_SETTING_2DIV_POPUP);
                    } 
                    saveNotificationsSetting(userNotifications, orgId, callback);
                }, 
                onClose: () => closePopup(settingConstants.NOTIFICATIONS_SETTING_2DIV_POPUP)
            });
        });
    }

    const ldapServerSettings = (items) => {
        const type = "ldapServer";
        const mainLdapServer = {...data.ldapServerMain};

        const ldapServerAddress = items.useLdapServerSettings?mainLdapServer.ldapServerAddress:items.ldapServerAddress;
        const useLdapSSL = items.useLdapServerSettings?mainLdapServer.useLdapSSL:items.useLdapSSL;
        const ldapRootDN = items.useLdapServerSettings?mainLdapServer.ldapRootDN:items.ldapRootDN;
        const ldapManagerDN = items.useLdapServerSettings?mainLdapServer.ldapManagerDN:items.ldapManagerDN;
        const ldapManagerPassword = items.useLdapServerSettings?mainLdapServer.ldapManagerPassword:items.ldapManagerPassword;

        const getLdapAddress = () => {
            let protocol = 'ldap://';
            if(useLdapSSL) {
                protocol = 'ldaps://';
            }
    
            return (
                <>
                    <div style={{float: 'left', marginRight: '2px'}}>{protocol}</div>
                    <div style={{float: 'left'}}>
                        <TextInput propertyName={'ldapServerAddress'} value={ldapServerAddress} onChange={(e)=>onChangeTextInput(e, type, onChange)} maxLength={150} disabled={!(items.useLdap&&!items.useLdapServerSettings)} />
                    </div>
                </>
            )
        }

        const checkMainSettings = (e) => {
            if(e.target.checked){
                if(isNil(mainLdapServer) || !mainLdapServer.useLdap){
                    toastr.error(t("MIS_SID_20_NO_LDAP_INFORMATION_IN_THE_SERVER_SETTINGS"));
                    return;
                }
            }
            onChangeCheckBox(e, type, onChange);
        }

        const checkUseLdap = (e) => {
            const id = "SETTING_COMMON_CONFIRM_POPUP";
            const target = e.target.getAttribute('data-name');
            const checked = e.target.checked;
            if(checked){
                onChange(type, target, checked);
                return;
            }
            addPopup({
                type: commonConstants.COMMON_CONFIRM_POPUP,
                id: id,
                title: t("COM_BUTTON_CONFIRM"),
                message: t("MIS_MESSAGE_DELETE_LDAP_SERVER_INFO_DELETE_ALSO_LDAP_USER_INFO_P").replace("<br>", "\n"),
                yesTitle: t('BUTTON_OK_P'),
                noTitle: t('BUTTON_CANCEL_P'),
                onClickYes: () => {
                    onChange(type, target, checked);
                    closePopup(id);
                },
                onClose: () => closePopup(id)
            });
        }

        let ldapList = [
            {id: 'SERVER_SETTING_LDAP_USE', name: <Trans i18nKey={"COM_SETUP_NEW_STRING16_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].useLdap), 
            value: <Checkbox id={'useLdap'} propertyName={'useLdap'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>checkUseLdap(e)} checked={items.useLdap}/>},
            {id: 'SERVER_SETTING_LDAP_APPLY_COMMON_SETTING', name: <Trans i18nKey={"MIS_SID_20_APPLY_SERVER_MANAGEMENT_SETTINGS"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].useLdapServerSettings),
            value: <Checkbox id={'useLdapServerSettings'} propertyName={'useLdapServerSettings'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>checkMainSettings(e)} checked={items.useLdapServerSettings} disabled={!items.useLdap} /> },
            {id: 'SERVER_SETTING_LDAP_SSL', name: 'SSL', 
            changed: !isNil(changed[type])&&!isNil(changed[type].useLdapSSL),
            value: <Checkbox id={'useLdapSSL'} propertyName={'useLdapSSL'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={useLdapSSL} disabled={!(items.useLdap&&!items.useLdapServerSettings)}/>},
            {id: 'SERVER_SETTING_LDAP_ADDRESS', name: <Trans i18nKey={"MIS_TEXT_SERVER_ADDRESS_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].ldapServerAddress),
            value: getLdapAddress()},
            {id: 'SERVER_SETTING_LDAP_ROOT_DN', name: <Trans i18nKey={"MIS_SID_20_ROOT_DN"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].ldapRootDN),
            value: <TextInput propertyName={'ldapRootDN'} value={ldapRootDN} onChange={(e)=>onChangeTextInput(e, type, onChange)} maxLength={150} disabled={!(items.useLdap&&!items.useLdapServerSettings)} />},
            {id: 'SERVER_SETTING_LDAP_MANAGER_DN', name: <Trans i18nKey={"MIS_SID_20_MANAGER_DN"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].ldapManagerDN),
            value: <TextInput propertyName={'ldapManagerDN'} value={ldapManagerDN} onChange={(e)=>onChangeTextInput(e, type, onChange)} maxLength={150} disabled={!(items.useLdap&&!items.useLdapServerSettings)} />},
            {id: 'SERVER_SETTING_LDAP_PASSWORD', name: <Trans i18nKey={"MIS_SID_20_MANAGER_PASSWORD"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].ldapManagerPassword),
            value: <TextInput propertyName={'ldapManagerPassword'} value={ldapManagerPassword} type={"password"} onChange={(e)=>onChangeTextInput(e, type, onChange)} maxLength={50} disabled={!(items.useLdap&&!items.useLdapServerSettings)} />},
            {id: 'SERVER_SETTING_LDAP_ORGANIZATION_SYNC', name: <Trans i18nKey={"MIS_SID_20_USE_ORGANIZATION_SYNC"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].useOrganizationSync),
            value: <Checkbox id={'useOrganizationSync'} propertyName={'useOrganizationSync'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useOrganizationSync} disabled={!items.useLdap}/>}
        ]

        return ldapList;
    }

    const electronisLabelSettings = (items) => {
        if(items == undefined){
            items = {};
        }
        const type = "eslServer";

        return (
            [
                {id: 'SERVER_SETTING_ESL_USE', name: <Trans i18nKey={"COM_SETUP_NEW_STRING16_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useEslServer),
                value: <Checkbox id={'useEslServer'} propertyName={'useEslServer'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useEslServer}/>},
                {id: 'SERVER_SETTING_ESL_SERVER_ADDRESS', name: <Trans i18nKey={"MIS_TEXT_SERVER_ADDRESS_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].eslServerAddress),
                value: <TextInput propertyName={'eslServerAddress'} value={items.eslServerAddress} onChange={(e)=>onChangeTextInput(e, type, onChange)} /> },
                {id: 'SERVER_SETTING_ESL_SIGN_ID', name: <Trans i18nKey={"COM_TEXT_LOGIN_ID_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].eslSigninId),
                value: <TextInput propertyName={'eslSigninId'} value={items.eslSigninId} onChange={(e)=>onChangeTextInput(e, type, onChange)} /> },
                {id: 'SERVER_SETTING_ESL_PASSWORD', name: <Trans i18nKey={"TEXT_PASSWORD_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].eslPassword),
                value: <TextInput propertyName={'eslPassword'} value={items.eslPassword} onChange={(e)=>onChangeTextInput(e, type, onChange)} /> }
            ]
        )
    }

    return(
        <>
        {   !isNil(data.general) &&
            <SettingInformation id={'SERVER_SETTING_GENERAL'} title={t("COM_TEXT_GENERAL_P")} list={generalSettings(data.general)}/>
        }
        {   !isNil(data.smtpServerMain) && !isNil(data.smtpServerMain.useSmtpSeparateSettings) && data.smtpServerMain.useSmtpSeparateSettings &&
            <SettingInformation id={'SERVER_SETTING_SMTP_SERVER'} title={t("MIS_TEXT_SMTP_SERVER_P")} list={smtpServerSettings(data.smtpServer)}/>
        }
        {   !isNil(data.alarmMailing) &&
            <SettingInformation id={'SERVER_SETTING_ALARM_MAILING'} title={t("TEXT_TITLE_ALARM_MAILING_SERVICE_P")} list={alarmMailingSettings(data.alarmMailing)}/>
        }
        {   //!isNil(data.eslServer) &&
            //<SettingInformation id={'SERVER_SETTING_ELECTRONIC_LABELS'} title={t("MIS_SID_ELECTRONI_LABELS")} list={electronisLabelSettings(data.eslServer)}/>
        }
        {   !isNil(data.ldapServerMain) && !isNil(data.ldapServerMain.useLdapSeparateSettings) && data.ldapServerMain.useLdapSeparateSettings && 
            <SettingInformation id={'SERVER_SETTING_LDAP_SERVER'} title={t("MIS_TEXT_LDAP_SERVER_P")} list={ldapServerSettings(data.ldapServer)}/>
        }
        </>
    )
}
export default connect(
    null,
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)(ServerSettingsOrgan);