import React,{forwardRef} from 'react';
import {toInteger} from 'lodash';
import './NumberInput.css';

export const useNumberInput = (value, min, max, step, forceInt, onChange) => {
    const onClickUp = e => {
        const val = toInteger(value) + toInteger(step);
        if(onChange && val < max) {
            onChange(e, val);
        } else {
            onChange(e, max);
        }
    };

    const onClickDown = e => {
        const val = toInteger(value) - toInteger(step);
        if(onChange && val > min) {
            onChange(e, val);
        } else {
            onChange(e, min);
        }
    };

    const onChangeNumber = e => {
        if(forceInt) {
            onChange(e, toInteger(e.target.value));
        } else {
            onChange(e, e.target.value);
        }
    };

    const onBlurNumber = e => {
        const value = toInteger(e.target.value);
        if(onChange){
            if(value > max){
                onChange(e, max);
            } else if(value < min){
                onChange(e, min);
            } else {
                onChange(e, value);
            }
        }
    };

    return [onClickUp, onClickDown, onChangeNumber, onBlurNumber];
};

const NumberInput = ({
    width = 80, 
    propertyName, 
    value, 
    min = 0, 
    onChange, 
    onKeyChange, 
    disabled, 
    max = 100, 
    step = 1, 
    error=false, 
    rangeLabel=false,
    forceInt,
}, ref) => {
    const [onClickUp, onClickDown, onChangeNumber, onBlurNumber] = useNumberInput(value, min, max, step, forceInt, onChange);

    return (
        <>
        <div className="input_number_wrap float_l mr8" style={{width}}>
            <input className={"numeric" + (error?" error" : "")} value={value}
                   //type="number"
                   data-name={propertyName} onChange={onChangeNumber} ref={ref}
                   disabled={disabled} min={min} max={max} onBlur={onBlurNumber} />
            <button className="number_up" data-name={propertyName} onMouseDown={onClickUp} disabled={disabled} ></button>
            <button className="number_down" data-name={propertyName} onMouseDown={onClickDown}disabled={disabled} ></button>            
        </div>
        {
            rangeLabel &&
            <span className="error autoline" >({min} - {max})</span>
        }
        
        </>
    );
};

export default forwardRef(NumberInput);