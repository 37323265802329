import React from 'react';
import SPLAYER_4 from '../../../images/icon/SPLAYER4.0.png';
import SPLAYER_5 from '../../../images/icon/SPLAYER5.0.png';
import SPLAYER_6 from '../../../images/icon/SPLAYER6.0.png';
import SPLAYER_7 from '../../../images/icon/SPLAYER7.0.png';
import SPLAYER_9 from '../../../images/icon/SPLAYER9.0.png';
import SPLAYER_10 from '../../../images/icon/SPLAYER10.0.png';
import SIGNAGE from '../../../images/icon/SIGNAGE.png';
import RMSI from '../../../images/icon/RMS_I.png';
import RMSS from '../../../images/icon/RMS_S.png';
import RMS from '../../../images/icon/RMS.png';
import LEDBOX_4 from "../../../images/icon/LEDBOX4.0.png";

const RMPlayer = ({deviceType, deviceTypeVersion, text, disabled = false, popup = false}) => {
    return(
        <span className={"device_icon"}>
            {(
                ()=>{
                    switch(deviceType) {
                        case 'RMI':
                        case 'RIPLAYER' :
                            return <img src={RMSI} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RIPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                        case 'RSPLAYER' :
                            switch (deviceTypeVersion) {
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                    return (
                                        <>
                                            <img src={SPLAYER_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            {
                                                !popup && <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RSPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            }
                                        </>
                                    );
                                case 5:
                                    return (
                                        <>
                                            <img src={SPLAYER_5} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            {
                                                !popup && <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RSPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            }
                                        </>
                                    );
                                case 6:
                                    return (
                                        <>
                                            <img src={SPLAYER_6} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            {
                                                !popup && <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RSPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            }
                                        </>
                                    );
                                case 7:
                                    return (
                                        <>
                                            <img src={SPLAYER_7} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            {
                                                !popup && <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RSPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            }
                                        </>
                                    );
                                case 9:
                                    return (
                                        <>
                                            <img src={SPLAYER_9} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            {
                                                !popup && <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RSPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            }
                                        </>
                                    );
                                case 10:
                                    return (
                                        <>
                                            <img src={SPLAYER_10} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            {
                                                !popup && <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RSPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            }
                                        </>
                                    );
                            }
                        case 'RSIGNAGE' :
                            switch (deviceTypeVersion) {
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                    return (
                                        <>
                                            <img src={SPLAYER_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            <img src={SIGNAGE} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SIGNAGE'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RSIGNAGE'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        </>
                                    );
                                case 5:
                                    return (
                                        <>
                                            <img src={SPLAYER_5} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            <img src={SIGNAGE} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SIGNAGE'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RSIGNAGE'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        </>
                                    );
                                case 6:
                                    return (
                                        <>
                                            <img src={SPLAYER_6} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            <img src={SIGNAGE} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SIGNAGE'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RSIGNAGE'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        </>
                                    );
                                case 7:
                                    return (
                                        <>
                                            <img src={SPLAYER_7} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            <img src={SIGNAGE} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}}  data-deviceType={'SIGNAGE'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}}  data-deviceType={'RSIGNAGE'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        </>
                                    );
                                case 9:
                                    return (
                                        <>
                                            <img src={SPLAYER_9} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            <img src={SIGNAGE} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}}  data-deviceType={'SIGNAGE'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}}  data-deviceType={'RSIGNAGE'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        </>
                                    );
                                case 10:
                                    return (
                                        <>
                                            <img src={SPLAYER_10} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            <img src={SIGNAGE} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}}  data-deviceType={'SIGNAGE'} data-deviceTypeVersion={deviceTypeVersion}/>
                                            <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}}  data-deviceType={'RSIGNAGE'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        </>
                                    );
                            }
                        case 'RLEDBOX' :switch (deviceTypeVersion) {
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                return (
                                    <>
                                        <img src={SPLAYER_4} style={{opacity: disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        <img src={LEDBOX_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'LEDBOX'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RLEDBOX'} data-deviceTypeVersion={deviceTypeVersion}/>
                                    </>
                                );
                            case 5:
                                return (
                                    <>
                                        <img src={SPLAYER_5} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        <img src={LEDBOX_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'LEDBOX'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RLEDBOX'} data-deviceTypeVersion={deviceTypeVersion}/>
                                    </>
                                );
                            case 6:
                                return (
                                    <>
                                        <img src={SPLAYER_6} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        <img src={LEDBOX_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'LEDBOX'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RLEDBOX'} data-deviceTypeVersion={deviceTypeVersion}/>
                                    </>
                                );
                            case 7:
                                return (
                                    <>
                                        <img src={SPLAYER_7} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion} />
                                        <img src={LEDBOX_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'LEDBOX'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RLEDBOX'} data-deviceTypeVersion={deviceTypeVersion}/>
                                    </>
                                );
                            case 9:
                                return (
                                    <>
                                        <img src={SPLAYER_9} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion} />
                                        <img src={LEDBOX_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'LEDBOX'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RLEDBOX'} data-deviceTypeVersion={deviceTypeVersion}/>
                                    </>
                                );
                            case 10:
                                return (
                                    <>
                                        <img src={SPLAYER_10} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion} />
                                        <img src={LEDBOX_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'LEDBOX'} data-deviceTypeVersion={deviceTypeVersion}/>
                                        <img src={RMS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'RLEDBOX'} data-deviceTypeVersion={deviceTypeVersion}/>
                                    </>
                                );
                        }

                    }
                }
            )()}
            {/*<img src={RMSS} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}}/>*/}
            {text ? 'RM Player' : ''}
        </span>
    )
}
export default RMPlayer
