import React, {useEffect, useRef, useState} from 'react';
import MISDialog from '../MISDialog';
import {useTranslation} from "react-i18next";
import TextInput from "../../input/TextInput";
import {userService} from "../../../services";
import WhiteButton from "../../button/WhiteButton";
import Checkbox from "../../checkbox/Checkbox";
import "./AddOrganizationGroupPopup.css"
import {toastr} from 'helper/toastrIntercept';
import CircleButton from "../../button/CircleButton";
import cloneDeep from "lodash/cloneDeep";
import {getErrorMessage} from "../../../helper/responseHandler";

const AddOrganizationGroupPopup = ({onSave, onClose}) => {

    const {t} = useTranslation();

    const [data, setState] = useState({
        organizationGroupName: "",
        organizations: [],
        searchOrganizationName: "",
        searchOrganizations: [],
    });

    const {organizationGroupName = "", organizations = [], searchOrganizationName = "", searchOrganizations = []} = data;
    const checkAll = useRef();
    const checkBoxRefs = [];

    useEffect(() => {
        fetchOrganizationGroupOrganizations();
    }, []);

    const onChangeOrganizationGroupName = (value) => {
        setState({
            ...data,
            organizationGroupName: value,
        })
    };

    const onChangeSearchText = (value) => {
        setState({
            ...data,
            searchOrganizationName: value,
        })
    };

    const onKeyDownForSearch = e => {
        if (e.keyCode === 13) {
            onClickSearchOrganizationName();
        }
    };

    const onClickSearchOrganizationName = () => {
        let searchOrganizations = [];
        organizations.forEach(organization => {
            if (organization.groupName.toUpperCase().includes(searchOrganizationName.toUpperCase())) {
                searchOrganizations.push(organization);
            }
        });

        setState({
            ...data,
            searchOrganizations: cloneDeep(searchOrganizations),
        })
    };

    const reset = () => {
        checkAll.current.checked = false;
        organizations.forEach(organization => organization.isChecked = false);
        setState({
            ...data,
            searchOrganizationName: "",
            searchOrganizations: cloneDeep(organizations),
        })
    };

    const setOrganizationCheck = (groupName, checked) => {
        const oriIndex = organizations.findIndex(organization => organization.groupName === groupName);
        organizations[oriIndex].isChecked = checked;
    };

    const checkAllOrganization = (e) => {
        if (e.target.checked) {
            for (let index = 0; index < organizations.length; ++index) {
                organizations[index].isChecked = true;
            }
            for (let index = 0; index < searchOrganizations.length; ++index) {
                searchOrganizations[index].isChecked = true;
            }
        } else {
            for (let index = 0; index < organizations.length; ++index) {
                organizations[index].isChecked = false;
            }
            for (let index = 0; index < searchOrganizations.length; ++index) {
                searchOrganizations[index].isChecked = false;
            }
        }
        setState({
            ...data,
            organizations: organizations,
            searchOrganizations: searchOrganizations,
        })
    };

    const checkOrganization = (index) => {
        searchOrganizations[index].isChecked = checkBoxRefs[index].checked;
        setOrganizationCheck(searchOrganizations[index].groupName, checkBoxRefs[index].checked);
        checkAll.current.checked = true;
        for (let index = 0; index < searchOrganizations.length; ++index) {
            if (!searchOrganizations[index].isChecked) {
                checkAll.current.checked = false;
                break;
            }
        }
        setState({
            ...data,
            searchOrganizations: searchOrganizations,
        })
    };

    const save = () => {
        if (!organizationGroupName || organizationGroupName.trim().length === 0) {
            toastr.error(t("MESSAGE_COMMON_ENTER_NAME_P"));
            return;
        }
        let selectedGroupIds = [];
        searchOrganizations.forEach((organization) => {
            if (organization.isChecked) {
                selectedGroupIds.push(organization.organizationId);
            }
        });
        if (selectedGroupIds.length <= 1) {
            toastr.error(t("MIS_SID_SELELCT_LEAST_2_ORGANIZITIONS_MSG"));
            return;
        }
        saveNewOrganizationGroup({
            organizationGroupName: organizationGroupName.trim(),
            organizationIds: selectedGroupIds,
        });
    };

    const fetchOrganizationGroupOrganizations = () => {
        userService.fetchOrganizationGroupOrganizations().then(res => {
            let index = res.items.findIndex(item => item.parentGroupId === -1);
            if(index >= 0) {
                res.items.splice(index, 1);
            }
            let all = res.items;
            for (let i = 0; i < all.length; i++) {
                all[i] = {
                    ...all[i],
                    isChecked: false,
                }
            }
            setState({
                ...data,
                organizations: cloneDeep(all),
                searchOrganizations: cloneDeep(all),
            })
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    const saveNewOrganizationGroup = (data) => {
        userService.createOrganizationGroups(data).then(res => {
            toastr.success(t("MIS_SID_ORGANIZATION_GROUP_HAS_BEEN_ADDED"));
            onSave();
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage));
        });
    };

    return (
        <MISDialog
            dialog={{
                title: t('MIS_SID_SERVER_ADD_ORGANIZTION_GROUP'),
                closeOnEscape: true,
                modal: true,
                width: 405,
                height: 442,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {id: "SAVE", title: t("COM_BUTTON_SAVE"), onClick: save},
                    {id: "CANCEL", title: t("BUTTON_CANCEL_P"), onClick: onClose}
                ]
            }}>
            <div className="organization_group_contents">
                <div className='organization_group_contents_name'>
                    <span className='organization_group_contents_title'>{t('MIS_SID_ORGANIZATION_GROUP_NAME')}</span>
                    <TextInput width={178} height={27} maxLength={25} placeholder={t('MESSAGE_COMMON_ENTER_NAME_P')} value={organizationGroupName}
                               onChange={e => onChangeOrganizationGroupName(e.target.value)}/>
                </div>
                <div style={{height: '8px'}}/>
                <div className='organization_group_contents_name'>
                    <TextInput width={250} height={27} placeholder={t('MIS_SID_ENTER_ORGANIZTION_NAME')} value={searchOrganizationName}
                               onChange={e => onChangeSearchText(e.target.value)} onKeyDown={onKeyDownForSearch}/>
                    <div style={{width: '10px'}}/>
                    <CircleButton id={"SEARCH"} onClick={onClickSearchOrganizationName}>
                        <span className='organization_group_contents_search_btn'/>
                    </CircleButton>
                    <div style={{width: '10px'}}/>
                    <WhiteButton id={"RESET"} name={t('COM_DID_LFD_RESET')} onClick={reset} style={{marginRight: '4px'}}/>
                </div>
                <div style={{height: '8px'}}/>
                <div className='organization_group_contents_list'>
                    <div className='organization_group_contents_row'>
                        <Checkbox id={'organization-all'} name={t("SETUP_NEW_STRING29_P")} onChange={checkAllOrganization} ref={checkAll}/>
                    </div>
                    <div>
                        {
                            searchOrganizations.map((organization, index) =>
                                <div key={index} className='organization_group_contents_row'>
                                    <Checkbox id={'organization-' + index} name={organization.groupName}
                                              checked={organization.isChecked} onChange={() => checkOrganization(index)}
                                              ref={ref => checkBoxRefs[index] = ref}/>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </MISDialog>
    );
};

export default AddOrganizationGroupPopup;