import React, {Component} from "react";
import './DashboardPanel.css'
import './SoftwareUpdateSummary.css'
import {SortableHandle} from "react-sortable-hoc";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {menuAction} from "../../actions";
import totalIcon from '../../images/icon/icon_total.png';
import downloadingIcon from '../../images/icon/icon_downloading.png';
import downloadedIcon from '../../images/icon/icon_downloadCompleted.png';
import upgradedIcon from '../../images/icon/icon_upgradeSusscess.png';
import failIcon from '../../images/icon/icon_upgradeFail.png';
import Moment from "moment";
import {dashboardService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import {MoonLoader} from "react-spinners";
import {getErrorMessage} from "../../helper/responseHandler";

class SoftwareUpdateSummary extends Component {

    state = {
        softwareUpdateInfo: {},
        endDate: "TEXT_TODAY_P",
        startDate: Moment(new Date().setDate(new Date().getDate() - 7)).format('YYYY-MM-DD'),
        loading: true,
    };

    constructor(props) {
        super(props);
        this.onItemClickEvent = this.onItemClickEvent.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
    }

    componentDidMount() {
        this.fetchGetSoftwareUpdateInfo();
    }

    async onItemClickEvent() {
        const {removeTab, moveTab, t} = this.props;
        removeTab('DEVICE');
        moveTab({id: 'DEVICE', title : t("TEXT_DEVICE_P"), active: true, close: true}, 'SOFTWARE_UPDATE', 'SOFTWARE_APPLIED_STATUS');
    }

    fetchGetSoftwareUpdateInfo = () => {
        this.setState({loading: true,});
        dashboardService.fetchSoftwareUpdate().then(res => {
            this.setState({
                softwareUpdateInfo: res.items,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally(() => {this.setState({loading: false});});
    };

    render() {
        let {t} = this.props;
        let {softwareUpdateInfo, startDate, endDate, loading} = this.state;
        let total = softwareUpdateInfo.downloadFinished + softwareUpdateInfo.downloading + softwareUpdateInfo.upgradeSuccess + softwareUpdateInfo.upgradeFail;
        return (
            <div className='dashboard-widget'>
                {this.renderHeader()}
                <div className='dashboard-widget-body'>
                    {loading && <MoonLoader css={`position: absolute;top: calc(50% - 40px);left: calc(50% - 40px);`} color="#6e78c8"/>}
                    {!loading &&
                    <div className="software-update">
                        <ul>
                            <li onClick={this.onItemClickEvent}>
                                <div className='software-update-icon'><img src={totalIcon} style={{marginRight: '11px'}}/></div>
                                <div className='software-update-title'>{t("LIST_TITLE_TOTAL")}</div>
                                <div className='software-update-count' style={{color: '#454545'}}>{total}</div>
                            </li>
                            <li onClick={this.onItemClickEvent}>
                                <div className='software-update-icon'><img src={downloadingIcon} style={{marginRight: '11px'}}/></div>
                                <div className='software-update-title'>{t("MSG_DOWNLOAD")}</div>
                                <div className='software-update-count' style={{color: '#198DCB'}}>{softwareUpdateInfo.downloading}</div>
                            </li>
                            <li onClick={this.onItemClickEvent}>
                                <div className='software-update-icon'><img src={downloadedIcon} style={{marginRight: '11px'}}/></div>
                                <div className='software-update-title'>{t("MIS_TEXT_DOWNLOAD_FINISHED_P")}</div>
                                <div className='software-update-count' style={{color: '#198DCB'}}>{softwareUpdateInfo.downloadFinished}</div>
                            </li>
                            <li onClick={this.onItemClickEvent}>
                                <div className='software-update-icon'><img src={upgradedIcon} style={{marginRight: '11px'}}/></div>
                                <div className='software-update-title'>{t("MIS_TEXT_UPGRADE_SUCCESS_P")}</div>
                                <div className='software-update-count' style={{color: '#198DCB'}}>{softwareUpdateInfo.upgradeSuccess}</div>
                            </li>
                            <li onClick={this.onItemClickEvent}>
                                <div className='software-update-icon'><img src={failIcon} style={{marginRight: '11px'}}/></div>
                                <div className='software-update-title'>{t("MIS_TEXT_UPGRADE_FAIL_P")}</div>
                                <div className='software-update-count' style={{color: '#EE3A2F'}}>{softwareUpdateInfo.upgradeFail}</div>
                            </li>
                        </ul>
                        <div className='software-update-period'>{startDate} ~ {t(endDate)}</div>
                    </div>
                    }
                </div>
            </div>
        )
    };

    renderHeader() {
        let {id, isEditMode, t} = this.props;
        const DragHandle = SortableHandle(() =>
            <div className='title'>{t("MIS_SID_SOFTWARE_UPDATE_SUMMARY")}</div>
        );
        return (
            <div className='dashboard-widget-header'>
                <DragHandle />
                {
                    isEditMode && <div title="Close" className="remove" onClick={() => this.props.onRemoveLayoutClickEvent(id)}></div>
                }
            </div>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        removeTab: (id) => dispatch(menuAction.removeTab(id)),
        moveTab: (tab, id, childrenId) => dispatch(menuAction.moveTab(tab, id, childrenId)),
    })
)(withTranslation()(SoftwareUpdateSummary));
