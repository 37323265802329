import React, {useEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group'
import {Loading} from "./Loading";
import classNames from 'classnames';
import './LoadingOverlay.css';

const LoadingOverlay = ({children, className = '', onClick, active, text}) => {

    const wrapper = useRef();

    const [overflowCss, setOverflowCss] = useState({});

    useEffect(()=>{
        window.getComputedStyle(wrapper.current);
        const wrapperStyle = window.getComputedStyle(wrapper.current);
        const overflowCSS = ['overflow', 'overflowX', 'overflowY'].reduce((m, i) => {
            if (wrapperStyle[i] !== 'visible') m[i] = 'hidden'
            return m
        }, {})
        setOverflowCss(overflowCSS);

    }, [])

    return (
        <div
            data-testid='wrapper'
            ref={wrapper}
            className={classNames('loading_overlay_wrap', className, 'wrapper', overflowCss, {'wrapper--active' : active === true})}
        >
            <CSSTransition
                in={active}
                classNames='_loading-overlay-transition'
                unmountOnExit
            >
                <div
                    data-testid='overlay'
                    className={'overlay'}
                    onClick={onClick}
                >
                    <div className={'content'}>
                        <Loading />
                        {text}
                    </div>
                </div>
            </CSSTransition>
            {children}
        </div>
    )
}
export default LoadingOverlay;