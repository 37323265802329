import React, {createContext, useContext, useState} from "react";
import {userService} from "../../services";
import {toastr} from "../../helper/toastrIntercept";
import {popupAction} from "../../actions";
import {commonConstants, userConstants} from "../../constants";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import classNames from "classnames";
import SettingInformation from "../../components/setting/SettingInformation";
import StoredDevicePopup from "../../components/popup/user/StoredDevicePopup";


export const MfaManagement = (props) => {

    const {type, userId} = props;
    const [viewType, setViewType] = useState(type);

    const [isStoredDevice, setIsStoredDevice] = useState(false);
    const [storedDevice, setStoredDevice] = useState();

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const onStoredDevicePopupClose = () => {
        setIsStoredDevice(false);
    }


    const openStoredDevicePopup = () => {

        userService.fetchStoredDevices(userId).then(res => {

            let storedDevice = res.items.map(data => ({
                ...data,
                deviceInfo: `${data.authDeviceId}:${data.osName} ${data.osVersion} ${data.browserName} ${data.browserVersion}`
            }));

            setStoredDevice(storedDevice);
            setIsStoredDevice(true);

        }).catch(err => {
            err && err.errorMessage ? toastr.error(err.errorMessage) : toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
        });


    }

    const handleResetPassowrd = (resetPassword, id) => {
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: id,
            title: t("TEXT_RESET_PASSWORD_P"),
            useMessageLine: false,
            message: (
                <div style={{height: '90px', width: '330px', verticalAlign: 'middle', display: 'table-cell'}}>
                    <div style={{height: '15px', textAlign: "center", lineHeight: '15px'}}>{resetPassword}</div>
                </div>
            ),
            width: 330,
            height: 110,
            useNoButton: false,
            onClickYes: () => dispatch(popupAction.closePopup(id))
        }));
    };

    const resetPasswordPopup = (e, id) => {

        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: id,
            title: t("MIS_SID_CAJUL_RESET_OTP_AUTHENTICATION"),
            yesTitle: t('BUTTON_OK_P'),
            noTitle: t('COM_BUTTON_CLOSE_P'),
            useMessageLine: false,
            message: (
                <div style={{height: '90px', textAlign: "center", marginTop: '20px'}}>
                    {id === userConstants.RESET_PASSWORD &&
                    t('MESSAGE_USER_ISSUE_PWD_P')
                    }
                    {id === userConstants.RESET_OTP &&
                    t('MIS_SID_MIX_CAJUL_A_U_SURE_WANT_RESET_OTP_AUTH_INFO_FOR').replace("<<A>>", userId)
                    }
                </div>
            ),
            width: 320,
            height: 110,
            onClickYes: () => {

                if (id === userConstants.RESET_PASSWORD) {
                    userService.updateTemporaryPassword(userId).then(res => {
                        handleResetPassowrd(res.items.resetPassword, 'DISPLAY_RESET_PASSWORD');
                        dispatch(popupAction.closePopup(id));
                        /*popupAction.closePopup(id);*/
                    }).catch(err => {
                        err && err.errorMessage ? toastr.error(err.errorMessage) : toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
                    });
                } else if (id === userConstants.RESET_OTP) {

                    userService.resetMfa(userId).then(res => {
                        if (res.status === 'Success') {
                            toastr.success(t('COM_TV_SID_SUPPORT_RESET_COMPLETE'));
                        }
                        dispatch(popupAction.closePopup(id));
                    }).catch(err => {
                        err && err.errorMessage ? toastr.error(err.errorMessage) : toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
                    });

                    dispatch(popupAction.closePopup(id));

                }
            },
            onClose: () => dispatch(popupAction.closePopup(id)),
        }));
    };


    const getMfaItems = () => {
        return [
            {
                id: "storedDevice", name: <Trans i18nKey={"MIS_SID_CASEP_TRUSTED_DEVICES"}/>,/* title: getDateByFormat(null, items.dateFormat),*/
                value: <>
                    <button className={classNames('base', 'mr8')} style={{width: '100px'}}
                            onClick={(e) => {
                                openStoredDevicePopup();
                            }}>{t('MIS_SID_VIEW')}</button>
                </>
            },
            {
                id: "resetOtp", name: <Trans i18nKey={"MIS_SID_CAJUL_RESET_OTP_AUTHENTICATION"}/>,/* title: getDay(items.firstDay),*/
                value: <>
                    <button className={classNames('base', 'mr8')} style={{width: '100px'}}
                            onClick={(e) => {
                                resetPasswordPopup(e, userConstants.RESET_OTP)
                            }}>{t('COM_DID_LFD_RESET')}</button>
                </>
            }

        ]
    }

    return (
        <>
            {isStoredDevice &&
            <StoredDevicePopup userId={userId} storedDeviceData={storedDevice} onClose={onStoredDevicePopupClose}/>
            }
            {viewType === 'MY_INFORMATION' &&
            <SettingInformation id={"MFA_INFORMATION"} title={t("MIS_SID_CAJUL_OTP_AUTH_INFO")}
                                list={getMfaItems()}/>
            }
            {viewType === 'USER_PASSWORD_RESET' &&

            <tr>
                <th>{t('TEXT_RESET_PASSWORD_P')}</th>
                <td>
                    <button className={classNames('base', 'mr8')} style={{width: '100px'}}
                            onClick={(e) => {

                                resetPasswordPopup(e, userConstants.RESET_PASSWORD)
                            }}>{t('MIS_BUTTON_ISSUANCE_P')}</button>
                </td>
            </tr>

            }
            {viewType === 'USER_EDIT' &&
            <table>
                <colgroup>
                    <col width="203px"/>
                    <col width=""/>
                </colgroup>
                <tbody>
                <tr>
                    <th colSpan={2}>
                        <h4>
                            {t('MIS_SID_CAJUL_OTP_AUTH_INFO')}
                        </h4>
                    </th>
                </tr>
                <tr>
                    <th>{t('MIS_SID_CASEP_TRUSTED_DEVICES')}</th>
                    <td>
                        <button className={classNames('base', 'mr8')} style={{width: '100px'}}
                                onClick={(e) => {
                                    openStoredDevicePopup();
                                }}>{t('MIS_SID_VIEW')}</button>
                    </td>
                </tr>

                <tr>
                    <th>{t('MIS_SID_CAJUL_RESET_OTP_AUTHENTICATION')}</th>
                    <td>
                        <button className={classNames('base', 'mr8')} style={{width: '100px'}}
                                onClick={(e) => {
                                    resetPasswordPopup(e, userConstants.RESET_OTP)
                                }}>{t('COM_DID_LFD_RESET')}</button>
                    </td>
                </tr>

                </tbody>
            </table>
            }
        </>
    )
}

