import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {get, isEmpty, isNil, isObject, set} from 'lodash';
import {connect} from "react-redux";
import {toastr} from 'helper/toastrIntercept';
import moment from 'moment';
import {
    commonConstants,
    DEVICE_UPDATE_SUB_TABS,
    DEVICE_UPDATE_SUB_TABS_FOR_CHILD,
    IPLAYER,
    LPLAYER,
    RIPLAYER,
    SIGNAGE_CHILD
} from "../../../../constants";
import {popupAction} from "../../../../actions";
import MISDialog from '../../MISDialog';
import SubTab from '../../../tab/SubTab';
import {deviceService} from "../../../../services";
import EditInformation from './EditInformation';
import EditTime from './EditTime';
import EditSetup from './EditSetup';
import EditDisplay from './EditDisplay';
import EditSecurity from './EditSecurity';
import {deviceValidator, parseDeviceSupportFlag} from '../../../../helper';
import './EditDevice.css';
import {Loading} from '../../../loading/Loading';
import {DeviceFadeInTransition, DeviceFadeOutTransition} from '../../../device/DeviceTransition';
import {getErrorMessage} from 'helper/responseHandler';

const handleChange = (e, value, info, t) => {
    const name = e['data-name'] || e.target.getAttribute('data-name');
    const newInfo = {...info};
    const {data, changed, errors} = newInfo;
    
    set(changed, name, true);
    if(!isNil(value)) {
        set(data, name, value);
    } else if(!isNil(e.target.value)) {
        if(preValidateChange(data,name,e.target.value)) {
            set(data, name, e.target.value);
        }else {
            delete changed[name];
        }
    } else {
        set(data, name, e.target.getAttribute('data-value'));
    }

    const result = deviceValidator(name, get(data, name));
    if(result) {
        if(result.isValid) {
            delete errors[name];
        } else {
            errors[name] = t(result.messageId);
        }
    }
    return newInfo;
};

const preValidateChange = (data,name, currentVal) => {
    if(name === 'pinCode'){
        const result = deviceValidator(name, get(data, name),currentVal);
        return result.isValid;
    }
    return true;
}

export const useDeviceInfo = (initialInfo, t) => {
    const [deviceInfo, setDeviceInfo] = useState(initialInfo);
    
    const onChangeDeviceInfo = (e, value) => {
        const newInfo = handleChange(e, value, deviceInfo, t);
        setDeviceInfo(newInfo);
    };

    return [deviceInfo, setDeviceInfo, onChangeDeviceInfo];
};

const createUpdateInfo = (deviceIds, {data, changed}) => {
    let updateInfo = {deviceIds};
    for(let propName in changed) {
        set(updateInfo, propName, get(data, propName));
    }
    return updateInfo;
};

const isChanged = (changed, props) => {
    return props.some(prop => changed[prop]);
};

const propertyGroups = {
    dayLightSavingManual: ['dstStartMonth', 'dstStartWeek', 'dstStartDay', 'dstStartTime', 'dstEndMonth', 'dstEndWeek', 'dstEndDay', 'dstEndTime', 'dstTimeDifference'],
};

const isOnlyPopRefresh = (info) => {
    const {changed} = info;
    let updateInfoList = [];
    for(let propName in changed) {
        updateInfoList.push(propName);
    }

    return (updateInfoList.length === 1 && updateInfoList[0] === 'statisticsFileRefresh');
};

const createSetupInfo = (deviceIds, info) => {
    const {data, changed} = info;
    let updateInfo = createUpdateInfo(deviceIds, info);

    const {dayLightSaving} = data;
    const dayLightSavingManualChanged = isChanged(changed, propertyGroups.dayLightSavingManual);

    if(changed['dayLightSaving'] || dayLightSavingManualChanged) {
        set(updateInfo, 'dayLightSaving', dayLightSaving);
    }

    if(data.deviceType !== IPLAYER && data.deviceType !== RIPLAYER) {
        set(updateInfo, 'dstManualChanged', dayLightSavingManualChanged || (changed['dayLightSaving'] && dayLightSaving));
        if(get(updateInfo, 'dstManualChanged')) {
            propertyGroups.dayLightSavingManual.forEach(propName => set(updateInfo, propName, get(data, propName)));
        }
    }
    return updateInfo;
};

const createTimeInfo = (deviceIds, info) => {
    const {data, changed} = info;
    let updateInfo = createUpdateInfo(deviceIds, info);

    const {timerConfTimerList, deviceTimeHolidayHistory} = updateInfo;

    if(timerConfTimerList && timerConfTimerList.length > 0) {
        set(updateInfo, 'timerConfTimerList', timerConfTimerList.map((timer, i) => {
            if(isNil(get(changed, `timerConfTimerList[${i}]`))) {
                return null;
            }
            return timer;
        }));
    }
    if(deviceTimeHolidayHistory && deviceTimeHolidayHistory.length > 0) {
        set(updateInfo, 'deviceTimeHolidayList', deviceTimeHolidayHistory);
    }
    delete updateInfo['deviceTimeHolidayHistory'];

    return updateInfo;
};

const createSetupResponseWarning = (info, updateResult) => {
    let responseWarning = {};
    const {data, changed} = info;

    try {
        for(let propName in changed) {
            if(get(data, propName) != get(updateResult, propName)) {
                if(propName === 'edgeServers') {
                    let changedIndex = -1;
                    get(data, propName).map((server, index) => {
                        get(updateResult, propName).map((updateServer) => {
                            if(server.hostName === updateServer.hostName && server.ipAddress === updateServer.ipAddress &&
                                server.selected !== updateServer.selected) {
                                changedIndex = index;
                            }
                        })
                    });
                    if(changedIndex > -1) {
                        set(responseWarning, `edgeServers[${changedIndex}].selected`, true);
                    }
                } else {
                    set(responseWarning, propName, get(updateResult, propName));
                }
            }
        }
    } catch(error) {
        console.log(error);
    }

    return responseWarning;
};

const getPropPath = (path, propName) => {
    return path ? `${path}.${propName}` : propName;
};

const equalTime = (time, responseTime) => {
    return moment(responseTime, 'hh:mmA').format('hh:mmA') === time;
};

const equalColorSpace = (colorSpace, responseColorSpace) => {
    return (colorSpace+'').split(';')[0] === (responseColorSpace+'').split(';')[0];
}

const comparators = {
    mntAutoMaxTime: equalTime,
    mntAutoMinTime: equalTime,
    scrSafeStartTime: equalTime,
    scrSafeEndTime: equalTime,
    colorSpace: equalColorSpace
};

const setResponseWarning = (changed, data, updateResult, responseWarning, path) => {
    try {
        for(let propName in changed) {
            const changedProp = get(changed, propName);
            if(isObject(changedProp)) {
                setResponseWarning(changedProp, get(data, propName), get(updateResult, propName), responseWarning, getPropPath(path, propName));
            } else {
                if(['advOsdChangedStatus'].includes(propName)) // skip
                    continue;
                else if(propName === 'advOsdChanged'){
                    const advOsdType = ['advSourceOsd', 'advNotOptimimumModeOsd', 'advNoSignalOsd', 'advMdcOsd', 'advDownloadStatusOsd'][get(data, propName)];
                    if(get(updateResult, advOsdType) !== get(data, 'advOsdChangedStatus')){
                        set(responseWarning, getPropPath(path, advOsdType), get(data, 'advOsdChangedStatus'));
                    }
                } else {
                    const comp = comparators[propName];
                    if(comp) {
                        if(!comp(get(data, propName), get(updateResult, propName))) {
                            set(responseWarning, getPropPath(path, propName), get(updateResult, propName));
                        }
                    } else {
                        if(get(data, propName) != get(updateResult, propName)) {
                            set(responseWarning, getPropPath(path, propName), get(updateResult, propName));
                        }
                    }
                }
            }
        }
    } catch(error) {
        console.log(error);
    }
};

const createResponseWarning = (info, updateResult) => {
    let responseWarning = {};
    const {data, changed} = info;
    setResponseWarning(changed, data, updateResult, responseWarning);
    return responseWarning;
};

const fetchUpdateResult = (fetch, deviceIds, requestId, timer, closePopup, checkCount, t, step, isRequestStatistics) => {
    return fetch(deviceIds, requestId, step ? step : (isRequestStatistics !== undefined ? isRequestStatistics : undefined)).then(res => {
        if(res.status === 'Fail') {
            checkCount.current ++;
            if(checkCount.current >= 30) {
                clearInterval(timer.current);
                timer.current = null;
                checkCount.current = 0;
                closePopup('EDIT_DEVICE_LOADING');
                toastr.warning(t('MESSAGE_COMMON_ERROR_P'));
            }
            throw 'Fail';
        } else {
            clearInterval(timer.current);
            timer.current = null;
            checkCount.current = 0;
            closePopup('EDIT_DEVICE_LOADING');
            return res.items;
        }
    }).catch(error => {
        checkCount.current ++;
        if(checkCount.current >= 30) {
            clearInterval(timer.current);
            timer.current = null;
            checkCount.current = 0;
            closePopup('EDIT_DEVICE_LOADING');
            toastr.warning(t('MESSAGE_COMMON_ERROR_P'));
        }
        throw error;
    });
};

const EditDevice = ({deviceIds, tabIndex, hasDeviceSecurity = false, onSave, onChange, onClose, addPopup, closePopup}) => {
    const {t} = useTranslation();
    const timer = useRef(null);
    const checkCount = useRef(0);
    const [subTab, setSubTab] = useState(DEVICE_UPDATE_SUB_TABS[tabIndex]);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [generalInfo, setGeneralInfo, onChangeGeneralInfo] = useDeviceInfo({
        data: {},
        changed: {},
        errors: {},
    }, t);

    const [timeInfo, setTimeInfo, onChangeTimeInfo] = useDeviceInfo({
        data: undefined,
        changed: {},
        errors: {},
    }, t);
    const [setupInfo, setSetupInfo, onChangeSetupInfo] = useDeviceInfo({
        data: undefined,
        changed: {},
        errors: {},
    }, t);
    const [displayInfo, setDisplayInfo, onChangeDisplayInfo] = useDeviceInfo({
        data: undefined,
        changed: {},
        errors: {},
    }, t);
    const [securityInfo, setSecurityInfo, onChangeSecurityInfo] = useDeviceInfo({
        data: undefined,
        changed: {},
        errors: {},
    }, t);

    const {data: {deviceName, deviceType, power, vwtId} = {}} = generalInfo || {};

    useEffect(() => {
        return () => {
            if(timer.current) {
                closePopup('EDIT_DEVICE_LOADING');
                clearInterval(timer.current);
            }
        };
    }, []);

    const handleSave = () => {
        if(subTab === DEVICE_UPDATE_SUB_TABS[0]) {
            deviceService.updateDeviceGeneralInfo(createUpdateInfo(deviceIds, generalInfo)).then(res => {
                toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                if(deviceIds.length === 1 && generalInfo.sendCleanStorageFlag) {
                    if(timer.current) {
                        clearInterval(timer.current);
                    }
                    timer.current = setInterval(() => {
                        fetchUpdateResult(deviceService.fetchGeneralInfoRequestStatus, deviceIds, res.items.requestId, timer, closePopup, checkCount, t).then(items => {
                            toastr.success(t('MSG_FINISHED'));
                            const {successList = [{}]} = items;
                            setGeneralInfo({data: successList[0], changed: {}, errors: {}});
                        }).catch(error => {
                            if(error !== 'Fail') {
                                toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
                            }
                        });
                    }, 1500);
                } else {
                    const newInfo = {...generalInfo, changed: {}, errors: {}};
                    setGeneralInfo(newInfo);
                    onChange();
                    if(deviceIds.length > 1) {
                        setTitle(`${t('COM_BUTTON_EDIT')} ${get(newInfo, 'data.deviceName')} (${t('SETUP_STRING028')} ${deviceIds.length - 1})`);
                    } else {
                        setTitle(`${t('COM_BUTTON_EDIT')} ${get(newInfo, 'data.deviceName')}`);
                    }
                }
            }).catch(error => {
                toastr.error(getErrorMessage(error));
            }).finally(() => closePopup('EDIT_DEVICE_LOADING'));
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[1]) {
            deviceService.updateDeviceTimeInfo(createTimeInfo(deviceIds, timeInfo)).then(res => {
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                if(deviceIds.length === 1) {
                    // addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP});
                    if(timer.current) {
                        clearInterval(timer.current);
                    }
                    timer.current = setInterval(() => {
                        fetchUpdateResult(deviceService.fetchTimeInfoRequestStatus, deviceIds, res.items.requestId, timer, closePopup, checkCount, t, checkCount.current + 4).then(items => {
                            toastr.success(t('MSG_FINISHED'));
                            const {successList = [{}]} = items;
                            setTimeInfo({data: successList[0], changed: {}, errors: {}});
                        }).catch(error => {
                            if(error !== 'Fail') {
                                toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
                            }
                        });
                    }, 3000);
                } else {
                    if(typeof res.items.successList  !== 'undefined' && res.items.successList.length > 0 ){
                        deviceService.fetchDeviceCurrentTimeInfo(res.items.successList)
                         .then()
                         .catch(error => {
                            console.log(error);
                        });
                    }
                    closePopup('EDIT_DEVICE_LOADING');
                    onClose();
                }
            }).catch(error => {
                closePopup('EDIT_DEVICE_LOADING');
                toastr.error(getErrorMessage(error));
            });
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[2]) {
            deviceService.updateDeviceSetupInfo(createSetupInfo(deviceIds, setupInfo)).then(res => {
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                if(deviceIds.length === 1) {
                    // addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP});
                    const isRequestStatistics = isOnlyPopRefresh(setupInfo);
                    if(timer.current) {
                        clearInterval(timer.current);
                    }
                    timer.current = setInterval(() => {
                        fetchUpdateResult(deviceService.fetchSetupInfoRequestStatus, deviceIds, res.items.requestId, timer, closePopup, checkCount, t, undefined, isRequestStatistics).then(items => {
                            toastr.success(t('MSG_FINISHED'));
                            const {successList = [{}]} = items;
                            setSetupInfo({data: successList[0], changed: {}, errors: {}, responseWarning: createSetupResponseWarning(setupInfo, successList[0])});
                        }).catch(error => {
                            if(error !== 'Fail') {
                                toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
                            }
                        });
                    }, 1500);
                } else {
                    closePopup('EDIT_DEVICE_LOADING');
                    onClose();
                }
            }).catch(error => {
                closePopup('EDIT_DEVICE_LOADING');
                toastr.error(getErrorMessage(error));
            });
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[3]) {
            deviceService.updateDeviceDisplayInfo(createUpdateInfo(deviceIds, displayInfo)).then(res => {
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                if(deviceIds.length === 1) {
                    // addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP});
                    if(timer.current) {
                        clearInterval(timer.current);
                    }
                    timer.current = setInterval(() => {
                        fetchUpdateResult(deviceService.fetchDisplayInfoRequestStatus, deviceIds, res.items.requestId, timer, closePopup, checkCount, t).then(items => {
                            const {successList = [{}]} = items;
                            if(successList[0] && successList[0].settingResultSummary){
                                if(successList[0].settingResultSummary.FAIL > 0){
                                    toastr.error(t('TEXT_FAIL_P'));
                                } else {
                                    toastr.success(t('MSG_FINISHED'));
                                }
                            } else {
                                toastr.success(t('MSG_FINISHED'));
                            }
                            setDisplayInfo({data: successList[0], changed: {}, errors: {}, responseWarning: createResponseWarning(displayInfo, successList[0])});
                        }).catch(error => {
                            if(error !== 'Fail') {
                                toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
                            }
                        });
                    }, 1500);
                } else {
                    closePopup('EDIT_DEVICE_LOADING');
                    onClose();
                }
            }).catch(error => {
                closePopup('EDIT_DEVICE_LOADING');
                toastr.error(getErrorMessage(error));
            });
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[4]) {
            deviceService.updateDeviceSecurityInfo(createUpdateInfo(deviceIds, securityInfo)).then(res => {
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                if(deviceIds.length === 1) {
                    // addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP});
                    if(timer.current) {
                        clearInterval(timer.current);
                    }
                    timer.current = setInterval(() => {
                        fetchUpdateResult(deviceService.fetchSecurityInfoRequestStatus, deviceIds, res.items.requestId, timer, closePopup, checkCount, t).then(items => {
                            toastr.success(t('MSG_FINISHED'));
                            const {successList = [{}]} = items;
                            setSecurityInfo({data: successList[0], changed: {}, errors: {}, responseWarning: createResponseWarning(securityInfo, successList[0])});
                        }).catch(error => {
                            if(error !== 'Fail') {
                                toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
                            }
                        });
                    }, 1500);
                } else {
                    closePopup('EDIT_DEVICE_LOADING');
                    onClose();
                }
            }).catch(error => {
                closePopup('EDIT_DEVICE_LOADING');
                toastr.error(getErrorMessage(error));
            });
        }
    };

    const handleConfirmClose = (id) => {
        closePopup(commonConstants.COMMON_CONFIRM_POPUP);
        setSubTab(id);
    };

    const onClickSubTab = id => {
        const changed = subTab === DEVICE_UPDATE_SUB_TABS[0] ? generalInfo.changed :
            subTab === DEVICE_UPDATE_SUB_TABS[1] ? timeInfo.changed :
            subTab === DEVICE_UPDATE_SUB_TABS[2] ? setupInfo.changed :
            subTab === DEVICE_UPDATE_SUB_TABS[3] ? displayInfo.changed :
            securityInfo.changed;

        if(isNil(changed) || Object.keys(changed).length === 0) {
            setSubTab(id);
        } else {
            addPopup({
                id: commonConstants.COMMON_CONFIRM_POPUP,
                type: commonConstants.COMMON_CONFIRM_POPUP, 
                title: t('COM_BUTTON_SAVE'), 
                message: t('COM_ID_SCENEEDITOR_SAVE_CHANGE'),
                yesTitle: t('BUTTON_YES_P'),
                noTitle: t('BUTTON_NO_P'),
                width: 300,
                height: 100,
                textAlign: 'center',
                onClickYes: () => {
                    closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                    onClickSave();
                    setSubTab(id);
                }, 
                onClose: () => handleConfirmClose(id),
            });
        }
    };

    const handleSaveDisabled = ({changed, errors}) => {
        if(Object.keys(changed).length > 0) {
            if(Object.keys(errors).length === 0) {
                setSaveDisabled(false);
            } else {
                setSaveDisabled(true);
            }
        } else {
            setSaveDisabled(true);
        }
    };

    const onClickSave = () => {
        addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP});
        deviceService.fetchDeviceConnectionCheck(deviceIds).then(res => {
            const {items: {successList = []} = {}} = res;
            if(successList.length === deviceIds.length) {
                handleSave();
            } else {
                closePopup('EDIT_DEVICE_LOADING');
                toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
            }
        }).catch(error => {
            closePopup('EDIT_DEVICE_LOADING');
            toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
        });
    };

    const getCurrentData = callback => {
        if(subTab === DEVICE_UPDATE_SUB_TABS[1]) {
            deviceService.fetchDeviceCurrentTimeInfo([deviceIds[0]]).then(res => {
                const {requestId} = res.items;
                if(timer.current) {
                    clearInterval(timer.current);
                }
                timer.current = setInterval(() => {
                    fetchUpdateResult(deviceService.fetchTimeInfoRequestStatus, [deviceIds[0]], requestId, timer, closePopup, checkCount, t, callback).then(items => {
                        toastr.success(t('MSG_FINISHED'));
                        if(callback) {
                            callback();
                        }
                        const {successList = [{}]} = items;
                        setTimeInfo({data: successList[0], changed: {}, errors: {}});
                    }).catch(error => {
                        if(error !== 'Fail') {
                            toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
                        }
                    });
                }, 3000);
            }).catch(error => {
                // toastr.error(error);
                toastr.error(t('MESSAGE_COMMON_ERROR_P'));
                closePopup('EDIT_DEVICE_LOADING');
            });
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[3]) {
            deviceService.fetchDeviceCurrentDisplayInfo([deviceIds[0]]).then(res => {
                const {requestId} = res.items;
                if(timer.current) {
                    clearInterval(timer.current);
                }
                timer.current = setInterval(() => {
                    fetchUpdateResult(deviceService.fetchDisplayInfoRequestStatus, [deviceIds[0]], requestId, timer, closePopup, checkCount, t, callback).then(items => {
                        toastr.success(t('MSG_FINISHED'));
                        if(callback) {
                            callback();
                        }
                        const {successList = [{}]} = items;
                        setDisplayInfo({data: successList[0], changed: {}, errors: {}});
                    }).catch(error => {
                        if(error !== 'Fail') {
                            toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
                        }
                    });
                }, 3000);
            }).catch(error => {
                // toastr.error(error);
                toastr.error(t('MESSAGE_COMMON_ERROR_P'));
                closePopup('EDIT_DEVICE_LOADING');
            });
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[4]) {
            deviceService.fetchDeviceCurrentSecurityInfo([deviceIds[0]]).then(res => {
                const {requestId} = res.items;
                if(timer.current) {
                    clearInterval(timer.current);
                }
                timer.current = setInterval(() => {
                    fetchUpdateResult(deviceService.fetchSecurityInfoRequestStatus, [deviceIds[0]], requestId, timer, closePopup, checkCount, t, callback).then(items => {
                        //toastr.success(t('MSG_FINISHED'));
                        if(callback) {
                            callback();
                        }
                        const {successList = [{}]} = items;
                        setSecurityInfo({data: successList[0], changed: {}, errors: {}});
                    }).catch(error => {
                        if(error !== 'Fail') {
                            toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
                        }
                    });
                }, 1000);
            }).catch(error => {
                // toastr.error(error);
                toastr.error(t('MESSAGE_COMMON_ERROR_P'));
                closePopup('EDIT_DEVICE_LOADING');
            });
        }
    };

    const onClickViewMore = callback => {
        if(deviceIds.length === 1) {
            addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP});
            getCurrentData(callback);
        } else {
            if(callback) {
                callback()
            }
        }
    };

    const onClickCurrent = () => {
        addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP});
        getCurrentData();
    };

    useEffect(() => {
        setLoading(true);
        if(subTab === DEVICE_UPDATE_SUB_TABS[0]) {
            deviceService.fetchDeviceGeneralInfo([deviceIds[0]]).then(res => {
                const {items: {successList} = [{generalConf: {}}]} = res;
                const generalInfo = successList[0].generalConf;
                generalInfo.hasE2ELicense = successList[0].hasE2ELicense;
                generalInfo.recommendPlay = successList[0].recommendPlay;
                setGeneralInfo({...generalInfo, data: generalInfo, changed: {}, errors: {}});
                if(deviceIds.length > 1) {
                    setTitle(`${t('COM_BUTTON_EDIT')} ${get(successList[0], 'generalConf.deviceName')} (${t('SETUP_STRING028')} ${deviceIds.length - 1})`);
                } else {
                    setTitle(`${t('COM_BUTTON_EDIT')} ${get(successList[0], 'generalConf.deviceName')}`);
                }
            }).catch(error => {
                console.error(error);
            }).finally(() => setLoading(false));
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[1]) {
            deviceService.fetchDeviceTimeInfo([deviceIds[0]]).then(res => {
                const {items: {successList} = [{}]} = res;
                setTimeInfo({...timeInfo, data: successList[0], changed: {}, errors: {}});

                if(power && get(successList[0], 'modelKind') === 'NEW' && isEmpty(get(successList[0], 'timeClockConf.clockMon'))) {
                    toastr.info(t('MIS_TEXT_INITIALIZING_P'));
                    addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP});
                    getCurrentData();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => setLoading(false));
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[2]) {
            deviceService.fetchDeviceSetupInfo([deviceIds[0]]).then(res => {
                const {items: {successList} = [{}]} = res;
                setSetupInfo({...setupInfo, data: successList[0], changed: {}, errors: {}});
            }).catch(error => {
                console.log(error);
            }).finally(() => setLoading(false));
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[3]) {
            deviceService.fetchDeviceDisplayInfo([deviceIds[0]]).then(res => {
                const {items: {successList} = [{}]} = res;
                setDisplayInfo({...displayInfo, data: successList[0], changed: {}, errors: {}});
            }).catch(error => {
                console.log(error);
            }).finally(() => setLoading(false));
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[4]) {
            deviceService.fetchDeviceSecurityInfo([deviceIds[0]]).then(res => {
                const {items: {successList} = [{}]} = res;
                setSecurityInfo({...securityInfo, data: successList[0], changed: {}, errors: {}});
                /*
                if(power && res.items.isInitSecurity !== true) {
                    addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP});
                    getCurrentData();
                }*/
            }).catch(error => {
                console.log(error);
            }).finally(() => setLoading(false));
        }
    }, [subTab, deviceIds]);

    useEffect(() => {
        if(subTab === DEVICE_UPDATE_SUB_TABS[0]) {
            handleSaveDisabled(generalInfo);
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[1]) {
            handleSaveDisabled(timeInfo);
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[2]) {
            handleSaveDisabled(setupInfo);
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[3]) {
            handleSaveDisabled(displayInfo);
        } else if(subTab === DEVICE_UPDATE_SUB_TABS[4]) {
            handleSaveDisabled(securityInfo);
        }
    }, [generalInfo, timeInfo, setupInfo, displayInfo, securityInfo]);

    return (
        <div className='edit_device_pop_wrap'>
            <MISDialog
                dialog={{
                    title,
                    isDraggable: true,
                    closeOnEscape: true,
                    modal: true,
                    width: 860,
                    height: 610,
                    onClose: onClose,
                }}
                buttons={{
                    leftButtons: (deviceIds.length === 1 && (subTab === DEVICE_UPDATE_SUB_TABS[1] || subTab === DEVICE_UPDATE_SUB_TABS[3] || subTab === DEVICE_UPDATE_SUB_TABS[4])) ? [
                        {
                            id: 'CURRENT_STATUS',
                            title: t('COM_TV_SID_CURRENT_STATUS_KR_KOR'),
                            onClick: onClickCurrent,
                            hide: deviceIds > 1 || !generalInfo.data.power,
                        }
                    ] : null,
                    rightButtons: [
                        {
                            id: 'DEVICE_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: onClickSave,
                            disable: saveDisabled,
                        },
                        {
                            id: 'DEVICE_CANCEL',
                            title: t('COM_BUTTON_CLOSE_P'),
                            onClick: onClose,
                        }
                    ]
                }}>
                <div className='edit_device_wrap'>
                    <SubTab tabs={
                        deviceType === SIGNAGE_CHILD ? DEVICE_UPDATE_SUB_TABS_FOR_CHILD : 
                        (hasDeviceSecurity ? DEVICE_UPDATE_SUB_TABS : DEVICE_UPDATE_SUB_TABS.slice(0,4))
                        } selected={subTab} onClick={onClickSubTab} />
                    <DeviceFadeOutTransition transition={!loading} classes={['device_loading_wrap']}>
                        <Loading/>
                    </DeviceFadeOutTransition>
                    <DeviceFadeInTransition transition={!loading}>
                    { 
                        subTab === DEVICE_UPDATE_SUB_TABS[0] && 
                        <EditInformation deviceIds={deviceIds} generalInfo={generalInfo} onChange={onChangeGeneralInfo} />
                    }
                    { 
                        subTab === DEVICE_UPDATE_SUB_TABS[1] && 
                        <EditTime timeInfo={timeInfo} power={power} onChange={onChangeTimeInfo} /> 
                    }
                    { 
                        subTab === DEVICE_UPDATE_SUB_TABS[2] && 
                        <EditSetup isVwt={!isNil(vwtId) && vwtId.length > 0} setupInfo={setupInfo} onChange={onChangeSetupInfo} />
                    }
                    { 
                        subTab === DEVICE_UPDATE_SUB_TABS[3] && 
                        <EditDisplay displayInfo={{...displayInfo, ...(parseDeviceSupportFlag(generalInfo.supportFlag))}} power={power} onChange={onChangeDisplayInfo} onClickViewMore={onClickViewMore} />
                    }
                    { 
                        subTab === DEVICE_UPDATE_SUB_TABS[4] && hasDeviceSecurity &&
                        <EditSecurity securityInfo={securityInfo} onChange={onChangeSecurityInfo} /> 
                    }
                    </DeviceFadeInTransition>
                </div>
            </MISDialog>
        </div>
    );
};

EditDevice.defaultProps = {
    tabIndex: 0,
    onSave: () => {},
    onChange: () => {}
};

export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id))
    })
)(EditDevice);
