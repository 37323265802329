import React, {Component} from "react";
import './DashboardPanel.css'
import './DeviceFaultRealTimeChart.css'
import {SortableHandle} from "react-sortable-hoc";
import {Line} from "react-chartjs-2";
import {withTranslation} from "react-i18next";
import Moment from "moment";
import {dashboardService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from "../../helper/responseHandler";

class DeviceFaultRealTimeChart extends Component {

    state = {
        currentDeviceFaultDate: null,
        currentDeviceFaultInfo: {},
        connectionTimes: [],
        errorCounts: [],
        warningCounts: [],
    };

    constructor(props) {
        super(props);
        this.initTime = this.initTime.bind(this);
        this.onTimer = this.onTimer.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
    }

    componentDidMount() {
        this.charElement = document.getElementById('line-chart');
        this.deviceFaultInterval = setInterval(this.onTimer, 1000);
        this.initTime();
    }

    componentWillUnmount() {
        clearInterval(this.deviceFaultInterval);
    }

    initTime() {
        let initConnectionTimes = [];
        let initErrorCounts = [];
        let initWarningCounts = [];
        for (let index = 0; index < 300; index++) {
            initConnectionTimes.push(null);
            initErrorCounts.push(null);
            initWarningCounts.push(null);
        }
        this.setState({
            connectionTimes: initConnectionTimes,
            errorCounts: initErrorCounts,
            warningCounts: initWarningCounts,
        });
    }

    onTimer() {
        let currentDate = Moment(new Date()).format('HH:mm:ss');
        this.fetchGetCurrentDeviceHealthInfo(currentDate);
    }

    fetchGetCurrentDeviceHealthInfo = (date) => {
        dashboardService.fetchDeviceHealthInfo().then(res => {
            let connectionTimes = this.state.connectionTimes;
            let errorCounts = this.state.errorCounts;
            let warningCounts = this.state.warningCounts;
            connectionTimes.splice(0, 1);
            errorCounts.splice(0, 1);
            warningCounts.splice(0, 1);
            connectionTimes.push(date);
            errorCounts.push(res.items.error);
            warningCounts.push(res.items.warning);
            this.setState({
                currentDeviceFaultDate: date,
                currentDeviceFaultInfo: res.items,
                connectionTimes: connectionTimes,
                errorCounts: errorCounts,
                warningCounts: warningCounts,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    render() {
        return (
            <div className='dashboard-widget'>
                {this.renderHeader()}
                <div className='dashboard-widget-body'>
                    <div className='device-fault'>
                        {this.renderGuide()}
                        {this.renderChart()}
                        {this.renderLabel()}
                    </div>
                </div>
            </div>
        )
    };

    renderGuide() {
        let {t} = this.props;
        return (
            <div className='device-fault-guide'>
                <div className='device-fault-guide-color-index' style={{background: '#ee392f'}}/>
                <div className='device-fault-guide-title-index'>{t('COM_TEXT_TITLE_ERROR_P')}</div>
                <div className='device-fault-guide-color-index' style={{background: '#ffa41f', marginLeft: '10px'}}/>
                <div className='device-fault-guide-title-index'>{t('TEXT_TITLE_WARNING_P')}</div>
            </div>
        );
    }

    renderChart() {
        let charData = {
            labels: [],
            datasets: [],
        }
        let tick = {beginAtZero: true, min: 0, max: 10};
        if (this.charElement) {
            let chart = this.charElement.getContext('2d');
            let {connectionTimes, errorCounts, warningCounts} = this.state;
            charData.labels = connectionTimes;

            let errorGradient = chart.createLinearGradient(0, 0, 0, 180);
            errorGradient.addColorStop(0, 'rgba(238, 57, 47, 0.7)');
            errorGradient.addColorStop(1, 'rgba(238, 57, 47, 0)');
            let errorDataset = {
                label: "Error",
                data: errorCounts,
                borderColor: '#ee392f',
                backgroundColor: errorGradient,
                borderWidth: 2,
            };
            let warningGradient = chart.createLinearGradient(0, 0, 0, 180);
            warningGradient.addColorStop(0, 'rgba(255, 164, 31, 0.7)');
            warningGradient.addColorStop(1, 'rgba(255, 164, 31, 0)');
            let warningDataset = {
                label: "Warning",
                data: warningCounts,
                borderColor: '#ffa41f',
                backgroundColor: warningGradient,
                borderWidth: 2,
            };
            charData.datasets.push(errorDataset);
            charData.datasets.push(warningDataset);

            let max = Math.max(...errorCounts, ...warningCounts);
            if (max > 10) {
                tick = {beginAtZero: true};
            }
        }
        return (
            <div className='device-fault-chart'>
                <Line id='line-chart' data={charData} width={358} height={142} redraw={true}
                      options={{
                          legend: {
                              display: false,
                          },
                          animation: {
                              duration: 0
                          },
                          scales: {
                              xAxes: [{
                                  display: false,
                              }],
                              yAxes: [{ticks: tick}]
                          },
                          elements: {point: {radius: 0}},
                          tooltips: {
                              displayColors: false,
                              xPadding: 10,
                              yPadding: 10,
                              callbacks: {
                                  title: function (tooltipItems, data) {
                                      return null;
                                  },
                                  label: function (tooltipItem, data) {
                                      return tooltipItem.value;
                                  },
                              },
                          }
                      }}/>
            </div>
        );
    }

    renderLabel() {
        let {connectionTimes} = this.state;
        return (
            <div className='device-fault-label'>
                <div className='device-fault-label-ago'>5 minutes ago</div>
                <div className='device-fault-label-current'>{connectionTimes[connectionTimes.length - 1]}</div>
            </div>
        );
    }

    renderHeader() {
        let {id, isEditMode, t} = this.props;
        const DragHandle = SortableHandle(() =>
            <div className='title'>{t("MIS_SID_DEVICE_HEALTH_ISSUES")}</div>
        );
        return (
            <div className='dashboard-widget-header'>
                <DragHandle/>
                {
                    isEditMode && <div title="Close" className="remove" onClick={() => this.props.onRemoveLayoutClickEvent(id)}></div>
                }
            </div>
        );
    }
}

export default withTranslation()(DeviceFaultRealTimeChart);
