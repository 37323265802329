import React, {Component} from "react";
import './DashboardPanel.css'
import './UserSummary.css'
import {SortableHandle} from "react-sortable-hoc";
import {connect} from "react-redux";
import {menuAction} from "../../actions";
import {withTranslation} from "react-i18next";
import {dashboardService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import {MoonLoader} from "react-spinners";
import {getErrorMessage} from "../../helper/responseHandler";

class UserSummary extends Component {

    state = {
        user: {},
        loading: true,
    };

    constructor(props) {
        super(props);
        this.onAllUserClickEvent = this.onAllUserClickEvent.bind(this);
        this.onWithdrawnUserClickEvent = this.onWithdrawnUserClickEvent.bind(this);
        this.onUnapprovedUserClickEvent = this.onUnapprovedUserClickEvent.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
    }

    componentDidMount() {
        this.fetchGetUserInfo();
    }

    async onAllUserClickEvent() {
        const {removeTab, moveTab, t} = this.props;
        removeTab('USER');
        moveTab({id: 'USER', title : t("TEXT_TITLE_USER_P"), active: true, close: true}, 'ALL_USER');
    }

    async onWithdrawnUserClickEvent() {
        const {removeTab, moveTab, t} = this.props;
        removeTab('USER');
        moveTab({id: 'USER', title : t("TEXT_TITLE_USER_P"), active: true, close: true}, 'WITHDRAWN_USERS');
    }

    async onUnapprovedUserClickEvent() {
        const {removeTab, moveTab, t} = this.props;
        removeTab('USER');
        moveTab({id: 'USER', title : t("TEXT_TITLE_USER_P"), active: true, close: true}, 'UNAPPROVED');
    }

    fetchGetUserInfo = () => {
        this.setState({loading: true,});
        dashboardService.fetchUserInfo().then(res => {
            this.setState({
                user: res.items,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally(() => {this.setState({loading: false});});
    };

    render() {
        let {t} = this.props;
        let {user, loading} = this.state;
        return (
            <div className='dashboard-widget'>
                {this.renderHeader()}
                <div className='dashboard-widget-body'>
                    {loading && <MoonLoader css={`position: absolute;top: calc(50% - 40px);left: calc(50% - 40px);`} color="#6e78c8"/>}
                    {!loading &&
                    <div className='user-summary'>
                        <div className='user-summary-all' onClick={this.onAllUserClickEvent}>
                            {user.totalInCount}<span>{t("BUTTON_ALL_USERS_P")}</span>
                        </div>
                        <div className='user-summary-drawn' onClick={this.onWithdrawnUserClickEvent}>
                            {user.totalOutCount}<span>{t("TEXT_TITLE_WITHDRAWAL_P")}</span>
                        </div>
                        <div className='user-summary-request' onClick={this.onUnapprovedUserClickEvent}>
                            {user.unapprovedCount}<span>{t("TEXT_USER_NA_P")}</span>
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    };

    renderHeader() {
        let {id, isEditMode, t} = this.props;
        const DragHandle = SortableHandle(() =>
            <div className='title'>{t("TEXT_USER_SUMMARY_P_KR_MIS20")}</div>
        );
        return (
            <div className='dashboard-widget-header'>
                <DragHandle />
                {
                    isEditMode && <div title="Close" className="remove" onClick={() => this.props.onRemoveLayoutClickEvent(id)}></div>
                }
            </div>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        removeTab: (id) => dispatch(menuAction.removeTab(id)),
        moveTab: (tab, id, childrenId) => dispatch(menuAction.moveTab(tab, id, childrenId)),
    })
)(withTranslation()(UserSummary));
