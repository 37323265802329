import React from 'react';
import FLIP_1 from '../../../images/icon/FLIP1.0.png';
import FLIP_2 from '../../../images/icon/FLIP2.0.png';
import FLIP_3 from '../../../images/icon/FLIP3.0.png';
import FLIP_4 from '../../../images/icon/FLIPPRO.0.png'; // Flip4 is Flip pro

const getSrc= deviceVersion =>{
    switch(deviceVersion) {
		case 1: return FLIP_1;
		case 2: return FLIP_2;
		case 3: return FLIP_3;
		case 4: return FLIP_4;  // Flip4 is Flip pro
		default: return FLIP_1;
	}
}
const Flip = ({deviceTypeVersion, text, disabled = false}) => {
    let imgValue= getSrc(deviceTypeVersion);
    return(
        <span className={"device_icon"}>
            {
                <img src={imgValue} style={{opacity: disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'FLIP'} data-deviceTypeVersion={deviceTypeVersion}/>
            }
            
            {text ?
                `Flip ${deviceTypeVersion === 4 ? 'Pro' : deviceTypeVersion > 1 ? deviceTypeVersion : ''}`
                : ''
            }
        </span>
    )
}
export default Flip