import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {get, isEmpty, isNil, set} from 'lodash';
import {popupAction} from '../../../actions';
import {commonConstants} from '../../../constants';
import MISDialog from '../MISDialog';
import TextInput from '../../input/TextInput';
import {SearchButton} from '../../button/SearchButton';
import Checkbox from '../../checkbox/Checkbox';
import {dateToString, validateDeviceName, validateIpAddress} from '../../../helper';
import './DeviceApprovalPopup.css';
import DateInput from '../../input/DateInput';
import NumberInput from '../../input/NumberInput';
import {deviceService} from '../../../services';
import {useMISOpt} from '../../misopt';
import {getErrorMessage} from '../../../helper/responseHandler';
import moment from 'moment';
import {settingConstants} from '../../../constants/';


const LedBoxApproval = ({data, errors, index, cabinetGroupSettingConnectExisting, supportCabinetSetting, onChangeSlaveNumber, 
    onChangeAutoSetID, onChangeCabinetIP}) => {
    const {t} = useTranslation();
    const {slaveNumber, autoSetID, cabinetIP} = data;
    const {cabinetIP: cabinetIPError = []} = errors || {};

    return (
        <>
            <tr>
                <th style={{fontWeight:'bold'}}>{t('COM_SID_SIGNAGE_MIX_CABINET_GROUP').replace('<<A>>', `${index + 1}`)}</th>
                <td className="background_bar"></td>
            </tr>
            <tr>
                <th>{t('COM_MIS_SID_SERVER_NUMBER_OF_CABINETS')}</th>
                <td>
                    <NumberInput width={100} value={slaveNumber[index]} min={0} max={255} disabled={cabinetGroupSettingConnectExisting}
                        onChange={(e, value) => onChangeSlaveNumber(e, value, index)} forceInt />
                    {/* <div className="input_number float_l mr8"><input id="slaveNumber1" type="text" className="" style="width:50px" value="0"><button className="number_up" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber1\').val(),10)+1; if(isNaN(newVal)){newVal = 0;} $(\'#slaveNumber1\').val(newVal>255?255:newVal);}"></button><button className="number_down" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber1\').val(),10)-1; if(isNaN(newVal)){newVal = 0;} $(\'#slaveNumber1\').val(newVal<0?0:newVal);}"></button></div> */}
                    {
                        supportCabinetSetting &&
                        <Checkbox id={`autoSetID${index + 1}`} name={t('MIS_SID_ID_AUTO_SET')} checked={autoSetID[index]} disabled={cabinetGroupSettingConnectExisting}
                            onChange={e => onChangeAutoSetID(e, index)} />
                        // <input type="checkbox" id="autoSetID1" checked><label for="autoSetID1">' + $.i18n.prop('MIS_SID_ID_AUTO_SET') + '</label>
                    }
                </td>
            </tr>
            {
                supportCabinetSetting &&
                <tr>
                    <th>{t('COM_SID_IP_ADDRESS')}</th>
                    <td>
                        <TextInput width={200} value={cabinetIP[index]} disabled={cabinetGroupSettingConnectExisting} error={get(cabinetIPError, `[${index}]`)}
                            onChange={e => onChangeCabinetIP(e, index)} />
                        {/* <input id="cabinetIP1" type="text" style="width:40%" value="'+ selDeivceIPAddrSubnet + '" onchange="checkUserInputValidation(\'cabinetIP1\', \'ipaddress\');"></input> */}
                    </td>
                </tr>
            }
        </>
    );
};

const DeviceApprovalPopup = ({devices = [], deviceType, hasSignage = false, hasUhdLedBox = false, hasFhdLedBox = false, supportCabinetSetting = false, isE2E = false, e2eLicenseSystem = settingConstants.E2E_LICENSE_SYSTEM_PBP, onSave, onClose}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {misopt: {user: {dateFormat}}} = useMISOpt();
    const momentDateFormat = (dateFormat || 'yyyy-MM-dd').toUpperCase();
    const today = new Date();
    const [data, setData] = useState({
        deviceName: '',
        groupId: undefined,
        groupName: '',
        location: '',
        expiredDate: today,
        neverExpire: true,
        slaveNumber: new Array(hasSignage ? 1 : 4).fill(0),
        autoSetID: new Array(4).fill(true),
        cabinetIP: new Array(4).fill(''),


       //SLM Direct
        soldToCodeId :'',
        secorgId: '',

       //V8 PBP
        accountCode: '',
        brandNm:'',
        accountNm : '',
        brandCode  : '',
        modelCd: ''
    });
    const [errors, setErrors] = useState({});
    const [cabinetGroupSettingConnectExisting, setCabinetGroupSettingConnectExisting] = useState(false);

    const onChangeName = e => {
        const deviceName = e.target.value;
        const result = validateDeviceName(deviceName);
        setData({...data, deviceName});
        if(result && result.isValid === true) {
            setErrors({...errors, deviceName: undefined});
        } else {
            setErrors({...errors, deviceName: t(result.messageId)});
        }
    };

    const onChangeLocation = e => {
        setData({...data, location: e.target.value});
    };

    const saveGroup = groups => {
        if(!groups || groups.length === 0 || groups[0].type === 'ORGANIZATION') {
            return;
        }

        dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
        if(groups && groups.length > 0) {
            deviceService.fetchReplacementCheck({
                deviceIds: devices.map(device => device.deviceId),
                groupId: groups[0].groupId,
                modelName: devices.length === 1 ? devices[0].deviceModelName : '',
            }).then(res => {
                const {isVwlGroup, hasModel} = res.items;
                if(isVwlGroup) {
                    if(hasModel) {
                        setData({...data, groupId: groups[0].groupId, groupName: groups[0].groupName});
                    } else {
                        dispatch(popupAction.addPopup({
                            id: commonConstants.COMMON_ALERT_POPUP,
                            type: commonConstants.COMMON_ALERT_POPUP,
                            title: t('MIS_SID_PREMIUM_REPLACE_DEVICE'),
                            message: t('MIS_SID_PREMIUM_SELECTED_GROUP_VIDEO_SELECT_MSG'),
                            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_ALERT_POPUP)),
                        }));
                    }
                } else {
                    setData({...data, groupId: groups[0].groupId, groupName: groups[0].groupName});
                }
            }).catch(error => {
                const {items: {reasonCode} = {}} = error || {};
                if(reasonCode === '400630') {
                    toastr.error(t('COM_SID_NOT_AVAILABLE_KR_USE'));
                } else {
                    toastr.error(getErrorMessage(error));
                }
            });
        }
    };

    const onClickGroup = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_GROUP_POPUP,
            type: commonConstants.COMMON_GROUP_POPUP,
            mode: 'device',
            allEnabled: true,
            allowSelectOrganization: true,
            contextMenuHandler: {
                'onCreate': (e, callback) => {
                    deviceService.createDeviceGroup({
                        groupName: e.target.value || 'new', 
                        parentGroupId: e.target.getAttribute('data-parent-id')
                    }).then(res => {
                        callback && callback();
                    }).catch(error => toastr.error(getErrorMessage(error)));
                }
            },
            save: saveGroup, 
            close: () => dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP)),
        }));
    };

    const saveSoldToCode = groups => {
        if(!groups || groups.length === 0) {
            return;
        }
        dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
        if(groups && groups.length > 0) {
            setData({...data, soldToCodeId:groups[0].soldToCode, accountNm : groups[0].accountNm, modelCd: groups[0].modelCd, secorgId: groups[0].secorgId});
        }
    };

    const saveBrandCode = groups => {
        if(!groups || groups.length === 0) {
            return;
        }
        dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
        if(groups && groups.length > 0) {
            setData({...data, brandCode :groups[0].brandCode, accountCode : groups[0].accountCode, accountNm: groups[0].accountNm, brandNm:'('+ groups[0].brandNm +') ' ,modelCd: groups[0].modelCd});
        }
    };


    //SLM Direct
    const onClickSoldToCode = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_GROUP_POPUP,
            type: commonConstants.COMMON_GROUP_POPUP,
            mode: 'soldToCode',
            disableSearchToolbar : true,
            save: saveSoldToCode,
            close: () => dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP)),
        }));
    };


    //V8 PBP
    const onClickCompany = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_GROUP_POPUP,
            type: commonConstants.COMMON_GROUP_POPUP,
            mode: 'brandCode',
            disableSearchToolbar : true,
            save: saveBrandCode,
            close: () => dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP)),
        }));
    };

    const onChangeNeverExpire = e => {
        setData({...data, neverExpire: e.target.checked});
    };

    const onChangeExpireDate = value => {
        setData({...data, expiredDate: value});
    };

    const onChangeSlaveNumber = (e, value, index) => {
        const newSlaveNumber = [...data.slaveNumber];
        newSlaveNumber[index] = value;
        setData({...data, slaveNumber: newSlaveNumber});
    };

    const onChangeAutoSetID = (e, index) => {
        const newAutoSetID = [...data.autoSetID];
        newAutoSetID[index] = e.target.checked;
        setData({...data, autoSetID: newAutoSetID});
    };

    const onChangeCabinetIP = (e, index) => {
        const newCabinetIP = [...data.cabinetIP];
        newCabinetIP[index] = e.target.value;
        setData({...data, cabinetIP: newCabinetIP});

        const result = validateIpAddress(e.target.value);
        if(result && result.isValid === true) {
            const newErrors = {...errors};
            set(newErrors, `cabinetIP[${index}]`, null);
            setErrors(newErrors);
        } else {
            const newErrors = {...errors};
            set(newErrors, `cabinetIP[${index}]`, t(result.messageId));
            setErrors(newErrors);
        }
    };

    const onClickSave = () => {
        if(!data.groupId) {
            toastr.warning(t('MESSAGE_DEVICE_SELECT_DEVICE_GROUP_P'));
            return;
        }

        if(data.deviceName === undefined || data.deviceName === '') {
            toastr.warning(t('MESSAGE_DEVICE_INPUT_DEVICE_NAME_P'));
            return;
        }

        let slaveNumber = data.slaveNumber, autoSetID = data.autoSetID, cabinetIP = data.cabinetIP;

        if(hasUhdLedBox) {
            slaveNumber = data.slaveNumber;
            autoSetID = data.autoSetID;
            cabinetIP = data.cabinetIP;
        } else if(hasFhdLedBox) {
            slaveNumber = [data.slaveNumber[0]];
            autoSetID = [data.autoSetID[0]];
            cabinetIP = [data.cabinetIP[0]];
        }

        if(cabinetGroupSettingConnectExisting) {
            autoSetID = undefined;
            cabinetIP = undefined;
        }
        
        onSave({
            ...data,
            deviceType,
            expiredDate: data.neverExpire ? 'INFINITE' : moment(data.expiredDate).format(momentDateFormat),
            slaveNumber,
            autoSetID: autoSetID ? autoSetID.map(set => set ? '1' : '0') : undefined,
            cabinetIP
        });
    };

    const disableOk = () => {
        return !isNil(errors.deviceName) || isNil(data.groupId) || (!isNil(errors.cabinetIP) && !isEmpty(errors.cabinetIP.filter(el => !isNil(el))));
    };

    useEffect(() => {
        if(devices && devices.length === 1) {
            try {
                let cabinetIP;
                if((devices[0].deviceType === 'LEDBOX' || devices[0].deviceType === 'RLEDBOX') && devices[0].ipAddress){
                    cabinetIP = new Array(4).fill((devices[0].ipAddress.substr(0, devices[0].ipAddress.lastIndexOf('.')) + '.0') || '0.0.0.0');
                }
                if(devices[0].preAssignedGroupId >= 0) {
                    setData({...data, deviceName: devices[0].deviceName, cabinetIP, groupId: devices[0].preAssignedGroupId, groupName: devices[0].preAssignedGroupName});
                } else {
                    setData({...data, deviceName: devices[0].deviceName, cabinetIP});
                }                
            } catch(error){
                console.error(error);
            };
        }
    }, []);

    let height = 150;
    if(hasSignage) {
        height = 250;
    } else if(hasFhdLedBox || hasUhdLedBox) {
        height = 250;
        if(hasUhdLedBox) {
            height = 480;
        }
    }

    const selDeviceIpAddr = devices[devices.length - 1].ipAddress;

    return (
        <div className='device_approval_popup_wrap'>
        <MISDialog
            dialog={{
                title: t('TEXT_TITLE_DEVICE_APPROVAL_P'),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 530,
                height: 'auto',
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'BUTTON_OK_P',
                        title: t('BUTTON_OK_P'),
                        onClick: onClickSave,
                        disable: disableOk(),
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
                <div className='device_approval_body_wrap'>
                <table>
                    <colgroup>
                        <col width="140px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                            {
                                ((hasFhdLedBox || hasUhdLedBox) && supportCabinetSetting) &&
                                <>
                                    <tr>
                                        <th style={{fontWeight:'bold'}}>{t('MIS_SID_S_BOX_INFORMATION')}</th>
                                        <td className="background_bar"></td>
                                    </tr>
                                    <tr>
                                        <th>{t('COM_SID_IP_ADDRESS')}</th>
                                        <td>{selDeviceIpAddr}</td>
                                    </tr>
                                </>
                            }
                        <tr>
                            <th>{t('TABLE_DEVICE_NAME_P')}</th>
                            <td>
                                <TextInput width={140} maxLength={50} placeholder={t('TABLE_DEVICE_NAME_P')} value={data.deviceName} onChange={onChangeName} error={errors.deviceName} >
                                    { devices.length === 1 ? null : `_(${t('TEXT_SEQUENTIAL_NUMBER_P')})` }
                                </TextInput>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_DEVICE_GROUP_P')}</th>
                            <td>
                                <input type="text" style={{width: "207px"}} placeholder={t('TEXT_TITLE_SEL_DEVICE_GROUP_P')} value={data.groupName} className="float_l mr8" readOnly />
                                <SearchButton onClick={onClickGroup} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TABLE_LOCATION_P')}</th>
                            <td>
                                <TextInput width={260} maxLength={150} placeholder={t('TABLE_LOCATION_P')} value={data.location} onChange={onChangeLocation} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_EXPIRED_P')}</th>
                            <td>
                                <DateInput width={120} dateFormat={dateFormat} date={dateToString(data.expiredDate)} minDate={today} disabled={data.neverExpire} className='device_date_picker' onChange={onChangeExpireDate} />
                                <Checkbox id='approvalNeverExpire' name={t('MIS_SID_20_NEVER_EXPIRE')} checked={data.neverExpire} onChange={onChangeNeverExpire} />
                            </td>
                        </tr>
                        {
                            ((hasFhdLedBox || hasUhdLedBox) && supportCabinetSetting) &&
                            <>
                                <tr>
                                    <th style={{fontWeight:'bold'}}>{t('MIS_SID_CABINET_INFORMATION')}</th>
                                    <td className="background_bar"></td>
                                </tr>
                                <tr>
                                    <th>{t('COM_LFD_SID_CABINET_SETTINGS')}</th>
                                    <td>
                                        <Checkbox id='cabinetGroupSettingConnectExisting' name={t('COM_SID_SIGNAGE_CONNECT_WITH_EXISTING_SETTINGS')} checked={cabinetGroupSettingConnectExisting} onChange={e => setCabinetGroupSettingConnectExisting(e.target.checked)} />
                                        {/* <input type="checkbox" id="cabinetGroupSettingConnectExisting" ><label for="cabinetGroupSettingConnectExisting">' + $.i18n.prop('COM_SID_SIGNAGE_CONNECT_WITH_EXISTING_SETTINGS') + '</label> */}
                                    </td>
                                </tr>
                            </>
                        }
                        {
                            hasSignage &&
                            <tr>
                                <th>{t('MIS_SID_NUMBER_SLAVE_DEVICE')}</th>
                                <td>
                                    <NumberInput width={100} value={data.slaveNumber[0]} min={0} max={16} onChange={(e, value) => setData({...data, slaveNumber: [value]})} forceInt />
                                    {/* <div className="input_number float_l mr8"><input id="slaveNumber" type="text" className="" style="width:50px" value="0"><button className="number_up" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber\').val(),10)+1; $(\'#slaveNumber\').val(newVal>16?16:newVal);}"></button><button className="number_down" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber\').val(),10)-1; $(\'#slaveNumber\').val(newVal<0?0:newVal);}"></button></div> */}
                                </td>
                            </tr>
                        }
                        {
                            !hasSignage && (hasFhdLedBox || hasUhdLedBox) &&
                            <>
                                {/* <tr>
                                    <th colSpan="2" style={{height:10}}></th>
                                </tr> */}
                                <LedBoxApproval data={data} errors={errors} index={0} cabinetGroupSettingConnectExisting={cabinetGroupSettingConnectExisting} supportCabinetSetting={supportCabinetSetting} onChangeSlaveNumber={onChangeSlaveNumber} 
                                    onChangeAutoSetID={onChangeAutoSetID} onChangeCabinetIP={onChangeCabinetIP} />
                                {
                                    hasUhdLedBox &&
                                    <>
                                        <LedBoxApproval data={data} errors={errors} index={1} cabinetGroupSettingConnectExisting={cabinetGroupSettingConnectExisting} supportCabinetSetting={supportCabinetSetting} 
                                            onChangeSlaveNumber={onChangeSlaveNumber} onChangeAutoSetID={onChangeAutoSetID} onChangeCabinetIP={onChangeCabinetIP} />
                                        <LedBoxApproval data={data} errors={errors} index={2} cabinetGroupSettingConnectExisting={cabinetGroupSettingConnectExisting} supportCabinetSetting={supportCabinetSetting} 
                                            onChangeSlaveNumber={onChangeSlaveNumber} onChangeAutoSetID={onChangeAutoSetID} onChangeCabinetIP={onChangeCabinetIP} />
                                        <LedBoxApproval data={data} errors={errors} index={3} cabinetGroupSettingConnectExisting={cabinetGroupSettingConnectExisting} supportCabinetSetting={supportCabinetSetting} 
                                            onChangeSlaveNumber={onChangeSlaveNumber} onChangeAutoSetID={onChangeAutoSetID} onChangeCabinetIP={onChangeCabinetIP} />
                                    </>
                                }
                            </>
                        }
                        {
                            isE2E && e2eLicenseSystem == settingConstants.E2E_LICENSE_SYSTEM_PBP &&
                            <tr>
                                <th>Select Company</th>
                                <td>
                                    <input type="hidden" id="accountCode" value={data.accountCode} />
                                    <input type="hidden" id="modelCd" value={data.modelCd} />

                                    <input type="text" style={{width:207}}  placeholder="Select Company" className="float_l mr8" value={data.brandNm+data.accountNm}/>
                                    <SearchButton onClick={onClickCompany} />
                                </td>
                            </tr>
                        }
                        {
                            isE2E && e2eLicenseSystem==settingConstants.E2E_LICENSE_SYSTEM_SLM &&
                            <tr>
                            <th>Select Company</th>
                            <td>
                                <input type="hidden" id="soldToCodeId" value={data.soldToCodeId} />
                                <input type="hidden" id="modelCd" value={data.modelCd} />
                                <input type="hidden" id="secorgId" value={data.secorgId} />
                                <input type="text" style={{width:207}} id="soldToCodeTreeBtn" placeholder="Select Company" className="float_l mr8" value={data.accountNm}/>

                                <SearchButton onClick={onClickSoldToCode} />
                            </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </MISDialog>
        </div>
    );
};

export default DeviceApprovalPopup;