import React from 'react';
import { withTranslation } from 'react-i18next';
import MISDialog from '../MISDialog';
import TextInput from '../../input/TextInput';
import {connect} from "react-redux";
import {popupAction} from "../../../actions";
import './playlist.css';
import {isNil, isUndefined} from "lodash";
import {commonConstants} from "../../../constants";
import RadioGroup from "../../radio/RadioGroup";
import {MISOptContext} from "../../misopt";
import {toastr} from "../../../helper/toastrIntercept";
import Moment from "moment";

const onOffSharedOtion = [
    {value:"1", title:'TEXT_ON_P'},
    {value:"0", title:'BUTTON_OFF'}
];

class PlaylistCopyPopup extends React.Component {

    static contextType = MISOptContext;

    constructor(props) {
        super(props);
        const {t} = this.props;

        this.state = {
            groupName : this.props.param.groupName || '',
            groupId : this.props.param.groupId || '0',
            playlistName : "[New Playlist] " + Moment().format('YYYY-MM-DD HH:mm:ss'),
            shareFlag : "1",
            metaData : ''
        }
    }

    getAuthority(id) {
        const {authority}  = this.context;
        let auth = {};
        if (authority !== undefined && id !== undefined) {
            auth = authority[id];
        }
        return auth !== undefined ? auth : {};
    }

    onPlaylistName = (val) => {
        this.setState(  {
            playlistName : val
        });
    };

    onChangeRadio = (val) => {
        this.setState(  {
            shareFlag : val
        });
    };

    onMetaData = (val) => {
        this.setState(  {
            metaData : val

        });
    };

    onClickGroup = () =>{
        const {addPopup, closePopup, t} = this.props;
        addPopup({
            type: commonConstants.COMMON_DETAIL_VIEW,
            viewType: 'PLAYLIST_GROUP_POPUP',  //PLAYLIST_GROUP_POPUP
            id: 'PLAYLIST_GROUP_POPUP',
            title: t("MIS_SID_SELECT_FOLDER"),
            onSave: this.handleGroup,
            onClose: () => closePopup('PLAYLIST_GROUP_POPUP')
        });
    }

    handleGroup = (param) => {
        if(!param) return null;
        const {closePopup} = this.props;
        const groupId = param[0].groupId;
        const groupName = param[0].groupName;
        closePopup('PLAYLIST_GROUP_POPUP');

        if(isNil(groupId)){
            return null;
        };

        this.setState({
            groupId : groupId,
            groupName : groupName
        })
    };

    handleSave = ({playlistId, groupId, playlistName, shareFlag, metaData}) => {
        const {onSave, t} = this.props;

        if (isNil(playlistName) || isUndefined(playlistName) || playlistName === '') {
            toastr.error(t("MESSAGE_CONTENT_ENTER_PLAYLIST_NAME_P"));
            return false;
        }

        let name = playlistName.trim();
        if(name.length === 0 ){
            toastr.error(t("MESSAGE_COMMON_CANT_NAME_SPACE_P"));
            return false;
        }
        onSave({playlistId, groupId, playlistName : name, shareFlag, metaData})
    }

    render () {

        const {t, onClose, onSave   } = this.props;
        const playlistId = this.props.param.playlistId;
        const {groupId, groupName, playlistName, shareFlag, metaData } = this.state;
        const authority = this.getAuthority('CONTENT_PLAYLIST_RULESET');

        return (
            <div className='playlist_copy_popup'>
                <MISDialog
                    dialog={{
                        title: t('BUTTON_SAVE_AS_P'),
                        isDraggable: false,
                        closeOnEscape: true,
                        modal: true,
                        width: 350,
                        height: 215,
                        position: {x: -150, y: -200},
                        onClose: onClose,
                    }}
                    buttons={{
                        leftButtons: [
                        ],
                        rightButtons: [
                            {
                                id: 'PLAYLIST_SAVE',
                                title: t("COM_TEXT_CREATE_P"),
                                onClick: () => this.handleSave({playlistId, groupId, playlistName, shareFlag, metaData}) ,
                                authority : authority.CREATE || authority.MANAGE,
                            },
                            {
                                id: 'PLAYLIST_CANCEL',
                                title: t("BUTTON_CANCEL_P"),
                                onClick: onClose,
                            }
                        ],
                    }}>
                    <div className="detail_view mini_p mt14 mb9">
                        <table>
                            <colgroup>
                                <col style={{width: "151px"}} />
                                <col />
                            </colgroup>
                            <tbody>
                            <tr className="bullet_line">
                                <th>{t("TABLE_PLAYLIST_NAME_P")}</th>
                                <td>
                                    <TextInput width={222} errorWidth={530} name='playlistName' value={playlistName} maxLength={60}
                                               onChange={e => this.onPlaylistName(e.target.value)}   />
                                </td>
                            </tr>
                            <tr className="bullet_line">
                                <th>{t("TEXT_GROUP_P")}</th>
                                <td>
                                    <div className="menu_select_box">
                                    <button className="base un_radius select" style={{width: 222}} id="playlistGroupBtn" onClick={this.onClickGroup}>
                                        <span className="playlist_copy_title_nowrap" style={{width: 205}}>{groupName}</span>
                                    </button>
                                    </div>
                                </td>
                            </tr>
                            <tr className="bullet_line">
                                <th>{t("TEXT_YES_SHARE_P")}</th>
                                <td>
                                    <RadioGroup
                                        propertyName="playEvenly"
                                        selects={onOffSharedOtion}
                                        value={shareFlag}
                                        onChange={(e) => this.onChangeRadio(e.target.getAttribute('data-value'))}
                                    />
                                </td>
                            </tr>
                            <tr className="bullet_line">
                                <th>{t("TEXT_DESCRIPTION_P")}</th>
                                <td>
                                    <div className="va_t">
                                        <textarea
                                            maxLength={200}
                                            value={metaData}
                                            onChange={e => this.onMetaData(e.target.value)}></textarea>

                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </MISDialog>
            </div>
        );
    }
};

export default connect(
    state => ({

    }),
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
    })
)(withTranslation()(PlaylistCopyPopup));

