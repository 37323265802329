import React from 'react';
import {useTranslation} from "react-i18next";

const FileTypeFilterStatus = ({selectedContents, mediaTypes}) => {

    const {t} = useTranslation();
    const mediaTypesLabels = [
        {label:"DID_LAYOUTS_COMMON_LFD", value:"LFD"},
        {label:"TEXT_IMAGE_P", value:"IMAGE"},
        {label:"TEXT_TITLE_MOVIE_P", value:"MOVIE"},
        {label:"TEXT_TITLE_OFFICE_P", value:"OFFICE"},
        {label:"DID_LAYOUTS_COMMON_PDF", value:"PDF"},
        {label:"TEXT_TITLE_FLASH_P", value:"FLASH"},
        {label:"TEXT_TITLE_SOUND_P", value:"SOUND"},
        {label:"DLK", value:"DLK"},
        {label:"LFT", value:"LFT"},
        {label:"DID_CMS_FTP", value:"FTP"},
        {label:"CIFS", value:"CIFS"},
        {label:"TEXT_IS_STREAMING_P", value:"STRM"},
        {label:"VWL", value:"VWL"},
        {label:"HTML", value:"HTML"},
        {label:"COM_LIST_TITLE_URL", value:"URL"},
        {label:"SAPP", value:"SAPP"}];

    const getContentLabelByValue = value => {
        let types = mediaTypesLabels.find(item => item.value === value)
        return types ? types.label : '';
    }

    return (
        <span id="filter_box_content_type">

        {
            selectedContents.length != 0 &&
            <>
                {
                    selectedContents && ((selectedContents.length === mediaTypes.length) || selectedContents[0] === 'ALL')
                        ?
                        <span className="filter_status_content_type_filter">
                            <span>{t("TEXT_ALL_P")}</span>
                        </span>
                        :
                        selectedContents.map(content => (
                            <span className="filter_status_content_type_filter">
                                <span>{t(getContentLabelByValue(content))}</span>
                            </span>
                        ))
                }
            </>
        }

        </span>
    )
}
export default FileTypeFilterStatus;