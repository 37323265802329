import React, {Component} from 'react';
import './DashboardPanel.css';
import {arrayMove, SortableContainer, SortableElement} from "react-sortable-hoc";
import MyInfoDashboard from "./MySummary";
import NoticeDashboard from "./Notice";
import ScheduleSummary from "./ScheduleSummary";
import PlaylistSummary from "./PlaylistSummary";
import ContentSummary from "./ContentSummary";
import UserSummary from "./UserSummary";
import DeviceSummary from "./DeviceSummary";
import DeviceFaultRealTimeChart from "./DeviceFaultRealTimeChart";
import StorageSummary from "./StorageSummary";
import DeviceConnectionHistory from "./DeviceConnectionHistory";
import DeviceConnectionRealTimeChart from "./DeviceConnectionRealTimeChart";
import EdgeServerSummary from "./EdgeServerSummary";
import RMServerSummary from "./RMServerSummary";
import DatalinkServerSummary from "./DatalinkServerSummary";
import SoftwareUpdateSummary from  "./SoftwareUpdateSummary";

const SortableItem = SortableElement(({id, isEditMode, onRemoveLayoutClickEvent}) => {
    switch (id) {
        case 1:
            return <MyInfoDashboard id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 2:
            return <NoticeDashboard id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 3:
            return <StorageSummary id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 4:
            return <ContentSummary id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 5:
            return <PlaylistSummary id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 6:
            return <ScheduleSummary id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 7:
            return <DeviceSummary id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 8:
            return <UserSummary id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 9:
            return <EdgeServerSummary id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 10:
            return <RMServerSummary id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 11:
            return <DatalinkServerSummary id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 12:
            return <DeviceConnectionRealTimeChart id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 13:
            return <DeviceConnectionHistory id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 14:
            return <DeviceFaultRealTimeChart id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        case 15:
            return <SoftwareUpdateSummary id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
        default:
            return <NoticeDashboard id={id} isEditMode={isEditMode} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>;
    }
});

const SortableList = SortableContainer(({dashboards, isEditMode, onRemoveLayoutClickEvent}) => {
    return (
        <div className='dashboard-panel'>
            {
                dashboards.map((dashboard, index) => (
                    <SortableItem key={dashboard.dashboardId} index={index} disabled={!isEditMode}
                                  id={dashboard.dashboardId}
                                  isEditMode={isEditMode}
                                  onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>
                ))
            }
        </div>
    );
});

export default class DashboardPanel extends Component {
    state = {
        dashboards: [],
    };

    constructor(props) {
        super(props);
        this.renderDashboardPanel = this.renderDashboardPanel.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const {dashboards} = props;
        return {
            dashboards: dashboards ? dashboards : [],
        };
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        if(this.props.onSortChangeEvent) {
            this.props.onSortChangeEvent(arrayMove(this.state.dashboards, oldIndex, newIndex));
        }
    };

    render() {
        return (
            <div className='dashboard'>
                {this.renderDashboardPanel()}
            </div>
        );
    }

    renderDashboardPanel() {
        return (
            <SortableList
                axis="xy"
                helperClass='dashboard-widget-drag-mode'
                onSortEnd={this.onSortEnd}
                useDragHandle={this.props.isEditMode}
                dashboards={this.state.dashboards}
                isEditMode={this.props.isEditMode}
                onRemoveLayoutClickEvent={this.props.onRemoveLayoutClickEvent}/>
        );
    }
}
