import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactTable from "react-table";
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {snakeCase} from 'lodash';
import fileDownload from 'js-file-download';
import {commonConstants, DEVICE_PAGE_SIZE_OPTIONS} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {deviceService} from '../../services';
import {deviceAction, popupAction} from "../../actions";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import Pagination from '../../components/table/Pagination';
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import DeviceRelativeTimeCell from '../../components/device/DeviceRelativeTimeCell';
import {useTrGroupProps} from '../../helper/tables';
import {updateCache} from "../../helper/cache/tableCache";
import MagicInfoTable from "../../components/table/MagicInfoTable";

const heightOffset = 204;
const defaultSort = {id: 'createDate', desc: true};

const SoftwareRegistered = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const downloading = useSelector(({device}) => device.downloading);
    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;

        setData({...data, loading: true});

        deviceService.fetchDeviceRegisteredSoftware({
            startIndex: page * pageSize + 1,
            pageSize,
            softwareType: 'APPLICATION',
            searchText: keyword,
            sortColumn: snakeCase(id),
            sortOrder: desc ? 'desc' : 'asc',
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount});
            updateCache('DEVICE', {items: res.items, filter: filter, totalCount: res.totalCount}, props.currContent);
        });
    };

    const onKeywordChange = value => {
        setFilter({...filter, keyword: value, page: 0, sorted: [defaultSort]});
    };

    const onRegister = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.REGISTER_SOFTWARE,
            type: commonConstants.REGISTER_SOFTWARE,
            onOk: () => {
                dispatch(popupAction.closePopup(commonConstants.REGISTER_SOFTWARE));
                fetchData();
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.REGISTER_SOFTWARE))
        }))
    };

    const onEdit = () => {
        if(selected.current.length === 0) {
            return;
        }

        const softwareId = items[selected.current[0]].softwareId;
        dispatch(popupAction.addPopup({
            id: commonConstants.EDIT_SOFTWARE + softwareId,
            type: commonConstants.EDIT_SOFTWARE,
            softwareId,
            onSave: () => {
                dispatch(popupAction.closePopup(commonConstants.EDIT_SOFTWARE + softwareId));
                fetchData();
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.EDIT_SOFTWARE + softwareId))
        }));
    };

    const onDelete = () => {
        const softwareIds = selected.current.map(s => items[s].softwareId);

        if(selected.current.length === 0) {
            return;
        }

        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t('TEXT_TITLE_DELETE_SOFTWARE_P'),
            message: t('COM_TV_SID_DO_YOU_WANT_TO_DELETE'),
            onClickYes: () => {
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                dispatch(popupAction.addPopup({id: commonConstants.LOADING_POPUP, type: commonConstants.LOADING_POPUP}));
                deviceService.deleteSoftware(softwareIds).then(res => {
                    if(res.status === 'Success') {
                        toastr.success(t('ALERT_SUCCESS_DELETE'));
                        fetchData();
                    }
                }).catch(error => console.log(error))
                .finally(() => dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)));
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
        }))
    };

    const onPublish = () => {
        if(selected.current.length === 0) {
            return;
        }

        const {softwareId} = items[selected.current[0]];
        dispatch(popupAction.addPopup({
            id: commonConstants.PUBLISH_SOFTWARE,
            type: commonConstants.PUBLISH_SOFTWARE,
            softwareId,
            onPublish: () => {
                dispatch(popupAction.closePopup(commonConstants.PUBLISH_SOFTWARE));
                fetchData();
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.PUBLISH_SOFTWARE))
        }))
    };

    const onDownload = () => {
        if(selected.current.length === 0) {
            return;
        }

        const {filePath} = items[selected.current[0]];
        const controller = new AbortController();
        const signal = controller.signal;

        dispatch(deviceAction.softwareDownloading(true));
        dispatch(popupAction.addPopup({
            id: commonConstants.LOADING_POPUP,
            type: commonConstants.LOADING_POPUP,
            modal:false,
            title: t('MSG_DOWNLOAD'),
            isDraggable: true,
            hasCloseIcon: true,
            onCloseBtn: () => {
                controller.abort();
                dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                dispatch(deviceAction.softwareDownloading(false));
            }
        }));
        deviceService.downloadSoftware(filePath, signal).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(error => console.log(error))
        .finally(() => {
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
            dispatch(deviceAction.softwareDownloading(false));
        });
    };

    const onExport = exportType => {
        const {keyword, sorted: [{id, desc}]} = filter;
        exportType = 'EXCEL';
        deviceService.exportSoftware({
            exportType,
            softwareType: 'APPLICATION',
            searchText: keyword,
            sortColumn: snakeCase(id),
            sortOrder: desc ? 'desc' : 'asc',
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(error => console.log(error));
    };

    const updateDimensions = () => {
        let height = window.innerHeight - heightOffset;
        setStyle({
            height: height
        })
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const searchPlaceHolder = t('COM_TEXT_SOFTWARE_NAME_P');

    const columns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllDevice_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={'AllDevice_'+row.index}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs} 
                    />
                );
            },
        },
        {
            Header: t('TABLE_DEVICE_MODEL_NAME_P'),
            accessor: 'deviceModelName',
            width: 300,
        },
        {
            Header: t('COM_TEXT_SOFTWARE_NAME_P'),
            accessor: 'softwareName',
            width: 400,
        },
        {
            Header: t('TABLE_VERSION_P'),
            accessor: 'softwareVersion',
            width: 300,
        },
        {
            Header: t('TEXT_LAST_MODIFIED_P'),
            accessor: 'createDate',
            Cell: data => <DeviceRelativeTimeCell value={data.value} />
        }
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'device_tr_group');

    return (
        <div style={{width: '100%',display: props.currContent === 'SOFTWARE_REGISTER_PUBLISH' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id='REGISTER_SOFTWARE' name={t('BUTTON_REGISTER_P')} onClick={onRegister} />
                    <WhiteButton id='EDIT_SOFTWARE' name={t('COM_BUTTON_EDIT')} disable={selectedCnt !== 1} onClick={onEdit} />
                    <WhiteButton id='DELETE_SOFTWARE' name={t('COM_BUTTON_DELETE')} disable={selectedCnt === 0} onClick={onDelete} />
                    <WhiteButton id='PUBLISH_SOFTWARE' name={t('MIS_SID_PUBLISH')} disable={selectedCnt !== 1} onClick={onPublish} />
                    <WhiteButton id='DOWNLOAD_SOFTWARE' name={t('BUTTON_DOWNLOAD_P')} disable={selectedCnt !== 1 || downloading} onClick={onDownload} />
                    <WhiteButton id={"DEVICE_EXPORT"} name={t('BUTTON_EXPORT_P')} onClick={onExport} style={{borderColor: '#5e5e5e'}} />
                </div>
                <div className="rightButton">
                    <SearchBar id="deviceSearch" placeholder={searchPlaceHolder} onClickSearch={onKeywordChange} keyword={filter.keyword} />
                </div>
            </div>
            <div className='device_list_view_wrap'>
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns= {columns}
                    showPagination={false}
                    className="-striped -highlight"
                    style={style}
                    manual
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} divide={props.divide} />
            </div>
        </div>
    );
};

export default SoftwareRegistered;